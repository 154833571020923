import { useState, useEffect } from 'react';

export function useLetterAnimation(word: string, delay: number = 200) {
  const [visibleLetters, setVisibleLetters] = useState<number[]>([]);

  useEffect(() => {
    setVisibleLetters([]); // Reset on word change
    const letters = word.split('').map((_, i) => i);
    
    letters.forEach((_, index) => {
      setTimeout(() => {
        setVisibleLetters(prev => [...prev, index]);
      }, index * delay);
    });

    return () => {
      setVisibleLetters([]);
    };
  }, [word, delay]);

  return visibleLetters;
}