import React, { useState } from 'react';
import { Heart, MessageCircle, Share2, Gift, BadgeCheck } from 'lucide-react';
import { Comments } from '../Comments';
import { ShareButton } from '../../shared/ShareButton';
import { RewardModal } from './RewardModal';

interface ContentInteractionsProps {
  contentId: string;
  contentTitle: string;
  creatorName: string;
  likes: number;
  comments: number;
  isLiked?: boolean;
  isVerified?: boolean;
  onLike?: () => void;
  onComment?: (content: string) => void;
  onReward?: (amount: number) => void;
}

export function ContentInteractions({
  contentId,
  contentTitle,
  creatorName,
  likes,
  comments,
  isLiked = false,
  isVerified = false,
  onLike,
  onComment,
  onReward
}: ContentInteractionsProps) {
  const [showComments, setShowComments] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [localLikes, setLocalLikes] = useState(likes);
  const [localIsLiked, setLocalIsLiked] = useState(isLiked);

  const handleLike = () => {
    setLocalIsLiked(!localIsLiked);
    setLocalLikes(prev => localIsLiked ? prev - 1 : prev + 1);
    onLike?.();
  };

  const handleReward = (amount: number) => {
    onReward?.(amount);
    setShowRewardModal(false);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-6">
          <button 
            onClick={handleLike}
            className={`flex items-center gap-1 ${localIsLiked ? 'text-red-500' : 'text-white'}`}
          >
            <Heart size={20} fill={localIsLiked ? 'currentColor' : 'none'} />
            <span>{localLikes}</span>
          </button>
          
          <button 
            onClick={() => setShowComments(!showComments)}
            className="flex items-center gap-1 text-white"
          >
            <MessageCircle size={20} />
            <span>{comments}</span>
          </button>

          <ShareButton />

          {isVerified && (
            <button 
              onClick={() => setShowRewardModal(true)}
              className="flex items-center gap-1 text-yellow-400"
            >
              <Gift size={20} />
              <span>Récompenser</span>
            </button>
          )}
        </div>
      </div>

      {showComments && (
        <Comments
          postId={contentId}
          comments={[]}
          onAddComment={onComment}
        />
      )}

      {showRewardModal && (
        <RewardModal
          creatorName={creatorName}
          contentTitle={contentTitle}
          onReward={handleReward}
          onClose={() => setShowRewardModal(false)}
        />
      )}
    </div>
  );
}