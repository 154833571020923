import React, { useState, useRef } from 'react';
import { 
  Image as ImageIcon, 
  Video, 
  Send, 
  MapPin, 
  Hash, 
  Globe,
  X,
  Edit3,
  Languages,
  AlertTriangle,
  Loader,
  Camera,
  Radio,
  Music,
  Smile,
  MessageCircle
} from 'lucide-react';
import { LiveBroadcastControls } from './LiveBroadcastControls';
import { MusicUpload } from './MusicUpload';
import { LocationPicker } from './LocationPicker';
import { EmojiPicker } from './EmojiPicker';
import { HashtagPicker } from './HashtagPicker';
import { moderateContent } from '../../utils/contentModeration';
import { ModerationAlert } from './ModerationAlert';
import type { Post } from '../../types/social';

interface CreatePostProps {
  onPost: (post: Post) => void;
}

export function CreatePost({ onPost }: CreatePostProps) {
  const [isCreating, setIsCreating] = useState(false);
  const [isLiveStreaming, setIsLiveStreaming] = useState(false);
  const [isMusicUploading, setIsMusicUploading] = useState(false);
  const [content, setContent] = useState('');
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showLocationPicker, setShowLocationPicker] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showHashtagPicker, setShowHashtagPicker] = useState(false);
  const [location, setLocation] = useState('');
  const [hashtags, setHashtags] = useState<string[]>([]);
  const [showModerationAlert, setShowModerationAlert] = useState(false);
  const [detectedWords, setDetectedWords] = useState<string[]>([]);
  const [moderatedContent, setModeratedContent] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handlePost = async () => {
    if (!content.trim() && mediaFiles.length === 0) return;

    setIsProcessing(true);

    try {
      // Content moderation
      const moderation = await moderateContent(content);
      
      if (!moderation.isClean) {
        setDetectedWords(moderation.detectedWords);
        setModeratedContent(moderation.moderatedText);
        setShowModerationAlert(true);
        setIsProcessing(false);
        return;
      }

      // Create post with moderated content
      const post: Post = {
        id: Date.now().toString(),
        userId: '1',
        username: 'Romain Lopez',
        userAvatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
        content: moderation.moderatedText,
        mediaUrl: mediaFiles.length > 0 ? URL.createObjectURL(mediaFiles[0]) : undefined,
        mediaType: mediaFiles.length > 0 ? (mediaFiles[0].type.startsWith('image/') ? 'image' : 'video') : undefined,
        likes: 0,
        comments: 0,
        shares: 0,
        createdAt: new Date().toISOString(),
        isLiked: false,
        isVerified: true,
        communityImpact: {
          cashback: 0,
          communityFund: 150,
          totalContribution: 150
        }
      };

      onPost(post);
      setContent('');
      setMediaFiles([]);
      setLocation('');
      setHashtags([]);
      setIsCreating(false);
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleModerationConfirm = () => {
    const post: Post = {
      id: Date.now().toString(),
      userId: '1',
      username: 'Romain Lopez',
      userAvatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
      content: moderatedContent,
      mediaUrl: mediaFiles.length > 0 ? URL.createObjectURL(mediaFiles[0]) : undefined,
      mediaType: mediaFiles.length > 0 ? (mediaFiles[0].type.startsWith('image/') ? 'image' : 'video') : undefined,
      likes: 0,
      comments: 0,
      shares: 0,
      createdAt: new Date().toISOString(),
      isLiked: false,
      isVerified: true,
      communityImpact: {
        cashback: 0,
        communityFund: 150,
        totalContribution: 150
      }
    };

    onPost(post);
    setContent('');
    setMediaFiles([]);
    setLocation('');
    setHashtags([]);
    setIsCreating(false);
    setShowModerationAlert(false);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setMediaFiles([...mediaFiles, ...files]);
  };

  const handleEmojiSelect = (emoji: string) => {
    setContent(prev => prev + emoji);
  };

  const handleHashtagSelect = (tag: string) => {
    setHashtags(prev => [...prev, tag]);
    setContent(prev => `${prev} #${tag}`);
  };

  const handleLocationSelect = (place: string) => {
    setLocation(place);
    setContent(prev => `${prev} 📍 ${place}`);
  };

  if (isLiveStreaming) {
    return (
      <LiveBroadcastControls
        onClose={() => setIsLiveStreaming(false)}
        onEnd={() => setIsLiveStreaming(false)}
      />
    );
  }

  if (isMusicUploading) {
    return (
      <MusicUpload
        onClose={() => setIsMusicUploading(false)}
        onSubmit={(musicData) => {
          console.log('Music uploaded:', musicData);
          setIsMusicUploading(false);
        }}
      />
    );
  }

  if (!isCreating) {
    return (
      <div className="grid grid-cols-4 gap-2 sm:gap-4">
        <button
          onClick={() => setIsCreating(true)}
          className="bg-purple-900 p-3 sm:p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800 transition-colors"
        >
          <Edit3 className="text-yellow-400" size={20} />
          <span className="text-white text-xs sm:text-sm truncate">Statut</span>
        </button>
        <button
          onClick={() => {
            fileInputRef.current?.click();
            setIsCreating(true);
          }}
          className="bg-purple-900 p-3 sm:p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800 transition-colors"
        >
          <Camera className="text-yellow-400" size={20} />
          <span className="text-white text-xs sm:text-sm truncate">Photo/Vidéo</span>
        </button>
        <button
          onClick={() => setIsLiveStreaming(true)}
          className="bg-purple-900 p-3 sm:p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800 transition-colors"
        >
          <Radio className="text-yellow-400" size={20} />
          <span className="text-white text-xs sm:text-sm truncate">Live</span>
        </button>
        <button
          onClick={() => setIsMusicUploading(true)}
          className="bg-purple-900 p-3 sm:p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800 transition-colors"
        >
          <Music className="text-yellow-400" size={20} />
          <span className="text-white text-xs sm:text-sm truncate">Musique</span>
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="fixed inset-0 bg-purple-900/95 z-50 flex flex-col">
        <div className="flex justify-between items-center p-4 border-b border-purple-800">
          <h3 className="text-xl font-bold text-white">Créer une publication</h3>
          <button 
            onClick={() => setIsCreating(false)}
            className="text-gray-300 hover:text-white"
          >
            <X size={24} />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          <div className="max-w-lg mx-auto bg-purple-800 rounded-xl p-4">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Partagez quelque chose avec votre communauté..."
              className="w-full bg-purple-700 text-white rounded-xl p-4 min-h-[150px] mb-4 resize-none focus:ring-2 focus:ring-yellow-400"
            />

            {mediaFiles.length > 0 && (
              <div className="grid grid-cols-2 gap-2 mb-4">
                {mediaFiles.map((file, index) => (
                  <div key={index} className="relative">
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      className="w-full h-32 object-cover rounded-lg"
                    />
                    <button
                      onClick={() => setMediaFiles(mediaFiles.filter((_, i) => i !== index))}
                      className="absolute top-2 right-2 bg-red-500 rounded-full p-1"
                    >
                      <X size={16} className="text-white" />
                    </button>
                  </div>
                ))}
              </div>
            )}

            <div className="flex justify-between items-center">
              <div className="flex gap-4">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  accept="image/*,video/*"
                  className="hidden"
                  multiple
                />
                <button 
                  onClick={() => fileInputRef.current?.click()}
                  className="text-yellow-400 hover:text-yellow-300"
                >
                  <ImageIcon size={24} />
                </button>
                <button 
                  onClick={() => setShowLocationPicker(true)}
                  className="text-yellow-400 hover:text-yellow-300"
                >
                  <MapPin size={24} />
                </button>
                <button 
                  onClick={() => setShowHashtagPicker(true)}
                  className="text-yellow-400 hover:text-yellow-300"
                >
                  <Hash size={24} />
                </button>
                <button 
                  onClick={() => setShowEmojiPicker(true)}
                  className="text-yellow-400 hover:text-yellow-300"
                >
                  <Smile size={24} />
                </button>
              </div>

              <button
                onClick={handlePost}
                disabled={isProcessing || (!content.trim() && mediaFiles.length === 0)}
                className={`bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold flex items-center gap-2 transition-colors ${
                  isProcessing || (!content.trim() && mediaFiles.length === 0)
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-yellow-300'
                }`}
              >
                {isProcessing ? (
                  <>
                    <Loader className="animate-spin" size={20} />
                    Traitement...
                  </>
                ) : (
                  <>
                    <Send size={20} />
                    Publier
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {showLocationPicker && (
        <LocationPicker
          onSelect={handleLocationSelect}
          onClose={() => setShowLocationPicker(false)}
        />
      )}

      {showEmojiPicker && (
        <EmojiPicker
          onSelect={handleEmojiSelect}
          onClose={() => setShowEmojiPicker(false)}
        />
      )}

      {showHashtagPicker && (
        <HashtagPicker
          onSelect={handleHashtagSelect}
          onClose={() => setShowHashtagPicker(false)}
        />
      )}

      {showModerationAlert && (
        <ModerationAlert
          detectedWords={detectedWords}
          onClose={() => {
            setShowModerationAlert(false);
            handleModerationConfirm();
          }}
        />
      )}
    </>
  );
}