import React, { useState, useEffect } from 'react';
import { Plane, Train, Car, MapPin, Star, BadgeCheck, Gift, Globe, Clock, Info, Navigation } from 'lucide-react';

interface TransportModalProps {
  event: {
    title: string;
    location: string;
    date: string;
    price: number;
    cashback: number;
    impact: number;
  };
  onClose: () => void;
  onTransportSelected: (transport: any) => void;
}

export function TransportModal({ event, onClose, onTransportSelected }: TransportModalProps) {
  const [isSearching, setIsSearching] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [transportOptions, setTransportOptions] = useState<any[]>([]);
  const [isAnalyzing, setIsAnalyzing] = useState(true);

  useEffect(() => {
    // Simulate getting user's location
    const getUserLocation = () => {
      setIsAnalyzing(true);
      // Mock location (Paris coordinates)
      setUserLocation({ lat: 48.8566, lng: 2.3522 });
      
      setTimeout(() => {
        setIsAnalyzing(false);
        setIsSearching(false);
        setShowResults(true);
      }, 3000);
    };

    getUserLocation();
  }, []);

  useEffect(() => {
    if (!isAnalyzing && userLocation) {
      // Mock distance calculation and transport recommendations
      const distance = 500; // km
      let recommendedOptions = [];

      if (distance > 800) {
        // Long distance - recommend flight
        recommendedOptions.push({
          id: 'flight1',
          type: 'flight',
          title: 'Air France',
          from: 'Paris CDG',
          to: event.location,
          image: 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?w=800&h=400&fit=crop',
          duration: '1h20',
          rating: 4.7,
          reviews: 892,
          price: 199,
          features: ['Repas inclus', 'Bagage cabine', 'Miles Flying Blue'],
          cashback: 30,
          impact: 30,
          recommended: true,
          recommendationReason: 'Option la plus rapide pour cette distance'
        });
      }

      if (distance > 100 && distance < 1000) {
        // Medium distance - recommend train
        recommendedOptions.push({
          id: 'train1',
          type: 'train',
          title: 'TGV INOUI',
          from: 'Paris Gare de Lyon',
          to: event.location,
          image: 'https://images.unsplash.com/photo-1474487548417-781cb71495f3?w=800&h=400&fit=crop',
          duration: '2h',
          rating: 4.8,
          reviews: 892,
          price: 89,
          features: ['WiFi gratuit', 'Bar TGV', 'Prises électriques'],
          cashback: 13,
          impact: 13,
          recommended: true,
          recommendationReason: 'Option écologique et confortable'
        });
      }

      // Always offer car service as an option
      recommendedOptions.push({
        id: 'car1',
        type: 'car',
        title: 'Mercedes Classe S avec chauffeur',
        from: 'Votre adresse',
        to: 'Lieu de l\'événement',
        image: 'https://images.unsplash.com/photo-1550355291-bbee04a92027?w=800&h=400&fit=crop',
        duration: 'Variable',
        rating: 4.9,
        reviews: 156,
        price: 199,
        features: ['Chauffeur professionnel', 'Confort premium', 'Service personnalisé'],
        cashback: 30,
        impact: 30,
        recommended: distance < 100,
        recommendationReason: distance < 100 ? 'Option idéale pour cette distance' : undefined
      });

      setTransportOptions(recommendedOptions);
    }
  }, [isAnalyzing, userLocation, event.location]);

  const handleBooking = (transport: any) => {
    onTransportSelected(transport);
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-2xl max-h-[90vh] overflow-hidden flex flex-col">
        {/* Header */}
        <div className="p-6 border-b border-purple-800">
          <h2 className="text-xl font-bold text-white mb-2">Options de transport</h2>
          <p className="text-gray-300">{event.title} - {event.location}</p>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-6">
          {isAnalyzing ? (
            <div className="text-center py-12">
              <div className="w-16 h-16 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
              <p className="text-white font-medium">Analyse de votre position...</p>
              <p className="text-gray-300 text-sm mt-2">
                Nous déterminons les meilleures options de transport en fonction de votre localisation
              </p>
            </div>
          ) : isSearching ? (
            <div className="text-center py-12">
              <div className="w-16 h-16 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
              <p className="text-white font-medium">Recherche des options de transport...</p>
              <p className="text-gray-300 text-sm mt-2">
                Nous trouvons les meilleures options pour {event.location}
              </p>
            </div>
          ) : (
            <div className="space-y-6">
              {/* Location Info */}
              <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
                <Navigation className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
                <div>
                  <p className="text-white font-medium">Transport intelligent</p>
                  <p className="text-sm text-gray-300">
                    Recommandations basées sur votre position actuelle et le lieu de l'événement
                  </p>
                </div>
              </div>

              {transportOptions.map(transport => (
                <div key={transport.id} className={`bg-purple-800 rounded-xl overflow-hidden ${
                  transport.recommended ? 'ring-2 ring-yellow-400' : ''
                }`}>
                  <div className="relative">
                    <img src={transport.image} alt={transport.title} className="w-full h-48 object-cover" />
                    <div className="absolute top-4 right-4 bg-purple-900/80 px-3 py-1 rounded-full text-white text-sm flex items-center gap-1">
                      {transport.type === 'train' ? <Train size={14} /> : 
                       transport.type === 'flight' ? <Plane size={14} /> : 
                       <Car size={14} />}
                      {transport.type === 'train' ? 'Train' : 
                       transport.type === 'flight' ? 'Avion' : 
                       'Voiture'}
                    </div>
                    {transport.recommended && (
                      <div className="absolute top-4 left-4 bg-yellow-400 text-purple-900 px-3 py-1 rounded-full text-sm font-semibold">
                        Recommandé
                      </div>
                    )}
                  </div>

                  <div className="p-6">
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="text-xl font-bold text-white">{transport.title}</h3>
                        <div className="flex items-center gap-4 text-gray-300 mt-1">
                          <div className="flex items-center gap-2">
                            <MapPin size={16} />
                            <span>{transport.from} → {transport.to}</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <Clock size={16} />
                            <span>{transport.duration}</span>
                          </div>
                        </div>
                        {transport.recommendationReason && (
                          <p className="text-yellow-400 text-sm mt-2">
                            {transport.recommendationReason}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center gap-1">
                        <Star className="text-yellow-400" size={16} fill="currentColor" />
                        <span className="text-white">{transport.rating}</span>
                        <span className="text-gray-300">({transport.reviews})</span>
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-2 mb-4">
                      {transport.features.map((feature: string, index: number) => (
                        <span
                          key={index}
                          className="bg-purple-700 text-white px-3 py-1 rounded-full text-sm"
                        >
                          {feature}
                        </span>
                      ))}
                    </div>

                    <div className="space-y-2 mb-4">
                      <div className="flex items-center gap-2 text-sm">
                        <Gift className="text-green-400" size={16} />
                        <span className="text-white">{transport.cashback}€ crédités sur votre PLATFFPAY</span>
                      </div>
                      <div className="flex items-center gap-2 text-sm">
                        <Globe className="text-blue-400" size={16} />
                        <span className="text-white">{transport.impact}€ ajoutés au Fonds Communautaire</span>
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-2xl font-bold text-white">{transport.price}€</p>
                        <p className="text-sm text-gray-300">
                          {transport.type === 'train' || transport.type === 'flight' ? 'par personne' : 'par trajet'}
                        </p>
                      </div>
                      <button 
                        onClick={() => handleBooking(transport)}
                        className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
                      >
                        Réserver
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="p-4 border-t border-purple-800">
          <button
            onClick={onClose}
            className="w-full bg-purple-800 text-white py-2 rounded-full hover:bg-purple-700 transition-colors"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
}