import React, { useState } from 'react';
import { CreditCard, Gift, Globe, Lock, ArrowRight, Shield, Info, Wallet, BadgeCheck, Clock } from 'lucide-react';
import { useBalance } from '../../contexts/BalanceContext';

interface PromotionBookingModalProps {
  promotion: {
    id: string;
    title: string;
    price: number;
    originalPrice: number;
    savings: number;
    features: string[];
    validUntil: string;
    type: string;
  };
  onClose: () => void;
  onConfirm: () => void;
}

export function PromotionBookingModal({ promotion, onClose, onConfirm }: PromotionBookingModalProps) {
  const [paymentMethod, setPaymentMethod] = useState<'platffpay' | 'card' | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const { cashbackBalance } = useBalance();

  const cashbackAmount = promotion.price * 0.05;
  const communityImpact = promotion.price * 0.15;

  const handlePayment = async () => {
    if (!paymentMethod) return;
    
    setIsProcessing(true);
    
    try {
      // Simulate payment processing
      await new Promise(resolve => setTimeout(resolve, 2000));
      onConfirm();
    } catch (error) {
      console.error('Payment error:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-md flex flex-col max-h-[90vh]">
        {/* Fixed Header */}
        <div className="p-6 border-b border-purple-800 flex-shrink-0">
          <div className="flex items-center gap-2">
            <CreditCard className="text-yellow-400" size={24} />
            <h2 className="text-xl font-bold text-white">Réserver l'offre exclusive</h2>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {/* Promotion Summary */}
          <div className="bg-purple-800 p-4 rounded-xl">
            <h3 className="text-lg font-bold text-white mb-2">{promotion.title}</h3>
            <div className="flex items-center gap-2 mb-3">
              <Clock size={16} className="text-yellow-400" />
              <span className="text-gray-300 text-sm">
                Valable jusqu'au {new Date(promotion.validUntil).toLocaleDateString()}
              </span>
            </div>
            <div className="space-y-2">
              {promotion.features.map((feature, index) => (
                <div key={index} className="flex items-center gap-2 text-sm text-gray-300">
                  <BadgeCheck size={14} className="text-yellow-400" />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Price Summary */}
          <div className="bg-purple-800 p-4 rounded-xl space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-gray-300">Prix normal</span>
              <span className="text-gray-300 line-through">{promotion.originalPrice}€</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-green-400">Économies</span>
              <span className="text-green-400">-{promotion.savings}€</span>
            </div>
            <div className="flex justify-between items-center text-lg font-bold text-white pt-2 border-t border-purple-700">
              <span>Prix final</span>
              <span>{promotion.price}€</span>
            </div>
          </div>

          {/* Impact Summary */}
          <div className="bg-purple-800 p-4 rounded-xl space-y-2">
            <div className="flex items-center gap-2 text-sm">
              <Gift className="text-green-400" size={16} />
              <span className="text-white">
                {cashbackAmount.toFixed(2)}€ seront crédités sur votre PLATFFPAY
              </span>
            </div>
            <div className="flex items-center gap-2 text-sm">
              <Globe className="text-blue-400" size={16} />
              <span className="text-white">
                {communityImpact.toFixed(2)}€ au Fonds Communautaire
              </span>
            </div>
          </div>

          {/* Payment Methods */}
          <div className="space-y-3">
            <h3 className="text-white font-medium">Mode de paiement</h3>
            
            <button
              onClick={() => setPaymentMethod('platffpay')}
              className={`w-full p-4 rounded-xl flex items-center justify-between transition-colors ${
                paymentMethod === 'platffpay'
                  ? 'bg-yellow-400 text-purple-900'
                  : 'bg-purple-800 text-white hover:bg-purple-700'
              }`}
            >
              <div className="flex items-center gap-3">
                <Wallet size={20} />
                <div className="text-left">
                  <p className="font-medium">Solde PLATFFPAY</p>
                  <p className="text-sm opacity-80">{cashbackBalance.toFixed(2)}€ disponible</p>
                </div>
              </div>
              {paymentMethod === 'platffpay' && <BadgeCheck size={20} />}
            </button>

            <button
              onClick={() => setPaymentMethod('card')}
              className={`w-full p-4 rounded-xl flex items-center justify-between transition-colors ${
                paymentMethod === 'card'
                  ? 'bg-yellow-400 text-purple-900'
                  : 'bg-purple-800 text-white hover:bg-purple-700'
              }`}
            >
              <div className="flex items-center gap-3">
                <CreditCard size={20} />
                <p className="font-medium">Carte bancaire</p>
              </div>
              {paymentMethod === 'card' && <BadgeCheck size={20} />}
            </button>
          </div>

          {/* Info Box */}
          <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
            <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <p className="text-sm text-gray-300">
              Cette offre exclusive est garantie par PLATFF. Le cashback sera instantanément crédité sur votre compte PLATFFPAY.
            </p>
          </div>
        </div>

        {/* Fixed Footer */}
        <div className="p-6 border-t border-purple-800 flex-shrink-0">
          <div className="flex gap-3">
            <button
              onClick={onClose}
              className="flex-1 bg-purple-800 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors"
            >
              Annuler
            </button>
            <button
              onClick={handlePayment}
              disabled={!paymentMethod || isProcessing}
              className={`flex-1 bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2 ${
                !paymentMethod || isProcessing ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isProcessing ? (
                <>
                  <div className="w-5 h-5 border-2 border-purple-900 border-t-transparent rounded-full animate-spin" />
                  Traitement...
                </>
              ) : (
                <>
                  <Lock size={20} />
                  Payer {promotion.price}€
                  <ArrowRight size={20} />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}