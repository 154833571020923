import React, { useState, useEffect } from 'react';
import { Search, Zap, ShoppingBag, TrendingDown } from 'lucide-react';

interface PriceSearchPopupProps {
  onClose: () => void;
}

export function PriceSearchPopup({ onClose }: PriceSearchPopupProps) {
  const [visibleSteps, setVisibleSteps] = useState<number[]>([]);

  useEffect(() => {
    const showStep = (step: number) => {
      setVisibleSteps(prev => [...prev, step]);
    };

    // Show steps sequentially with longer delays
    showStep(1);
    const timer1 = setTimeout(() => showStep(2), 3000); // Increased delay
    const timer2 = setTimeout(() => showStep(3), 5000); // Increased delay

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 animate-fade-in">
      <div className="bg-purple-900 rounded-xl p-6 max-w-md w-full shadow-xl animate-slide-up">
        <div className="flex flex-col items-center text-center space-y-4">
          <div className="relative w-16 h-16 mb-2">
            <div className="absolute inset-0 bg-yellow-400/20 rounded-full animate-ping" />
            <div className="relative bg-yellow-400 rounded-full p-4">
              <Search className="text-purple-900 w-8 h-8" />
            </div>
          </div>

          <h3 className="text-xl font-bold text-white">Analyse des prix en temps réel</h3>
          
          <div className="space-y-4 w-full">
            {visibleSteps.includes(1) && (
              <div className="bg-purple-800 p-3 rounded-lg flex items-center gap-3 animate-fade-in">
                <ShoppingBag className="text-yellow-400" />
                <p className="text-gray-300 text-sm text-left">
                  Notre algorithme analyse les prix des principaux revendeurs nationaux...
                </p>
              </div>
            )}

            {visibleSteps.includes(2) && (
              <div className="bg-purple-800 p-3 rounded-lg flex items-center gap-3 animate-fade-in">
                <TrendingDown className="text-green-400" />
                <p className="text-gray-300 text-sm text-left">
                  Notre logiciel ajuste dynamiquement nos prix pour être le plus compétitif...
                </p>
              </div>
            )}

            {visibleSteps.includes(3) && (
              <div className="bg-purple-800 p-3 rounded-lg flex items-center gap-3 animate-fade-in">
                <Zap className="text-yellow-400" />
                <p className="text-gray-300 text-sm text-left">
                  Calcul automatique de votre cashback et impact communautaire...
                </p>
              </div>
            )}
          </div>

          <div className="w-full pt-4">
            <div className="bg-purple-800 rounded-full h-2 overflow-hidden">
              <div className="bg-yellow-400 h-full animate-progress" style={{ width: '90%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}