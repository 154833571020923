import React, { useState } from 'react';
import { BookingWidget } from '../booking/BookingWidget';
import { Gift, Star, Clock, MapPin, BadgeCheck, TrendingDown, Shield } from 'lucide-react';
import { PromotionBookingModal } from '../booking/PromotionBookingModal';
import { HotelBooking } from '../booking/HotelBooking';
import { FlightBooking } from '../booking/FlightBooking';
import { TrainBooking } from '../booking/TrainBooking';
import { CarBooking } from '../booking/CarBooking';
import { RestaurantBooking } from '../booking/RestaurantBooking';
import { EventBooking } from '../booking/EventBooking';

export function BookingSection() {
  const [searchCriteria, setSearchCriteria] = useState<any>(null);
  const [selectedPromotion, setSelectedPromotion] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleSearch = (criteria: any) => {
    setSearchCriteria(criteria);
    setSelectedCategory(criteria.type);
  };

  const handleBookingConfirm = () => {
    console.log('Booking confirmed for promotion:', selectedPromotion);
    setSelectedPromotion(null);
  };

  const promotions = [
    {
      id: '1',
      title: 'Week-end de luxe à Paris',
      image: 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?w=800&h=400&fit=crop',
      location: 'Paris, France',
      originalPrice: 399,
      price: 299,
      savings: 100,
      rating: 4.9,
      reviews: 128,
      type: 'hotel',
      badge: { text: 'PLATFF Luxe', color: 'yellow' },
      features: ['Petit-déjeuner inclus', 'Spa accès', 'Vue Tour Eiffel'],
      validUntil: '2024-04-30'
    },
    {
      id: '2',
      title: 'Vol Paris - New York Business',
      image: 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?w=800&h=400&fit=crop',
      location: 'CDG → JFK',
      originalPrice: 2999,
      price: 2499,
      savings: 500,
      rating: 4.8,
      reviews: 89,
      type: 'flight',
      badge: { text: 'Air France', color: 'blue' },
      features: ['Classe Business', 'Lounge accès', 'Fast Track'],
      validUntil: '2024-04-15'
    },
    {
      id: '3',
      title: 'TGV INOUI Première Classe',
      image: 'https://images.unsplash.com/photo-1474487548417-781cb71495f3?w=800&h=400&fit=crop',
      location: 'Paris → Lyon',
      originalPrice: 129,
      price: 89,
      savings: 40,
      rating: 4.7,
      reviews: 156,
      type: 'train',
      badge: { text: 'TGV INOUI', color: 'blue' },
      features: ['1ère classe', 'WiFi inclus', 'Repas servi'],
      validUntil: '2024-04-20'
    }
  ];

  const renderSearchResults = () => {
    if (!searchCriteria) return null;

    switch (selectedCategory) {
      case 'hotel':
        return <HotelBooking searchCriteria={searchCriteria} />;
      case 'flight':
        return <FlightBooking searchCriteria={searchCriteria} />;
      case 'train':
        return <TrainBooking searchCriteria={searchCriteria} />;
      case 'car':
        return <CarBooking searchCriteria={searchCriteria} />;
      case 'restaurant':
        return <RestaurantBooking searchCriteria={searchCriteria} />;
      case 'event':
        return <EventBooking />;
      default:
        return null;
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4 space-y-8">
      {/* Search Widget */}
      <BookingWidget onSearch={handleSearch} />

      {/* Search Results */}
      {searchCriteria && (
        <div className="space-y-6">
          {renderSearchResults()}
        </div>
      )}

      {/* Promotions Section */}
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold text-white flex items-center gap-2">
            <Gift className="text-yellow-400" />
            Offres Exclusives
          </h2>
          <div className="flex items-center gap-2 text-gray-300">
            <Shield className="text-yellow-400" size={16} />
            <span>Prix garantis par PLATFF</span>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {promotions.map(promo => (
            <div key={promo.id} className="bg-purple-900 rounded-xl overflow-hidden group hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
              <div className="relative">
                <img 
                  src={promo.image} 
                  alt={promo.title}
                  className="w-full h-48 object-cover group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute top-4 right-4 flex gap-2">
                  <div className={`bg-${promo.badge.color}-400 text-purple-900 px-3 py-1 rounded-full text-sm font-semibold flex items-center gap-1`}>
                    <BadgeCheck size={14} />
                    {promo.badge.text}
                  </div>
                </div>
                <div className="absolute bottom-4 left-4 bg-purple-900/80 backdrop-blur-sm px-3 py-1 rounded-full text-white text-sm flex items-center gap-1">
                  <Clock size={14} />
                  Expire le {new Date(promo.validUntil).toLocaleDateString()}
                </div>
              </div>

              <div className="p-4">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="text-lg font-bold text-white">{promo.title}</h3>
                  <div className="flex items-center gap-1">
                    <Star className="text-yellow-400" size={14} fill="currentColor" />
                    <span className="text-white">{promo.rating}</span>
                    <span className="text-gray-300">({promo.reviews})</span>
                  </div>
                </div>

                <div className="flex items-center gap-2 text-gray-300 mb-3">
                  <MapPin size={14} />
                  <span>{promo.location}</span>
                </div>

                <div className="flex flex-wrap gap-2 mb-4">
                  {promo.features.map((feature, index) => (
                    <span
                      key={index}
                      className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm"
                    >
                      {feature}
                    </span>
                  ))}
                </div>

                <div className="flex items-center justify-between">
                  <div>
                    <div className="flex items-center gap-2">
                      <p className="text-2xl font-bold text-white">{promo.price}€</p>
                      <p className="text-sm text-gray-400 line-through">{promo.originalPrice}€</p>
                    </div>
                    <div className="flex items-center gap-1 text-green-400 text-sm">
                      <TrendingDown size={14} />
                      <span>-{promo.savings}€</span>
                    </div>
                  </div>
                  <button 
                    onClick={() => setSelectedPromotion(promo)}
                    className="bg-yellow-400 text-purple-900 px-4 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
                  >
                    Réserver
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Booking Modal */}
      {selectedPromotion && (
        <PromotionBookingModal
          promotion={selectedPromotion}
          onClose={() => setSelectedPromotion(null)}
          onConfirm={handleBookingConfirm}
        />
      )}
    </div>
  );
}