import React, { useState } from 'react';
import { CreditCard, Gift, Globe, Lock, ArrowRight, Shield, Info, Wallet, BadgeCheck } from 'lucide-react';
import { useBalance } from '../../contexts/BalanceContext';
import type { Product } from '../../types/shop';

interface ProductPurchaseModalProps {
  product: Product;
  onClose: () => void;
  onPurchase: () => void;
}

export function ProductPurchaseModal({ product, onClose, onPurchase }: ProductPurchaseModalProps) {
  const [paymentMethod, setPaymentMethod] = useState<'platffpay' | 'card' | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { cashbackBalance, updateCashbackBalance } = useBalance();

  const handlePayment = async () => {
    if (!paymentMethod) return;
    
    setIsProcessing(true);
    
    try {
      // Simulate payment processing
      await new Promise(resolve => setTimeout(resolve, 2000));

      if (paymentMethod === 'platffpay') {
        // Update PLATFFPAY balance
        updateCashbackBalance(cashbackBalance - product.price);
      }

      setShowConfirmation(true);
    } catch (error) {
      console.error('Payment error:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  if (showConfirmation) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
        <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md animate-slide-up">
          <div className="text-center">
            <div className="w-16 h-16 bg-green-400 rounded-full flex items-center justify-center mx-auto mb-4">
              <Check className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-bold text-white mb-2">Achat confirmé !</h3>
            <p className="text-gray-300 mb-6">
              Votre achat de {product.title} a été confirmé.
              {paymentMethod === 'platffpay' && (
                <span className="block mt-2">
                  {product.price}€ ont été débités de votre compte PLATFFPAY.
                </span>
              )}
            </p>

            <div className="bg-purple-800 p-4 rounded-xl mb-6">
              <div className="flex items-center gap-2 mb-2">
                <Gift className="text-green-400" size={20} />
                <p className="text-white">Récompenses</p>
              </div>
              <div className="space-y-2 text-sm">
                <div className="flex justify-between">
                  <span className="text-gray-300">Cashback PLATFFPAY</span>
                  <span className="text-green-400">+{(product.price * 0.05).toFixed(2)}€</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-300">Impact communautaire</span>
                  <span className="text-blue-400">+{(product.price * 0.15).toFixed(2)}€</span>
                </div>
              </div>
            </div>

            <button
              onClick={onPurchase}
              className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-md">
        {/* Header */}
        <div className="p-6 border-b border-purple-800">
          <div className="flex items-center gap-2">
            <CreditCard className="text-yellow-400" size={24} />
            <h2 className="text-xl font-bold text-white">Finaliser l'achat</h2>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          {/* Product Summary */}
          <div className="flex gap-4">
            <img 
              src={product.images[0]} 
              alt={product.title}
              className="w-20 h-20 rounded-lg object-cover"
            />
            <div>
              <h3 className="text-white font-semibold">{product.title}</h3>
              <p className="text-2xl font-bold text-white">{product.price}€</p>
            </div>
          </div>

          {/* Impact Summary */}
          <div className="bg-purple-800 p-4 rounded-xl space-y-2">
            <div className="flex items-center gap-2 text-sm">
              <Gift className="text-green-400" size={16} />
              <span className="text-white">
                {(product.price * 0.05).toFixed(2)}€ seront crédités sur votre PLATFFPAY
              </span>
            </div>
            <div className="flex items-center gap-2 text-sm">
              <Globe className="text-blue-400" size={16} />
              <span className="text-white">
                {(product.price * 0.15).toFixed(2)}€ au Fonds Communautaire
              </span>
            </div>
          </div>

          {/* Payment Methods */}
          <div className="space-y-3">
            <h3 className="text-white font-medium">Mode de paiement</h3>
            
            <button
              onClick={() => setPaymentMethod('platffpay')}
              className={`w-full p-4 rounded-xl flex items-center justify-between transition-colors ${
                paymentMethod === 'platffpay'
                  ? 'bg-yellow-400 text-purple-900'
                  : 'bg-purple-800 text-white hover:bg-purple-700'
              }`}
            >
              <div className="flex items-center gap-3">
                <Wallet size={20} />
                <div className="text-left">
                  <p className="font-medium">Solde PLATFFPAY</p>
                  <p className="text-sm opacity-80">{cashbackBalance.toFixed(2)}€ disponible</p>
                </div>
              </div>
              {paymentMethod === 'platffpay' && <BadgeCheck size={20} />}
            </button>

            <button
              onClick={() => setPaymentMethod('card')}
              className={`w-full p-4 rounded-xl flex items-center justify-between transition-colors ${
                paymentMethod === 'card'
                  ? 'bg-yellow-400 text-purple-900'
                  : 'bg-purple-800 text-white hover:bg-purple-700'
              }`}
            >
              <div className="flex items-center gap-3">
                <CreditCard size={20} />
                <p className="font-medium">Carte bancaire</p>
              </div>
              {paymentMethod === 'card' && <BadgeCheck size={20} />}
            </button>
          </div>

          {/* Info Box */}
          <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
            <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <p className="text-sm text-gray-300">
              Votre achat est protégé par PLATFF. Le cashback sera instantanément crédité sur votre compte PLATFFPAY.
            </p>
          </div>

          {/* Actions */}
          <div className="flex gap-3">
            <button
              onClick={onClose}
              className="flex-1 bg-purple-800 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors"
            >
              Annuler
            </button>
            <button
              onClick={handlePayment}
              disabled={!paymentMethod || isProcessing}
              className={`flex-1 bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2 ${
                !paymentMethod || isProcessing ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isProcessing ? (
                <>
                  <div className="w-5 h-5 border-2 border-purple-900 border-t-transparent rounded-full animate-spin" />
                  Traitement...
                </>
              ) : (
                <>
                  <Lock size={20} />
                  Payer {product.price}€
                  <ArrowRight size={20} />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}