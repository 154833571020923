import React, { useState } from 'react';
import { 
  Users, 
  Heart, 
  MessageCircle, 
  Share2, 
  Edit3,
  Image as ImageIcon,
  Link,
  Globe,
  Shield,
  Star,
  Info,
  BadgeCheck,
  Lock
} from 'lucide-react';

interface FanPageProps {
  page?: {
    name: string;
    avatar: string;
    cover: string;
    description: string;
    followers: number;
    category: string;
    verified: boolean;
  };
  isVerified?: boolean;
}

const mockPage = {
  name: 'PLATFF Community',
  avatar: 'https://images.unsplash.com/photo-1549692520-acc6669e2f0c?w=200&h=200&fit=crop',
  cover: 'https://images.unsplash.com/photo-1516245834210-c4c142787335?w=1200&h=400&fit=crop',
  description: 'La communauté officielle PLATFF. Rejoignez-nous pour découvrir les dernières actualités et participer à notre révolution sociale !',
  followers: 15000,
  category: 'Communauté',
  verified: true
};

export function FanPage({ page = mockPage, isVerified = false }: FanPageProps) {
  const [isFollowing, setIsFollowing] = useState(false);
  const [showVerificationAlert, setShowVerificationAlert] = useState(false);

  const handleFollowClick = () => {
    if (!isVerified) {
      setShowVerificationAlert(true);
      return;
    }
    setIsFollowing(!isFollowing);
  };

  return (
    <div className="space-y-4">
      {/* Cover & Profile */}
      <div className="bg-purple-900 rounded-xl overflow-hidden">
        <div className="relative h-32 sm:h-48">
          <img 
            src={page.cover}
            alt="Cover"
            className="w-full h-full object-cover"
          />
          <button className="absolute top-4 right-4 bg-purple-900/50 p-2 rounded-full text-white hover:bg-purple-900/80">
            <Edit3 size={20} />
          </button>
        </div>

        <div className="p-4 sm:p-6 relative">
          <div className="absolute -top-16 left-4 sm:left-6">
            <div className="relative">
              <img 
                src={page.avatar}
                alt={page.name}
                className="w-24 h-24 sm:w-32 sm:h-32 rounded-xl border-4 border-purple-900"
              />
              <button className="absolute bottom-2 right-2 bg-purple-800 p-2 rounded-full text-white hover:bg-purple-700">
                <ImageIcon size={16} />
              </button>
            </div>
          </div>

          <div className="ml-28 sm:ml-40">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div>
                <div className="flex items-center gap-2">
                  <h1 className="text-xl sm:text-2xl font-bold text-white">{page.name}</h1>
                  {page.verified && (
                    <Shield className="text-yellow-400" size={20} />
                  )}
                </div>
                <p className="text-gray-300 text-sm">{page.category}</p>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={handleFollowClick}
                  className={`px-4 py-2 rounded-full text-sm font-semibold transition-colors ${
                    isFollowing
                      ? 'bg-purple-800 text-white'
                      : 'bg-yellow-400 text-purple-900 hover:bg-yellow-300'
                  }`}
                >
                  {isFollowing ? 'Abonné' : 'Suivre'}
                </button>
                <button className="bg-purple-800 p-2 rounded-full text-white hover:bg-purple-700">
                  <Share2 size={20} />
                </button>
              </div>
            </div>

            <div className="flex flex-wrap gap-4 mt-4">
              <div className="flex items-center gap-2">
                <Users className="text-yellow-400" size={16} />
                <span className="text-white font-semibold">
                  {page.followers.toLocaleString()} abonnés
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Star className="text-yellow-400" size={16} />
                <span className="text-white font-semibold">
                  4.9/5 (450 avis)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-3 gap-2 sm:gap-4">
        <button className="bg-purple-900 p-3 sm:p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800">
          <MessageCircle className="text-yellow-400" size={20} />
          <span className="text-white text-sm">Messages</span>
        </button>
        <button className="bg-purple-900 p-3 sm:p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800">
          <Globe className="text-yellow-400" size={20} />
          <span className="text-white text-sm">Site Web</span>
        </button>
        <button className="bg-purple-900 p-3 sm:p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800">
          <Link className="text-yellow-400" size={20} />
          <span className="text-white text-sm">Liens</span>
        </button>
      </div>

      {/* About */}
      <div className="bg-purple-900 p-4 sm:p-6 rounded-xl">
        <h2 className="text-lg sm:text-xl font-bold text-white mb-4">À propos</h2>
        <p className="text-gray-300 text-sm sm:text-base">{page.description}</p>
      </div>

      {/* Stats */}
      <div className="bg-purple-900 p-4 sm:p-6 rounded-xl">
        <h2 className="text-lg sm:text-xl font-bold text-white mb-4">Statistiques</h2>
        <div className="grid grid-cols-3 gap-2 sm:gap-4">
          <div className="bg-purple-800 p-3 sm:p-4 rounded-xl">
            <p className="text-xl sm:text-2xl font-bold text-yellow-400">89%</p>
            <p className="text-xs sm:text-sm text-gray-300">Engagement</p>
          </div>
          <div className="bg-purple-800 p-3 sm:p-4 rounded-xl">
            <p className="text-xl sm:text-2xl font-bold text-yellow-400">2.5K</p>
            <p className="text-xs sm:text-sm text-gray-300">Interactions/jour</p>
          </div>
          <div className="bg-purple-800 p-3 sm:p-4 rounded-xl">
            <p className="text-xl sm:text-2xl font-bold text-yellow-400">450</p>
            <p className="text-xs sm:text-sm text-gray-300">Publications</p>
          </div>
        </div>
      </div>

      {/* Verification Alert */}
      {showVerificationAlert && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
          <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md">
            <div className="flex items-center gap-3 mb-4">
              <Lock className="text-yellow-400" size={24} />
              <h3 className="text-xl font-bold text-white">Vérification Requise</h3>
            </div>
            
            <p className="text-gray-300 mb-6">
              Seuls les membres vérifiés peuvent suivre la page officielle PLATFF Community. 
              La vérification nous permet de maintenir une communauté de confiance.
            </p>

            <div className="bg-purple-800 p-4 rounded-xl mb-6">
              <div className="flex items-center gap-2 mb-2">
                <BadgeCheck className="text-yellow-400" size={20} />
                <p className="text-white font-medium">Avantages de la vérification</p>
              </div>
              <ul className="space-y-2 text-sm text-gray-300">
                <li className="flex items-center gap-2">
                  <Shield size={16} className="text-yellow-400" />
                  Accès aux contenus exclusifs
                </li>
                <li className="flex items-center gap-2">
                  <Star size={16} className="text-yellow-400" />
                  Participation aux événements communautaires
                </li>
                <li className="flex items-center gap-2">
                  <Globe size={16} className="text-yellow-400" />
                  Impact communautaire amplifié
                </li>
              </ul>
            </div>

            <div className="flex gap-3">
              <button
                onClick={() => setShowVerificationAlert(false)}
                className="flex-1 bg-purple-800 text-white py-2 rounded-xl hover:bg-purple-700 transition-colors"
              >
                Plus tard
              </button>
              <button
                onClick={() => {
                  // Handle verification process
                  setShowVerificationAlert(false);
                }}
                className="flex-1 bg-yellow-400 text-purple-900 py-2 rounded-xl font-semibold hover:bg-yellow-300 transition-colors"
              >
                Me vérifier
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}