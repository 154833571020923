import React, { useState, useRef, useEffect } from 'react';
import { 
  Camera, 
  Music, 
  MapPin, 
  Hash, 
  AtSign, 
  Sliders, 
  X, 
  Image as ImageIcon, 
  Video, 
  Send, 
  Smile,
  Type,
  Bold,
  Italic,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Palette,
  Loader
} from 'lucide-react';
import { moderateContent } from '../../utils/contentModeration';

interface StoryCreatorProps {
  onClose: () => void;
  onSubmit: (story: { mediaUrl: string; type: 'image' | 'video' | 'text'; text?: string; textStyle?: any }) => void;
}

export function StoryCreator({ onClose, onSubmit }: StoryCreatorProps) {
  const [mode, setMode] = useState<'camera' | 'text' | 'upload' | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [mediaType, setMediaType] = useState<'photo' | 'video'>('photo');
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [text, setText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [textStyle, setTextStyle] = useState({
    color: '#FFFFFF',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '20px'
  });

  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [mediaStream]);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: mediaType === 'video'
      });
      setMediaStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const stopCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setMediaStream(null);
    }
  };

  const startRecording = () => {
    if (!mediaStream) return;

    const mediaRecorder = new MediaRecorder(mediaStream);
    mediaRecorderRef.current = mediaRecorder;
    setRecordedChunks([]);
    setIsRecording(true);

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        setRecordedChunks(prev => [...prev, event.data]);
      }
    };

    mediaRecorder.start();
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      
      // Create preview URL after stopping
      setTimeout(() => {
        const blob = new Blob(recordedChunks, { type: mediaType === 'video' ? 'video/webm' : 'image/jpeg' });
        setPreviewUrl(URL.createObjectURL(blob));
      }, 100);
    }
  };

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0);
        
        canvasRef.current.toBlob((blob) => {
          if (blob) {
            setPreviewUrl(URL.createObjectURL(blob));
          }
        }, 'image/jpeg');
      }
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const type = file.type.startsWith('image/') ? 'photo' : 'video';
      setMediaType(type);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async () => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      let finalText = text;
      if (text) {
        const moderation = await moderateContent(text);
        finalText = moderation.moderatedText;
      }

      if (mode === 'text') {
        onSubmit({
          mediaUrl: '',
          type: 'text',
          text: finalText,
          textStyle
        });
      } else if (previewUrl) {
        onSubmit({
          mediaUrl: previewUrl,
          type: mediaType === 'photo' ? 'image' : 'video',
          text: finalText,
          textStyle
        });
      }
    } catch (error) {
      console.error('Error submitting story:', error);
    } finally {
      setIsProcessing(false);
      onClose();
    }
  };

  const renderCamera = () => (
    <div className="relative h-full">
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className="w-full h-full object-cover"
      />
      <canvas ref={canvasRef} className="hidden" />
      
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex items-center gap-4">
        {mediaType === 'photo' ? (
          <button
            onClick={capturePhoto}
            className="w-16 h-16 rounded-full bg-white border-4 border-yellow-400"
          />
        ) : (
          <button
            onClick={isRecording ? stopRecording : startRecording}
            className={`w-16 h-16 rounded-full ${isRecording ? 'bg-red-500' : 'bg-white'} border-4 border-yellow-400`}
          >
            {isRecording && (
              <span className="block w-6 h-6 bg-white rounded-sm mx-auto" />
            )}
          </button>
        )}
      </div>

      <div className="absolute top-4 right-4 flex gap-2">
        <button
          onClick={() => setMediaType(mediaType === 'photo' ? 'video' : 'photo')}
          className="bg-purple-900/80 p-2 rounded-full text-white"
        >
          {mediaType === 'photo' ? <Camera size={24} /> : <Video size={24} />}
        </button>
      </div>
    </div>
  );

  const renderTextEditor = () => (
    <div className="relative h-full bg-gradient-to-br from-purple-900 to-purple-800 p-6">
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Écrivez votre story..."
        className="w-full h-1/2 bg-transparent text-white text-3xl font-bold resize-none focus:outline-none"
        style={textStyle}
      />

      <div className="absolute bottom-4 left-4 right-4">
        <div className="flex flex-wrap gap-2 mb-4">
          <button
            onClick={() => setTextStyle(prev => ({
              ...prev,
              fontWeight: prev.fontWeight === 'bold' ? 'normal' : 'bold'
            }))}
            className="bg-purple-800 p-2 rounded-lg text-white"
          >
            <Bold size={20} />
          </button>
          <button
            onClick={() => setTextStyle(prev => ({
              ...prev,
              fontStyle: prev.fontStyle === 'italic' ? 'normal' : 'italic'
            }))}
            className="bg-purple-800 p-2 rounded-lg text-white"
          >
            <Italic size={20} />
          </button>
          <button
            onClick={() => setTextStyle(prev => ({
              ...prev,
              textAlign: 'left'
            }))}
            className="bg-purple-800 p-2 rounded-lg text-white"
          >
            <AlignLeft size={20} />
          </button>
          <button
            onClick={() => setTextStyle(prev => ({
              ...prev,
              textAlign: 'center'
            }))}
            className="bg-purple-800 p-2 rounded-lg text-white"
          >
            <AlignCenter size={20} />
          </button>
          <button
            onClick={() => setTextStyle(prev => ({
              ...prev,
              textAlign: 'right'
            }))}
            className="bg-purple-800 p-2 rounded-lg text-white"
          >
            <AlignRight size={20} />
          </button>

          <select
            value={textStyle.fontSize}
            onChange={(e) => setTextStyle(prev => ({
              ...prev,
              fontSize: e.target.value
            }))}
            className="bg-purple-800 text-white rounded-lg px-3"
          >
            <option value="18px">18px</option>
            <option value="24px">24px</option>
            <option value="32px">32px</option>
            <option value="48px">48px</option>
          </select>

          <input
            type="color"
            value={textStyle.color}
            onChange={(e) => setTextStyle(prev => ({
              ...prev,
              color: e.target.value
            }))}
            className="w-10 h-10 rounded-lg cursor-pointer"
          />
        </div>
      </div>
    </div>
  );

  if (!mode) {
    return (
      <div className="fixed inset-0 bg-purple-900 z-50 p-4">
        <div className="max-w-lg mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-xl font-bold text-white">Créer une story</h2>
            <button onClick={onClose} className="text-gray-300 hover:text-white">
              <X size={24} />
            </button>
          </div>

          <div className="grid grid-cols-3 gap-4">
            <button
              onClick={() => {
                setMode('camera');
                startCamera();
              }}
              className="bg-purple-800 p-6 rounded-xl flex flex-col items-center gap-3 hover:bg-purple-700 transition-colors"
            >
              <Camera className="text-yellow-400" size={32} />
              <span className="text-white text-sm">Appareil photo</span>
            </button>

            <button
              onClick={() => {
                setMode('text');
              }}
              className="bg-purple-800 p-6 rounded-xl flex flex-col items-center gap-3 hover:bg-purple-700 transition-colors"
            >
              <Type className="text-yellow-400" size={32} />
              <span className="text-white text-sm">Texte</span>
            </button>

            <button
              onClick={() => {
                setMode('upload');
                fileInputRef.current?.click();
              }}
              className="bg-purple-800 p-6 rounded-xl flex flex-col items-center gap-3 hover:bg-purple-700 transition-colors"
            >
              <ImageIcon className="text-yellow-400" size={32} />
              <span className="text-white text-sm">Galerie</span>
            </button>
          </div>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/*,video/*"
            className="hidden"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-purple-900 z-50 flex flex-col">
      <div className="p-4 flex justify-between items-center">
        <button onClick={() => {
          stopCamera();
          setMode(null);
        }} className="text-white">
          <X size={24} />
        </button>
        <button
          onClick={handleSubmit}
          disabled={isProcessing || (!previewUrl && !text)}
          className={`bg-yellow-400 text-purple-900 px-4 py-2 rounded-full font-semibold flex items-center gap-2 ${
            isProcessing || (!previewUrl && !text) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-300'
          }`}
        >
          {isProcessing ? (
            <>
              <Loader className="animate-spin" size={20} />
              Traitement...
            </>
          ) : (
            <>
              <Send size={20} />
              Partager
            </>
          )}
        </button>
      </div>

      <div className="flex-1 relative">
        {mode === 'camera' && !previewUrl && renderCamera()}
        {mode === 'text' && renderTextEditor()}
        {previewUrl && (
          <div className="relative h-full">
            {mediaType === 'photo' ? (
              <img
                src={previewUrl}
                alt=""
                className="w-full h-full object-cover"
              />
            ) : (
              <video
                src={previewUrl}
                className="w-full h-full object-cover"
                controls
                autoPlay
                loop
                muted
              />
            )}
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Ajouter un texte..."
              className="absolute bottom-20 left-4 right-4 bg-purple-900/80 text-white rounded-xl p-4 resize-none"
            />
          </div>
        )}
      </div>
    </div>
  );
}