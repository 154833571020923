import React, { useState } from 'react';
import { 
  Home, 
  ShoppingBag, 
  Calendar, 
  Users, 
  Wallet, 
  Globe,
  Settings,
  HelpCircle,
  Bell,
  LogOut,
  ChevronRight,
  User,
  Shield,
  Crown,
  Tv
} from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useBalance } from '../../contexts/BalanceContext';

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onSectionChange: (section: string) => void;
  isAdmin?: boolean;
}

export function SideMenu({ isOpen, onClose, onSectionChange, isAdmin }: SideMenuProps) {
  const { t } = useLanguage();
  const { cashbackBalance, communityFundBalance } = useBalance();

  const navItems = [
    { id: 'home', icon: Home, label: t('nav.home') },
    { id: 'shop', icon: ShoppingBag, label: t('nav.shop') },
    { id: 'bookings', icon: Calendar, label: t('nav.bookings') },
    { id: 'social', icon: Users, label: t('nav.social') },
    { id: 'streaming', icon: Tv, label: 'Streaming' },
    { id: 'cashback', icon: Wallet, label: t('nav.wallet') },
    { id: 'impact', icon: Globe, label: t('nav.impact') }
  ];

  const profileItems = [
    { id: 'profile', icon: User, label: 'Profil' },
    { id: 'notifications', icon: Bell, label: 'Notifications' },
    { id: 'security', icon: Shield, label: 'Sécurité' },
    { id: 'preferences', icon: Settings, label: 'Préférences' }
  ];

  const handleNavigation = (section: string) => {
    onSectionChange(section);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100]">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm animate-fade-in"
        onClick={onClose}
      />

      {/* Menu Content */}
      <div className="absolute inset-y-0 left-0 w-80 bg-purple-900 shadow-lg animate-slide-in">
        {/* Header */}
        <div className="p-6 border-b border-purple-800">
          <div className="flex items-center gap-4 mb-4">
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop"
                alt="Profile"
                className="w-16 h-16 rounded-full border-2 border-yellow-400"
              />
              <div className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-purple-900" />
            </div>
            <div>
              <h3 className="text-white font-semibold flex items-center gap-2">
                Romain Lopez
                {isAdmin && (
                  <Crown className="text-yellow-400" size={16} />
                )}
              </h3>
              <p className="text-gray-300 text-sm">Founder & CEO</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="bg-purple-800 p-3 rounded-xl">
              <p className="text-gray-300 text-sm mb-1">Cashback</p>
              <p className="text-yellow-400 font-bold">{cashbackBalance.toFixed(2)}€</p>
            </div>
            <div className="bg-purple-800 p-3 rounded-xl">
              <p className="text-gray-300 text-sm mb-1">Impact</p>
              <p className="text-yellow-400 font-bold">{communityFundBalance.toFixed(2)}€</p>
            </div>
          </div>
        </div>

        {/* Navigation */}
        <div className="p-4 h-[calc(100%-200px)] overflow-y-auto">
          <div className="space-y-6">
            {/* Main Navigation */}
            <div>
              <p className="text-gray-400 text-sm px-2 mb-2">Navigation</p>
              <nav className="space-y-1">
                {navItems.map(item => (
                  <button
                    key={item.id}
                    onClick={() => handleNavigation(item.id)}
                    className="flex items-center gap-3 w-full p-3 hover:bg-purple-800 rounded-lg group transition-colors"
                  >
                    <item.icon className="text-yellow-400" size={20} />
                    <span className="text-white flex-1 text-left">{item.label}</span>
                    <ChevronRight 
                      size={16} 
                      className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" 
                    />
                  </button>
                ))}
              </nav>
            </div>

            {/* Account Settings */}
            <div>
              <p className="text-gray-400 text-sm px-2 mb-2">Compte</p>
              <nav className="space-y-1">
                {profileItems.map(item => (
                  <button
                    key={item.id}
                    onClick={() => handleNavigation(item.id)}
                    className="flex items-center gap-3 w-full p-3 hover:bg-purple-800 rounded-lg group transition-colors"
                  >
                    <item.icon className="text-yellow-400" size={20} />
                    <span className="text-white flex-1 text-left">{item.label}</span>
                    <ChevronRight 
                      size={16} 
                      className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" 
                    />
                  </button>
                ))}
              </nav>
            </div>

            {/* Support */}
            <div>
              <p className="text-gray-400 text-sm px-2 mb-2">Support</p>
              <nav className="space-y-1">
                <button className="flex items-center gap-3 w-full p-3 hover:bg-purple-800 rounded-lg group transition-colors">
                  <HelpCircle className="text-yellow-400" size={20} />
                  <span className="text-white flex-1 text-left">Aide</span>
                  <ChevronRight 
                    size={16} 
                    className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" 
                  />
                </button>
              </nav>
            </div>

            {/* Logout */}
            <div>
              <nav className="space-y-1">
                <button 
                  className="flex items-center gap-3 w-full p-3 hover:bg-purple-800 rounded-lg text-red-400 transition-colors"
                >
                  <LogOut size={20} />
                  <span className="flex-1 text-left">Déconnexion</span>
                  <ChevronRight 
                    size={16} 
                    className="opacity-0 group-hover:opacity-100 transition-opacity" 
                  />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}