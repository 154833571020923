import React, { useState } from 'react';
import { PaymentLimits } from '../wallet/settings/PaymentLimits';
import { SecuritySettings } from '../wallet/settings/SecuritySettings';
import { NotificationSettings } from '../wallet/settings/NotificationSettings';
import { BankAccountSetup } from '../wallet/BankAccountSetup';
import { ActivationModal } from '../wallet/ActivationModal';
import { CreditFeatures } from '../wallet/CreditFeatures';
import { CreditCard, Shield, Gift, Globe, ChevronRight, Lock, Wallet, Building } from 'lucide-react';

interface WalletSectionProps {
  balance?: number;
}

export function WalletSection({ balance: initialBalance = 325.50 }: WalletSectionProps) {
  const [activeTab, setActiveTab] = useState<'overview' | 'card' | 'bank' | 'advance'>('overview');
  const [activeSettings, setActiveSettings] = useState<'limits' | 'security' | 'notifications' | null>(null);
  const [isCardActivated, setIsCardActivated] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [isPlatffpayActivated, setIsPlatffpayActivated] = useState(false);
  const [cashbackBalance, setCashbackBalance] = useState(45.50);
  const [balance, setBalance] = useState(initialBalance);

  const handleActivatePlatffpay = () => {
    setShowActivationModal(true);
  };

  const handleActivationComplete = () => {
    setShowActivationModal(false);
    setIsPlatffpayActivated(true);
    setIsCardActivated(true);
  };

  const handleAdvanceRequested = (amount: number) => {
    setBalance(prev => prev + amount);
  };

  const handleSettingsClick = (setting: 'limits' | 'security' | 'notifications') => {
    setActiveSettings(setting);
  };

  const handleBackToCard = () => {
    setActiveSettings(null);
  };

  // Render settings content
  if (activeSettings) {
    return (
      <div className="max-w-lg mx-auto">
        <button
          onClick={handleBackToCard}
          className="text-yellow-400 mb-6 hover:text-yellow-300 transition-colors flex items-center gap-2"
        >
          ← Retour
        </button>

        {activeSettings === 'limits' && <PaymentLimits />}
        {activeSettings === 'security' && <SecuritySettings />}
        {activeSettings === 'notifications' && <NotificationSettings />}
      </div>
    );
  }

  return (
    <div className="max-w-lg mx-auto space-y-6">
      {/* Navigation */}
      <div className="flex gap-2">
        <button
          onClick={() => setActiveTab('overview')}
          className={`flex-1 py-2 rounded-full font-medium transition-all ${
            activeTab === 'overview'
              ? 'bg-yellow-400 text-purple-900'
              : 'bg-purple-800 text-white'
          }`}
        >
          Aperçu
        </button>
        <button
          onClick={() => setActiveTab('card')}
          className={`flex-1 py-2 rounded-full font-medium transition-all ${
            activeTab === 'card'
              ? 'bg-yellow-400 text-purple-900'
              : 'bg-purple-800 text-white'
          }`}
        >
          Carte
        </button>
        <button
          onClick={() => setActiveTab('bank')}
          className={`flex-1 py-2 rounded-full font-medium transition-all ${
            activeTab === 'bank'
              ? 'bg-yellow-400 text-purple-900'
              : 'bg-purple-800 text-white'
          }`}
        >
          Compte
        </button>
        {isPlatffpayActivated && (
          <button
            onClick={() => setActiveTab('advance')}
            className={`flex-1 py-2 rounded-full font-medium transition-all ${
              activeTab === 'advance'
                ? 'bg-yellow-400 text-purple-900'
                : 'bg-purple-800 text-white'
            }`}
          >
            Avance
          </button>
        )}
      </div>

      {/* Content */}
      {activeTab === 'overview' && (
        <>
          {/* Balance Overview */}
          <div className="bg-purple-900 p-6 rounded-xl">
            <div className="flex items-center gap-3 mb-6">
              <Wallet className="text-yellow-400" size={24} />
              <div>
                <h2 className="text-xl font-bold text-white">PLATFFPAY</h2>
                <p className="text-gray-300">Votre portefeuille numérique</p>
              </div>
            </div>

            {!isPlatffpayActivated ? (
              <div className="space-y-6">
                {/* Cashback Balance */}
                <div className="bg-purple-800 p-4 rounded-xl">
                  <div className="flex items-center gap-2 mb-2">
                    <Gift className="text-green-400" size={20} />
                    <h3 className="text-white font-medium">Cashback disponible</h3>
                  </div>
                  <p className="text-2xl font-bold text-green-400">{cashbackBalance.toFixed(2)}€</p>
                  <p className="text-sm text-gray-300 mt-1">
                    Activez PLATFFPAY pour accéder à vos fonds
                  </p>
                </div>

                <div className="text-center">
                  <button
                    onClick={handleActivatePlatffpay}
                    className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors inline-flex items-center gap-2"
                  >
                    <Lock size={20} />
                    Activer PLATFFPAY
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="text-center mb-6">
                  <p className="text-gray-300 mb-2">Solde disponible</p>
                  <p className="text-4xl font-bold text-yellow-400">{balance.toFixed(2)}€</p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-purple-800 p-4 rounded-xl">
                    <div className="flex items-center gap-2 mb-1">
                      <Gift className="text-green-400" size={16} />
                      <span className="text-white">Cashback</span>
                    </div>
                    <p className="text-lg font-bold text-green-400">+{cashbackBalance.toFixed(2)}€</p>
                    <p className="text-xs text-gray-300">ce mois</p>
                  </div>
                  <div className="bg-purple-800 p-4 rounded-xl">
                    <div className="flex items-center gap-2 mb-1">
                      <Globe className="text-blue-400" size={16} />
                      <span className="text-white">Impact</span>
                    </div>
                    <p className="text-lg font-bold text-blue-400">+125.30€</p>
                    <p className="text-xs text-gray-300">contribution</p>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Quick Actions */}
          {isPlatffpayActivated && (
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => setActiveTab('card')}
                className="bg-purple-900 p-4 rounded-xl text-center hover:bg-purple-800 transition-colors"
              >
                <CreditCard className="text-yellow-400 mx-auto mb-2" size={24} />
                <p className="text-white font-medium">Gérer ma carte</p>
              </button>
              <button
                onClick={() => setActiveTab('bank')}
                className="bg-purple-900 p-4 rounded-xl text-center hover:bg-purple-800 transition-colors"
              >
                <Building className="text-yellow-400 mx-auto mb-2" size={24} />
                <p className="text-white font-medium">Mon compte</p>
              </button>
            </div>
          )}
        </>
      )}

      {activeTab === 'card' && (
        <>
          {!isCardActivated ? (
            <div className="bg-purple-900 p-6 rounded-xl text-center">
              <CreditCard className="text-yellow-400 mx-auto mb-4" size={48} />
              <h3 className="text-xl font-bold text-white mb-2">Activez votre carte PLATFFPAY</h3>
              <p className="text-gray-300 mb-6">
                Utilisez votre solde partout avec la carte virtuelle PLATFFPAY
              </p>
              <button
                onClick={() => setIsCardActivated(true)}
                className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2 w-full"
              >
                <Lock size={20} />
                Activer ma carte
              </button>
            </div>
          ) : (
            <div className="bg-purple-900 p-6 rounded-xl">
              {/* Virtual Card Display */}
              <div className="relative h-48 bg-gradient-to-br from-purple-600 to-purple-900 rounded-xl p-6 mb-6 overflow-hidden">
                <div className="absolute inset-0 opacity-30">
                  <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(120,119,198,0.3),rgba(255,255,255,0))]" />
                </div>

                <div className="relative h-full flex flex-col justify-between">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-white font-bold text-xl mb-1">PLATFFPAY</h3>
                      <p className="text-sm text-gray-300">Carte Virtuelle</p>
                    </div>
                    <img 
                      src="https://logos-world.net/wp-content/uploads/2020/04/Visa-Logo.png"
                      alt="Visa"
                      className="h-8 object-contain"
                    />
                  </div>

                  <div className="flex items-center gap-4">
                    <div className="w-12 h-8 bg-yellow-400 rounded-md" />
                    <div className="text-white font-mono">
                      •••• •••• •••• 4242
                    </div>
                  </div>

                  <div className="flex justify-between items-end">
                    <div>
                      <p className="text-sm text-gray-300 mb-1">Titulaire</p>
                      <p className="text-white font-medium">ROMAIN LOPEZ</p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm text-gray-300 mb-1">Expire le</p>
                      <p className="text-white font-medium">12/27</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card Settings */}
              <div className="space-y-4">
                <button 
                  onClick={() => handleSettingsClick('limits')}
                  className="w-full bg-purple-800 p-4 rounded-xl flex items-center justify-between text-white hover:bg-purple-700 transition-colors"
                >
                  <span>Limites de paiement</span>
                  <ChevronRight size={20} />
                </button>
                
                <button 
                  onClick={() => handleSettingsClick('security')}
                  className="w-full bg-purple-800 p-4 rounded-xl flex items-center justify-between text-white hover:bg-purple-700 transition-colors"
                >
                  <span>Paramètres de sécurité</span>
                  <ChevronRight size={20} />
                </button>
                
                <button 
                  onClick={() => handleSettingsClick('notifications')}
                  className="w-full bg-purple-800 p-4 rounded-xl flex items-center justify-between text-white hover:bg-purple-700 transition-colors"
                >
                  <span>Notifications</span>
                  <ChevronRight size={20} />
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {activeTab === 'bank' && (
        <BankAccountSetup 
          onComplete={() => setActiveTab('overview')} 
          isActivated={isPlatffpayActivated}
          onActivate={handleActivatePlatffpay}
        />
      )}

      {activeTab === 'advance' && isPlatffpayActivated && (
        <CreditFeatures 
          isVerified={true}
          platffpayBalance={balance}
          onAdvanceRequested={handleAdvanceRequested}
        />
      )}

      {showActivationModal && (
        <ActivationModal
          onComplete={handleActivationComplete}
          onClose={() => setShowActivationModal(false)}
        />
      )}
    </div>
  );
}