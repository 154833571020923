import React, { useState } from 'react';
import { 
  BadgeCheck, 
  Star, 
  TrendingUp, 
  Heart,
  Video,
  Image as ImageIcon,
  MessageCircle,
  Share2,
  Users,
  Wallet,
  Award,
  Globe,
  UserPlus,
  Mail,
  Link,
  MapPin,
  Calendar
} from 'lucide-react';

interface UserProfileProps {
  user: {
    id: string;
    name: string;
    avatar: string;
    bio?: string;
    isVerified: boolean;
    followers: number;
    following: number;
    creatorRewards: number;
    totalContributions: number;
    stats: {
      posts: number;
      videos: number;
      photos: number;
      comments: number;
    };
  };
  onClose?: () => void;
}

export function UserProfile({ user, onClose }: UserProfileProps) {
  const [isFollowing, setIsFollowing] = useState(false);
  const [showFriendRequest, setShowFriendRequest] = useState(false);

  const handleFollow = () => {
    setIsFollowing(!isFollowing);
  };

  const handleFriendRequest = () => {
    setShowFriendRequest(true);
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-lg max-h-[90vh] flex flex-col overflow-hidden">
        {/* Fixed Header */}
        <div className="flex-shrink-0">
          {/* Cover Photo */}
          <div className="relative h-48 bg-gradient-to-br from-purple-800 to-purple-600">
            <div className="absolute -bottom-16 left-6">
              <div className="relative">
                <img 
                  src={user.avatar}
                  alt={user.name}
                  className="w-32 h-32 rounded-xl border-4 border-purple-900"
                />
                {user.isVerified && (
                  <BadgeCheck 
                    className="absolute -bottom-2 -right-2 text-yellow-400 bg-purple-900 rounded-full p-1" 
                    size={24} 
                  />
                )}
              </div>
            </div>
          </div>

          {/* Profile Header */}
          <div className="pt-20 px-6 pb-4 border-b border-purple-800">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-2xl font-bold text-white">{user.name}</h2>
                {user.bio && <p className="text-gray-300 mt-1">{user.bio}</p>}
              </div>
              <div className="flex gap-2">
                <button
                  onClick={handleFollow}
                  className={`px-4 py-2 rounded-full font-semibold ${
                    isFollowing
                      ? 'bg-purple-800 text-white'
                      : 'bg-yellow-400 text-purple-900'
                  }`}
                >
                  {isFollowing ? 'Abonné' : 'Suivre'}
                </button>
                <button
                  onClick={handleFriendRequest}
                  className="bg-purple-800 text-white p-2 rounded-full"
                  title="Ajouter en ami"
                >
                  <UserPlus size={20} />
                </button>
                <button
                  className="bg-purple-800 text-white p-2 rounded-full"
                  title="Envoyer un message"
                >
                  <Mail size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {/* Basic Info */}
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-purple-800 p-4 rounded-xl">
              <div className="flex items-center gap-2 text-gray-300">
                <MapPin size={16} className="text-yellow-400" />
                <span>Paris, France</span>
              </div>
            </div>
            <div className="bg-purple-800 p-4 rounded-xl">
              <div className="flex items-center gap-2 text-gray-300">
                <Calendar size={16} className="text-yellow-400" />
                <span>Membre depuis 2023</span>
              </div>
            </div>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-4 gap-4 text-center">
            <div className="bg-purple-800 p-4 rounded-xl">
              <p className="text-2xl font-bold text-white">{user.stats.posts}</p>
              <p className="text-sm text-gray-300">Publications</p>
            </div>
            <div className="bg-purple-800 p-4 rounded-xl">
              <p className="text-2xl font-bold text-white">{user.followers}</p>
              <p className="text-sm text-gray-300">Abonnés</p>
            </div>
            <div className="bg-purple-800 p-4 rounded-xl">
              <p className="text-2xl font-bold text-white">{user.following}</p>
              <p className="text-sm text-gray-300">Abonnements</p>
            </div>
            <div className="bg-purple-800 p-4 rounded-xl">
              <p className="text-2xl font-bold text-yellow-400">{user.creatorRewards}€</p>
              <p className="text-sm text-gray-300">Récompenses</p>
            </div>
          </div>

          {/* Content Stats */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-white">Statistiques du contenu</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-purple-800 p-4 rounded-xl">
                <div className="flex items-center gap-2 mb-2">
                  <Video className="text-yellow-400" size={20} />
                  <span className="text-white">{user.stats.videos} vidéos</span>
                </div>
                <div className="w-full bg-purple-700 rounded-full h-2">
                  <div className="bg-yellow-400 h-2 rounded-full" style={{ width: '75%' }} />
                </div>
              </div>

              <div className="bg-purple-800 p-4 rounded-xl">
                <div className="flex items-center gap-2 mb-2">
                  <ImageIcon className="text-yellow-400" size={20} />
                  <span className="text-white">{user.stats.photos} photos</span>
                </div>
                <div className="w-full bg-purple-700 rounded-full h-2">
                  <div className="bg-yellow-400 h-2 rounded-full" style={{ width: '60%' }} />
                </div>
              </div>
            </div>
          </div>

          {/* Impact Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-white">Impact Communautaire</h3>
            <div className="bg-purple-800 p-4 rounded-xl space-y-4">
              <div>
                <p className="text-2xl font-bold text-yellow-400">{user.totalContributions}€</p>
                <p className="text-sm text-gray-300">Contribution totale au Fonds</p>
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-lg font-bold text-green-400">45</p>
                  <p className="text-sm text-gray-300">Projets soutenus</p>
                </div>
                <div>
                  <p className="text-lg font-bold text-blue-400">12</p>
                  <p className="text-sm text-gray-300">Initiatives lancées</p>
                </div>
              </div>
            </div>
          </div>

          {/* Recent Activity */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-white">Activité récente</h3>
            <div className="space-y-2">
              {[1, 2, 3].map((_, i) => (
                <div key={i} className="bg-purple-800 p-4 rounded-xl">
                  <div className="flex items-start gap-3">
                    <Heart className="text-red-400" size={20} />
                    <div>
                      <p className="text-white">A soutenu un projet communautaire</p>
                      <p className="text-sm text-gray-300">Il y a {i + 1} jours</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Close Button */}
          {onClose && (
            <button
              onClick={onClose}
              className="w-full bg-purple-800 text-white py-2 rounded-xl hover:bg-purple-700 transition-colors"
            >
              Fermer
            </button>
          )}
        </div>
      </div>
    </div>
  );
}