import React, { useState } from 'react';
import { Bell, Mail, Smartphone, Globe, CreditCard, Gift, Info } from 'lucide-react';

export function NotificationSettings() {
  const [settings, setSettings] = useState({
    transactions: {
      push: true,
      email: false,
      sms: true
    },
    security: {
      push: true,
      email: true,
      sms: true
    },
    rewards: {
      push: true,
      email: true,
      sms: false
    },
    marketing: {
      push: false,
      email: false,
      sms: false
    }
  });

  const toggleNotification = (category: keyof typeof settings, type: keyof typeof settings.transactions) => {
    setSettings(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [type]: !prev[category][type]
      }
    }));
  };

  const NotificationToggle = ({ category, type, label }: { category: keyof typeof settings; type: keyof typeof settings.transactions; label: string }) => (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={settings[category][type]}
        onChange={() => toggleNotification(category, type)}
        className="sr-only peer"
      />
      <div className="w-11 h-6 bg-purple-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
      <span className="ms-3 text-sm text-gray-300">{label}</span>
    </label>
  );

  return (
    <div className="bg-purple-900 rounded-xl p-6">
      <div className="flex items-center gap-3 mb-6">
        <Bell className="text-yellow-400" size={24} />
        <div>
          <h2 className="text-xl font-bold text-white">Notifications</h2>
          <p className="text-gray-300">Gérez vos préférences de notification</p>
        </div>
      </div>

      <div className="space-y-6">
        {/* Transaction Notifications */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center gap-2 mb-4">
            <CreditCard className="text-yellow-400" size={20} />
            <h3 className="text-white font-medium">Transactions</h3>
          </div>
          <div className="space-y-4">
            <NotificationToggle category="transactions" type="push" label="Notifications push" />
            <NotificationToggle category="transactions" type="email" label="Email" />
            <NotificationToggle category="transactions" type="sms" label="SMS" />
          </div>
        </div>

        {/* Security Alerts */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center gap-2 mb-4">
            <Globe className="text-yellow-400" size={20} />
            <h3 className="text-white font-medium">Alertes de sécurité</h3>
          </div>
          <div className="space-y-4">
            <NotificationToggle category="security" type="push" label="Notifications push" />
            <NotificationToggle category="security" type="email" label="Email" />
            <NotificationToggle category="security" type="sms" label="SMS" />
          </div>
        </div>

        {/* Rewards & Cashback */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center gap-2 mb-4">
            <Gift className="text-yellow-400" size={20} />
            <h3 className="text-white font-medium">Récompenses & Cashback</h3>
          </div>
          <div className="space-y-4">
            <NotificationToggle category="rewards" type="push" label="Notifications push" />
            <NotificationToggle category="rewards" type="email" label="Email" />
            <NotificationToggle category="rewards" type="sms" label="SMS" />
          </div>
        </div>

        {/* Marketing & Offers */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center gap-2 mb-4">
            <Mail className="text-yellow-400" size={20} />
            <h3 className="text-white font-medium">Marketing & Offres</h3>
          </div>
          <div className="space-y-4">
            <NotificationToggle category="marketing" type="push" label="Notifications push" />
            <NotificationToggle category="marketing" type="email" label="Email" />
            <NotificationToggle category="marketing" type="sms" label="SMS" />
          </div>
        </div>

        {/* Info Box */}
        <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
          <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
          <div>
            <p className="text-white font-medium mb-1">Notifications importantes</p>
            <p className="text-sm text-gray-300">
              Les notifications de sécurité critiques seront toujours envoyées, indépendamment de vos préférences.
            </p>
          </div>
        </div>

        <button className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2">
          <Bell size={20} />
          Sauvegarder les préférences
        </button>
      </div>
    </div>
  );
}