import React from 'react';
import { useWebRTC } from '../../hooks/useWebRTC';
import { 
  Mic, 
  MicOff, 
  PhoneOff,
  MessageCircle,
  MoreVertical,
  Shield,
  Volume2 
} from 'lucide-react';

interface AudioCallProps {
  recipientId: string;
  recipientName: string;
  recipientAvatar: string;
  onEnd: () => void;
}

export function AudioCall({ 
  recipientId, 
  recipientName, 
  recipientAvatar,
  onEnd 
}: AudioCallProps) {
  const {
    error,
    isConnected,
    isCalling,
    startCall,
    endCall
  } = useWebRTC();

  React.useEffect(() => {
    startCall(recipientId, false); // false for audio-only
    return () => endCall();
  }, [recipientId]);

  if (error) {
    return (
      <div className="fixed inset-0 bg-purple-900 flex items-center justify-center">
        <div className="text-center p-8">
          <Shield className="text-yellow-400 mx-auto mb-4" size={48} />
          <h3 className="text-xl font-bold text-white mb-2">Erreur de connexion</h3>
          <p className="text-gray-300">{error}</p>
          <button
            onClick={onEnd}
            className="mt-4 bg-red-500 text-white px-6 py-2 rounded-full"
          >
            Terminer
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-purple-900 flex items-center justify-center">
      <div className="max-w-md w-full p-8">
        <div className="text-center mb-8">
          <div className="relative inline-block">
            <img
              src={recipientAvatar}
              alt={recipientName}
              className="w-32 h-32 rounded-full mx-auto mb-4"
            />
            <div className={`absolute bottom-4 right-4 w-4 h-4 rounded-full border-2 border-purple-900 ${
              isConnected ? 'bg-green-400' : 'bg-gray-400'
            }`} />
          </div>
          <h2 className="text-2xl font-bold text-white mb-2">{recipientName}</h2>
          <p className="text-gray-300">
            {isCalling ? 'Appel en cours...' : isConnected ? 'En appel' : 'Connexion...'}
          </p>
        </div>

        <div className="flex justify-center gap-6 mb-8">
          <div className="text-center">
            <div className="w-12 h-12 bg-purple-800 rounded-full flex items-center justify-center mb-2">
              <Volume2 className="text-yellow-400" size={24} />
            </div>
            <p className="text-sm text-gray-300">00:12:34</p>
          </div>
        </div>

        <div className="flex justify-center gap-4">
          <button className="p-4 rounded-full bg-purple-800 text-white">
            <Mic size={24} />
          </button>
          <button
            onClick={() => {
              endCall();
              onEnd();
            }}
            className="p-4 rounded-full bg-red-500 text-white"
          >
            <PhoneOff size={24} />
          </button>
          <button className="p-4 rounded-full bg-purple-800 text-white">
            <MessageCircle size={24} />
          </button>
        </div>
      </div>
    </div>
  );
}