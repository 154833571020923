import React, { useState } from 'react';
import { Gift, Wallet, ArrowRight, Info, Calculator, Clock, Shield, Check, Loader } from 'lucide-react';
import { useBalance } from '../../contexts/BalanceContext';

interface CreditFeaturesProps {
  isVerified: boolean;
  platffpayBalance: number;
  onAdvanceRequested?: (amount: number) => void;
}

export function CreditFeatures({ isVerified, platffpayBalance, onAdvanceRequested }: CreditFeaturesProps) {
  const [showSimulation, setShowSimulation] = useState(false);
  const [advanceAmount, setAdvanceAmount] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [approvedAmount, setApprovedAmount] = useState(0);
  const { updateCashbackBalance } = useBalance();

  const mockUserData = {
    averageMonthlyIncome: 2000,
    accountAge: 120,
    transactionHistory: new Array(15),
    hasDefaultedPayments: false
  };

  const limits = {
    advance: Math.min(200, mockUserData.averageMonthlyIncome * 0.3),
    facilityAmount: Math.min(100, mockUserData.averageMonthlyIncome * 0.1)
  };

  const handleRequestAdvance = async () => {
    if (!advanceAmount) return;
    const amount = Number(advanceAmount);
    
    if (amount > limits.advance) {
      alert('Le montant dépasse votre limite d\'avance');
      return;
    }
    
    setIsProcessing(true);
    setIsChecking(true);

    // Simulate credit check process
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsChecking(false);
    
    // Simulate approval
    const approved = amount <= limits.advance;
    setIsApproved(approved);
    
    if (approved) {
      setApprovedAmount(amount);
      await new Promise(resolve => setTimeout(resolve, 1000));
      onAdvanceRequested?.(amount);
      updateCashbackBalance(platffpayBalance + amount);
    }
    
    setIsProcessing(false);
  };

  const renderCheckingStatus = () => (
    <div className="bg-purple-900 p-6 rounded-xl">
      <div className="text-center">
        <Loader className="animate-spin text-yellow-400 mx-auto mb-4" size={32} />
        <h3 className="text-lg font-bold text-white mb-2">Analyse en cours</h3>
        <p className="text-gray-300 mb-4">Vérification de votre éligibilité...</p>
        <div className="space-y-4">
          <div className="bg-purple-800 p-3 rounded-lg">
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-300">Historique des transactions</span>
              <Check className="text-green-400" size={16} />
            </div>
          </div>
          <div className="bg-purple-800 p-3 rounded-lg">
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-300">Solde moyen</span>
              <Check className="text-green-400" size={16} />
            </div>
          </div>
          <div className="bg-purple-800 p-3 rounded-lg">
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-300">Comportement de paiement</span>
              <Check className="text-green-400" size={16} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderApprovalStatus = () => (
    <div className="bg-purple-900 p-6 rounded-xl text-center">
      <div className="w-16 h-16 bg-green-400 rounded-full flex items-center justify-center mx-auto mb-4">
        <Check className="text-white" size={32} />
      </div>
      <h3 className="text-xl font-bold text-white mb-2">Avance approuvée !</h3>
      <p className="text-gray-300 mb-6">
        Votre demande d'avance de {approvedAmount}€ a été acceptée et créditée sur votre compte PLATFFPAY.
      </p>
      <div className="bg-purple-800 p-4 rounded-xl mb-6">
        <p className="text-sm text-gray-300 mb-1">Nouveau solde PLATFFPAY</p>
        <p className="text-2xl font-bold text-yellow-400">{(platffpayBalance + approvedAmount).toFixed(2)}€</p>
      </div>
      <button
        onClick={() => {
          setShowSimulation(false);
          setIsApproved(false);
          setAdvanceAmount('');
        }}
        className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
      >
        Retour à l'accueil
      </button>
    </div>
  );

  if (isChecking) {
    return renderCheckingStatus();
  }

  if (isApproved) {
    return renderApprovalStatus();
  }

  return (
    <div className="space-y-6">
      {/* Info Card */}
      <div className="bg-purple-900 p-6 rounded-xl">
        <div className="flex items-center gap-3 mb-4">
          <Gift className="text-yellow-400" size={24} />
          <div>
            <h2 className="text-xl font-bold text-white">Solutions de financement</h2>
            <p className="text-gray-300">Avances et facilités de caisse à 0%</p>
          </div>
        </div>

        <p className="text-sm text-gray-300 mb-4">
          Les solutions de financement PLATFFPAY sont financées par le Fonds PLATFFPay, alimenté par 5% des bénéfices de la plateforme. 
          Ce système unique permet d'offrir des avances jusqu'à 200€ et facilités de caisse sans frais aux membres vérifiés.
        </p>

        <div className="grid grid-cols-2 gap-4">
          <div className="bg-purple-800 p-4 rounded-xl">
            <Gift className="text-yellow-400 mb-2" size={20} />
            <div className="flex items-center gap-1">
              <p className="text-lg font-bold text-white">Jusqu'à 200€</p>
              <span className="text-yellow-400 text-xs">*</span>
            </div>
            <p className="text-sm text-gray-300">Avance disponible</p>
          </div>
          <div className="bg-purple-800 p-4 rounded-xl">
            <Wallet className="text-yellow-400 mb-2" size={20} />
            <div className="flex items-center gap-1">
              <p className="text-lg font-bold text-white">Jusqu'à 100€</p>
              <span className="text-yellow-400 text-xs">*</span>
            </div>
            <p className="text-sm text-gray-300">Facilité de caisse</p>
          </div>
        </div>
        <p className="text-xs text-yellow-400 mt-2">* Sous réserve de vérification PLATFF</p>
      </div>

      {/* Simulation Form */}
      {showSimulation ? (
        <div className="bg-purple-900 p-6 rounded-xl">
          <h3 className="text-lg font-bold text-white mb-4">Demande d'avance</h3>
          
          <div className="space-y-4">
            <div>
              <label className="text-white text-sm mb-1 block">Montant souhaité (€)</label>
              <input
                type="number"
                value={advanceAmount}
                onChange={(e) => setAdvanceAmount(e.target.value)}
                max={limits.advance}
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                placeholder={`Maximum ${limits.advance}€`}
              />
            </div>

            <div className="bg-purple-800 p-4 rounded-xl">
              <div className="flex items-center gap-2 mb-2">
                <Clock className="text-yellow-400" size={16} />
                <span className="text-white">Remboursement sous 2 mois</span>
              </div>
              <div className="text-sm text-gray-300">
                <p>• Taux d'intérêt : 0%</p>
                <p>• Sans frais</p>
              </div>
            </div>

            <button
              onClick={handleRequestAdvance}
              disabled={!advanceAmount || isProcessing || Number(advanceAmount) > limits.advance}
              className={`w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold flex items-center justify-center gap-2 ${
                !advanceAmount || isProcessing || Number(advanceAmount) > limits.advance
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-yellow-300'
              }`}
            >
              {isProcessing ? (
                'Traitement en cours...'
              ) : (
                <>
                  Demander une avance
                  <ArrowRight size={20} />
                </>
              )}
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={() => setShowSimulation(true)}
          className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2"
        >
          <Calculator size={20} />
          Simuler une avance
        </button>
      )}

      {/* Info Box */}
      <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
        <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
        <p className="text-sm text-gray-300">
          Les avances PLATFFPAY sont disponibles pour les membres vérifiés et sont remboursables sous 2 mois sans frais ni intérêts.
        </p>
      </div>
    </div>
  );
}