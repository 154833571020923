import React, { useState } from 'react';
import { 
  Clock, 
  Languages, 
  Lock, 
  Shield, 
  Info, 
  X,
  Check,
  MoreVertical,
  Eye,
  EyeOff,
  Globe,
  Zap
} from 'lucide-react';

interface MessageOptionsMenuProps {
  onClose: () => void;
  onSettingsChange: (settings: MessageSettings) => void;
  currentSettings: MessageSettings;
}

export interface MessageSettings {
  isEncrypted: boolean;
  isEphemeral: boolean;
  autoTranslate: boolean;
  expiryTime: number;
  targetLanguage: string;
}

export function MessageOptionsMenu({ onClose, onSettingsChange, currentSettings }: MessageOptionsMenuProps) {
  const [settings, setSettings] = useState(currentSettings);

  const handleChange = (key: keyof MessageSettings, value: any) => {
    const newSettings = { ...settings, [key]: value };
    setSettings(newSettings);
    onSettingsChange(newSettings);
  };

  return (
    <div className="absolute top-full right-0 mt-2 bg-purple-900 rounded-xl shadow-lg overflow-hidden w-72 z-50">
      <div className="p-4 border-b border-purple-800">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Shield className="text-yellow-400" size={20} />
            <h3 className="text-white font-medium">Options de message</h3>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={20} />
          </button>
        </div>
      </div>

      <div className="p-4 space-y-4">
        {/* Encrypted Messages */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Lock className="text-yellow-400" size={18} />
            <div>
              <p className="text-white text-sm">Chiffrement</p>
              <p className="text-xs text-gray-400">Chiffrement de bout en bout</p>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.isEncrypted}
              onChange={(e) => handleChange('isEncrypted', e.target.checked)}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-purple-700 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
          </label>
        </div>

        {/* Ephemeral Messages */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Clock className="text-yellow-400" size={18} />
            <div>
              <p className="text-white text-sm">Message éphémère</p>
              <p className="text-xs text-gray-400">Disparaît après lecture</p>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.isEphemeral}
              onChange={(e) => handleChange('isEphemeral', e.target.checked)}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-purple-700 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
          </label>
        </div>

        {settings.isEphemeral && (
          <select
            value={settings.expiryTime}
            onChange={(e) => handleChange('expiryTime', Number(e.target.value))}
            className="w-full bg-purple-700 text-white rounded-lg px-3 py-2 text-sm"
          >
            <option value={60000}>1 minute</option>
            <option value={300000}>5 minutes</option>
            <option value={3600000}>1 heure</option>
            <option value={86400000}>24 heures</option>
          </select>
        )}

        {/* Live Translation */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Languages className="text-yellow-400" size={18} />
            <div>
              <p className="text-white text-sm">Traduction IA</p>
              <p className="text-xs text-gray-400">Traduction en temps réel</p>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={settings.autoTranslate}
              onChange={(e) => handleChange('autoTranslate', e.target.checked)}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-purple-700 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
          </label>
        </div>

        {settings.autoTranslate && (
          <select
            value={settings.targetLanguage}
            onChange={(e) => handleChange('targetLanguage', e.target.value)}
            className="w-full bg-purple-700 text-white rounded-lg px-3 py-2 text-sm"
          >
            <option value="fr">Français</option>
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
        )}

        {/* Info Box */}
        <div className="bg-purple-700 p-3 rounded-lg flex items-start gap-2">
          <Info className="text-yellow-400 flex-shrink-0 mt-1" size={16} />
          <p className="text-sm text-gray-300">
            Les messages sont modérés par notre IA pour maintenir une communauté bienveillante.
          </p>
        </div>
      </div>
    </div>
  );
}