import React, { useState } from 'react';
import { VideoPlayer } from '../social/VideoPlayer';
import { StreamingHeader } from './streaming/StreamingHeader';
import { CategoryNav } from './streaming/CategoryNav';
import { ContentGrid } from './streaming/ContentGrid';
import { UploadModal } from './streaming/UploadModal';
import { streamingContent } from '../../data/streamingCategories';
import { useVerification } from '../../contexts/VerificationContext';
import type { StreamContent } from '../../types/streaming';

export function StreamingSection() {
  const [selectedContent, setSelectedContent] = useState<StreamContent | null>(null);
  const [activeCategory, setActiveCategory] = useState<string>('all');
  const [activeSubcategory, setActiveSubcategory] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { isVerified, startVerification } = useVerification();

  const filteredContent = streamingContent.filter(content => {
    const matchesCategory = activeCategory === 'all' || content.category === activeCategory;
    const matchesSubcategory = !activeSubcategory || content.subcategory === activeSubcategory;
    const matchesSearch = !searchQuery || 
      content.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      content.description.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSubcategory && matchesSearch;
  });

  const handleUploadClick = () => {
    if (!isVerified) {
      startVerification();
    } else {
      setShowUploadModal(true);
    }
  };

  const handleUpload = (data: any) => {
    console.log('Upload data:', data);
    setShowUploadModal(false);
  };

  if (selectedContent) {
    return (
      <div className="max-w-6xl mx-auto p-4">
        <button 
          onClick={() => setSelectedContent(null)}
          className="text-white mb-4 hover:text-yellow-400 transition-colors flex items-center gap-2"
        >
          ← Retour
        </button>
        <VideoPlayer src={selectedContent.videoUrl} title={selectedContent.title} />
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto space-y-6">
      <div className="bg-purple-900 p-4 sm:p-6 rounded-xl">
        <StreamingHeader
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          onUploadClick={handleUploadClick}
          isVerified={isVerified}
        />
        <CategoryNav
          activeCategory={activeCategory}
          activeSubcategory={activeSubcategory}
          onCategoryChange={setActiveCategory}
          onSubcategoryChange={setActiveSubcategory}
        />
      </div>

      <ContentGrid 
        content={filteredContent}
        onContentSelect={setSelectedContent}
      />

      {showUploadModal && (
        <UploadModal
          onClose={() => setShowUploadModal(false)}
          onUpload={handleUpload}
        />
      )}
    </div>
  );
}