import React from 'react';
import { 
  ShoppingBag, 
  Calendar, 
  Users, 
  Wallet, 
  Globe, 
  Zap, 
  Shield, 
  MessageCircle,
  Heart,
  Briefcase,
  TrendingUp,
  CreditCard,
  Building,
  Gift,
  Search,
  Bot
} from 'lucide-react';

interface FeaturesSectionProps {
  onNavigate?: (section: string) => void;
}

const features = [
  {
    id: 'shop',
    icon: ShoppingBag,
    label: 'Marketplace Intelligent',
    description: 'Prix dynamiques et comparateur IA en temps réel',
    color: 'from-green-400 to-emerald-600',
    delay: 0,
    section: 'shop'
  },
  {
    id: 'bookings',
    icon: Calendar,
    label: 'Réservations Smart',
    description: 'Algorithme prédictif pour les meilleurs tarifs',
    color: 'from-blue-400 to-blue-600',
    delay: 100,
    section: 'bookings'
  },
  {
    id: 'social',
    icon: Users,
    label: 'Réseau Social Sécurisé',
    description: 'Communauté vérifiée avec modération IA',
    color: 'from-purple-400 to-purple-600',
    delay: 200,
    section: 'social'
  },
  {
    id: 'cashback',
    icon: CreditCard,
    label: 'PLATFFPAY',
    description: 'Cashback instantané et avances sans frais',
    color: 'from-yellow-400 to-yellow-600',
    delay: 300,
    section: 'cashback'
  },
  {
    id: 'impact',
    icon: Globe,
    label: 'Fonds Communautaire',
    description: '15% des bénéfices reversés à la communauté',
    color: 'from-indigo-400 to-indigo-600',
    delay: 400,
    section: 'impact'
  },
  {
    id: 'dynamic-pricing',
    icon: TrendingUp,
    label: 'Prix Dynamiques',
    description: 'Ajustement automatique pour être le plus compétitif',
    color: 'from-red-400 to-red-600',
    delay: 500,
    section: 'shop'
  },
  {
    id: 'ai',
    icon: Bot,
    label: 'IA Intégrée',
    description: 'Traduction, modération et recommandations',
    color: 'from-pink-400 to-pink-600',
    delay: 600,
    section: 'social'
  },
  {
    id: 'rewards',
    icon: Gift,
    label: 'Récompenses Créateurs',
    description: '50% du Fonds dédié aux créateurs de contenu',
    color: 'from-orange-400 to-orange-600',
    delay: 700,
    section: 'social'
  },
  {
    id: 'banking',
    icon: Building,
    label: 'Services Bancaires',
    description: 'IBAN et carte virtuelle sans frais',
    color: 'from-teal-400 to-teal-600',
    delay: 800,
    section: 'cashback'
  },
  {
    id: 'search',
    icon: Search,
    label: 'Comparateur Intelligent',
    description: 'Analyse des prix en temps réel',
    color: 'from-cyan-400 to-cyan-600',
    delay: 900,
    section: 'shop'
  },
  {
    id: 'security',
    icon: Shield,
    label: 'Protection Maximale',
    description: 'Vérification d\'identité et transactions sécurisées',
    color: 'from-violet-400 to-violet-600',
    delay: 1000,
    section: 'social'
  },
  {
    id: 'all-in-one',
    icon: Zap,
    label: 'Tout-en-Un',
    description: 'Une seule app pour tous vos besoins',
    color: 'from-amber-400 to-amber-600',
    delay: 1100,
    section: 'home'
  }
];

export function FeaturesSection({ onNavigate }: FeaturesSectionProps) {
  const handleFeatureClick = (section: string) => {
    if (onNavigate) {
      onNavigate(section);
    }
  };

  return (
    <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
      {features.map((feature, index) => (
        <button
          key={feature.id}
          onClick={() => handleFeatureClick(feature.section)}
          className="group relative bg-purple-900 rounded-xl p-4 sm:p-6 overflow-hidden transform hover:scale-105 transition-all duration-300 touch-feedback text-left w-full"
          style={{ animationDelay: `${feature.delay}ms` }}
        >
          {/* Animated gradient background */}
          <div className={`absolute inset-0 bg-gradient-to-br ${feature.color} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />
          
          <div className="relative">
            <div className="bg-purple-800/50 w-10 h-10 sm:w-12 sm:h-12 rounded-lg flex items-center justify-center mb-4 group-hover:scale-110 transition-transform">
              <feature.icon className="text-yellow-400" size={20} />
            </div>
            
            <h3 className="text-lg sm:text-xl font-bold text-white mb-2">{feature.label}</h3>
            <p className="text-sm sm:text-base text-gray-300">{feature.description}</p>
            
            <div className="mt-4 flex items-center gap-2 text-yellow-400 opacity-0 group-hover:opacity-100 transition-opacity">
              <span className="text-sm font-medium">En savoir plus</span>
              <div className="w-4 h-4 rounded-full bg-yellow-400/10 flex items-center justify-center group-hover:translate-x-1 transition-transform">
                <span className="text-xs">→</span>
              </div>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}