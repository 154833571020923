import React from 'react';
import { Gift, Globe, Users, TrendingUp } from 'lucide-react';

interface ShopImpactStatsProps {
  stats: {
    totalSales: number;
    totalRevenue: number;
    totalCashback: number;
    totalImpact: number;
    verifiedCustomers: number;
    monthlyGrowth: number;
  };
}

export function ShopImpactStats({ stats }: ShopImpactStatsProps) {
  return (
    <div className="bg-purple-800 p-4 rounded-xl">
      <h3 className="text-lg font-bold text-white mb-4 flex items-center gap-2">
        <Globe className="text-yellow-400" />
        Impact des ventes
      </h3>

      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        <div className="bg-purple-700/50 p-3 rounded-lg">
          <div className="flex items-center gap-2 mb-1">
            <Gift className="text-green-400" size={16} />
            <span className="text-white text-sm">Cashback généré</span>
          </div>
          <p className="text-xl font-bold text-green-400">
            {stats.totalCashback.toFixed(2)}€
          </p>
          <p className="text-xs text-gray-300">pour vos clients</p>
        </div>

        <div className="bg-purple-700/50 p-3 rounded-lg">
          <div className="flex items-center gap-2 mb-1">
            <Globe className="text-blue-400" size={16} />
            <span className="text-white text-sm">Impact total</span>
          </div>
          <p className="text-xl font-bold text-blue-400">
            {stats.totalImpact.toFixed(2)}€
          </p>
          <p className="text-xs text-gray-300">au Fonds Communautaire</p>
        </div>

        <div className="bg-purple-700/50 p-3 rounded-lg">
          <div className="flex items-center gap-2 mb-1">
            <Users className="text-yellow-400" size={16} />
            <span className="text-white text-sm">Clients vérifiés</span>
          </div>
          <p className="text-xl font-bold text-yellow-400">
            {stats.verifiedCustomers}
          </p>
          <p className="text-xs text-gray-300">sur {stats.totalSales} ventes</p>
        </div>
      </div>

      <div className="mt-4 p-3 bg-purple-700/50 rounded-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <TrendingUp className="text-yellow-400" size={16} />
            <span className="text-white text-sm">Croissance mensuelle</span>
          </div>
          <span className="text-green-400 font-bold">+{stats.monthlyGrowth}%</span>
        </div>
        <div className="mt-2 w-full bg-purple-600 rounded-full h-2">
          <div 
            className="bg-yellow-400 h-2 rounded-full transition-all duration-500"
            style={{ width: `${stats.monthlyGrowth}%` }}
          />
        </div>
      </div>
    </div>
  );
}