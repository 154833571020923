import React, { useState } from 'react';
import { mockCourses } from '../../data/mockSocialData';
import { Clock, Star, Users, BadgeCheck, Globe, BookOpen, Shield, Gift, Info } from 'lucide-react';

interface EnrollmentModalProps {
  course: any;
  onClose: () => void;
  onConfirm: () => void;
}

function EnrollmentModal({ course, onClose, onConfirm }: EnrollmentModalProps) {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleEnroll = async () => {
    setIsProcessing(true);
    // Simulate enrollment process
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsProcessing(false);
    onConfirm();
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md animate-slide-up">
        <div className="text-center mb-6">
          <div className="w-16 h-16 bg-yellow-400 rounded-full flex items-center justify-center mx-auto mb-4">
            <BookOpen className="text-purple-900" size={32} />
          </div>
          <h3 className="text-xl font-bold text-white mb-2">{course.title}</h3>
          <p className="text-gray-300">
            {course.price === 0 ? 'Formation gratuite' : `Prix: ${course.price}€`}
          </p>
        </div>

        <div className="space-y-4 mb-6">
          <div className="bg-purple-800 p-4 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Shield className="text-yellow-400" size={20} />
              <p className="text-white font-medium">Ce que vous obtenez</p>
            </div>
            <ul className="space-y-2 text-sm text-gray-300">
              <li className="flex items-center gap-2">
                <BookOpen size={16} className="text-yellow-400" />
                Accès à vie au contenu
              </li>
              <li className="flex items-center gap-2">
                <Gift size={16} className="text-yellow-400" />
                Certificat de réussite
              </li>
              <li className="flex items-center gap-2">
                <Globe size={16} className="text-yellow-400" />
                Impact communautaire de {course.communityImpact}€
              </li>
            </ul>
          </div>

          <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
            <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <p className="text-sm text-gray-300">
              En vous inscrivant, vous contribuez au Fonds Communautaire et soutenez la création de contenu éducatif de qualité.
            </p>
          </div>
        </div>

        <div className="flex gap-3">
          <button
            onClick={onClose}
            className="flex-1 bg-purple-800 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors"
          >
            Annuler
          </button>
          <button
            onClick={handleEnroll}
            disabled={isProcessing}
            className="flex-1 bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2"
          >
            {isProcessing ? (
              <>
                <div className="w-5 h-5 border-2 border-purple-900 border-t-transparent rounded-full animate-spin" />
                Traitement...
              </>
            ) : (
              "S'inscrire"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export function LearningSection() {
  const [enrollingCourse, setEnrollingCourse] = useState<any>(null);
  const [enrolledCourses, setEnrolledCourses] = useState<string[]>([]);

  const handleEnrollment = (course: any) => {
    setEnrollingCourse(course);
  };

  const handleConfirmEnrollment = () => {
    if (enrollingCourse) {
      setEnrolledCourses(prev => [...prev, enrollingCourse.id]);
      setEnrollingCourse(null);
    }
  };

  return (
    <div className="space-y-6">
      {mockCourses.map(course => (
        <div key={course.id} className="bg-purple-900 rounded-xl overflow-hidden">
          <div className="relative">
            <img src={course.thumbnail} alt="" className="w-full h-48 object-cover" />
            <div className="absolute top-4 right-4 bg-purple-900/80 px-3 py-1 rounded-full text-white flex items-center gap-2">
              <Star size={16} className="text-yellow-400" fill="currentColor" />
              {course.rating}
            </div>
          </div>

          <div className="p-6">
            <div className="flex items-start gap-4 mb-4">
              <img src={course.instructor.avatar} alt="" className="w-12 h-12 rounded-full" />
              <div>
                <div className="flex items-center gap-2">
                  <h3 className="font-semibold text-white">{course.instructor.name}</h3>
                  {course.instructor.verified && (
                    <BadgeCheck size={16} className="text-yellow-400" />
                  )}
                </div>
                <p className="text-sm text-gray-300">{course.level}</p>
              </div>
            </div>

            <h2 className="text-2xl font-bold text-white mb-2">{course.title}</h2>
            <p className="text-gray-300 mb-4">{course.description}</p>

            <div className="grid grid-cols-3 gap-4 mb-4">
              <div className="flex items-center gap-2 text-gray-300">
                <Clock size={16} />
                <span>{course.duration}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-300">
                <BookOpen size={16} />
                <span>{course.level}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-300">
                <Users size={16} />
                <span>{course.students.toLocaleString()}</span>
              </div>
            </div>

            <div className="space-y-2 mb-4">
              {course.chapters.map((chapter, index) => (
                <div key={index} className="bg-purple-800 p-3 rounded-lg flex justify-between items-center">
                  <span className="text-white">{chapter.title}</span>
                  <span className="text-gray-300">{chapter.duration}</span>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <span className="text-xl font-bold text-white">
                  {course.price === 0 ? 'Gratuit' : `${course.price}€`}
                </span>
                <span className="text-sm text-gray-300">{course.duration}</span>
              </div>
              <div className="flex items-center gap-2 text-gray-300">
                <Globe size={16} />
                <span>Impact: {course.communityImpact}€</span>
              </div>
            </div>

            <button 
              onClick={() => handleEnrollment(course)}
              disabled={enrolledCourses.includes(course.id)}
              className={`w-full ${
                enrolledCourses.includes(course.id)
                  ? 'bg-green-400 cursor-not-allowed'
                  : 'bg-yellow-400 hover:bg-yellow-300'
              } text-purple-900 py-3 rounded-full font-semibold transition-colors`}
            >
              {enrolledCourses.includes(course.id) ? 'Inscrit' : "S'inscrire"}
            </button>
          </div>
        </div>
      ))}

      {enrollingCourse && (
        <EnrollmentModal
          course={enrollingCourse}
          onClose={() => setEnrollingCourse(null)}
          onConfirm={handleConfirmEnrollment}
        />
      )}
    </div>
  );
}