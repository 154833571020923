import React, { useState } from 'react';
import { Calendar, MapPin, Users, BadgeCheck, Globe, Ticket, Rocket, Music, Heart } from 'lucide-react';
import { mockEvents } from '../../data/mockSocialData';
import { EventMatchingModal } from './events/EventMatchingModal';

export function EventsSection() {
  const [showMatching, setShowMatching] = useState(false);
  const [showMatches, setShowMatches] = useState(false);

  const handleFindMatches = () => {
    setShowMatching(false);
    setShowMatches(true);
  };

  return (
    <div className="space-y-6">
      {/* Event Recommendation */}
      <div className="bg-purple-900 p-6 rounded-xl text-center">
        <h2 className="text-2xl font-bold text-white mb-4">Découvrez des événements qui vous correspondent</h2>
        <p className="text-gray-300 mb-6">
          Notre algorithme analyse vos goûts musicaux, vos centres d'intérêt et votre localisation pour vous proposer des événements personnalisés
        </p>
        <button
          onClick={() => setShowMatching(true)}
          className="bg-yellow-400 text-purple-900 px-8 py-4 rounded-full font-semibold hover:bg-yellow-300 transition-colors inline-flex items-center gap-2"
        >
          <Rocket size={20} />
          Laissez PLATFF trouver vos événements
        </button>
      </div>

      {/* Events Grid - Only shown after matching */}
      {showMatches && (
        <div className="space-y-6">
          {mockEvents.map(event => (
            <div key={event.id} className="bg-purple-900 rounded-xl overflow-hidden animate-fade-in">
              <div className="relative">
                <img src={event.image} alt="" className="w-full h-48 object-cover" />
                <div className="absolute top-4 right-4 bg-purple-900/80 px-3 py-1 rounded-full text-white flex items-center gap-2">
                  <Users size={16} />
                  {event.registered}/{event.capacity}
                </div>
              </div>

              <div className="p-6">
                <div className="flex items-start gap-4 mb-4">
                  <img src={event.organizer.avatar} alt="" className="w-12 h-12 rounded-full" />
                  <div>
                    <div className="flex items-center gap-2">
                      <h3 className="font-semibold text-white">{event.organizer.name}</h3>
                      {event.organizer.verified && (
                        <BadgeCheck size={16} className="text-yellow-400" />
                      )}
                    </div>
                    <p className="text-sm text-gray-300">{event.category}</p>
                  </div>
                </div>

                <h2 className="text-2xl font-bold text-white mb-2">{event.title}</h2>
                <p className="text-gray-300 mb-4">{event.description}</p>

                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="flex items-center gap-2 text-gray-300">
                    <Calendar size={16} />
                    <span>{new Date(event.date).toLocaleDateString()} à {event.time}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-300">
                    <MapPin size={16} />
                    <span>{event.location}</span>
                  </div>
                </div>

                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <Ticket size={16} className="text-yellow-400" />
                    <span className="text-xl font-bold text-white">
                      {event.price === 0 ? 'Gratuit' : `${event.price}€`}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-300">
                    <Globe size={16} />
                    <span>Impact: {event.communityImpact}€</span>
                  </div>
                </div>

                <button className="w-full bg-yellow-400 text-purple-900 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors">
                  S'inscrire
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Event Matching Modal */}
      {showMatching && (
        <EventMatchingModal 
          onClose={() => setShowMatching(false)}
          onFindMatches={handleFindMatches}
        />
      )}
    </div>
  );
}