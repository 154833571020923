import React from 'react';
import { Check, Shield, BadgeCheck } from 'lucide-react';

interface VerificationSuccessProps {
  onClose: () => void;
}

export function VerificationSuccess({ onClose }: VerificationSuccessProps) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md text-center">
        <div className="w-16 h-16 bg-green-400 rounded-full flex items-center justify-center mx-auto mb-4">
          <Check className="text-white" size={32} />
        </div>
        <h3 className="text-xl font-bold text-white mb-2">Vérification réussie !</h3>
        <p className="text-gray-300 mb-6">
          Votre compte est maintenant vérifié. Vous avez accès à toutes les fonctionnalités PLATFF.
        </p>

        <div className="bg-purple-800 p-4 rounded-xl mb-6">
          <div className="flex items-center gap-2 mb-2">
            <BadgeCheck className="text-yellow-400" size={20} />
            <p className="text-white font-medium">Fonctionnalités débloquées</p>
          </div>
          <ul className="space-y-2 text-sm text-gray-300">
            <li className="flex items-center gap-2">
              <Shield size={16} className="text-yellow-400" />
              Messagerie sécurisée
            </li>
            <li className="flex items-center gap-2">
              <Shield size={16} className="text-yellow-400" />
              Récompenses créateurs
            </li>
            <li className="flex items-center gap-2">
              <Shield size={16} className="text-yellow-400" />
              Fonds Communautaire
            </li>
          </ul>
        </div>

        <button
          onClick={onClose}
          className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
        >
          Continuer
        </button>
      </div>
    </div>
  );
}