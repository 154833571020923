import React, { useState, useEffect } from 'react';
import { Calendar, Info, Plus, Minus, ArrowRight } from 'lucide-react';

interface DateSelectionModalProps {
  event: {
    title: string;
    location: string;
    date: string;
  };
  onClose: () => void;
  onConfirm: (dates: { checkIn: string; checkOut: string; nights: number }) => void;
}

export function DateSelectionModal({ event, onClose, onConfirm }: DateSelectionModalProps) {
  const [checkInDate, setCheckInDate] = useState(event.date);
  const [nights, setNights] = useState(1);
  const [checkOutDate, setCheckOutDate] = useState('');

  useEffect(() => {
    const date = new Date(checkInDate);
    date.setDate(date.getDate() + nights);
    setCheckOutDate(date.toISOString().split('T')[0]);
  }, [checkInDate, nights]);

  const handleConfirm = () => {
    onConfirm({
      checkIn: checkInDate,
      checkOut: checkOutDate,
      nights: nights
    });
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-md overflow-hidden">
        <div className="p-4">
          <h2 className="text-lg font-bold text-white mb-1">{event.title}</h2>
          <p className="text-sm text-gray-300 mb-4">{event.location}</p>

          <div className="bg-purple-800 p-4 rounded-xl mb-4">
            <div className="flex items-center gap-2 mb-4">
              <Calendar className="text-yellow-400" size={20} />
              <h3 className="font-semibold text-white">Dates de séjour</h3>
            </div>

            <div className="space-y-4">
              <div>
                <label className="text-gray-300 text-sm block mb-1">Date d'arrivée</label>
                <input
                  type="date"
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                  min={event.date}
                  className="w-full bg-purple-700 text-white rounded-lg px-3 py-2 text-sm focus:ring-2 focus:ring-yellow-400"
                />
              </div>

              <div>
                <label className="text-gray-300 text-sm block mb-1">Nombre de nuits</label>
                <div className="flex items-center gap-3">
                  <button
                    onClick={() => setNights(Math.max(1, nights - 1))}
                    className="bg-purple-700 text-white p-2 rounded-lg hover:bg-purple-600 transition-colors"
                  >
                    <Minus size={16} />
                  </button>
                  <span className="text-xl font-bold text-white w-12 text-center">{nights}</span>
                  <button
                    onClick={() => setNights(nights + 1)}
                    className="bg-purple-700 text-white p-2 rounded-lg hover:bg-purple-600 transition-colors"
                  >
                    <Plus size={16} />
                  </button>
                </div>
              </div>

              <div className="flex items-start gap-2 bg-purple-700/50 p-3 rounded-lg">
                <Info className="text-yellow-400 flex-shrink-0 mt-0.5" size={16} />
                <div className="space-y-1 text-sm">
                  <p className="text-white">Check-out le {new Date(checkOutDate).toLocaleDateString()}</p>
                  <p className="text-gray-300 text-xs">
                    Nous vous montrerons les hébergements disponibles pour ces dates.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-3">
            <button
              onClick={onClose}
              className="flex-1 bg-purple-800 text-white py-2 rounded-xl text-sm hover:bg-purple-700 transition-colors"
            >
              Annuler
            </button>
            <button
              onClick={handleConfirm}
              className="flex-1 bg-yellow-400 text-purple-900 py-2 rounded-xl text-sm font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-1"
            >
              Voir les hébergements
              <ArrowRight size={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}