import React, { useState, useEffect } from 'react';
import { Clock, Star, Sparkles } from 'lucide-react';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const launchDate = new Date('2026-01-01T00:00:00');
      const difference = launchDate.getTime() - new Date().getTime();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-br from-yellow-400 to-purple-600 rounded-xl blur opacity-50 group-hover:opacity-75 transition-opacity animate-pulse" />
        <div className="relative bg-purple-900 px-6 py-4 rounded-xl backdrop-blur-sm">
          <div className="text-4xl sm:text-5xl font-bold text-white mb-1">{timeLeft.days}</div>
          <div className="text-sm text-gray-300">Jours</div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-br from-yellow-400 to-purple-600 rounded-xl blur opacity-50 group-hover:opacity-75 transition-opacity animate-pulse" />
        <div className="relative bg-purple-900 px-6 py-4 rounded-xl backdrop-blur-sm">
          <div className="text-4xl sm:text-5xl font-bold text-white mb-1">{timeLeft.hours}</div>
          <div className="text-sm text-gray-300">Heures</div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-br from-yellow-400 to-purple-600 rounded-xl blur opacity-50 group-hover:opacity-75 transition-opacity animate-pulse" />
        <div className="relative bg-purple-900 px-6 py-4 rounded-xl backdrop-blur-sm">
          <div className="text-4xl sm:text-5xl font-bold text-white mb-1">{timeLeft.minutes}</div>
          <div className="text-sm text-gray-300">Minutes</div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-br from-yellow-400 to-purple-600 rounded-xl blur opacity-50 group-hover:opacity-75 transition-opacity animate-pulse" />
        <div className="relative bg-purple-900 px-6 py-4 rounded-xl backdrop-blur-sm">
          <div className="text-4xl sm:text-5xl font-bold text-white mb-1">{timeLeft.seconds}</div>
          <div className="text-sm text-gray-300">Secondes</div>
        </div>
      </div>
    </div>
  );
}