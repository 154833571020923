import nextTick from 'next-tick';
import { Buffer } from 'buffer';

if (typeof window !== 'undefined') {
  // Polyfill process
  (window as any).process = {
    nextTick,
    env: {},
    browser: true,
    version: 'v16.0.0',
    versions: { node: 'v16.0.0' },
    platform: 'browser'
  };

  // Ensure global exists
  (window as any).global = window;

  // Add Buffer
  (window as any).Buffer = Buffer;
}

export {};