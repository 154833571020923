import React from 'react';
import { Clock, Star, Globe } from 'lucide-react';
import type { StreamContent } from '../../../types/streaming';

interface ContentGridProps {
  content: StreamContent[];
  onContentSelect: (content: StreamContent) => void;
}

export function ContentGrid({ content, onContentSelect }: ContentGridProps) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {content.map(item => (
        <div
          key={item.id}
          className="bg-purple-900 rounded-xl overflow-hidden group cursor-pointer"
          onClick={() => onContentSelect(item)}
        >
          <div className="relative">
            <img
              src={item.thumbnail}
              alt={item.title}
              className="w-full h-48 object-cover group-hover:scale-105 transition-transform duration-300"
            />
            <div className="absolute top-4 right-4 bg-purple-900/80 px-3 py-1 rounded-full text-white text-sm flex items-center gap-2">
              <Clock size={14} />
              {item.duration}
            </div>
          </div>

          <div className="p-4">
            <div className="flex items-start justify-between mb-2">
              <div>
                <h3 className="text-lg font-bold text-white">{item.title}</h3>
                <div className="flex items-center gap-4 text-gray-300 mt-1">
                  <span className="text-sm">{item.year}</span>
                  <div className="flex items-center gap-1">
                    <Star className="text-yellow-400" size={12} fill="currentColor" />
                    <span className="text-sm">{item.rating}</span>
                  </div>
                </div>
              </div>
            </div>

            <p className="text-gray-300 text-sm line-clamp-2 mb-4">{item.description}</p>

            <div className="flex items-center justify-between text-sm">
              <div className="flex flex-wrap gap-2">
                {item.genre.slice(0, 2).map((genre, index) => (
                  <span key={index} className="bg-purple-800 text-white px-2 py-1 rounded-full">
                    {genre}
                  </span>
                ))}
              </div>
              <div className="flex items-center gap-2 text-blue-400">
                <Globe size={16} />
                <span>{item.communityImpact}€</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}