import React from 'react';
import { Package, DollarSign, Users, TrendingUp } from 'lucide-react';

export function ShopStats() {
  const stats = {
    products: 12,
    sales: 45,
    revenue: 1250,
    growth: 25
  };

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <div className="bg-purple-800 p-4 rounded-xl">
        <div className="flex items-center gap-2 mb-2">
          <Package className="text-yellow-400" size={20} />
          <span className="text-white">Produits</span>
        </div>
        <p className="text-2xl font-bold text-white">{stats.products}</p>
        <p className="text-sm text-gray-300">actifs</p>
      </div>

      <div className="bg-purple-800 p-4 rounded-xl">
        <div className="flex items-center gap-2 mb-2">
          <DollarSign className="text-green-400" size={20} />
          <span className="text-white">Ventes</span>
        </div>
        <p className="text-2xl font-bold text-white">{stats.sales}</p>
        <p className="text-sm text-gray-300">ce mois</p>
      </div>

      <div className="bg-purple-800 p-4 rounded-xl">
        <div className="flex items-center gap-2 mb-2">
          <Users className="text-blue-400" size={20} />
          <span className="text-white">Revenu</span>
        </div>
        <p className="text-2xl font-bold text-white">{stats.revenue}€</p>
        <p className="text-sm text-gray-300">total</p>
      </div>

      <div className="bg-purple-800 p-4 rounded-xl">
        <div className="flex items-center gap-2 mb-2">
          <TrendingUp className="text-purple-400" size={20} />
          <span className="text-white">Croissance</span>
        </div>
        <p className="text-2xl font-bold text-white">+{stats.growth}%</p>
        <p className="text-sm text-gray-300">ce mois</p>
      </div>
    </div>
  );
}