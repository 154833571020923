import React, { useState } from 'react';
import { 
  Settings, 
  Globe, 
  Truck, 
  CreditCard, 
  Shield,
  X,
  Info
} from 'lucide-react';

interface ShopSettingsProps {
  onClose: () => void;
  onSave: (settings: any) => void;
}

export function ShopSettings({ onClose, onSave }: ShopSettingsProps) {
  const [settings, setSettings] = useState({
    shopName: 'Ma Boutique',
    description: '',
    shippingMethods: {
      standard: true,
      express: false,
      pickup: true
    },
    paymentMethods: {
      platffpay: true,
      card: true,
      transfer: false
    },
    autoFulfillment: true,
    notifications: true
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(settings);
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-lg">
        <div className="p-6 border-b border-purple-800">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Settings className="text-yellow-400" size={24} />
              <h2 className="text-xl font-bold text-white">Paramètres de la boutique</h2>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X size={24} />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Basic Info */}
          <div className="space-y-4">
            <div>
              <label className="text-white text-sm mb-2 block">Nom de la boutique</label>
              <input
                type="text"
                value={settings.shopName}
                onChange={(e) => setSettings({ ...settings, shopName: e.target.value })}
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
              />
            </div>

            <div>
              <label className="text-white text-sm mb-2 block">Description</label>
              <textarea
                value={settings.description}
                onChange={(e) => setSettings({ ...settings, description: e.target.value })}
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400 min-h-[100px]"
                placeholder="Décrivez votre boutique..."
              />
            </div>
          </div>

          {/* Shipping Methods */}
          <div className="bg-purple-800 p-4 rounded-xl">
            <div className="flex items-center gap-2 mb-4">
              <Truck className="text-yellow-400" size={20} />
              <h3 className="text-white font-medium">Modes de livraison</h3>
            </div>
            <div className="space-y-3">
              <label className="flex items-center justify-between">
                <span className="text-white">Livraison standard</span>
                <input
                  type="checkbox"
                  checked={settings.shippingMethods.standard}
                  onChange={(e) => setSettings({
                    ...settings,
                    shippingMethods: {
                      ...settings.shippingMethods,
                      standard: e.target.checked
                    }
                  })}
                  className="form-checkbox text-yellow-400"
                />
              </label>
              <label className="flex items-center justify-between">
                <span className="text-white">Livraison express</span>
                <input
                  type="checkbox"
                  checked={settings.shippingMethods.express}
                  onChange={(e) => setSettings({
                    ...settings,
                    shippingMethods: {
                      ...settings.shippingMethods,
                      express: e.target.checked
                    }
                  })}
                  className="form-checkbox text-yellow-400"
                />
              </label>
              <label className="flex items-center justify-between">
                <span className="text-white">Click & Collect</span>
                <input
                  type="checkbox"
                  checked={settings.shippingMethods.pickup}
                  onChange={(e) => setSettings({
                    ...settings,
                    shippingMethods: {
                      ...settings.shippingMethods,
                      pickup: e.target.checked
                    }
                  })}
                  className="form-checkbox text-yellow-400"
                />
              </label>
            </div>
          </div>

          {/* Payment Methods */}
          <div className="bg-purple-800 p-4 rounded-xl">
            <div className="flex items-center gap-2 mb-4">
              <CreditCard className="text-yellow-400" size={20} />
              <h3 className="text-white font-medium">Moyens de paiement</h3>
            </div>
            <div className="space-y-3">
              <label className="flex items-center justify-between">
                <span className="text-white">PLATFFPAY</span>
                <input
                  type="checkbox"
                  checked={settings.paymentMethods.platffpay}
                  onChange={(e) => setSettings({
                    ...settings,
                    paymentMethods: {
                      ...settings.paymentMethods,
                      platffpay: e.target.checked
                    }
                  })}
                  className="form-checkbox text-yellow-400"
                />
              </label>
              <label className="flex items-center justify-between">
                <span className="text-white">Carte bancaire</span>
                <input
                  type="checkbox"
                  checked={settings.paymentMethods.card}
                  onChange={(e) => setSettings({
                    ...settings,
                    paymentMethods: {
                      ...settings.paymentMethods,
                      card: e.target.checked
                    }
                  })}
                  className="form-checkbox text-yellow-400"
                />
              </label>
              <label className="flex items-center justify-between">
                <span className="text-white">Virement bancaire</span>
                <input
                  type="checkbox"
                  checked={settings.paymentMethods.transfer}
                  onChange={(e) => setSettings({
                    ...settings,
                    paymentMethods: {
                      ...settings.paymentMethods,
                      transfer: e.target.checked
                    }
                  })}
                  className="form-checkbox text-yellow-400"
                />
              </label>
            </div>
          </div>

          {/* Additional Settings */}
          <div className="bg-purple-800 p-4 rounded-xl space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Globe className="text-yellow-400" size={20} />
                <span className="text-white">Traitement automatique</span>
              </div>
              <input
                type="checkbox"
                checked={settings.autoFulfillment}
                onChange={(e) => setSettings({ ...settings, autoFulfillment: e.target.checked })}
                className="form-checkbox text-yellow-400"
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Shield className="text-yellow-400" size={20} />
                <span className="text-white">Notifications</span>
              </div>
              <input
                type="checkbox"
                checked={settings.notifications}
                onChange={(e) => setSettings({ ...settings, notifications: e.target.checked })}
                className="form-checkbox text-yellow-400"
              />
            </div>
          </div>

          {/* Info Box */}
          <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
            <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <p className="text-sm text-gray-300">
              Tous les produits sont soumis à une vérification avant publication pour garantir la qualité de notre marketplace.
            </p>
          </div>

          {/* Actions */}
          <div className="flex gap-3">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 bg-purple-800 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="flex-1 bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors"
            >
              Sauvegarder
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}