import React, { useState } from 'react';
import { MapPin, Heart, BadgeCheck, Globe, MessageCircle, Video } from 'lucide-react';
import { mockDatingProfiles } from '../../data/mockSocialData';
import { MatchmakingModal } from './dating/MatchmakingModal';

export function DatingSection() {
  const [showMatchmaking, setShowMatchmaking] = useState(false);
  const [showMatches, setShowMatches] = useState(false);

  const handleFindMatches = () => {
    setShowMatchmaking(false);
    setShowMatches(true);
  };

  return (
    <div className="space-y-6">
      {/* Matchmaking Button */}
      <div className="bg-purple-900 p-6 rounded-xl text-center">
        <h2 className="text-2xl font-bold text-white mb-4">Trouvez l'âme sœur avec PLATFF</h2>
        <p className="text-gray-300 mb-6">
          Notre algorithme analyse votre activité pour vous proposer des profils compatibles
        </p>
        <button
          onClick={() => setShowMatchmaking(true)}
          className="bg-yellow-400 text-purple-900 px-8 py-4 rounded-full font-semibold hover:bg-yellow-300 transition-colors inline-flex items-center gap-2"
        >
          <Heart size={20} />
          Laissez PLATFF trouver votre match
        </button>
      </div>

      {/* Profiles Grid - Only shown after matchmaking */}
      {showMatches && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {mockDatingProfiles.map(profile => (
            <div key={profile.id} className="bg-purple-900 rounded-xl overflow-hidden animate-fade-in">
              <div className="relative">
                <img 
                  src={profile.photos[0]} 
                  alt="" 
                  className="w-full h-64 object-cover"
                />
                <div className="absolute top-4 right-4 flex gap-2">
                  {profile.verified && (
                    <div className="bg-yellow-400 text-purple-900 px-3 py-1 rounded-full text-sm font-semibold flex items-center gap-1">
                      <BadgeCheck size={14} />
                      Vérifié
                    </div>
                  )}
                  {profile.online && (
                    <div className="bg-green-400 text-white px-3 py-1 rounded-full text-sm font-semibold">
                      En ligne
                    </div>
                  )}
                </div>
              </div>

              <div className="p-4">
                <div className="flex items-center justify-between mb-2">
                  <div>
                    <h3 className="text-xl font-bold text-white">{profile.name}, {profile.age}</h3>
                    <div className="flex items-center gap-1 text-gray-300">
                      <MapPin size={14} />
                      <span>{profile.location}</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 text-gray-300">
                    <Globe size={16} />
                    <span>{profile.communityImpact}€ impact</span>
                  </div>
                </div>

                <p className="text-gray-300 mb-4">{profile.bio}</p>

                <div className="flex flex-wrap gap-2 mb-4">
                  {profile.interests.map((interest, index) => (
                    <span 
                      key={index}
                      className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm"
                    >
                      {interest}
                    </span>
                  ))}
                </div>

                <div className="flex gap-2">
                  <button className="flex-1 bg-yellow-400 text-purple-900 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2">
                    <Heart size={18} />
                    J'aime
                  </button>
                  <button className="flex-1 bg-purple-800 text-white py-2 rounded-full font-semibold hover:bg-purple-700 transition-colors flex items-center justify-center gap-2">
                    <MessageCircle size={18} />
                    Message
                  </button>
                  <button className="flex-1 bg-purple-800 text-white py-2 rounded-full font-semibold hover:bg-purple-700 transition-colors flex items-center justify-center gap-2">
                    <Video size={18} />
                    Appel
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Matchmaking Modal */}
      {showMatchmaking && (
        <MatchmakingModal 
          onClose={() => setShowMatchmaking(false)}
          onFindMatches={handleFindMatches}
        />
      )}
    </div>
  );
}