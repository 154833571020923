import React, { useState } from 'react';
import { 
  Music, 
  Play, 
  Pause, 
  SkipForward, 
  SkipBack, 
  Upload,
  Radio,
  List,
  Heart,
  Share2,
  Plus,
  Star,
  Globe,
  Gift,
  Clock,
  BadgeCheck
} from 'lucide-react';
import { useAudioPlayer } from '../../../hooks/useAudioPlayer';
import { ContentInteractions } from './ContentInteractions';
import { UploadModal } from './UploadModal';

interface Track {
  id: string;
  title: string;
  artist: string;
  duration: string;
  cover: string;
  audioUrl: string;
  plays: number;
  likes: number;
}

const mockTracks: Track[] = [
  {
    id: '1',
    title: 'Summer Vibes',
    artist: 'DJ PLATFF',
    duration: '3:45',
    cover: 'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?w=300&h=300&fit=crop',
    audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    plays: 1250,
    likes: 450
  },
  {
    id: '2',
    title: 'Deep House Mix',
    artist: 'DJ PLATFF',
    duration: '4:20',
    cover: 'https://images.unsplash.com/photo-1493225457124-a3eb161ffa5f?w=300&h=300&fit=crop',
    audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3',
    plays: 980,
    likes: 320
  },
  {
    id: '3',
    title: 'Chill Beats',
    artist: 'DJ PLATFF',
    duration: '3:15',
    cover: 'https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?w=300&h=300&fit=crop',
    audioUrl: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3',
    plays: 750,
    likes: 280
  }
];

export function MusicChannel() {
  const [tracks, setTracks] = useState(mockTracks);
  const [currentTrack, setCurrentTrack] = useState<Track | null>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const {
    isPlaying,
    duration,
    currentTime,
    containerRef,
    togglePlay,
    initializeWaveform
  } = useAudioPlayer();

  const handleTrackSelect = (track: Track) => {
    if (currentTrack?.id === track.id) {
      togglePlay();
    } else {
      setCurrentTrack(track);
      initializeWaveform(track.audioUrl);
    }
  };

  const handleReward = (amount: number) => {
    console.log(`Rewarded ${amount}€ for track:`, currentTrack?.title);
  };

  const handleUpload = (trackData: any) => {
    const newTrack: Track = {
      id: trackData.id,
      title: trackData.title,
      artist: 'DJ PLATFF',
      duration: trackData.duration,
      cover: trackData.thumbnailUrl || 'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?w=300&h=300&fit=crop',
      audioUrl: trackData.fileUrl,
      plays: 0,
      likes: 0
    };
    setTracks([newTrack, ...tracks]);
  };

  return (
    <div className="space-y-4 sm:space-y-6">
      {/* Upload Section */}
      <div className="bg-purple-900 p-4 sm:p-6 rounded-xl">
        <div className="flex items-center justify-between mb-4 sm:mb-6">
          <h3 className="text-lg sm:text-xl font-bold text-white flex items-center gap-2">
            <Music className="text-yellow-400" />
            <span className="truncate">Votre Musique</span>
          </h3>
          <button
            onClick={() => setShowUploadModal(true)}
            className="bg-yellow-400 text-purple-900 px-3 sm:px-4 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center gap-2"
          >
            <Upload size={18} />
            <span className="hidden sm:inline">Upload</span>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-2 sm:gap-4">
          <div className="bg-purple-800 p-3 sm:p-4 rounded-xl">
            <Radio className="text-yellow-400 mb-2" size={20} />
            <h4 className="text-white font-semibold text-sm sm:text-base truncate">Mix DJ</h4>
            <p className="text-xs sm:text-sm text-gray-300 truncate">
              Partagez vos mixes
            </p>
          </div>

          <div className="bg-purple-800 p-3 sm:p-4 rounded-xl">
            <Music className="text-yellow-400 mb-2" size={20} />
            <h4 className="text-white font-semibold text-sm sm:text-base truncate">Tracks</h4>
            <p className="text-xs sm:text-sm text-gray-300 truncate">
              Uploadez vos morceaux
            </p>
          </div>
        </div>
      </div>

      {/* Player */}
      {currentTrack && (
        <div className="bg-purple-900 p-4 sm:p-6 rounded-xl">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6">
            <img 
              src={currentTrack.cover}
              alt={currentTrack.title}
              className="w-full sm:w-24 h-48 sm:h-24 object-cover rounded-lg"
            />
            <div className="flex-1">
              <div className="flex items-center gap-2">
                <h4 className="text-white font-semibold truncate">{currentTrack.title}</h4>
                <BadgeCheck className="text-yellow-400 flex-shrink-0" size={16} />
              </div>
              <p className="text-gray-300 text-sm truncate">{currentTrack.artist}</p>
              
              <div className="mt-4">
                <div ref={containerRef} className="mb-4" />
                <div className="flex items-center justify-between text-sm text-gray-300">
                  <span>
                    {Math.floor(currentTime / 60)}:
                    {String(Math.floor(currentTime % 60)).padStart(2, '0')}
                  </span>
                  <span>
                    {Math.floor(duration / 60)}:
                    {String(Math.floor(duration % 60)).padStart(2, '0')}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-4 mt-4">
                <button className="text-white">
                  <SkipBack size={24} />
                </button>
                <button 
                  onClick={togglePlay}
                  className="bg-yellow-400 text-purple-900 p-3 rounded-full hover:bg-yellow-300 transition-colors"
                >
                  {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                </button>
                <button className="text-white">
                  <SkipForward size={24} />
                </button>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <ContentInteractions
              contentId={currentTrack.id}
              contentTitle={currentTrack.title}
              creatorName={currentTrack.artist}
              likes={currentTrack.likes}
              comments={0}
              isVerified={true}
              onReward={handleReward}
            />
          </div>
        </div>
      )}

      {/* Tracks List */}
      <div className="bg-purple-900 p-4 sm:p-6 rounded-xl">
        <h3 className="text-lg sm:text-xl font-bold text-white mb-4 flex items-center gap-2">
          <List className="text-yellow-400" />
          <span className="truncate">Vos Tracks</span>
        </h3>

        <div className="space-y-4">
          {tracks.map(track => (
            <div 
              key={track.id}
              className="bg-purple-800 p-4 rounded-xl flex items-center gap-4"
            >
              <img 
                src={track.cover}
                alt={track.title}
                className="w-16 h-16 rounded-lg flex-shrink-0"
              />
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2">
                  <h4 className="text-white font-semibold truncate">{track.title}</h4>
                  <BadgeCheck className="text-yellow-400 flex-shrink-0" size={16} />
                </div>
                <p className="text-gray-300 text-sm truncate">{track.artist}</p>
                <div className="flex items-center gap-4 mt-2 text-sm text-gray-300">
                  <span className="flex items-center gap-1">
                    <Play size={14} />
                    {track.plays}
                  </span>
                  <span className="flex items-center gap-1">
                    <Heart size={14} />
                    {track.likes}
                  </span>
                  <span className="flex items-center gap-1">
                    <Gift size={14} className="text-yellow-400" />
                    <span className="text-yellow-400">Récompenses disponibles</span>
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <button className="text-gray-300 hover:text-white">
                  <Share2 size={20} />
                </button>
                <button 
                  onClick={() => handleTrackSelect(track)}
                  className="bg-yellow-400 text-purple-900 p-2 rounded-full hover:bg-yellow-300 transition-colors z-10"
                >
                  {currentTrack?.id === track.id && isPlaying ? (
                    <Pause size={20} />
                  ) : (
                    <Play size={20} />
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Upload Modal */}
      {showUploadModal && (
        <UploadModal
          type="music"
          onClose={() => setShowUploadModal(false)}
          onUpload={handleUpload}
        />
      )}
    </div>
  );
}