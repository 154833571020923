import React from 'react';
import { Globe, Users, TrendingUp, Heart } from 'lucide-react';
import { useCountAnimation } from '../../../hooks/useAnimation';

export function ImpactSection() {
  const displayedFund = useCountAnimation(15000);
  const displayedProjects = useCountAnimation(25);
  const displayedMembers = useCountAnimation(1000);

  return (
    <div className="bg-purple-900 rounded-3xl p-8 sm:p-12 relative overflow-hidden">
      {/* Animated background */}
      <div className="absolute inset-0">
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-br from-yellow-400/10 to-transparent rounded-full filter blur-3xl animate-float" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-to-tr from-purple-600/10 to-transparent rounded-full filter blur-3xl animate-float delay-1000" />
      </div>

      <div className="relative">
        <div className="text-center mb-12">
          <div className="inline-flex items-center gap-2 bg-purple-800/50 backdrop-blur-sm px-4 py-2 rounded-full mb-4">
            <Globe className="text-yellow-400" size={20} />
            <span className="text-white font-medium">Notre Impact</span>
          </div>
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
            Ensemble, créons un changement positif
          </h2>
          <p className="text-gray-300 max-w-2xl mx-auto">
            Chaque action sur PLATFF contribue à notre Fonds Communautaire et soutient des projets à impact positif
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <div className="flex items-center gap-3 mb-4">
              <div className="bg-yellow-400/10 p-3 rounded-lg">
                <Globe className="text-yellow-400" size={24} />
              </div>
              <div>
                <p className="text-2xl font-bold text-white">{Math.round(displayedFund).toLocaleString()}€</p>
                <p className="text-sm text-gray-300">Fonds total</p>
              </div>
            </div>
            <div className="w-full bg-purple-700 h-2 rounded-full">
              <div className="bg-yellow-400 h-2 rounded-full animate-pulse" style={{ width: '75%' }} />
            </div>
          </div>

          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <div className="flex items-center gap-3 mb-4">
              <div className="bg-green-400/10 p-3 rounded-lg">
                <TrendingUp className="text-green-400" size={24} />
              </div>
              <div>
                <p className="text-2xl font-bold text-white">+25%</p>
                <p className="text-sm text-gray-300">Croissance mensuelle</p>
              </div>
            </div>
            <div className="w-full bg-purple-700 h-2 rounded-full">
              <div className="bg-green-400 h-2 rounded-full animate-pulse" style={{ width: '25%' }} />
            </div>
          </div>

          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <div className="flex items-center gap-3 mb-4">
              <div className="bg-blue-400/10 p-3 rounded-lg">
                <Users className="text-blue-400" size={24} />
              </div>
              <div>
                <p className="text-2xl font-bold text-white">{Math.round(displayedMembers).toLocaleString()}</p>
                <p className="text-sm text-gray-300">Membres actifs</p>
              </div>
            </div>
            <div className="w-full bg-purple-700 h-2 rounded-full">
              <div className="bg-blue-400 h-2 rounded-full animate-pulse" style={{ width: '85%' }} />
            </div>
          </div>

          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <div className="flex items-center gap-3 mb-4">
              <div className="bg-red-400/10 p-3 rounded-lg">
                <Heart className="text-red-400" size={24} />
              </div>
              <div>
                <p className="text-2xl font-bold text-white">{Math.round(displayedProjects)}</p>
                <p className="text-sm text-gray-300">Projets soutenus</p>
              </div>
            </div>
            <div className="w-full bg-purple-700 h-2 rounded-full">
              <div className="bg-red-400 h-2 rounded-full animate-pulse" style={{ width: '65%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}