import React, { useState, useEffect } from 'react';
import { Heart, Star, Shield, Zap, Video, MessageCircle, BadgeCheck } from 'lucide-react';

interface MatchmakingModalProps {
  onClose: () => void;
  onFindMatches: () => void;
}

export function MatchmakingModal({ onClose, onFindMatches }: MatchmakingModalProps) {
  const [progress, setProgress] = useState({
    interests: 0,
    content: 0,
    verification: 0
  });

  useEffect(() => {
    // Animate progress bars sequentially
    const interestsTimer = setTimeout(() => {
      setProgress(prev => ({ ...prev, interests: 100 }));
    }, 3000);

    const contentTimer = setTimeout(() => {
      setProgress(prev => ({ ...prev, content: 100 }));
    }, 6000);

    const verificationTimer = setTimeout(() => {
      setProgress(prev => ({ ...prev, verification: 100 }));
    }, 9000);

    // Trigger matches display after all analyses are complete
    const completionTimer = setTimeout(() => {
      onFindMatches();
    }, 10000);

    return () => {
      clearTimeout(interestsTimer);
      clearTimeout(contentTimer);
      clearTimeout(verificationTimer);
      clearTimeout(completionTimer);
    };
  }, [onFindMatches]);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-4 w-full max-w-md animate-slide-up">
        {/* Header */}
        <div className="text-center mb-6">
          <div className="w-12 h-12 bg-yellow-400 rounded-full flex items-center justify-center mx-auto mb-3">
            <Heart className="text-purple-900" size={24} />
          </div>
          <h2 className="text-xl font-bold text-white mb-1">PLATFF Matchmaking</h2>
          <p className="text-sm text-gray-300">Analyse de votre profil en cours...</p>
        </div>

        {/* Analysis Steps */}
        <div className="space-y-3">
          <div className="bg-purple-800 p-3 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Star className="text-yellow-400" size={18} />
              <div className="flex-1">
                <h3 className="text-sm font-semibold text-white">Centres d'intérêt</h3>
                <p className="text-xs text-gray-300">Publications, likes, commentaires</p>
              </div>
              <Zap className={`text-yellow-400 ${progress.interests < 100 ? 'animate-pulse' : ''}`} size={18} />
            </div>
            <div className="w-full bg-purple-700 rounded-full h-1.5">
              <div 
                className="bg-yellow-400 h-1.5 rounded-full transition-all duration-1000"
                style={{ width: `${progress.interests}%` }}
              />
            </div>
          </div>

          <div className="bg-purple-800 p-3 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Video className="text-yellow-400" size={18} />
              <div className="flex-1">
                <h3 className="text-sm font-semibold text-white">Contenus</h3>
                <p className="text-xs text-gray-300">Vidéos, musique, événements</p>
              </div>
              <Zap className={`text-yellow-400 ${progress.content < 100 ? 'animate-pulse' : ''}`} size={18} />
            </div>
            <div className="w-full bg-purple-700 rounded-full h-1.5">
              <div 
                className="bg-yellow-400 h-1.5 rounded-full transition-all duration-1000"
                style={{ width: `${progress.content}%` }}
              />
            </div>
          </div>

          <div className="bg-purple-800 p-3 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Shield className="text-yellow-400" size={18} />
              <div className="flex-1">
                <h3 className="text-sm font-semibold text-white">Vérification</h3>
                <p className="text-xs text-gray-300">Sécurité et compatibilité</p>
              </div>
              <Zap className={`text-yellow-400 ${progress.verification < 100 ? 'animate-pulse' : ''}`} size={18} />
            </div>
            <div className="w-full bg-purple-700 rounded-full h-1.5">
              <div 
                className="bg-yellow-400 h-1.5 rounded-full transition-all duration-1000"
                style={{ width: `${progress.verification}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}