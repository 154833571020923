import React, { useState, useEffect } from 'react';
import { Star, MapPin, BadgeCheck, Gift, Globe, Clock, Users } from 'lucide-react';
import { FinalBookingModal } from './event/FinalBookingModal';
import { mockRestaurants } from '../../data/mockRestaurants';

interface RestaurantBookingProps {
  searchCriteria?: {
    location: string;
    checkIn: string;
    guests: number;
  };
}

export function RestaurantBooking({ searchCriteria }: RestaurantBookingProps) {
  const [showFinalBooking, setShowFinalBooking] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState<any>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [restaurants, setRestaurants] = useState(mockRestaurants);

  useEffect(() => {
    if (searchCriteria) {
      setIsLoading(true);
      // Simulate API call
      setTimeout(() => {
        setRestaurants(mockRestaurants);
        setIsLoading(false);
      }, 1500);
    } else {
      setIsLoading(false);
    }
  }, [searchCriteria]);

  const handleBooking = (restaurant: any, time: string) => {
    setSelectedRestaurant({
      ...restaurant,
      dates: {
        checkIn: searchCriteria?.checkIn || new Date().toISOString().split('T')[0],
        time: time,
        guests: searchCriteria?.guests || 2
      }
    });
    setSelectedTime(time);
    setShowFinalBooking(true);
  };

  const handleConfirmBooking = () => {
    console.log('Restaurant booking confirmed:', selectedRestaurant);
    setShowFinalBooking(false);
  };

  if (isLoading) {
    return (
      <div className="text-center py-12">
        <div className="w-12 h-12 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
        <p className="text-white">Recherche des meilleurs restaurants...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {restaurants.map(restaurant => (
        <div key={restaurant.id} className="bg-purple-900 rounded-xl overflow-hidden">
          <div className="relative">
            <img src={restaurant.image} alt="" className="w-full h-48 object-cover" />
            <div className={`absolute top-4 right-4 bg-${restaurant.badge.color}-400 text-purple-900 px-3 py-1 rounded-full text-sm font-semibold flex items-center gap-1`}>
              {restaurant.badge.text}
              <BadgeCheck size={14} />
            </div>
          </div>

          <div className="p-6">
            <div className="flex items-start gap-4 mb-4">
              <img src={restaurant.provider.logo} alt="" className="w-12 h-12 rounded-full" />
              <div>
                <div className="flex items-center gap-2">
                  <h3 className="font-semibold text-white">{restaurant.title}</h3>
                  {restaurant.provider.isVerified && (
                    <BadgeCheck size={16} className="text-yellow-400" />
                  )}
                </div>
                <div className="flex items-center gap-2 text-gray-300">
                  <MapPin size={16} />
                  <span>{restaurant.location}</span>
                </div>
                <div className="flex items-center gap-2 text-gray-300">
                  <Users size={16} />
                  <span>{restaurant.cuisine}</span>
                </div>
              </div>
              <div className="flex items-center gap-1 ml-auto">
                <Star className="text-yellow-400" size={16} fill="currentColor" />
                <span className="text-white">{restaurant.rating}</span>
                <span className="text-gray-300">({restaurant.reviews})</span>
              </div>
            </div>

            <p className="text-gray-300 mb-4">{restaurant.description}</p>

            <div className="mb-4">
              <p className="text-white font-semibold mb-2">Horaires disponibles</p>
              <div className="flex flex-wrap gap-2">
                {restaurant.availableTimes.map(time => (
                  <button
                    key={time}
                    onClick={() => handleBooking(restaurant, time)}
                    className="bg-purple-800 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors"
                  >
                    {time}
                  </button>
                ))}
              </div>
            </div>

            <div className="space-y-2 mb-4">
              <div className="flex items-center gap-2 text-sm">
                <Gift className="text-green-400" size={16} />
                <span className="text-white">{restaurant.cashback}€ crédités sur votre PLATFFPAY</span>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <Globe className="text-blue-400" size={16} />
                <span className="text-white">{restaurant.impact}€ ajoutés au Fonds Communautaire</span>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div>
                <p className="text-2xl font-bold text-white">~{restaurant.price}€</p>
                <p className="text-sm text-gray-300">Prix moyen / pers.</p>
              </div>
            </div>
          </div>
        </div>
      ))}

      {showFinalBooking && selectedRestaurant && (
        <FinalBookingModal
          event={selectedRestaurant}
          onClose={() => setShowFinalBooking(false)}
          onConfirm={handleConfirmBooking}
        />
      )}
    </div>
  );
}