import React, { useState } from 'react';
import { 
  Shield, 
  TrendingUp, 
  Users, 
  CreditCard,
  Edit3,
  Share2,
  MessageCircle,
  Globe,
  ChevronRight,
  BadgeCheck,
  Rocket,
  Target,
  Briefcase
} from 'lucide-react';
import { SocialActions } from '../profile/SocialActions';
import { AdminMetrics } from './AdminMetrics';
import { CommunityStats } from './CommunityStats';
import type { AdminProfile, AdminPost } from '../../types/admin';

const mockMetricsData = {
  totalUsers: 15000,
  activeUsers: 12500,
  monthlyGrowth: 25,
  transactions: {
    total: 45000,
    average: 89.99,
    growth: 15
  },
  engagement: {
    posts: 2500,
    comments: 15000,
    shares: 8500,
    likes: 45000
  },
  revenue: {
    total: 450000,
    growth: 18,
    projected: 750000
  }
};

const mockCommunityData = {
  fundTotal: 125000,
  monthlyGrowth: 25,
  contributors: 15000,
  projects: {
    total: 25,
    active: 12,
    completed: 8,
    impact: 75000
  },
  topContributors: [
    {
      id: '1',
      name: 'Sophie Martin',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
      contribution: 2500,
      isVerified: true
    },
    {
      id: '2',
      name: 'Lucas Dubois',
      avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
      contribution: 1800,
      isVerified: true
    },
    {
      id: '3',
      name: 'Marie Lambert',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop',
      contribution: 1500,
      isVerified: true
    }
  ]
};

export function AdminProfile() {
  const [activeTab, setActiveTab] = useState<'posts' | 'metrics' | 'community'>('posts');
  const [isEditing, setIsEditing] = useState(false);

  const handlePost = (postData: any) => {
    console.log('New post:', postData);
    // Handle post creation
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      {/* Profile Header */}
      <div className="bg-purple-900 rounded-xl p-6 mb-6">
        <div className="flex items-start gap-6">
          <div className="relative">
            <img
              src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150&h=150&fit=crop"
              alt="Profile"
              className="w-24 h-24 rounded-full border-2 border-yellow-400"
            />
            <BadgeCheck 
              className="absolute -bottom-1 -right-1 text-yellow-400 bg-purple-900 rounded-full p-1" 
              size={24} 
            />
          </div>
          
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-2">
              <h1 className="text-2xl font-bold text-white">Romain Lopez</h1>
              <span className="bg-yellow-400 text-purple-900 px-2 py-1 rounded-full text-sm font-semibold">
                Fondateur
              </span>
            </div>
            
            <p className="text-gray-300 mb-4">
              Fondateur & CEO de PLATFF. Entrepreneur passionné par l'innovation sociale et l'économie collaborative.
            </p>
            
            <div className="flex gap-6">
              <div className="text-center">
                <p className="text-xl font-bold text-white">248</p>
                <p className="text-sm text-gray-300">Posts</p>
              </div>
              <div className="text-center">
                <p className="text-xl font-bold text-white">18.5K</p>
                <p className="text-sm text-gray-300">Abonnés</p>
              </div>
              <div className="text-center">
                <p className="text-xl font-bold text-white">920</p>
                <p className="text-sm text-gray-300">Abonnements</p>
              </div>
              <div className="text-center">
                <p className="text-xl font-bold text-white">12.5%</p>
                <p className="text-sm text-gray-300">Engagement</p>
              </div>
            </div>
          </div>

          <button
            onClick={() => setIsEditing(true)}
            className="bg-purple-800 p-2 rounded-full text-yellow-400 hover:bg-purple-700 transition-colors"
          >
            <Edit3 size={20} />
          </button>
        </div>
      </div>

      {/* Navigation */}
      <div className="flex gap-2 mb-6 overflow-x-auto p-1">
        <button
          onClick={() => setActiveTab('posts')}
          className={`flex-1 py-2 px-4 rounded-full font-medium whitespace-nowrap transition-colors ${
            activeTab === 'posts'
              ? 'bg-yellow-400 text-purple-900'
              : 'bg-purple-800 text-white'
          }`}
        >
          Publications
        </button>
        <button
          onClick={() => setActiveTab('metrics')}
          className={`flex-1 py-2 px-4 rounded-full font-medium whitespace-nowrap transition-colors ${
            activeTab === 'metrics'
              ? 'bg-yellow-400 text-purple-900'
              : 'bg-purple-800 text-white'
          }`}
        >
          Métriques
        </button>
        <button
          onClick={() => setActiveTab('community')}
          className={`flex-1 py-2 px-4 rounded-full font-medium whitespace-nowrap transition-colors ${
            activeTab === 'community'
              ? 'bg-yellow-400 text-purple-900'
              : 'bg-purple-800 text-white'
          }`}
        >
          Communauté
        </button>
      </div>

      {/* Content */}
      <div className="space-y-6">
        {activeTab === 'posts' && (
          <div className="space-y-6">
            <SocialActions onPost={handlePost} />
            {/* Posts list would go here */}
          </div>
        )}

        {activeTab === 'metrics' && <AdminMetrics data={mockMetricsData} />}
        
        {activeTab === 'community' && <CommunityStats data={mockCommunityData} />}
      </div>
    </div>
  );
}