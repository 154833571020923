import React, { useState } from 'react';
import { 
  FileText, 
  Upload, 
  Globe, 
  Target, 
  Shield,
  BadgeCheck,
  Rocket,
  Heart,
  Info
} from 'lucide-react';

interface SubmitProjectProps {
  onSubmit: (project: any) => void;
  onClose: () => void;
}

export function SubmitProject({ onSubmit, onClose }: SubmitProjectProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [fundingGoal, setFundingGoal] = useState('');
  const [impact, setImpact] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [showInfo, setShowInfo] = useState(false);

  const categories = [
    'Éducation',
    'Environnement',
    'Santé',
    'Inclusion sociale',
    'Culture',
    'Innovation',
    'Sport',
    'Entrepreneuriat social'
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      title,
      description,
      category,
      fundingGoal: Number(fundingGoal),
      impact,
      files
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files));
    }
  };

  const renderStep1 = () => (
    <div className="space-y-6">
      <div>
        <label className="text-white text-sm mb-1 block">Titre du projet</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
          placeholder="Ex: Programme de mentorat jeunes entrepreneurs"
        />
      </div>

      <div>
        <label className="text-white text-sm mb-1 block">Description</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400 min-h-[150px]"
          placeholder="Décrivez votre projet et son impact potentiel..."
        />
      </div>

      <div>
        <label className="text-white text-sm mb-1 block">Catégorie</label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
        >
          <option value="">Sélectionnez une catégorie</option>
          {categories.map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-6">
      <div>
        <label className="text-white text-sm mb-1 block">Objectif de financement (€)</label>
        <input
          type="number"
          value={fundingGoal}
          onChange={(e) => setFundingGoal(e.target.value)}
          className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
          placeholder="Ex: 5000"
        />
      </div>

      <div>
        <label className="text-white text-sm mb-1 block">Impact attendu</label>
        <textarea
          value={impact}
          onChange={(e) => setImpact(e.target.value)}
          className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400 min-h-[150px]"
          placeholder="Décrivez l'impact concret que votre projet aura sur la communauté..."
        />
      </div>

      <div>
        <label className="text-white text-sm mb-1 block">Documents complémentaires</label>
        <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-purple-700 border-dashed rounded-xl">
          <div className="space-y-1 text-center">
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <div className="flex text-sm text-gray-400">
              <label className="relative cursor-pointer rounded-md font-medium text-yellow-400 hover:text-yellow-300">
                <span>Télécharger un fichier</span>
                <input
                  type="file"
                  className="sr-only"
                  multiple
                  onChange={handleFileChange}
                />
              </label>
              <p className="pl-1">ou glisser-déposer</p>
            </div>
            <p className="text-xs text-gray-400">
              PDF, DOC, PPT jusqu'à 10MB
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-2xl">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <div className="bg-yellow-400 p-2 rounded-lg">
              <Rocket className="text-purple-900" size={24} />
            </div>
            <div>
              <h2 className="text-xl font-bold text-white">Soumettre un Projet</h2>
              <p className="text-gray-300">Contribuez au changement positif</p>
            </div>
          </div>
          <button
            onClick={() => setShowInfo(!showInfo)}
            className="text-gray-400 hover:text-white"
          >
            <Info size={20} />
          </button>
        </div>

        {showInfo && (
          <div className="bg-purple-800 p-4 rounded-xl mb-6">
            <div className="flex items-center gap-2 mb-2">
              <Shield className="text-yellow-400" size={20} />
              <h3 className="text-white font-semibold">Comment ça marche ?</h3>
            </div>
            <ul className="space-y-2 text-sm text-gray-300">
              <li className="flex items-start gap-2">
                <BadgeCheck className="text-yellow-400 mt-1 flex-shrink-0" size={16} />
                Seuls les membres vérifiés peuvent soumettre des projets
              </li>
              <li className="flex items-start gap-2">
                <Target className="text-yellow-400 mt-1 flex-shrink-0" size={16} />
                Les projets sont évalués selon leur impact social
              </li>
              <li className="flex items-start gap-2">
                <Heart className="text-yellow-400 mt-1 flex-shrink-0" size={16} />
                La communauté vote pour les projets à financer
              </li>
              <li className="flex items-start gap-2">
                <Globe className="text-yellow-400 mt-1 flex-shrink-0" size={16} />
                50% du Fonds Communautaire finance les projets
              </li>
            </ul>
          </div>
        )}

        {/* Progress Steps */}
        <div className="flex items-center mb-6">
          <div className={`flex-1 h-2 rounded-full ${currentStep >= 1 ? 'bg-yellow-400' : 'bg-purple-800'}`} />
          <div className={`w-8 h-8 rounded-full flex items-center justify-center ${currentStep >= 1 ? 'bg-yellow-400 text-purple-900' : 'bg-purple-800 text-white'}`}>
            1
          </div>
          <div className={`flex-1 h-2 rounded-full ${currentStep >= 2 ? 'bg-yellow-400' : 'bg-purple-800'}`} />
          <div className={`w-8 h-8 rounded-full flex items-center justify-center ${currentStep >= 2 ? 'bg-yellow-400 text-purple-900' : 'bg-purple-800 text-white'}`}>
            2
          </div>
          <div className={`flex-1 h-2 rounded-full ${currentStep >= 2 ? 'bg-yellow-400' : 'bg-purple-800'}`} />
        </div>

        <form onSubmit={handleSubmit}>
          {currentStep === 1 ? renderStep1() : renderStep2()}

          <div className="flex justify-between mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 rounded-full text-white hover:bg-purple-800"
            >
              Annuler
            </button>
            <div className="flex gap-2">
              {currentStep === 2 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep(1)}
                  className="px-6 py-2 rounded-full text-white hover:bg-purple-800"
                >
                  Précédent
                </button>
              )}
              {currentStep === 1 ? (
                <button
                  type="button"
                  onClick={() => setCurrentStep(2)}
                  disabled={!title || !description || !category}
                  className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:bg-yellow-300 transition-colors"
                >
                  Suivant
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={!fundingGoal || !impact}
                  className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:bg-yellow-300 transition-colors"
                >
                  Soumettre
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}