import React, { useEffect, useRef } from 'react';
import { useWebRTC } from '../../hooks/useWebRTC';
import { 
  Mic, 
  MicOff, 
  Video, 
  VideoOff, 
  PhoneOff,
  MessageCircle,
  MoreVertical,
  Shield,
  Phone 
} from 'lucide-react';

interface VideoCallProps {
  recipientId: string;
  onEnd: () => void;
}

export function VideoCall({ recipientId, onEnd }: VideoCallProps) {
  const {
    localStream,
    remoteStream,
    error,
    isConnected,
    isCalling,
    startCall,
    endCall
  } = useWebRTC();

  const localVideoRef = useRef<HTMLVideoElement>(null);
  const remoteVideoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (localStream && localVideoRef.current) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    if (remoteStream && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  useEffect(() => {
    startCall(recipientId);
    return () => endCall();
  }, [recipientId]);

  if (error) {
    return (
      <div className="fixed inset-0 bg-purple-900 flex items-center justify-center">
        <div className="text-center p-8">
          <Shield className="text-yellow-400 mx-auto mb-4" size={48} />
          <h3 className="text-xl font-bold text-white mb-2">Erreur de connexion</h3>
          <p className="text-gray-300">{error}</p>
          <button
            onClick={onEnd}
            className="mt-4 bg-red-500 text-white px-6 py-2 rounded-full"
          >
            Terminer
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-purple-900">
      <div className="h-full flex flex-col">
        {/* Video Area */}
        <div className="flex-1 relative bg-black">
          {/* Remote Video */}
          <video
            ref={remoteVideoRef}
            autoPlay
            playsInline
            className="absolute inset-0 w-full h-full object-cover"
          />

          {/* Local Video (PiP) */}
          <div className="absolute bottom-4 right-4 w-32 h-48 bg-purple-800 rounded-lg overflow-hidden">
            <video
              ref={localVideoRef}
              autoPlay
              playsInline
              muted
              className="w-full h-full object-cover"
            />
          </div>

          {/* Call Status */}
          {isCalling && (
            <div className="absolute inset-0 flex items-center justify-center bg-purple-900/80">
              <div className="text-center">
                <div className="w-16 h-16 bg-yellow-400 rounded-full flex items-center justify-center mx-auto mb-4 animate-pulse">
                  <Phone className="text-purple-900" size={32} />
                </div>
                <p className="text-white text-lg">Appel en cours...</p>
              </div>
            </div>
          )}
        </div>

        {/* Controls */}
        <div className="bg-purple-800 p-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-4">
              <button
                onClick={() => {/* Toggle mic */}}
                className="p-3 rounded-full bg-purple-700 text-white"
              >
                <Mic size={24} />
              </button>
              <button
                onClick={() => {/* Toggle video */}}
                className="p-3 rounded-full bg-purple-700 text-white"
              >
                <Video size={24} />
              </button>
            </div>

            <button
              onClick={() => {
                endCall();
                onEnd();
              }}
              className="bg-red-500 p-4 rounded-full text-white"
            >
              <PhoneOff size={24} />
            </button>

            <div className="flex gap-4">
              <button className="p-3 rounded-full bg-purple-700 text-white">
                <MessageCircle size={24} />
              </button>
              <button className="p-3 rounded-full bg-purple-700 text-white">
                <MoreVertical size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}