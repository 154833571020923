import React from 'react';
import { X } from 'lucide-react';

interface EmojiPickerProps {
  onSelect: (emoji: string) => void;
  onClose: () => void;
}

export function EmojiPicker({ onSelect, onClose }: EmojiPickerProps) {
  const emojis = [
    '😊', '😂', '🥰', '😍', '🤩', '😎', '🥳',
    '👍', '❤️', '🎉', '✨', '🌟', '💫', '💪',
    '🙌', '👏', '🎵', '🎶', '🎸', '🎹', '🎼',
    '🌈', '🌞', '🌙', '⭐', '🔥', '💯', '💝'
  ];

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[60] p-4">
      <div className="bg-purple-900 rounded-xl p-4 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold text-white">Ajouter un emoji</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={20} />
          </button>
        </div>

        <div className="grid grid-cols-7 gap-2">
          {emojis.map((emoji) => (
            <button
              key={emoji}
              onClick={() => {
                onSelect(emoji);
                onClose();
              }}
              className="text-2xl hover:scale-125 transition-transform p-2"
            >
              {emoji}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}