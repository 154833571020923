import { Language } from '../types/social';

// Simulated AI translation responses for demonstration
const mockTranslations: Record<string, Record<string, string>> = {
  en: {
    "Bonjour tout le monde!": "Hello everyone!",
    "Comment allez-vous?": "How are you?",
    "J'aime PLATFF": "I love PLATFF"
  },
  es: {
    "Bonjour tout le monde!": "¡Hola a todos!",
    "Comment allez-vous?": "¿Cómo están?",
    "J'aime PLATFF": "Me encanta PLATFF"
  }
};

export async function translateWithAI(text: string, targetLanguage: string): Promise<string> {
  // Simulate AI processing time
  await new Promise(resolve => setTimeout(resolve, 1500));
  
  // In a real implementation, this would call an AI translation API
  // For demo, return mock translation or original text with language marker
  return mockTranslations[targetLanguage]?.[text] || 
    `[AI Translation to ${targetLanguage}] ${text}`;
}

export async function detectLanguage(text: string): Promise<string> {
  // Simulate language detection
  await new Promise(resolve => setTimeout(resolve, 500));
  return 'fr'; // Default to French for demo
}

export async function translatePost(
  content: string,
  targetLanguages: Language[]
): Promise<{ translations: Record<string, string>, detectedLanguage: string }> {
  const detectedLanguage = await detectLanguage(content);
  const translations: Record<string, string> = {};

  for (const lang of targetLanguages) {
    if (lang.code !== detectedLanguage) {
      translations[lang.code] = await translateWithAI(content, lang.code);
    }
  }

  return {
    translations,
    detectedLanguage
  };
}