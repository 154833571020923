import React from 'react';
import { HeroSection } from './home/HeroSection';
import { FeaturesSection } from './home/FeaturesSection';
import { ImpactSection } from './home/ImpactSection';
import { AnimatedLogo } from '../shared/AnimatedLogo';

interface HomeSectionProps {
  onNavigate?: (section: string) => void;
}

export function HomeSection({ onNavigate }: HomeSectionProps) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-8 sm:space-y-12 safe-area-top safe-area-bottom">
      <div className="text-center mb-8">
        <h1 className="text-2xl sm:text-3xl font-bold text-white mb-4">Bienvenue sur</h1>
        <div className="inline-block">
          <AnimatedLogo />
        </div>
      </div>

      {/* Features Grid */}
      <FeaturesSection onNavigate={onNavigate} />

      {/* Impact Section */}
      <ImpactSection />
    </div>
  );
}