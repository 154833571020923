import React, { useState } from 'react';
import { MapPin, BadgeCheck, Globe, Briefcase, Rocket, Target, Book, Heart, Zap, Shield } from 'lucide-react';
import { mockJobs } from '../../data/mockSocialData';
import { JobMatchingModal } from './jobs/JobMatchingModal';

export function JobsSection() {
  const [showMatching, setShowMatching] = useState(false);
  const [showMatches, setShowMatches] = useState(false);

  const handleFindMatches = () => {
    setShowMatching(false);
    setShowMatches(true);
  };

  return (
    <div className="space-y-6">
      {/* Smart Job Matching */}
      <div className="bg-purple-900 p-6 rounded-xl text-center">
        <h2 className="text-2xl font-bold text-white mb-4">Trouvez le job de vos rêves avec PLATFF</h2>
        <p className="text-gray-300 mb-6">
          Notre algorithme analyse votre profil, vos compétences et vos centres d'intérêt pour vous proposer les meilleures opportunités
        </p>
        <button
          onClick={() => setShowMatching(true)}
          className="bg-yellow-400 text-purple-900 px-8 py-4 rounded-full font-semibold hover:bg-yellow-300 transition-colors inline-flex items-center gap-2"
        >
          <Rocket size={20} />
          Laissez PLATFF trouver votre job idéal
        </button>
      </div>

      {/* Jobs Grid - Only shown after matching */}
      {showMatches && (
        <div className="space-y-6">
          {mockJobs.map(job => (
            <div key={job.id} className="bg-purple-900 rounded-xl p-6 animate-fade-in">
              <div className="flex items-start gap-4">
                <img src={job.company.logo} alt="" className="w-16 h-16 rounded-lg" />
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-xl font-bold text-white">{job.title}</h3>
                      <div className="flex items-center gap-2">
                        <p className="text-yellow-400">{job.company.name}</p>
                        {job.company.verified && (
                          <BadgeCheck size={16} className="text-yellow-400" />
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm">
                        {job.type}
                      </span>
                      {job.remote && (
                        <span className="bg-green-400/20 text-green-400 px-3 py-1 rounded-full text-sm">
                          Remote
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center gap-4 mt-2 text-gray-300">
                    <span className="flex items-center gap-1">
                      <MapPin size={16} />
                      {job.location}
                    </span>
                    <span className="flex items-center gap-1">
                      <Briefcase size={16} />
                      {job.salary.min.toLocaleString()}€ - {job.salary.max.toLocaleString()}€
                    </span>
                  </div>

                  <p className="text-gray-300 mt-4">{job.description}</p>

                  <div className="flex flex-wrap gap-2 mt-4">
                    {job.requirements.map((req, index) => (
                      <span key={index} className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm">
                        {req}
                      </span>
                    ))}
                  </div>

                  <div className="flex justify-between items-center mt-4">
                    <div className="flex items-center gap-2 text-gray-300">
                      <Globe size={16} />
                      <span>Impact: {job.communityImpact}€</span>
                    </div>
                    <button className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors">
                      Postuler
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Job Matching Modal */}
      {showMatching && (
        <JobMatchingModal 
          onClose={() => setShowMatching(false)}
          onFindMatches={handleFindMatches}
        />
      )}
    </div>
  );
}