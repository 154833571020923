import React from 'react';
import { useLetterAnimation } from '../../hooks/useLetterAnimation';

interface AnimatedLogoProps {
  variant?: 'default' | 'header';
}

export function AnimatedLogo({ variant = 'default' }: AnimatedLogoProps) {
  const visibleLetters = useLetterAnimation('PLATFF', 300);
  const letters = 'PLATFF'.split('');
  const allLettersVisible = visibleLetters.length === letters.length;

  const getLetterStyle = (letter: string, index: number) => {
    const isLastF = index === letters.length - 1;
    const isHeader = variant === 'header';

    const baseClasses = `
      logo-text inline-block transform-gpu
      transition-all duration-500
      ${visibleLetters.includes(index) ? 'animate-letter-appear' : 'opacity-0 scale-0'}
      ${isHeader ? 'text-5xl' : 'text-6xl sm:text-7xl md:text-8xl'}
      ${isLastF 
        ? `${isHeader ? 'text-yellow-400' : 'text-yellow-500'} logo-text-accent` 
        : `${isHeader ? 'text-white' : 'text-purple-900'} logo-text-normal`}
      hover:scale-110 hover:rotate-y-180
      ${allLettersVisible ? 'animate-float' : ''}
    `;

    return baseClasses;
  };

  const getDotSize = () => {
    return variant === 'header' ? {
      width: '14px',
      height: '14px',
      right: '-8px',
      bottom: '6px'
    } : {
      width: '20px',
      height: '20px',
      right: '-12px',
      bottom: '8px'
    };
  };

  return (
    <div className="inline-flex perspective-[1000px] relative">
      {letters.map((letter, index) => (
        <span
          key={index}
          className={getLetterStyle(letter, index)}
          style={{
            '--z-offset': `${index * 20}px`,
            animationDelay: allLettersVisible ? `${index * 100}ms` : `${(index * 300) + 500}ms`
          } as React.CSSProperties}
        >
          {letter}
        </span>
      ))}
      {/* Flashing dot after the last F */}
      <div 
        className="logo-dot"
        style={getDotSize()}
      />
    </div>
  );
}