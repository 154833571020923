import React, { useState } from 'react';
import { Shield, Smartphone, Lock, Eye, EyeOff, Fingerprint, Bell, Info } from 'lucide-react';

export function SecuritySettings() {
  const [showPin, setShowPin] = useState(false);
  const [pin, setPin] = useState('');
  const [settings, setSettings] = useState({
    biometric: true,
    twoFactor: true,
    locationTracking: true,
    instantNotifications: true
  });

  const toggleSetting = (setting: keyof typeof settings) => {
    setSettings(prev => ({ ...prev, [setting]: !prev[setting] }));
  };

  return (
    <div className="bg-purple-900 rounded-xl p-6">
      <div className="flex items-center gap-3 mb-6">
        <Shield className="text-yellow-400" size={24} />
        <div>
          <h2 className="text-xl font-bold text-white">Paramètres de sécurité</h2>
          <p className="text-gray-300">Protégez votre compte PLATFFPAY</p>
        </div>
      </div>

      <div className="space-y-6">
        {/* PIN Code */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <Lock className="text-yellow-400" size={20} />
              <h3 className="text-white font-medium">Code PIN</h3>
            </div>
            <button
              onClick={() => setShowPin(!showPin)}
              className="text-gray-300 hover:text-white"
            >
              {showPin ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          <input
            type={showPin ? 'text' : 'password'}
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            maxLength={4}
            placeholder="••••"
            className="w-full bg-purple-700 text-white rounded-lg px-4 py-2 text-center text-2xl tracking-widest"
          />
          <p className="text-sm text-gray-300 mt-2">
            Votre code PIN est requis pour les transactions importantes
          </p>
        </div>

        {/* Biometric Authentication */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Fingerprint className="text-yellow-400" size={20} />
              <div>
                <p className="text-white font-medium">Authentification biométrique</p>
                <p className="text-sm text-gray-300">Utiliser Face ID / Touch ID</p>
              </div>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.biometric}
                onChange={() => toggleSetting('biometric')}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-purple-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
            </label>
          </div>
        </div>

        {/* Two-Factor Authentication */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Smartphone className="text-yellow-400" size={20} />
              <div>
                <p className="text-white font-medium">Double authentification</p>
                <p className="text-sm text-gray-300">Code SMS pour les transactions</p>
              </div>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.twoFactor}
                onChange={() => toggleSetting('twoFactor')}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-purple-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
            </label>
          </div>
        </div>

        {/* Location Tracking */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Shield className="text-yellow-400" size={20} />
              <div>
                <p className="text-white font-medium">Suivi de localisation</p>
                <p className="text-sm text-gray-300">Détecter les transactions suspectes</p>
              </div>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.locationTracking}
                onChange={() => toggleSetting('locationTracking')}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-purple-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
            </label>
          </div>
        </div>

        {/* Instant Notifications */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Bell className="text-yellow-400" size={20} />
              <div>
                <p className="text-white font-medium">Notifications instantanées</p>
                <p className="text-sm text-gray-300">Pour chaque transaction</p>
              </div>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={settings.instantNotifications}
                onChange={() => toggleSetting('instantNotifications')}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-purple-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
            </label>
          </div>
        </div>

        {/* Info Box */}
        <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
          <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
          <div>
            <p className="text-white font-medium mb-1">Sécurité maximale</p>
            <p className="text-sm text-gray-300">
              Nous utilisons le chiffrement de bout en bout et des systèmes de sécurité avancés pour protéger vos transactions.
            </p>
          </div>
        </div>

        <button className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2">
          <Shield size={20} />
          Sauvegarder les paramètres
        </button>
      </div>
    </div>
  );
}