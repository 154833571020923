import type { Post } from '../types/social';

export const mockPosts: Post[] = [
  {
    id: '1',
    userId: '1',
    username: 'Romain Lopez',
    userAvatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
    content: `Découvrez en exclusivité notre nouvelle fonctionnalité de récompense communautaire ! 🚀

Les membres vérifiés peuvent maintenant attribuer une partie de leur allocation du Fonds Communautaire pour récompenser les créateurs de contenu qui apportent le plus de valeur à notre communauté.

Cette vidéo vous montre comment PLATFF révolutionne l'économie collaborative en créant un écosystème où chaque action génère un impact positif. 💫

Essayez dès maintenant en cliquant sur l'icône cadeau sous cette vidéo ! 🎁`,
    mediaUrl: 'https://cdn.coverr.co/videos/coverr-a-business-meeting-in-an-office-5244/1080p.mp4',
    mediaType: 'video',
    likes: 458,
    comments: 89,
    shares: 156,
    createdAt: new Date().toISOString(),
    isLiked: false,
    isVerified: true,
    communityImpact: {
      cashback: 0,
      communityFund: 250,
      totalContribution: 250
    }
  },
  {
    id: '2',
    userId: '1',
    username: 'Romain Lopez',
    userAvatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
    content: `🎉 Nouvelle mise à jour majeure : Modération IA intelligente ! 🤖

Notre système de modération IA remplace automatiquement les mots inappropriés par des noms de fruits pour maintenir une communauté bienveillante. 🍎 🍌 🍊

Exemple : "Les m**** de tr*** sont des c***" devient "Les 🍎 pommes de 🍌 bananes sont des 🍊 oranges" 

C'est à la fois efficace et amusant ! Plus besoin de supprimer les messages, notre IA les transforme instantanément en salade de fruits. 😄

#PLATFFSafe #CommunautéPositive #ModérationIA`,
    likes: 325,
    comments: 45,
    shares: 89,
    createdAt: new Date().toISOString(),
    isLiked: false,
    isVerified: true,
    communityImpact: {
      cashback: 0,
      communityFund: 180,
      totalContribution: 180
    }
  }
];