import React, { useState } from 'react';
import { Shield, X, BadgeCheck, Lock, MessageCircle } from 'lucide-react';
import { PassportVerificationModal } from './verification/PassportVerificationModal';

interface VerificationRequiredModalProps {
  onClose: () => void;
}

export function VerificationRequiredModal({ onClose }: VerificationRequiredModalProps) {
  const [showPassportVerification, setShowPassportVerification] = useState(false);

  const handleStartVerification = () => {
    setShowPassportVerification(true);
  };

  const handleVerificationComplete = () => {
    // Update user verification status
    onClose();
  };

  if (showPassportVerification) {
    return (
      <PassportVerificationModal
        onClose={() => setShowPassportVerification(false)}
        onVerified={handleVerificationComplete}
      />
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md animate-slide-up">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <Lock className="text-yellow-400" size={24} />
            <h3 className="text-xl font-bold text-white">Vérification Requise</h3>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>

        <div className="space-y-6">
          <div className="bg-purple-800 p-4 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <MessageCircle className="text-yellow-400" size={20} />
              <p className="text-white font-medium">Messagerie sécurisée</p>
            </div>
            <p className="text-sm text-gray-300">
              Pour la sécurité de notre communauté, seuls les membres vérifiés peuvent envoyer des messages sur PLATFF.
            </p>
          </div>

          <div className="bg-purple-800 p-4 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <BadgeCheck className="text-yellow-400" size={20} />
              <p className="text-white font-medium">Avantages de la vérification</p>
            </div>
            <ul className="space-y-2 text-sm text-gray-300">
              <li className="flex items-center gap-2">
                <Shield size={16} className="text-yellow-400" />
                Messagerie sécurisée
              </li>
              <li className="flex items-center gap-2">
                <Lock size={16} className="text-yellow-400" />
                Chiffrement de bout en bout
              </li>
              <li className="flex items-center gap-2">
                <MessageCircle size={16} className="text-yellow-400" />
                Appels audio/vidéo
              </li>
            </ul>
          </div>

          <button
            onClick={handleStartVerification}
            className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2"
          >
            <Shield size={20} />
            Vérifier mon compte maintenant
          </button>
        </div>
      </div>
    </div>
  );
}