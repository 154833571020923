import React, { useState } from 'react';
import { mockLiveStreams } from '../../data/mockSocialData';
import { Users, BadgeCheck, Globe, Star } from 'lucide-react';
import { LiveBroadcastControls } from './LiveBroadcastControls';

export function LiveSection() {
  const [selectedStream, setSelectedStream] = useState<any>(null);
  const [isJoining, setIsJoining] = useState(false);

  const handleJoinStream = (stream: any) => {
    setIsJoining(true);
    setSelectedStream(stream);
  };

  if (isJoining && selectedStream) {
    return (
      <LiveBroadcastControls
        onClose={() => {
          setIsJoining(false);
          setSelectedStream(null);
        }}
        onEnd={() => {
          setIsJoining(false);
          setSelectedStream(null);
        }}
        isViewer={true}
        stream={selectedStream}
      />
    );
  }

  return (
    <div className="space-y-6">
      {mockLiveStreams.map(stream => (
        <div key={stream.id} className="bg-purple-900 rounded-xl overflow-hidden">
          <div className="relative">
            <img src={stream.thumbnail} alt="" className="w-full h-48 sm:h-64 object-cover" />
            <div className="absolute top-4 left-4 bg-red-500 px-3 py-1 rounded-full text-white font-semibold flex items-center gap-2">
              <div className="w-2 h-2 bg-white rounded-full animate-pulse" />
              LIVE
            </div>
            <div className="absolute top-4 right-4 bg-purple-900/80 px-3 py-1 rounded-full text-white flex items-center gap-2">
              <Users size={16} />
              {stream.viewers.toLocaleString()}
            </div>
          </div>

          <div className="p-4 sm:p-6">
            <div className="flex items-start gap-3 mb-4">
              <img src={stream.streamer.avatar} alt="" className="w-12 h-12 rounded-full" />
              <div>
                <div className="flex items-center gap-2">
                  <h3 className="font-semibold text-white">{stream.streamer.name}</h3>
                  {stream.streamer.verified && (
                    <BadgeCheck size={16} className="text-yellow-400" />
                  )}
                </div>
                <p className="text-sm text-gray-300">{stream.category}</p>
              </div>
            </div>

            <h2 className="text-xl font-bold text-white mb-4">{stream.title}</h2>

            <div className="flex flex-wrap gap-2 mb-4">
              {stream.tags.map((tag, index) => (
                <span key={index} className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm">
                  {tag}
                </span>
              ))}
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 text-gray-300">
                <Globe size={16} />
                <span>Impact: {stream.communityImpact}€</span>
              </div>
              <button 
                onClick={() => handleJoinStream(stream)}
                className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center gap-2"
              >
                <Star size={18} />
                Rejoindre
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}