import React from 'react';
import { Star, MapPin, BadgeCheck, Clock } from 'lucide-react';
import { BookingProfitDisplay } from '../shared/BookingProfitDisplay';
import { calculateBookingProfitDistribution } from '../../utils/bookingCalculations';

interface BookingCardProps {
  item: {
    id: string;
    title: string;
    type: 'hotel' | 'flight' | 'train' | 'car' | 'restaurant';
    image: string;
    badge?: {
      text: string;
      color: string;
    };
    location: string;
    rating: number;
    reviews: number;
    description: string;
    price: number;
    features?: string[];
    duration?: string;
    provider: {
      name: string;
      logo: string;
      isVerified: boolean;
    };
  };
  onBook: () => void;
}

export function BookingCard({ item, onBook }: BookingCardProps) {
  const profitDistribution = calculateBookingProfitDistribution(item.price);

  return (
    <div className="bg-purple-900 rounded-xl overflow-hidden">
      <div className="relative">
        <img src={item.image} alt={item.title} className="w-full h-48 object-cover" />
        {item.badge && (
          <div className={`absolute top-4 right-4 bg-${item.badge.color}-400 text-purple-900 px-3 py-1 rounded-full text-sm font-semibold flex items-center gap-1`}>
            {item.badge.text}
            <BadgeCheck size={14} />
          </div>
        )}
      </div>

      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-xl font-bold text-white">{item.title}</h3>
            <div className="flex items-center gap-4 text-gray-300 mt-1">
              <div className="flex items-center gap-2">
                <MapPin size={16} />
                <span>{item.location}</span>
              </div>
              {item.duration && (
                <div className="flex items-center gap-2">
                  <Clock size={16} />
                  <span>{item.duration}</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-1">
            <Star className="text-yellow-400" size={16} fill="currentColor" />
            <span className="text-white">{item.rating}</span>
            <span className="text-gray-300">({item.reviews})</span>
          </div>
        </div>

        <p className="text-gray-300 mb-4">{item.description}</p>

        {item.features && (
          <div className="flex flex-wrap gap-2 mb-4">
            {item.features.map((feature, index) => (
              <span
                key={index}
                className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm"
              >
                {feature}
              </span>
            ))}
          </div>
        )}

        <div className="bg-purple-800 p-4 rounded-xl mb-4">
          <BookingProfitDisplay distribution={profitDistribution} />
        </div>

        <div className="flex justify-between items-center">
          <div>
            <p className="text-2xl font-bold text-white">{item.price}€</p>
            <p className="text-sm text-gray-300">
              {item.type === 'hotel' ? 'par nuit' : 
               item.type === 'car' ? 'par jour' : 
               'par personne'}
            </p>
          </div>
          <button 
            onClick={onBook}
            className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
          >
            Réserver
          </button>
        </div>
      </div>
    </div>
  );
}