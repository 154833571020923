import React, { useState, useEffect } from 'react';
import { Music, Heart, Star, Shield, Zap, Ticket } from 'lucide-react';

interface EventMatchingModalProps {
  onClose: () => void;
  onFindMatches: () => void;
}

export function EventMatchingModal({ onClose, onFindMatches }: EventMatchingModalProps) {
  const [progress, setProgress] = useState({
    music: 0,
    interests: 0,
    location: 0,
    preferences: 0
  });

  useEffect(() => {
    // Animate progress bars sequentially
    const musicTimer = setTimeout(() => {
      setProgress(prev => ({ ...prev, music: 100 }));
    }, 2500);

    const interestsTimer = setTimeout(() => {
      setProgress(prev => ({ ...prev, interests: 100 }));
    }, 5000);

    const locationTimer = setTimeout(() => {
      setProgress(prev => ({ ...prev, location: 100 }));
    }, 7500);

    const preferencesTimer = setTimeout(() => {
      setProgress(prev => ({ ...prev, preferences: 100 }));
    }, 9000);

    // Trigger matches display after all analyses are complete
    const completionTimer = setTimeout(() => {
      onFindMatches();
    }, 10000);

    return () => {
      clearTimeout(musicTimer);
      clearTimeout(interestsTimer);
      clearTimeout(locationTimer);
      clearTimeout(preferencesTimer);
      clearTimeout(completionTimer);
    };
  }, [onFindMatches]);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-4 w-full max-w-md animate-slide-up">
        {/* Header */}
        <div className="text-center mb-6">
          <div className="w-12 h-12 bg-yellow-400 rounded-full flex items-center justify-center mx-auto mb-3">
            <Ticket className="text-purple-900" size={24} />
          </div>
          <h2 className="text-xl font-bold text-white mb-1">PLATFF Event Matching</h2>
          <p className="text-sm text-gray-300">Analyse de vos préférences...</p>
        </div>

        {/* Analysis Steps */}
        <div className="space-y-3">
          <div className="bg-purple-800 p-3 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Music className="text-yellow-400" size={18} />
              <div className="flex-1">
                <h3 className="text-sm font-semibold text-white">Goûts musicaux</h3>
                <p className="text-xs text-gray-300">Artistes et genres favoris</p>
              </div>
              <Zap className={`text-yellow-400 ${progress.music < 100 ? 'animate-pulse' : ''}`} size={18} />
            </div>
            <div className="w-full bg-purple-700 rounded-full h-1.5">
              <div 
                className="bg-yellow-400 h-1.5 rounded-full transition-all duration-1000"
                style={{ width: `${progress.music}%` }}
              />
            </div>
          </div>

          <div className="bg-purple-800 p-3 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Heart className="text-yellow-400" size={18} />
              <div className="flex-1">
                <h3 className="text-sm font-semibold text-white">Centres d'intérêt</h3>
                <p className="text-xs text-gray-300">Activités et passions</p>
              </div>
              <Zap className={`text-yellow-400 ${progress.interests < 100 ? 'animate-pulse' : ''}`} size={18} />
            </div>
            <div className="w-full bg-purple-700 rounded-full h-1.5">
              <div 
                className="bg-yellow-400 h-1.5 rounded-full transition-all duration-1000"
                style={{ width: `${progress.interests}%` }}
              />
            </div>
          </div>

          <div className="bg-purple-800 p-3 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Star className="text-yellow-400" size={18} />
              <div className="flex-1">
                <h3 className="text-sm font-semibold text-white">Localisation</h3>
                <p className="text-xs text-gray-300">Événements à proximité</p>
              </div>
              <Zap className={`text-yellow-400 ${progress.location < 100 ? 'animate-pulse' : ''}`} size={18} />
            </div>
            <div className="w-full bg-purple-700 rounded-full h-1.5">
              <div 
                className="bg-yellow-400 h-1.5 rounded-full transition-all duration-1000"
                style={{ width: `${progress.location}%` }}
              />
            </div>
          </div>

          <div className="bg-purple-800 p-3 rounded-xl">
            <div className="flex items-center gap-2 mb-2">
              <Shield className="text-yellow-400" size={18} />
              <div className="flex-1">
                <h3 className="text-sm font-semibold text-white">Préférences</h3>
                <p className="text-xs text-gray-300">Type d'événements favoris</p>
              </div>
              <Zap className={`text-yellow-400 ${progress.preferences < 100 ? 'animate-pulse' : ''}`} size={18} />
            </div>
            <div className="w-full bg-purple-700 rounded-full h-1.5">
              <div 
                className="bg-yellow-400 h-1.5 rounded-full transition-all duration-1000"
                style={{ width: `${progress.preferences}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}