import React from 'react';
import { 
  Globe, 
  Users, 
  Heart, 
  Target,
  Award,
  TrendingUp,
  Shield,
  Star,
  BadgeCheck
} from 'lucide-react';

interface CommunityStatsProps {
  data: {
    fundTotal: number;
    monthlyGrowth: number;
    contributors: number;
    projects: {
      total: number;
      active: number;
      completed: number;
      impact: number;
    };
    topContributors: Array<{
      id: string;
      name: string;
      avatar: string;
      contribution: number;
      isVerified: boolean;
    }>;
  };
}

export function CommunityStats({ data }: CommunityStatsProps) {
  return (
    <div className="space-y-6">
      {/* Fund Overview */}
      <div className="bg-purple-800 p-6 rounded-xl">
        <div className="flex items-center gap-3 mb-6">
          <Globe className="text-yellow-400" size={24} />
          <div>
            <h2 className="text-xl font-bold text-white">Fonds Communautaire</h2>
            <p className="text-gray-300">Impact total généré</p>
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <div className="text-center">
            <p className="text-3xl font-bold text-yellow-400">
              {data.fundTotal.toLocaleString()}€
            </p>
            <p className="text-sm text-gray-300">Total collecté</p>
          </div>
          
          <div className="text-center">
            <p className="text-3xl font-bold text-green-400">
              +{data.monthlyGrowth}%
            </p>
            <p className="text-sm text-gray-300">Croissance</p>
          </div>
          
          <div className="text-center">
            <p className="text-3xl font-bold text-blue-400">
              {data.contributors.toLocaleString()}
            </p>
            <p className="text-sm text-gray-300">Contributeurs</p>
          </div>
          
          <div className="text-center">
            <p className="text-3xl font-bold text-purple-400">
              {data.projects.total}
            </p>
            <p className="text-sm text-gray-300">Projets</p>
          </div>
        </div>
      </div>

      {/* Projects Impact */}
      <div className="bg-purple-800 p-6 rounded-xl">
        <h3 className="text-lg font-semibold text-white mb-4 flex items-center gap-2">
          <Target className="text-yellow-400" />
          Impact des Projets
        </h3>
        
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div className="bg-purple-700 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="text-gray-300">Projets actifs</span>
              <span className="text-white font-bold">{data.projects.active}</span>
            </div>
            <div className="w-full bg-purple-600 rounded-full h-2">
              <div 
                className="bg-green-400 h-2 rounded-full" 
                style={{ width: `${(data.projects.active / data.projects.total) * 100}%` }} 
              />
            </div>
          </div>

          <div className="bg-purple-700 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="text-gray-300">Projets complétés</span>
              <span className="text-white font-bold">{data.projects.completed}</span>
            </div>
            <div className="w-full bg-purple-600 rounded-full h-2">
              <div 
                className="bg-blue-400 h-2 rounded-full" 
                style={{ width: `${(data.projects.completed / data.projects.total) * 100}%` }} 
              />
            </div>
          </div>

          <div className="bg-purple-700 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="text-gray-300">Impact généré</span>
              <span className="text-white font-bold">{data.projects.impact.toLocaleString()}€</span>
            </div>
            <div className="w-full bg-purple-600 rounded-full h-2">
              <div 
                className="bg-yellow-400 h-2 rounded-full" 
                style={{ width: '75%' }} 
              />
            </div>
          </div>
        </div>
      </div>

      {/* Top Contributors */}
      <div className="bg-purple-800 p-6 rounded-xl">
        <h3 className="text-lg font-semibold text-white mb-4 flex items-center gap-2">
          <Award className="text-yellow-400" />
          Top Contributeurs
        </h3>

        <div className="space-y-4">
          {data.topContributors.map((contributor) => (
            <div key={contributor.id} className="bg-purple-700 p-4 rounded-lg">
              <div className="flex items-center gap-4">
                <img
                  src={contributor.avatar}
                  alt={contributor.name}
                  className="w-12 h-12 rounded-full"
                />
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <div>
                      <h4 className="text-white font-semibold flex items-center gap-1">
                        {contributor.name}
                        {contributor.isVerified && (
                          <BadgeCheck size={16} className="text-yellow-400" />
                        )}
                      </h4>
                      <div className="flex items-center gap-4 text-sm text-gray-300 mt-1">
                        <span className="flex items-center gap-1">
                          <Heart size={12} /> {contributor.contribution.toLocaleString()}
                        </span>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-yellow-400 font-bold">
                        {contributor.contribution.toFixed(2)} €
                      </p>
                      <p className="text-xs text-gray-300">récompensés</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}