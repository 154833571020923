import type { Hotel } from '../types/booking';

export const mockHotels: Hotel[] = [
  {
    id: 'hotel1',
    title: 'Hôtel Le Royal',
    type: 'hotel',
    image: 'https://images.unsplash.com/photo-1566073771259-6a8506099945?w=800&h=400&fit=crop',
    badge: { text: 'PLATFF Luxe', color: 'yellow' },
    location: 'Nice, France',
    rating: 4.9,
    reviews: 512,
    description: 'Suite Deluxe avec vue sur la mer. Petit-déjeuner inclus et accès spa.',
    price: 299,
    features: ['Vue sur mer', 'Petit-déjeuner inclus', 'Spa', 'WiFi gratuit'],
    provider: {
      name: 'Le Royal Hotels',
      logo: 'https://images.unsplash.com/photo-1566073771259-6a8506099945?w=50&h=50&fit=crop',
      isVerified: true
    }
  },
  {
    id: 'hotel2',
    title: 'Green Hotel & Spa',
    type: 'hotel',
    image: 'https://images.unsplash.com/photo-1542314831-068cd1dbfeeb?w=800&h=400&fit=crop',
    badge: { text: 'Eco-responsable', color: 'green' },
    location: 'Annecy, France',
    rating: 4.8,
    reviews: 324,
    description: 'Hôtel écologique avec spa bio et restaurant locavore.',
    price: 199,
    features: ['Spa bio', 'Restaurant bio', 'Jardin', 'Parking'],
    provider: {
      name: 'Green Hotels',
      logo: 'https://images.unsplash.com/photo-1542314831-068cd1dbfeeb?w=50&h=50&fit=crop',
      isVerified: true
    }
  }
];