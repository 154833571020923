import React, { useState, useRef } from 'react';
import { Camera, Shield, Check, X, Loader, AlertTriangle } from 'lucide-react';
import { VerificationSuccess } from './VerificationSuccess';

interface PassportVerificationModalProps {
  onClose: () => void;
  onVerified: () => void;
}

export function PassportVerificationModal({ onClose, onVerified }: PassportVerificationModalProps) {
  const [step, setStep] = useState<'intro' | 'capture' | 'processing' | 'success'>('intro');
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0);
        const imageData = canvasRef.current.toDataURL('image/jpeg');
        setCapturedImage(imageData);
        stopCamera();
        setStep('processing');
        simulateAIProcessing();
      }
    }
  };

  const simulateAIProcessing = () => {
    // Simulate AI verification process
    setTimeout(() => {
      setStep('success');
    }, 3000);
  };

  const handleStartVerification = () => {
    setStep('capture');
    startCamera();
  };

  const handleRetry = () => {
    setCapturedImage(null);
    setStep('capture');
    startCamera();
  };

  if (step === 'success') {
    return <VerificationSuccess onClose={onVerified} />;
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md">
        {step === 'intro' && (
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Shield className="text-yellow-400" size={24} />
                <h3 className="text-xl font-bold text-white">Vérification PLATFF</h3>
              </div>
              <button onClick={onClose} className="text-gray-400 hover:text-white">
                <X size={24} />
              </button>
            </div>

            <div className="bg-purple-800 p-4 rounded-xl">
              <p className="text-white font-medium mb-2">Instructions :</p>
              <ul className="space-y-2 text-sm text-gray-300">
                <li className="flex items-center gap-2">
                  <Check size={16} className="text-yellow-400" />
                  Préparez votre passeport ou carte d'identité
                </li>
                <li className="flex items-center gap-2">
                  <Check size={16} className="text-yellow-400" />
                  Assurez-vous d'avoir un bon éclairage
                </li>
                <li className="flex items-center gap-2">
                  <Check size={16} className="text-yellow-400" />
                  Placez le document bien à plat face à la caméra
                </li>
              </ul>
            </div>

            <button
              onClick={handleStartVerification}
              className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2"
            >
              <Camera size={20} />
              Commencer la vérification
            </button>
          </div>
        )}

        {step === 'capture' && (
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <h3 className="text-xl font-bold text-white">Scanner votre document</h3>
              <button onClick={onClose} className="text-gray-400 hover:text-white">
                <X size={24} />
              </button>
            </div>

            <div className="relative aspect-[4/3] bg-black rounded-xl overflow-hidden">
              <video
                ref={videoRef}
                autoPlay
                playsInline
                className="absolute inset-0 w-full h-full object-cover"
              />
              <canvas ref={canvasRef} className="hidden" />
              <div className="absolute inset-0 border-2 border-yellow-400 opacity-50" />
            </div>

            <div className="flex gap-3">
              <button
                onClick={onClose}
                className="flex-1 bg-purple-800 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors"
              >
                Annuler
              </button>
              <button
                onClick={captureImage}
                className="flex-1 bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors"
              >
                Capturer
              </button>
            </div>
          </div>
        )}

        {step === 'processing' && (
          <div className="text-center py-8">
            <Loader className="animate-spin text-yellow-400 mx-auto mb-4" size={32} />
            <h3 className="text-xl font-bold text-white mb-2">Analyse en cours</h3>
            <p className="text-gray-300">
              Notre IA vérifie votre document...
            </p>
          </div>
        )}
      </div>
    </div>
  );
}