import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import type { Post, Story } from '../types/social';
import { moderateContent } from '../utils/contentModeration';
import { mockPosts } from '../data/mockPosts';

interface ContentFlowContextType {
  posts: Post[];
  stories: Story[];
  addPost: (post: Omit<Post, 'id' | 'createdAt'>) => Promise<void>;
  addStory: (story: Omit<Story, 'id' | 'createdAt'>) => Promise<void>;
  crossPostStory: (story: Story) => Promise<void>;
}

const ContentFlowContext = createContext<ContentFlowContextType | undefined>(undefined);

export function ContentFlowProvider({ children }: { children: React.ReactNode }) {
  const [posts, setPosts] = useState<Post[]>([]);
  const [stories, setStories] = useState<Story[]>([]);

  // Initialize feed with mock posts
  useEffect(() => {
    setPosts(mockPosts);
  }, []);

  const addPost = useCallback(async (post: Omit<Post, 'id' | 'createdAt'>) => {
    const moderation = await moderateContent(post.content);
    
    const newPost: Post = {
      ...post,
      id: Date.now().toString(),
      content: moderation.moderatedText,
      createdAt: new Date().toISOString(),
      likes: 0,
      comments: 0,
      shares: 0,
      isLiked: false
    };

    setPosts(prev => [newPost, ...prev]);
  }, []);

  const addStory = useCallback(async (story: Omit<Story, 'id' | 'createdAt'>) => {
    let moderatedContent = story.text;
    if (story.text) {
      const moderation = await moderateContent(story.text);
      moderatedContent = moderation.moderatedText;
    }

    const newStory: Story = {
      ...story,
      id: Date.now().toString(),
      text: moderatedContent,
      createdAt: new Date().toISOString(),
      expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
      views: 0,
      reactions: []
    };

    setStories(prev => [newStory, ...prev]);
    
    // Auto cross-post if it's a media story
    if (story.mediaUrl) {
      await crossPostStory(newStory);
    }
  }, []);

  const crossPostStory = useCallback(async (story: Story) => {
    const post: Omit<Post, 'id' | 'createdAt'> = {
      userId: story.userId,
      username: story.username,
      userAvatar: story.userAvatar,
      content: story.text || 'Shared a story',
      mediaUrl: story.mediaUrl,
      mediaType: story.mediaType,
      isVerified: story.isVerified,
      communityImpact: {
        cashback: 0,
        communityFund: story.communityImpact || 0,
        totalContribution: story.communityImpact || 0
      }
    };

    await addPost(post);
  }, [addPost]);

  return (
    <ContentFlowContext.Provider value={{
      posts,
      stories,
      addPost,
      addStory,
      crossPostStory
    }}>
      {children}
    </ContentFlowContext.Provider>
  );
}

export function useContentFlow() {
  const context = useContext(ContentFlowContext);
  if (context === undefined) {
    throw new Error('useContentFlow must be used within a ContentFlowProvider');
  }
  return context;
}