import React, { useState } from 'react';
import { Gift, Star, Shield, Info, BadgeCheck, TrendingUp, X } from 'lucide-react';
import { MEMBER_ALLOCATION } from '../../../utils/constants';

interface RewardModalProps {
  creatorName: string;
  contentTitle: string;
  onReward: (amount: number) => void;
  onClose: () => void;
}

export function RewardModal({ creatorName, contentTitle, onReward, onClose }: RewardModalProps) {
  const [amount, setAmount] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = () => {
    const rewardAmount = Number(amount);
    if (rewardAmount > 0 && rewardAmount <= MEMBER_ALLOCATION) {
      onReward(rewardAmount);
      setShowConfirmation(true);
    }
  };

  if (showConfirmation) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
        <div className="bg-purple-900 rounded-xl p-6 w-full max-w-sm animate-slide-up">
          <div className="text-center">
            <div className="w-16 h-16 bg-green-400 rounded-full flex items-center justify-center mx-auto mb-4">
              <Gift className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-bold text-white mb-2">Récompense envoyée !</h3>
            <p className="text-gray-300 mb-6">
              Vous avez récompensé {creatorName} avec {amount}€ pour "{contentTitle}".
            </p>
            <button
              onClick={onClose}
              className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-sm animate-slide-up">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <Gift className="text-yellow-400" size={24} />
            <h3 className="text-xl font-bold text-white">Récompenser</h3>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>

        <div className="space-y-4">
          <div className="bg-purple-800 p-4 rounded-xl">
            <p className="text-white">Récompenser {creatorName}</p>
            <p className="text-sm text-gray-300 mt-1">Pour : {contentTitle}</p>
            <div className="mt-4">
              <label className="text-sm text-gray-300 mb-2 block">Montant (€)</label>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                max={MEMBER_ALLOCATION}
                className="w-full bg-purple-700 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                placeholder={`Maximum ${MEMBER_ALLOCATION.toFixed(2)}€`}
              />
            </div>
          </div>

          <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
            <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div className="text-sm text-gray-300">
              <p className="mb-2">Votre allocation disponible: {MEMBER_ALLOCATION.toFixed(2)}€</p>
              <p>Les récompenses sont instantanément créditées sur le compte PLATFFPAY du créateur.</p>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            disabled={!amount || Number(amount) > MEMBER_ALLOCATION}
            className={`w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold ${
              !amount || Number(amount) > MEMBER_ALLOCATION
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-yellow-300'
            } transition-colors`}
          >
            Envoyer la récompense
          </button>
        </div>
      </div>
    </div>
  );
}