import React, { useState } from 'react';
import { Plus, BadgeCheck } from 'lucide-react';
import { StoryCreator } from './StoryCreator';
import { UserProfile } from './UserProfile';

interface Story {
  id: string;
  userId: string;
  username: string;
  userAvatar: string;
  mediaUrl: string;
  createdAt: string;
  seen: boolean;
  isVerified?: boolean;
}

const stories: Story[] = [
  {
    id: '1',
    userId: '1',
    username: 'Marie L.',
    userAvatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
    mediaUrl: 'https://images.unsplash.com/photo-1516245834210-c4c142787335?w=200&h=300&fit=crop',
    createdAt: new Date().toISOString(),
    seen: false,
    isVerified: true
  },
  {
    id: '2',
    userId: '2',
    username: 'Lucas D.',
    userAvatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
    mediaUrl: 'https://images.unsplash.com/photo-1516245834210-c4c142787335?w=200&h=300&fit=crop',
    createdAt: new Date().toISOString(),
    seen: true,
    isVerified: true
  }
];

export function Stories() {
  const [showStoryCreator, setShowStoryCreator] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const handleStorySubmit = (story: any) => {
    console.log('New story:', story);
    setShowStoryCreator(false);
  };

  const handleUserClick = (user: any) => {
    setSelectedUser({
      id: user.userId,
      name: user.username,
      avatar: user.userAvatar,
      isVerified: user.isVerified,
      followers: 1250,
      following: 450,
      creatorRewards: 325,
      totalContributions: 750,
      stats: {
        posts: 124,
        videos: 45,
        photos: 79,
        comments: 328
      }
    });
  };

  return (
    <>
      <div className="mb-6">
        <div className="flex gap-2 overflow-x-auto p-1">
          <div className="flex-shrink-0">
            <button 
              onClick={() => setShowStoryCreator(true)}
              className="w-20 flex flex-col items-center"
            >
              <div className="w-16 h-16 rounded-full bg-purple-900 flex items-center justify-center mb-1">
                <Plus className="text-yellow-400" size={24} />
              </div>
              <span className="text-xs text-white">Votre story</span>
            </button>
          </div>

          {stories.map(story => (
            <button 
              key={story.id}
              onClick={() => handleUserClick(story)}
              className="flex-shrink-0 w-20 flex flex-col items-center"
            >
              <div className="relative">
                <div className={`w-16 h-16 rounded-full p-0.5 ${
                  story.seen ? 'bg-gray-600' : 'bg-gradient-to-tr from-yellow-400 to-purple-600'
                }`}>
                  <img
                    src={story.userAvatar}
                    alt={story.username}
                    className="w-full h-full rounded-full border-2 border-purple-900"
                  />
                </div>
                {story.isVerified && (
                  <BadgeCheck 
                    size={16} 
                    className="absolute -bottom-1 -right-1 text-yellow-400 bg-purple-900 rounded-full" 
                  />
                )}
              </div>
              <span className="text-xs text-white mt-1">{story.username}</span>
            </button>
          ))}
        </div>
      </div>

      {showStoryCreator && (
        <StoryCreator 
          onClose={() => setShowStoryCreator(false)}
          onSubmit={handleStorySubmit}
        />
      )}

      {selectedUser && (
        <UserProfile 
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      )}
    </>
  );
}