import React from 'react';
import { 
  TrendingUp, 
  Users, 
  ShoppingBag, 
  CreditCard,
  Clock,
  ArrowUpRight,
  ArrowDownRight,
  Activity,
  Globe,
  Heart,
  Share2,
  MessageCircle
} from 'lucide-react';

interface MetricsProps {
  data: {
    totalUsers: number;
    activeUsers: number;
    monthlyGrowth: number;
    transactions: {
      total: number;
      average: number;
      growth: number;
    };
    engagement: {
      posts: number;
      comments: number;
      shares: number;
      likes: number;
    };
    revenue: {
      total: number;
      growth: number;
      projected: number;
    };
  };
}

export function AdminMetrics({ data }: MetricsProps) {
  return (
    <div className="space-y-6">
      {/* Key Performance Indicators */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center gap-2 mb-2">
            <Users className="text-yellow-400" size={20} />
            <span className="text-white font-medium">Utilisateurs</span>
          </div>
          <p className="text-2xl font-bold text-white">{data.totalUsers.toLocaleString()}</p>
          <div className="flex items-center gap-1 text-green-400 text-sm">
            <ArrowUpRight size={14} />
            <span>+{data.monthlyGrowth}%</span>
          </div>
        </div>

        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center gap-2 mb-2">
            <Activity className="text-yellow-400" size={20} />
            <span className="text-white font-medium">Actifs</span>
          </div>
          <p className="text-2xl font-bold text-white">{data.activeUsers.toLocaleString()}</p>
          <p className="text-sm text-gray-300">Utilisateurs actifs</p>
        </div>

        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center gap-2 mb-2">
            <ShoppingBag className="text-yellow-400" size={20} />
            <span className="text-white font-medium">Transactions</span>
          </div>
          <p className="text-2xl font-bold text-white">{data.transactions.total.toLocaleString()}</p>
          <div className="flex items-center gap-1 text-green-400 text-sm">
            <ArrowUpRight size={14} />
            <span>+{data.transactions.growth}%</span>
          </div>
        </div>

        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center gap-2 mb-2">
            <CreditCard className="text-yellow-400" size={20} />
            <span className="text-white font-medium">Revenu</span>
          </div>
          <p className="text-2xl font-bold text-white">{data.revenue.total.toLocaleString()}€</p>
          <div className="flex items-center gap-1 text-green-400 text-sm">
            <ArrowUpRight size={14} />
            <span>+{data.revenue.growth}%</span>
          </div>
        </div>
      </div>

      {/* Engagement Metrics */}
      <div className="bg-purple-800 p-6 rounded-xl">
        <h3 className="text-lg font-semibold text-white mb-4">Engagement</h3>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <div className="text-center">
            <div className="bg-purple-700 p-3 rounded-lg mb-2">
              <MessageCircle className="text-blue-400 mx-auto" size={24} />
            </div>
            <p className="text-2xl font-bold text-white">{data.engagement.posts.toLocaleString()}</p>
            <p className="text-sm text-gray-300">Publications</p>
          </div>
          
          <div className="text-center">
            <div className="bg-purple-700 p-3 rounded-lg mb-2">
              <Heart className="text-red-400 mx-auto" size={24} />
            </div>
            <p className="text-2xl font-bold text-white">{data.engagement.likes.toLocaleString()}</p>
            <p className="text-sm text-gray-300">Likes</p>
          </div>
          
          <div className="text-center">
            <div className="bg-purple-700 p-3 rounded-lg mb-2">
              <MessageCircle className="text-green-400 mx-auto" size={24} />
            </div>
            <p className="text-2xl font-bold text-white">{data.engagement.comments.toLocaleString()}</p>
            <p className="text-sm text-gray-300">Commentaires</p>
          </div>
          
          <div className="text-center">
            <div className="bg-purple-700 p-3 rounded-lg mb-2">
              <Share2 className="text-yellow-400 mx-auto" size={24} />
            </div>
            <p className="text-2xl font-bold text-white">{data.engagement.shares.toLocaleString()}</p>
            <p className="text-sm text-gray-300">Partages</p>
          </div>
        </div>
      </div>

      {/* Growth Projections */}
      <div className="bg-purple-800 p-6 rounded-xl">
        <h3 className="text-lg font-semibold text-white mb-4">Projections</h3>
        <div className="space-y-4">
          <div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-300">Utilisateurs</span>
              <span className="text-white font-medium">25,000</span>
            </div>
            <div className="w-full bg-purple-700 rounded-full h-2">
              <div className="bg-yellow-400 h-2 rounded-full" style={{ width: '60%' }} />
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-300">Revenu</span>
              <span className="text-white font-medium">{data.revenue.projected.toLocaleString()}€</span>
            </div>
            <div className="w-full bg-purple-700 rounded-full h-2">
              <div className="bg-green-400 h-2 rounded-full" style={{ width: '75%' }} />
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-300">Engagement</span>
              <span className="text-white font-medium">85%</span>
            </div>
            <div className="w-full bg-purple-700 rounded-full h-2">
              <div className="bg-blue-400 h-2 rounded-full" style={{ width: '85%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}