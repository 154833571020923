import React, { useState, useRef } from 'react';
import { 
  Upload, 
  X, 
  Image as ImageIcon, 
  Package, 
  DollarSign,
  Tag,
  Info,
  Gift,
  Globe,
  Shield
} from 'lucide-react';
import { useCart } from '../../../contexts/CartContext';

interface ProductFormProps {
  onClose: () => void;
  onSubmit: (product: any) => void;
}

export function ProductForm({ onClose, onSubmit }: ProductFormProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [stock, setStock] = useState('');
  const [images, setImages] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!title || !price || !category || !stock) return;

    const imageUrls = images.map(image => URL.createObjectURL(image));
    
    const product = {
      id: Date.now().toString(),
      title,
      description,
      price: Number(price),
      category,
      stock: Number(stock),
      images: imageUrls,
      sales: 0,
      impact: 0,
      seller: {
        id: 'user1',
        name: 'Romain Lopez',
        isVerified: true,
        isPlatff: false,
        avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
        rating: 4.9,
        totalSales: 45
      },
      condition: 'new',
      rating: 5,
      reviews: 0,
      isOfficialStore: false,
      createdAt: new Date().toISOString()
    };

    onSubmit(product);
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImages(Array.from(e.target.files));
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-lg max-h-[90vh] flex flex-col">
        <div className="p-6 border-b border-purple-800">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold text-white">Ajouter un produit</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X size={24} />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            {/* Images */}
            <div>
              <label className="text-white text-sm mb-2 block">Images du produit</label>
              <div className="border-2 border-dashed border-purple-800 rounded-xl p-8 text-center">
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageSelect}
                  accept="image/*"
                  multiple
                  className="hidden"
                />
                {images.length === 0 ? (
                  <div>
                    <ImageIcon className="text-yellow-400 mx-auto mb-4" size={32} />
                    <button
                      type="button"
                      onClick={() => fileInputRef.current?.click()}
                      className="bg-yellow-400 text-purple-900 px-4 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
                    >
                      Ajouter des images
                    </button>
                  </div>
                ) : (
                  <div className="grid grid-cols-3 gap-4">
                    {images.map((image, index) => (
                      <div key={index} className="relative">
                        <img
                          src={URL.createObjectURL(image)}
                          alt=""
                          className="w-full h-24 object-cover rounded-lg"
                        />
                        <button
                          type="button"
                          onClick={() => setImages(images.filter((_, i) => i !== index))}
                          className="absolute -top-2 -right-2 bg-red-500 rounded-full p-1"
                        >
                          <X size={12} className="text-white" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Product Details */}
            <div className="space-y-4">
              <div>
                <label className="text-white text-sm mb-2 block">Titre</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                  placeholder="Nom du produit"
                />
              </div>

              <div>
                <label className="text-white text-sm mb-2 block">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400 min-h-[100px]"
                  placeholder="Décrivez votre produit..."
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="text-white text-sm mb-2 block">Prix (€)</label>
                  <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                    placeholder="29.99"
                    step="0.01"
                  />
                </div>

                <div>
                  <label className="text-white text-sm mb-2 block">Stock</label>
                  <input
                    type="number"
                    value={stock}
                    onChange={(e) => setStock(e.target.value)}
                    className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                    placeholder="100"
                  />
                </div>
              </div>

              <div>
                <label className="text-white text-sm mb-2 block">Catégorie</label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                >
                  <option value="">Sélectionner une catégorie</option>
                  <option value="electronics">High-Tech</option>
                  <option value="fashion">Mode</option>
                  <option value="home">Maison</option>
                  <option value="beauty">Beauté</option>
                  <option value="sports">Sport</option>
                  <option value="books">Livres</option>
                  <option value="toys">Jouets</option>
                  <option value="automotive">Auto</option>
                  <option value="garden">Jardin</option>
                  <option value="food">Alimentation</option>
                </select>
              </div>
            </div>

            {/* Impact Info */}
            <div className="bg-purple-800 p-4 rounded-xl space-y-2">
              <div className="flex items-center gap-2 text-sm">
                <Gift className="text-green-400" size={16} />
                <span className="text-white">
                  Vos clients recevront {price ? (Number(price) * 0.05).toFixed(2) : '0.00'}€ de cashback
                </span>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <Globe className="text-blue-400" size={16} />
                <span className="text-white">
                  Impact communautaire activé pour ce produit
                </span>
              </div>
            </div>

            {/* Info Box */}
            <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
              <Shield className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
              <p className="text-sm text-gray-300">
                Votre produit sera vérifié par notre équipe avant d'être mis en ligne pour garantir la qualité de notre marketplace.
              </p>
            </div>

            {/* Submit Button */}
            <div className="flex gap-3">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 bg-purple-800 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors"
              >
                Annuler
              </button>
              <button
                type="submit"
                disabled={!title || !price || !category || !stock}
                className="flex-1 bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Ajouter le produit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}