import React from 'react';
import { Gift, Globe, Wallet } from 'lucide-react';
import type { BookingProfitDistribution } from '../../utils/bookingCalculations';

interface BookingProfitDisplayProps {
  distribution: BookingProfitDistribution;
}

export function BookingProfitDisplay({ distribution }: BookingProfitDisplayProps) {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between text-sm">
        <div className="flex items-center gap-2">
          <Gift className="text-green-400" size={16} />
          <span className="text-white">Cashback PLATFFPAY</span>
        </div>
        <span className="text-green-400">+{distribution.cashback.toFixed(2)}€</span>
      </div>
      
      <div className="flex items-center justify-between text-sm">
        <div className="flex items-center gap-2">
          <Globe className="text-blue-400" size={16} />
          <span className="text-white">Fonds Communautaire</span>
        </div>
        <span className="text-blue-400">+{distribution.communityFund.toFixed(2)}€</span>
      </div>
      
      <div className="flex items-center justify-between text-sm">
        <div className="flex items-center gap-2">
          <Wallet className="text-purple-400" size={16} />
          <span className="text-white">Fonds PLATFFPAY</span>
        </div>
        <span className="text-purple-400">+{distribution.platffpayFund.toFixed(2)}€</span>
      </div>
    </div>
  );
}