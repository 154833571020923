import React, { useState } from 'react';
import { 
  Music, 
  Video, 
  Users, 
  Star, 
  TrendingUp,
  Shield,
  BadgeCheck,
  Globe,
  Radio,
  Tv,
  Heart,
  ShoppingBag
} from 'lucide-react';
import { MusicChannel } from './MusicChannel';
import { VideoChannel } from './VideoChannel';
import { FanPage } from './FanPage';
import { VirtualShop } from '../shop/VirtualShop';

interface CreatorHubProps {
  isVerified: boolean;
}

export function CreatorHub({ isVerified }: CreatorHubProps) {
  const [activeTab, setActiveTab] = useState<'music' | 'video' | 'fanpage' | 'shop'>('music');

  if (!isVerified) {
    return (
      <div className="bg-purple-900 rounded-xl p-4 sm:p-8 text-center">
        <div className="bg-yellow-400/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
          <Shield className="text-yellow-400" size={32} />
        </div>
        <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">Vérification Requise</h2>
        <p className="text-sm sm:text-base text-gray-300 mb-6">
          Seuls les membres vérifiés peuvent créer leur boutique virtuelle sur PLATFF.
          La vérification nous permet de garantir la qualité des produits et services.
        </p>
        <button className="bg-yellow-400 text-purple-900 px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors inline-flex items-center gap-2">
          <Shield size={20} />
          <span className="truncate">Vérifier mon compte</span>
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-4 sm:space-y-6">
      {/* Creator Stats */}
      <div className="bg-purple-900 p-4 sm:p-6 rounded-xl">
        <div className="flex items-center justify-between mb-4 sm:mb-6">
          <div className="flex items-center gap-2">
            <Star className="text-yellow-400" size={24} />
            <h2 className="text-lg sm:text-xl font-bold text-white truncate">Espace Créateur</h2>
          </div>
          <div className="flex items-center gap-2">
            <BadgeCheck className="text-yellow-400" size={20} />
            <span className="text-white text-sm truncate">Compte Vérifié</span>
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4">
          <div className="bg-purple-800 p-3 sm:p-4 rounded-xl">
            <div className="flex items-center gap-2 mb-1 sm:mb-2">
              <Users className="text-blue-400" size={16} />
              <span className="text-white text-sm truncate">Abonnés</span>
            </div>
            <p className="text-lg sm:text-2xl font-bold text-blue-400">12.5K</p>
            <p className="text-xs sm:text-sm text-gray-300 truncate">+250 cette semaine</p>
          </div>

          <div className="bg-purple-800 p-3 sm:p-4 rounded-xl">
            <div className="flex items-center gap-2 mb-1 sm:mb-2">
              <Heart className="text-red-400" size={16} />
              <span className="text-white text-sm truncate">Engagement</span>
            </div>
            <p className="text-lg sm:text-2xl font-bold text-red-400">8.2K</p>
            <p className="text-xs sm:text-sm text-gray-300 truncate">interactions/jour</p>
          </div>

          <div className="bg-purple-800 p-3 sm:p-4 rounded-xl">
            <div className="flex items-center gap-2 mb-1 sm:mb-2">
              <Globe className="text-green-400" size={16} />
              <span className="text-white text-sm truncate">Impact</span>
            </div>
            <p className="text-lg sm:text-2xl font-bold text-green-400">2.5K€</p>
            <p className="text-xs sm:text-sm text-gray-300 truncate">généré ce mois</p>
          </div>
        </div>
      </div>

      {/* Content Type Selector */}
      <div className="flex gap-2 overflow-x-auto pb-2 scrollbar-hide">
        <button
          onClick={() => setActiveTab('music')}
          className={`flex-shrink-0 py-2 sm:py-4 px-4 sm:px-6 rounded-xl flex flex-col items-center gap-1 sm:gap-2 ${
            activeTab === 'music' 
              ? 'bg-yellow-400 text-purple-900' 
              : 'bg-purple-900 text-white hover:bg-purple-800'
          }`}
        >
          <Music size={20} />
          <span className="text-sm whitespace-nowrap">Musique</span>
        </button>

        <button
          onClick={() => setActiveTab('video')}
          className={`flex-shrink-0 py-2 sm:py-4 px-4 sm:px-6 rounded-xl flex flex-col items-center gap-1 sm:gap-2 ${
            activeTab === 'video' 
              ? 'bg-yellow-400 text-purple-900' 
              : 'bg-purple-900 text-white hover:bg-purple-800'
          }`}
        >
          <Tv size={20} />
          <span className="text-sm whitespace-nowrap">Vidéos</span>
        </button>

        <button
          onClick={() => setActiveTab('fanpage')}
          className={`flex-shrink-0 py-2 sm:py-4 px-4 sm:px-6 rounded-xl flex flex-col items-center gap-1 sm:gap-2 ${
            activeTab === 'fanpage' 
              ? 'bg-yellow-400 text-purple-900' 
              : 'bg-purple-900 text-white hover:bg-purple-800'
          }`}
        >
          <Users size={20} />
          <span className="text-sm whitespace-nowrap">Fan Page</span>
        </button>

        <button
          onClick={() => setActiveTab('shop')}
          className={`flex-shrink-0 py-2 sm:py-4 px-4 sm:px-6 rounded-xl flex flex-col items-center gap-1 sm:gap-2 ${
            activeTab === 'shop' 
              ? 'bg-yellow-400 text-purple-900' 
              : 'bg-purple-900 text-white hover:bg-purple-800'
          }`}
        >
          <ShoppingBag size={20} />
          <span className="text-sm whitespace-nowrap">Boutique</span>
        </button>
      </div>

      {/* Content Section */}
      <div className="overflow-hidden">
        {activeTab === 'music' && <MusicChannel />}
        {activeTab === 'video' && <VideoChannel />}
        {activeTab === 'fanpage' && <FanPage isVerified={isVerified} />}
        {activeTab === 'shop' && <VirtualShop isVerified={isVerified} />}
      </div>
    </div>
  );
}