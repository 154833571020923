import { 
  Smartphone, 
  Shirt, 
  Home, 
  Sparkles, 
  Dumbbell, 
  Book, 
  Gamepad2, 
  Car, 
  Flower2, 
  Apple,
  Laptop,
  Headphones,
  Watch,
  Camera,
  Tv,
  Shoe,
  Glasses,
  Crown,
  Handbag,
  Sofa,
  Bed,
  Kitchen,
  Lamp,
  Palette,
  Scissors,
  Heart,
  Dumbbell as Fitness,
  Trophy,
  Bike,
  GraduationCap,
  Baby,
  Wrench,
  Leaf,
  UtensilsCrossed
} from 'lucide-react';
import type { CategoryDefinition } from '../types/shop';

export const categories: CategoryDefinition[] = [
  {
    id: 'electronics',
    label: 'High-Tech',
    icon: Smartphone,
    subcategories: [
      { id: 'smartphones', label: 'Smartphones' },
      { id: 'computers', label: 'Ordinateurs' },
      { id: 'audio', label: 'Audio' },
      { id: 'wearables', label: 'Montres & Objets connectés' },
      { id: 'cameras', label: 'Photo & Vidéo' },
      { id: 'tv-video', label: 'TV & Vidéo' }
    ]
  },
  {
    id: 'fashion',
    label: 'Mode',
    icon: Shirt,
    subcategories: [
      { id: 'clothing', label: 'Vêtements' },
      { id: 'shoes', label: 'Chaussures' },
      { id: 'accessories', label: 'Accessoires' },
      { id: 'jewelry', label: 'Bijoux' },
      { id: 'bags', label: 'Sacs' },
      { id: 'luxury', label: 'Luxe' }
    ]
  },
  {
    id: 'home',
    label: 'Maison',
    icon: Home,
    subcategories: [
      { id: 'furniture', label: 'Meubles' },
      { id: 'bedding', label: 'Literie' },
      { id: 'kitchen', label: 'Cuisine' },
      { id: 'decor', label: 'Décoration' },
      { id: 'lighting', label: 'Luminaires' },
      { id: 'storage', label: 'Rangement' }
    ]
  },
  {
    id: 'beauty',
    label: 'Beauté',
    icon: Sparkles,
    subcategories: [
      { id: 'skincare', label: 'Soin du visage' },
      { id: 'makeup', label: 'Maquillage' },
      { id: 'fragrance', label: 'Parfums' },
      { id: 'haircare', label: 'Cheveux' },
      { id: 'bodycare', label: 'Corps & Bain' },
      { id: 'tools', label: 'Accessoires' }
    ]
  },
  {
    id: 'sports',
    label: 'Sport',
    icon: Dumbbell,
    subcategories: [
      { id: 'fitness', label: 'Fitness' },
      { id: 'running', label: 'Running' },
      { id: 'team-sports', label: 'Sports collectifs' },
      { id: 'cycling', label: 'Cyclisme' },
      { id: 'outdoor', label: 'Plein air' },
      { id: 'equipment', label: 'Équipement' }
    ]
  },
  {
    id: 'books',
    label: 'Livres',
    icon: Book,
    subcategories: [
      { id: 'fiction', label: 'Fiction' },
      { id: 'nonfiction', label: 'Non-fiction' },
      { id: 'education', label: 'Éducation' },
      { id: 'comics', label: 'BD & Mangas' },
      { id: 'children', label: 'Jeunesse' },
      { id: 'ebooks', label: 'E-books' }
    ]
  },
  {
    id: 'toys',
    label: 'Jouets',
    icon: Gamepad2,
    subcategories: [
      { id: 'games', label: 'Jeux & Puzzles' },
      { id: 'videogames', label: 'Jeux vidéo' },
      { id: 'educational', label: 'Jouets éducatifs' },
      { id: 'outdoor', label: 'Plein air' },
      { id: 'baby', label: 'Bébé & Éveil' },
      { id: 'collectibles', label: 'Collection' }
    ]
  },
  {
    id: 'automotive',
    label: 'Auto',
    icon: Car,
    subcategories: [
      { id: 'parts', label: 'Pièces détachées' },
      { id: 'accessories', label: 'Accessoires' },
      { id: 'tools', label: 'Outils' },
      { id: 'tires', label: 'Pneus' },
      { id: 'electronics', label: 'Électronique' },
      { id: 'care', label: 'Entretien' }
    ]
  },
  {
    id: 'garden',
    label: 'Jardin',
    icon: Flower2,
    subcategories: [
      { id: 'plants', label: 'Plantes' },
      { id: 'tools', label: 'Outils' },
      { id: 'furniture', label: 'Mobilier' },
      { id: 'decor', label: 'Décoration' },
      { id: 'lighting', label: 'Éclairage' },
      { id: 'equipment', label: 'Équipement' }
    ]
  },
  {
    id: 'food',
    label: 'Alimentation',
    icon: Apple,
    subcategories: [
      { id: 'organic', label: 'Bio' },
      { id: 'gourmet', label: 'Épicerie fine' },
      { id: 'beverages', label: 'Boissons' },
      { id: 'snacks', label: 'Snacks' },
      { id: 'fresh', label: 'Frais' },
      { id: 'supplements', label: 'Compléments' }
    ]
  }
];

export const getCategory = (id: string): CategoryDefinition | undefined => {
  return categories.find(cat => cat.id === id);
};

export const getSubcategory = (categoryId: string, subcategoryId: string) => {
  const category = getCategory(categoryId);
  return category?.subcategories.find(sub => sub.id === subcategoryId);
};