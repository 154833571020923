import React, { useState } from 'react';
import { Hash, Search, TrendingUp, X } from 'lucide-react';

interface HashtagPickerProps {
  onSelect: (hashtag: string) => void;
  onClose: () => void;
}

export function HashtagPicker({ onSelect, onClose }: HashtagPickerProps) {
  const [searchQuery, setSearchQuery] = useState('');

  const trendingHashtags = [
    { tag: 'Impact', count: 12500 },
    { tag: 'Innovation', count: 8900 },
    { tag: 'Community', count: 7500 },
    { tag: 'Sustainability', count: 6200 },
    { tag: 'Tech', count: 5800 }
  ];

  const recentHashtags = [
    'PLATFFMusic',
    'CreatorEconomy',
    'SocialImpact',
    'ChangeTheWorld',
    'Innovation'
  ];

  const filteredHashtags = [...trendingHashtags, ...recentHashtags.map(tag => ({ tag, count: 0 }))]
    .filter(({ tag }) => 
      tag.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[60] p-4">
      <div className="bg-purple-900 rounded-xl p-4 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold text-white">Ajouter un hashtag</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={20} />
          </button>
        </div>

        <div className="relative mb-4">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher un hashtag..."
            className="w-full bg-purple-800 text-white pl-10 pr-4 py-2 rounded-xl focus:ring-2 focus:ring-yellow-400"
          />
        </div>

        <div className="space-y-4">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <TrendingUp className="text-yellow-400" size={20} />
              <h4 className="text-white font-medium">Tendances</h4>
            </div>
            <div className="grid grid-cols-2 gap-2">
              {trendingHashtags
                .filter(({ tag }) => 
                  tag.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map(({ tag, count }) => (
                  <button
                    key={tag}
                    onClick={() => {
                      onSelect(tag);
                      onClose();
                    }}
                    className="bg-purple-800 p-3 rounded-lg hover:bg-purple-700 transition-colors text-left"
                  >
                    <div className="flex items-center gap-2">
                      <Hash className="text-yellow-400" size={16} />
                      <span className="text-white">{tag}</span>
                    </div>
                    <p className="text-sm text-gray-300 mt-1">{count.toLocaleString()} posts</p>
                  </button>
                ))}
            </div>
          </div>

          <div>
            <h4 className="text-white font-medium mb-2">Récents</h4>
            <div className="flex flex-wrap gap-2">
              {recentHashtags
                .filter(tag => 
                  tag.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map(tag => (
                  <button
                    key={tag}
                    onClick={() => {
                      onSelect(tag);
                      onClose();
                    }}
                    className="bg-purple-800 px-3 py-1 rounded-full hover:bg-purple-700 transition-colors flex items-center gap-1"
                  >
                    <Hash className="text-yellow-400" size={14} />
                    <span className="text-white">{tag}</span>
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}