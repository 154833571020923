import React, { useState } from 'react';
import { Building, Shield, ArrowRight, Copy, Check, Info, Lock } from 'lucide-react';

interface BankAccountSetupProps {
  onComplete: () => void;
  isActivated: boolean;
  onActivate: () => void;
}

export function BankAccountSetup({ onComplete, isActivated, onActivate }: BankAccountSetupProps) {
  const [iban, setIban] = useState('FR7630006000011234567890189');
  const [bic, setBic] = useState('BNPAFRPP');
  const [copied, setCopied] = useState(false);

  const handleCopyIban = async () => {
    try {
      await navigator.clipboard.writeText(iban);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const formatIban = (iban: string) => {
    return iban.replace(/(.{4})/g, '$1 ').trim();
  };

  if (!isActivated) {
    return (
      <div className="bg-purple-900 p-6 rounded-xl text-center">
        <Building className="text-yellow-400 mx-auto mb-4" size={48} />
        <h3 className="text-xl font-bold text-white mb-2">Activez votre compte PLATFFPAY</h3>
        <p className="text-gray-300 mb-6">
          Activez PLATFFPAY pour obtenir votre IBAN et gérer vos fonds
        </p>
        <button
          onClick={onActivate}
          className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2 w-full"
        >
          <Lock size={20} />
          Activer PLATFFPAY
        </button>
      </div>
    );
  }

  return (
    <div className="bg-purple-900 p-6 rounded-xl">
      <div className="flex items-center gap-3 mb-6">
        <Building className="text-yellow-400" size={24} />
        <div>
          <h2 className="text-xl font-bold text-white">Compte PLATFFPAY</h2>
          <p className="text-gray-300">Vos coordonnées bancaires</p>
        </div>
      </div>

      <div className="space-y-6">
        {/* IBAN Section */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex items-center justify-between mb-2">
            <label className="text-white font-medium">IBAN</label>
            <button
              onClick={handleCopyIban}
              className="text-yellow-400 hover:text-yellow-300 transition-colors flex items-center gap-1"
            >
              {copied ? (
                <>
                  <Check size={16} />
                  <span className="text-sm">Copié !</span>
                </>
              ) : (
                <>
                  <Copy size={16} />
                  <span className="text-sm">Copier</span>
                </>
              )}
            </button>
          </div>
          <div className="bg-purple-700 p-3 rounded-lg font-mono text-white text-lg tracking-wider">
            {formatIban(iban)}
          </div>
        </div>

        {/* BIC Section */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <label className="text-white font-medium block mb-2">BIC / SWIFT</label>
          <div className="bg-purple-700 p-3 rounded-lg font-mono text-white">
            {bic}
          </div>
        </div>

        {/* Bank Details */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <h3 className="text-white font-medium mb-3">Informations bancaires</h3>
          <div className="space-y-2 text-sm">
            <div className="flex justify-between">
              <span className="text-gray-300">Banque</span>
              <span className="text-white">PLATFFPAY</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-300">Titulaire</span>
              <span className="text-white">ROMAIN LOPEZ</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-300">Type de compte</span>
              <span className="text-white">Compte courant</span>
            </div>
          </div>
        </div>

        {/* Security Info */}
        <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
          <Shield className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
          <div>
            <p className="text-white font-medium mb-1">Compte sécurisé</p>
            <p className="text-sm text-gray-300">
              Votre compte PLATFFPAY est protégé par les mêmes standards de sécurité que les banques traditionnelles. Tous vos cashbacks sont automatiquement crédités sur ce compte.
            </p>
          </div>
        </div>

        {/* Features */}
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-purple-800 p-4 rounded-xl">
            <Lock className="text-yellow-400 mb-2" size={20} />
            <p className="text-white font-medium">Protection des fonds</p>
            <p className="text-sm text-gray-300">Garantie jusqu'à 100 000€</p>
          </div>
          <div className="bg-purple-800 p-4 rounded-xl">
            <Building className="text-yellow-400 mb-2" size={20} />
            <p className="text-white font-medium">Virements instantanés</p>
            <p className="text-sm text-gray-300">24/7 en Europe</p>
          </div>
        </div>

        {/* Info Box */}
        <div className="bg-yellow-400/10 p-4 rounded-xl flex items-start gap-3">
          <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
          <p className="text-sm text-gray-300">
            Utilisez ces coordonnées bancaires pour recevoir des virements et configurer vos prélèvements automatiques. Tous vos cashbacks seront automatiquement crédités sur ce compte.
          </p>
        </div>
      </div>
    </div>
  );
}