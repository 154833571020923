import type { Category, StreamContent } from '../types/streaming';

export const streamingCategories: Category[] = [
  {
    id: 'movies',
    label: 'Films',
    subcategories: [
      { id: 'action', label: 'Action' },
      { id: 'comedy', label: 'Comédie' },
      { id: 'drama', label: 'Drame' },
      { id: 'horror', label: 'Horreur' }
    ]
  },
  {
    id: 'series',
    label: 'Séries',
    subcategories: [
      { id: 'drama-series', label: 'Séries Dramatiques' },
      { id: 'comedy-series', label: 'Séries Comiques' },
      { id: 'documentary-series', label: 'Séries Documentaires' }
    ]
  },
  {
    id: 'documentaries',
    label: 'Documentaires',
    subcategories: [
      { id: 'nature', label: 'Nature' },
      { id: 'history', label: 'Histoire' },
      { id: 'science', label: 'Science' }
    ]
  }
];

export const streamingContent: StreamContent[] = [
  {
    id: '1',
    title: 'Night of the Living Dead',
    description: 'Un classique du cinéma d\'horreur de 1968 qui a redéfini le genre zombie.',
    thumbnail: 'https://images.unsplash.com/photo-1509248961158-e54f6934749c?w=800&h=400&fit=crop',
    videoUrl: 'https://archive.org/download/night_of_the_living_dead/night_of_the_living_dead_512kb.mp4',
    duration: '1h 36min',
    views: 1250000,
    likes: 45000,
    type: 'movie',
    genre: ['Horror', 'Classic'],
    year: 1968,
    rating: 4.8,
    creator: {
      name: 'George A. Romero',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop',
      verified: true
    },
    communityImpact: 250,
    category: 'movies',
    subcategory: 'horror'
  },
  {
    id: '2',
    title: 'The General',
    description: 'Un chef-d\'œuvre du cinéma muet de Buster Keaton sorti en 1926.',
    thumbnail: 'https://images.unsplash.com/photo-1542204165-65bf26472b9b?w=800&h=400&fit=crop',
    videoUrl: 'https://archive.org/download/TheGeneral_201504/The General.mp4',
    duration: '1h 15min',
    views: 890000,
    likes: 32000,
    type: 'movie',
    genre: ['Comedy', 'Classic'],
    year: 1926,
    rating: 4.9,
    creator: {
      name: 'Buster Keaton',
      avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
      verified: true
    },
    communityImpact: 180,
    category: 'movies',
    subcategory: 'comedy'
  },
  {
    id: '3',
    title: 'The Dick Van Dyke Show',
    description: 'Une série télévisée comique classique des années 60.',
    thumbnail: 'https://images.unsplash.com/photo-1522869635100-9f4c5e86aa37?w=800&h=400&fit=crop',
    videoUrl: 'https://archive.org/download/TheDickVanDykeShow/The Dick Van Dyke Show - S1E1.mp4',
    duration: '25min',
    views: 750000,
    likes: 28000,
    type: 'series',
    genre: ['Comedy', 'Classic'],
    year: 1961,
    rating: 4.7,
    creator: {
      name: 'Carl Reiner',
      avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&h=100&fit=crop',
      verified: true
    },
    communityImpact: 150,
    category: 'series',
    subcategory: 'comedy-series'
  },
  {
    id: '4',
    title: 'Dragnet',
    description: 'Une série policière emblématique des années 50.',
    thumbnail: 'https://images.unsplash.com/photo-1461151304267-38535e780c79?w=800&h=400&fit=crop',
    videoUrl: 'https://archive.org/download/Dragnet_201405/Dragnet - The Big Seventeen.mp4',
    duration: '30min',
    views: 620000,
    likes: 25000,
    type: 'series',
    genre: ['Drama', 'Crime', 'Classic'],
    year: 1951,
    rating: 4.6,
    creator: {
      name: 'Jack Webb',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
      verified: true
    },
    communityImpact: 120,
    category: 'series',
    subcategory: 'drama-series'
  }
];