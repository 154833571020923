import React from 'react';
import { Bell, Gift, Globe, Star, X, BadgeCheck, Heart, MessageCircle, ShoppingBag } from 'lucide-react';

interface NotificationsPanelProps {
  onClose: () => void;
}

export function NotificationsPanel({ onClose }: NotificationsPanelProps) {
  const notifications = [
    {
      id: '1',
      type: 'reward',
      title: 'Nouveau cashback reçu !',
      message: 'Vous avez reçu 15€ de cashback pour votre dernier achat.',
      icon: Gift,
      color: 'text-green-400',
      time: '2 min',
      unread: true
    },
    {
      id: '2',
      type: 'impact',
      title: 'Impact communautaire',
      message: 'Votre dernière action a généré 25€ pour le Fonds Communautaire.',
      icon: Globe,
      color: 'text-blue-400',
      time: '1h',
      unread: true
    },
    {
      id: '3',
      type: 'social',
      title: 'Nouveau message',
      message: 'Sophie Martin vous a envoyé un message.',
      icon: MessageCircle,
      color: 'text-yellow-400',
      time: '3h',
      unread: true
    }
  ];

  return (
    <div className="fixed inset-y-0 right-0 w-full max-w-md bg-purple-900 shadow-lg z-[60]">
      <div className="flex flex-col h-full">
        {/* Header */}
        <div className="p-4 border-b border-purple-800">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold text-white flex items-center gap-2">
              <Bell className="text-yellow-400" />
              Notifications
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X size={24} />
            </button>
          </div>
        </div>

        {/* Notifications List */}
        <div className="flex-1 overflow-y-auto p-4">
          <div className="space-y-4">
            {notifications.map(notification => (
              <div
                key={notification.id}
                className={`bg-purple-800 p-4 rounded-xl ${
                  notification.unread ? 'ring-2 ring-yellow-400/50' : ''
                }`}
              >
                <div className="flex gap-4">
                  <div className={`${notification.color}`}>
                    <notification.icon size={24} />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-white font-semibold mb-1">
                      {notification.title}
                    </h3>
                    <p className="text-gray-300 text-sm">
                      {notification.message}
                    </p>
                    <p className="text-gray-400 text-xs mt-2">
                      {notification.time}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="p-4 border-t border-purple-800">
          <button
            className="w-full bg-purple-800 text-white py-2 rounded-xl hover:bg-purple-700 transition-colors"
          >
            Tout marquer comme lu
          </button>
        </div>
      </div>
    </div>
  );
}