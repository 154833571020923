import React, { useState } from 'react';
import { 
  ShoppingBag, 
  Plus, 
  Edit3, 
  Trash2, 
  Package, 
  DollarSign,
  BadgeCheck,
  Globe,
  Gift,
  Shield,
  Lock
} from 'lucide-react';
import { ProductForm } from './ProductForm';
import { ShopStats } from './ShopStats';
import { ShopSettings } from './ShopSettings';
import { ShopImpactStats } from './ShopImpactStats';
import { useCart } from '../../../contexts/CartContext';

interface VirtualShopProps {
  isVerified: boolean;
}

const mockImpactStats = {
  totalSales: 45,
  totalRevenue: 1250,
  totalCashback: 62.50,
  totalImpact: 187.50,
  verifiedCustomers: 42,
  monthlyGrowth: 25
};

export function VirtualShop({ isVerified }: VirtualShopProps) {
  const [showProductForm, setShowProductForm] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [products, setProducts] = useState<any[]>([]);
  const { addToCart } = useCart();

  if (!isVerified) {
    return (
      <div className="bg-purple-900 rounded-xl p-8 text-center">
        <div className="bg-yellow-400/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
          <Lock className="text-yellow-400" size={32} />
        </div>
        <h2 className="text-2xl font-bold text-white mb-4">Vérification Requise</h2>
        <p className="text-gray-300 mb-6">
          Seuls les membres vérifiés peuvent créer leur boutique virtuelle sur PLATFF.
          La vérification nous permet de garantir la qualité des produits et services.
        </p>
        <button className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-colors inline-flex items-center gap-2">
          <Shield size={20} />
          Vérifier mon compte
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Shop Header */}
      <div className="bg-purple-900 p-6 rounded-xl">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <ShoppingBag className="text-yellow-400" size={24} />
            <div>
              <h2 className="text-xl font-bold text-white">Ma Boutique</h2>
              <p className="text-gray-300">Gérez vos produits et suivez vos ventes</p>
            </div>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => setShowSettings(true)}
              className="bg-purple-800 p-2 rounded-full text-white hover:bg-purple-700 transition-colors"
            >
              <Edit3 size={20} />
            </button>
            <button
              onClick={() => setShowProductForm(true)}
              className="bg-yellow-400 text-purple-900 px-4 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center gap-2"
            >
              <Plus size={20} />
              Ajouter un produit
            </button>
          </div>
        </div>

        <ShopStats />
      </div>

      {/* Impact Stats */}
      <ShopImpactStats stats={mockImpactStats} />

      {/* Products Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {products.map(product => (
          <div key={product.id} className="bg-purple-900 rounded-xl overflow-hidden">
            <div className="relative">
              <img 
                src={product.images[0]} 
                alt={product.title}
                className="w-full h-48 object-cover"
              />
              <div className="absolute top-4 right-4 flex gap-2">
                <button className="bg-purple-900/80 p-2 rounded-full text-white hover:bg-purple-900">
                  <Edit3 size={16} />
                </button>
                <button className="bg-red-500/80 p-2 rounded-full text-white hover:bg-red-500">
                  <Trash2 size={16} />
                </button>
              </div>
            </div>

            <div className="p-4">
              <h3 className="text-lg font-bold text-white mb-1">{product.title}</h3>
              <p className="text-gray-300 text-sm mb-4">{product.description}</p>

              <div className="flex flex-wrap gap-2 mb-4">
                <span className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm">
                  {product.category}
                </span>
                <span className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm flex items-center gap-1">
                  <Package size={14} />
                  Stock: {product.stock}
                </span>
                <span className="bg-purple-800 text-white px-3 py-1 rounded-full text-sm flex items-center gap-1">
                  <DollarSign size={14} />
                  Ventes: {product.sales}
                </span>
              </div>

              <div className="space-y-2 mb-4">
                <div className="flex items-center gap-2 text-sm">
                  <Gift className="text-green-400" size={16} />
                  <span className="text-white">
                    {(product.price * 0.05).toFixed(2)}€ cashback pour vos clients
                  </span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <Globe className="text-blue-400" size={16} />
                  <span className="text-white">
                    Impact total: {product.impact}€
                  </span>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <p className="text-2xl font-bold text-white">{product.price}€</p>
                <div className="flex items-center gap-2">
                  <BadgeCheck className="text-yellow-400" size={20} />
                  <span className="text-gray-300">Vérifié</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Product Form Modal */}
      {showProductForm && (
        <ProductForm
          onClose={() => setShowProductForm(false)}
          onSubmit={(product) => {
            setProducts(prev => [...prev, product]);
            addToCart({
              productId: product.id,
              quantity: 1,
              price: product.price,
              cashbackAmount: product.price * 0.05,
              communityAmount: product.price * 0.15
            });
            setShowProductForm(false);
          }}
        />
      )}

      {/* Settings Modal */}
      {showSettings && (
        <ShopSettings
          onClose={() => setShowSettings(false)}
          onSave={(settings) => {
            console.log('Shop settings:', settings);
            setShowSettings(false);
          }}
        />
      )}
    </div>
  );
}