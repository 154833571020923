import React, { useState, useEffect } from 'react';
import { BookingCard } from './BookingCard';
import { FinalBookingModal } from './event/FinalBookingModal';

const mockCars = [
  {
    id: 'car1',
    title: 'Mercedes-Benz Classe S avec chauffeur',
    type: 'car',
    image: 'https://images.unsplash.com/photo-1550355291-bbee04a92027?w=800&h=400&fit=crop',
    badge: { text: 'Premium', color: 'yellow' },
    location: 'Paris, France',
    rating: 4.9,
    reviews: 156,
    description: 'Berline de luxe avec chauffeur professionnel. Service conciergerie inclus.',
    price: 199,
    features: ['Chauffeur expérimenté', 'Minibar', 'WiFi', 'Sièges cuir'],
    provider: {
      name: 'PLATFF Premium',
      logo: 'https://images.unsplash.com/photo-1550355291-bbee04a92027?w=50&h=50&fit=crop',
      isVerified: true
    }
  },
  {
    id: 'car2',
    title: 'Tesla Model 3',
    type: 'car',
    image: 'https://images.unsplash.com/photo-1536700503339-1e4b06520771?w=800&h=400&fit=crop',
    badge: { text: 'Tesla', color: 'green' },
    location: 'Paris, France',
    rating: 4.9,
    reviews: 234,
    description: 'Véhicule électrique - Autonomie 500km. Location flexible avec assurance incluse.',
    price: 79,
    features: ['Autopilot', 'Supercharge', 'GPS', '500km autonomie'],
    provider: {
      name: 'PLATFF Green',
      logo: 'https://images.unsplash.com/photo-1536700503339-1e4b06520771?w=50&h=50&fit=crop',
      isVerified: true
    }
  }
];

interface CarBookingProps {
  searchCriteria?: {
    location: string;
    checkIn: string;
    checkOut: string;
    guests: number;
  };
}

export function CarBooking({ searchCriteria }: CarBookingProps) {
  const [showFinalBooking, setShowFinalBooking] = useState(false);
  const [selectedCar, setSelectedCar] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState(mockCars);

  useEffect(() => {
    if (searchCriteria) {
      setIsLoading(true);
      // Simulate API call
      setTimeout(() => {
        setCars(mockCars);
        setIsLoading(false);
      }, 1500);
    }
  }, [searchCriteria]);

  const handleBooking = (car: any) => {
    if (searchCriteria) {
      setSelectedCar({
        ...car,
        dates: {
          checkIn: searchCriteria.checkIn,
          checkOut: searchCriteria.checkOut,
          nights: Math.ceil(
            (new Date(searchCriteria.checkOut).getTime() - new Date(searchCriteria.checkIn).getTime()) / 
            (1000 * 60 * 60 * 24)
          )
        }
      });
      setShowFinalBooking(true);
    }
  };

  const handleConfirmBooking = () => {
    console.log('Car booking confirmed:', selectedCar);
    setShowFinalBooking(false);
  };

  if (isLoading) {
    return (
      <div className="text-center py-12">
        <div className="w-12 h-12 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
        <p className="text-white">Recherche des meilleures voitures...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {cars.map(car => (
        <BookingCard
          key={car.id}
          item={car}
          onBook={() => handleBooking(car)}
        />
      ))}

      {showFinalBooking && selectedCar && (
        <FinalBookingModal
          event={selectedCar}
          onClose={() => setShowFinalBooking(false)}
          onConfirm={handleConfirmBooking}
        />
      )}
    </div>
  );
}