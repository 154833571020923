import { 
  PLATFF_PERCENTAGE,
  COMMUNITY_FUND_PERCENTAGE,
  CASHBACK_PERCENTAGE,
  PLATFFPAY_PERCENTAGE,
  DEFAULT_BOOKING_COMMISSION
} from './constants';

export interface BookingProfitDistribution {
  platffProfit: number;
  communityFund: number;
  cashback: number;
  platffpayFund: number;
}

export function calculateBookingProfitDistribution(
  bookingAmount: number,
  commissionRate: number = DEFAULT_BOOKING_COMMISSION
): BookingProfitDistribution {
  // Calculate commission amount
  const commission = bookingAmount * (commissionRate / 100);

  // Calculate distribution based on commission
  return {
    platffProfit: commission * (PLATFF_PERCENTAGE / 100),
    communityFund: commission * (COMMUNITY_FUND_PERCENTAGE / 100),
    cashback: commission * (CASHBACK_PERCENTAGE / 100),
    platffpayFund: commission * (PLATFFPAY_PERCENTAGE / 100)
  };
}