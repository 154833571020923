import React from 'react';
import { streamingCategories } from '../../../data/streamingCategories';

interface CategoryNavProps {
  activeCategory: string;
  activeSubcategory: string | null;
  onCategoryChange: (category: string) => void;
  onSubcategoryChange: (subcategory: string | null) => void;
}

export function CategoryNav({
  activeCategory,
  activeSubcategory,
  onCategoryChange,
  onSubcategoryChange
}: CategoryNavProps) {
  return (
    <>
      <div className="flex gap-2 overflow-x-auto pb-2 mb-4">
        <button
          onClick={() => {
            onCategoryChange('all');
            onSubcategoryChange(null);
          }}
          className={`flex-shrink-0 px-4 py-2 rounded-full transition-colors ${
            activeCategory === 'all'
              ? 'bg-yellow-400 text-purple-900'
              : 'bg-purple-800 text-white hover:bg-purple-700'
          }`}
        >
          Tout
        </button>
        {streamingCategories.map(category => (
          <button
            key={category.id}
            onClick={() => {
              onCategoryChange(category.id);
              onSubcategoryChange(null);
            }}
            className={`flex-shrink-0 px-4 py-2 rounded-full transition-colors ${
              activeCategory === category.id
                ? 'bg-yellow-400 text-purple-900'
                : 'bg-purple-800 text-white hover:bg-purple-700'
            }`}
          >
            {category.label}
          </button>
        ))}
      </div>

      {activeCategory !== 'all' && (
        <div className="flex gap-2 overflow-x-auto pb-2">
          {streamingCategories
            .find(cat => cat.id === activeCategory)
            ?.subcategories.map(sub => (
              <button
                key={sub.id}
                onClick={() => onSubcategoryChange(sub.id)}
                className={`flex-shrink-0 px-4 py-2 rounded-full transition-colors ${
                  activeSubcategory === sub.id
                    ? 'bg-yellow-400 text-purple-900'
                    : 'bg-purple-800 text-white hover:bg-purple-700'
                }`}
              >
                {sub.label}
              </button>
            ))}
        </div>
      )}
    </>
  );
}