import { moderateContent } from './contentModeration';
import { translateWithAI } from './aiTranslation';

export interface MessageProcessingOptions {
  autoTranslate?: boolean;
  targetLanguage?: string;
  isEncrypted?: boolean;
  isEphemeral?: boolean;
  expiryTime?: number;
}

export interface ProcessedMessage {
  id: string;
  content: string;
  originalContent: string;
  isModerated: boolean;
  detectedWords: string[];
  moderatedWords: string[];
  encrypted?: boolean;
  ephemeral?: boolean;
  expiresAt?: number;
  timestamp: number;
  translations?: Record<string, string>;
  sender: 'me' | 'other';
}

export async function processMessage(
  content: string,
  options: MessageProcessingOptions
): Promise<ProcessedMessage> {
  // Step 1: Content moderation
  const moderation = await moderateContent(content);

  // Step 2: Translation if enabled
  let translatedContent = moderation.moderatedText;
  const translations: Record<string, string> = {};
  
  if (options.autoTranslate && options.targetLanguage) {
    translations[options.targetLanguage] = await translateWithAI(
      translatedContent, 
      options.targetLanguage
    );
  }

  // Step 3: Prepare message metadata
  const message: ProcessedMessage = {
    id: Date.now().toString(),
    content: translatedContent,
    originalContent: content,
    isModerated: !moderation.isClean,
    detectedWords: moderation.detectedWords,
    moderatedWords: moderation.detectedWords.map(() => '🍎'), // Replace with fruits
    encrypted: options.isEncrypted,
    ephemeral: options.isEphemeral,
    expiresAt: options.isEphemeral ? Date.now() + (options.expiryTime || 300000) : undefined,
    timestamp: Date.now(),
    translations: Object.keys(translations).length > 0 ? translations : undefined,
    sender: 'me'
  };

  return message;
}