import React, { useState } from 'react';
import { CreditCard, ArrowRight, Shield, Info } from 'lucide-react';

export function PaymentLimits() {
  const [limits, setLimits] = useState({
    daily: 1000,
    monthly: 5000,
    online: 500,
    atm: 300
  });

  const handleUpdateLimit = (type: keyof typeof limits, value: number) => {
    setLimits(prev => ({ ...prev, [type]: value }));
  };

  return (
    <div className="bg-purple-900 rounded-xl p-6">
      <div className="flex items-center gap-3 mb-6">
        <CreditCard className="text-yellow-400" size={24} />
        <div>
          <h2 className="text-xl font-bold text-white">Limites de paiement</h2>
          <p className="text-gray-300">Gérez vos plafonds de dépenses</p>
        </div>
      </div>

      <div className="space-y-6">
        {/* Daily Limit */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex justify-between items-center mb-2">
            <label className="text-white">Limite journalière</label>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={limits.daily}
                onChange={(e) => handleUpdateLimit('daily', Number(e.target.value))}
                className="w-24 bg-purple-700 text-white rounded-lg px-3 py-1 text-right"
              />
              <span className="text-gray-300">€</span>
            </div>
          </div>
          <div className="w-full bg-purple-700 rounded-full h-2">
            <div className="bg-yellow-400 h-2 rounded-full" style={{ width: '60%' }} />
          </div>
          <p className="text-sm text-gray-300 mt-1">600€ utilisés aujourd'hui</p>
        </div>

        {/* Monthly Limit */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex justify-between items-center mb-2">
            <label className="text-white">Limite mensuelle</label>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={limits.monthly}
                onChange={(e) => handleUpdateLimit('monthly', Number(e.target.value))}
                className="w-24 bg-purple-700 text-white rounded-lg px-3 py-1 text-right"
              />
              <span className="text-gray-300">€</span>
            </div>
          </div>
          <div className="w-full bg-purple-700 rounded-full h-2">
            <div className="bg-yellow-400 h-2 rounded-full" style={{ width: '45%' }} />
          </div>
          <p className="text-sm text-gray-300 mt-1">2250€ utilisés ce mois-ci</p>
        </div>

        {/* Online Payments */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex justify-between items-center">
            <label className="text-white">Paiements en ligne</label>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={limits.online}
                onChange={(e) => handleUpdateLimit('online', Number(e.target.value))}
                className="w-24 bg-purple-700 text-white rounded-lg px-3 py-1 text-right"
              />
              <span className="text-gray-300">€</span>
            </div>
          </div>
        </div>

        {/* ATM Withdrawals */}
        <div className="bg-purple-800 p-4 rounded-xl">
          <div className="flex justify-between items-center">
            <label className="text-white">Retraits DAB</label>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={limits.atm}
                onChange={(e) => handleUpdateLimit('atm', Number(e.target.value))}
                className="w-24 bg-purple-700 text-white rounded-lg px-3 py-1 text-right"
              />
              <span className="text-gray-300">€</span>
            </div>
          </div>
        </div>

        {/* Info Box */}
        <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
          <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
          <div>
            <p className="text-white font-medium mb-1">Protection renforcée</p>
            <p className="text-sm text-gray-300">
              Les limites de paiement vous protègent contre les transactions frauduleuses. Vous pouvez les modifier à tout moment.
            </p>
          </div>
        </div>

        <button className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2">
          <Shield size={20} />
          Mettre à jour les limites
        </button>
      </div>
    </div>
  );
}