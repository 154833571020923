import type { Product } from '../types/shop';

export const mockProducts: Product[] = [
  {
    id: '1',
    title: 'Cerruti 1881 Pour Homme - Eau de Toilette 100ml',
    description: 'Une fragrance boisée aromatique pour homme. Notes de tête : Lavande, Notes de cœur : Bois de santal, Notes de fond : Musc.',
    price: 59.99,
    originalPrice: 96.00,
    images: ['https://images.btswholesaler.com/imgs/productos_cosmetica/imagenes/5050456522743.jpg'],
    category: 'beauty',
    seller: {
      id: 'platff',
      name: 'PLATFF Official',
      isVerified: true,
      isPlatff: true,
      avatar: 'https://images.unsplash.com/photo-1549692520-acc6669e2f0c?w=100&h=100&fit=crop',
      rating: 4.9,
      totalSales: 15420
    },
    condition: 'new',
    stock: 85,
    rating: 4.7,
    reviews: 312,
    isOfficialStore: true,
    ean: '5050456522743',
    createdAt: new Date().toISOString()
  },
  {
    id: '2',
    title: 'iPhone 13 Pro - 256GB Reconditionné',
    description: 'iPhone 13 Pro reconditionné en excellent état. Garantie 12 mois. Batterie 100%.',
    price: 799.99,
    originalPrice: 1159.00,
    images: ['https://images.unsplash.com/photo-1632661674596-df8be070a5c5?w=800&h=800&fit=crop'],
    category: 'electronics',
    seller: {
      id: 'techpro',
      name: 'TechPro Store',
      isVerified: true,
      isPlatff: false,
      avatar: 'https://images.unsplash.com/photo-1633409361618-c73427e4e206?w=100&h=100&fit=crop',
      rating: 4.8,
      totalSales: 3240
    },
    condition: 'refurbished',
    stock: 12,
    rating: 4.8,
    reviews: 156,
    isOfficialStore: false,
    createdAt: new Date().toISOString()
  },
  {
    id: '3',
    title: 'MacBook Air M1 - Occasion',
    description: 'MacBook Air M1 2020 en très bon état. 8GB RAM, 256GB SSD. Batterie 89%.',
    price: 699.99,
    originalPrice: 1199.00,
    images: ['https://images.unsplash.com/photo-1611186871348-b1ce696e52c9?w=800&h=800&fit=crop'],
    category: 'electronics',
    seller: {
      id: 'user123',
      name: 'Sophie Martin',
      isVerified: true,
      isPlatff: false,
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
      rating: 4.7,
      totalSales: 45
    },
    condition: 'used',
    stock: 1,
    rating: 4.6,
    reviews: 23,
    isOfficialStore: false,
    createdAt: new Date().toISOString()
  },
  {
    id: '4',
    title: 'AirPods Pro 2ème Génération',
    description: 'AirPods Pro 2 neufs et scellés. Réduction de bruit active, audio spatial.',
    price: 249.99,
    originalPrice: 279.00,
    images: ['https://images.unsplash.com/photo-1600294037681-c80b4cb5b434?w=800&h=800&fit=crop'],
    category: 'electronics',
    seller: {
      id: 'platff',
      name: 'PLATFF Official',
      isVerified: true,
      isPlatff: true,
      avatar: 'https://images.unsplash.com/photo-1549692520-acc6669e2f0c?w=100&h=100&fit=crop',
      rating: 4.9,
      totalSales: 15420
    },
    condition: 'new',
    stock: 250,
    rating: 4.9,
    reviews: 892,
    isOfficialStore: true,
    createdAt: new Date().toISOString()
  },
  {
    id: '5',
    title: 'Collection Vintage - Veste en Cuir',
    description: 'Veste en cuir vintage années 80. Excellent état. Taille M.',
    price: 189.99,
    images: ['https://images.unsplash.com/photo-1551028719-00167b16eac5?w=800&h=800&fit=crop'],
    category: 'fashion',
    seller: {
      id: 'vintagelover',
      name: 'Marie Vintage',
      isVerified: true,
      isPlatff: false,
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop',
      rating: 4.9,
      totalSales: 156
    },
    condition: 'used',
    stock: 1,
    rating: 4.8,
    reviews: 12,
    isOfficialStore: false,
    createdAt: new Date().toISOString()
  },
  {
    id: '6',
    title: 'Console PS5 Reconditionnée',
    description: 'PS5 reconditionnée par nos experts. Garantie 12 mois. Comme neuve.',
    price: 449.99,
    originalPrice: 549.99,
    images: ['https://images.unsplash.com/photo-1606813907291-d86efa9b94db?w=800&h=800&fit=crop'],
    category: 'electronics',
    seller: {
      id: 'gamestop',
      name: 'GameStop France',
      isVerified: true,
      isPlatff: false,
      avatar: 'https://images.unsplash.com/photo-1550745165-9bc0b252726f?w=100&h=100&fit=crop',
      rating: 4.8,
      totalSales: 2450
    },
    condition: 'refurbished',
    stock: 8,
    rating: 4.7,
    reviews: 89,
    isOfficialStore: false,
    createdAt: new Date().toISOString()
  }
];