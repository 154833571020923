export const en = {
  'app.name': 'PLATFF',
  'app.tagline': 'A revolutionary new power transforming your digital experience',
  'landing.description': 'The first all-in-one platform combining marketplace, bookings, social network and Community Fund, creating a unique and engaging experience.',
  'landing.getStarted': 'Join the revolution',
  'landing.footer': 'Made with ❤️ in France | Europe',

  'auth.welcomeBack': 'Welcome Back!',
  'auth.emailPlaceholder': 'your@email.com',
  'auth.password': 'Password',
  'auth.passwordPlaceholder': '••••••••',
  'auth.forgotPassword': 'Forgot password?',
  'auth.login': 'Log in',
  'auth.logout': 'Log out',
  'auth.noAccount': "Don't have an account?",
  'auth.register': 'Sign up',

  'nav.home': 'Home',
  'nav.shop': 'Marketplace',
  'nav.bookings': 'Book',
  'nav.social': 'Social',
  'nav.wallet': 'PLATFFPAY',
  'nav.impact': 'Impact',
  'nav.navigation': 'Navigation',

  // Rest of translations remain the same...
};