import React, { useState } from 'react';
import { mockPolls } from '../../data/mockSocialData';
import { BarChart2, BadgeCheck, Globe, Clock } from 'lucide-react';

export function PollsSection() {
  const [votes, setVotes] = useState<Record<string, string>>({});

  const handleVote = (pollId: string, optionId: string) => {
    setVotes(prev => ({ ...prev, [pollId]: optionId }));
  };

  return (
    <div className="space-y-6">
      {mockPolls.map(poll => {
        const userVoted = votes[poll.id];
        const totalVotes = poll.options.reduce((sum, opt) => sum + opt.votes, 0);

        return (
          <div key={poll.id} className="bg-purple-900 rounded-xl p-6">
            {poll.featured && (
              <div className="bg-yellow-400 text-purple-900 px-3 py-1 rounded-full text-sm font-semibold inline-flex items-center gap-1 mb-4">
                <BarChart2 size={14} />
                Sondage à la une
              </div>
            )}

            <div className="flex items-start gap-4 mb-4">
              <img src={poll.creator.avatar} alt="" className="w-12 h-12 rounded-full" />
              <div>
                <div className="flex items-center gap-2">
                  <h3 className="font-semibold text-white">{poll.creator.name}</h3>
                  {poll.creator.verified && (
                    <BadgeCheck size={16} className="text-yellow-400" />
                  )}
                </div>
                <div className="flex items-center gap-2 text-gray-300 text-sm">
                  <Clock size={14} />
                  <span>
                    Termine le {new Date(poll.endDate).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>

            <h2 className="text-xl font-bold text-white mb-2">{poll.title}</h2>
            <p className="text-gray-300 mb-4">{poll.description}</p>

            <div className="space-y-3 mb-4">
              {poll.options.map(option => {
                const percentage = Math.round((option.votes / totalVotes) * 100) || 0;
                const isSelected = userVoted === option.id;

                return (
                  <button
                    key={option.id}
                    onClick={() => handleVote(poll.id, option.id)}
                    disabled={!!userVoted}
                    className={`w-full relative ${
                      isSelected ? 'bg-yellow-400' : 'bg-purple-800'
                    } p-4 rounded-lg text-left transition-colors`}
                  >
                    <div
                      className="absolute inset-0 bg-purple-700/50 rounded-lg"
                      style={{ width: `${percentage}%` }}
                    />
                    <div className="relative flex justify-between items-center">
                      <span className={isSelected ? 'text-purple-900 font-semibold' : 'text-white'}>
                        {option.text}
                      </span>
                      {userVoted && (
                        <span className={isSelected ? 'text-purple-900 font-semibold' : 'text-white'}>
                          {percentage}%
                        </span>
                      )}
                    </div>
                  </button>
                );
              })}
            </div>

            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-300">
                {poll.totalVotes.toLocaleString()} votes
              </span>
              <div className="flex items-center gap-2 text-gray-300">
                <Globe size={16} />
                <span>Impact: {poll.communityImpact}€</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}