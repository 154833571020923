import React, { useState } from 'react';
import { AuthPage } from './components/auth/AuthPage';
import { LandingPage } from './components/auth/LandingPage';
import { Header } from './components/layout/Header';
import { SideMenu } from './components/navigation/SideMenu';
import { HomeSection } from './components/sections/HomeSection';
import { ShopSection } from './components/sections/ShopSection';
import { BookingSection } from './components/sections/BookingSection';
import { SocialSection } from './components/sections/SocialSection';
import { StreamingSection } from './components/sections/StreamingSection';
import { WalletSection } from './components/sections/WalletSection';
import { CommunitySection } from './components/sections/CommunitySection';
import { AdminProfile } from './components/admin/AdminProfile';
import { CartProvider } from './contexts/CartContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { BookingProvider } from './contexts/BookingContext';
import { BalanceProvider } from './contexts/BalanceContext';
import { VerificationProvider } from './contexts/VerificationContext';
import { ContentFlowProvider } from './contexts/ContentFlowContext';

interface DemoModalProps {
  onClose: () => void;
}

function DemoModal({ onClose }: DemoModalProps) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md animate-slide-up">
        <h3 className="text-xl font-bold text-white mb-4">Version de démonstration</h3>
        <p className="text-gray-300 mb-6">
          Vous allez accéder à une version de démonstration de PLATFF qui vous permettra d'explorer toutes les fonctionnalités de la plateforme :
        </p>
        <ul className="space-y-2 text-gray-300 mb-6">
          <li>• Réseau social sécurisé avec modération IA</li>
          <li>• Marketplace avec prix dynamiques</li>
          <li>• Réservations optimisées</li>
          <li>• PLATFFPAY et cashback instantané</li>
          <li>• Fonds Communautaire</li>
        </ul>
        <button
          onClick={onClose}
          className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors"
        >
          Accéder à la démo
        </button>
      </div>
    </div>
  );
}

function App() {
  const [showLanding, setShowLanding] = useState(true);
  const [activeSection, setActiveSection] = useState('home');
  const [menuOpen, setMenuOpen] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState(false);

  const handleGetStarted = () => {
    setShowDemoModal(true);
  };

  const handleDemoAccess = () => {
    setShowDemoModal(false);
    setShowLanding(false);
  };

  return (
    <VerificationProvider>
      <LanguageProvider>
        <CartProvider>
          <BookingProvider>
            <BalanceProvider>
              <ContentFlowProvider>
                {showLanding ? (
                  <>
                    <LandingPage onGetStarted={handleGetStarted} />
                    {showDemoModal && <DemoModal onClose={handleDemoAccess} />}
                  </>
                ) : (
                  <div className="min-h-screen bg-purple-800">
                    <Header 
                      onMenuToggle={() => setMenuOpen(true)}
                      onNavigate={setActiveSection}
                      isAdmin={true}
                      isVerified={true}
                    />

                    <main className="pb-6">
                      {activeSection === 'home' && <HomeSection onNavigate={setActiveSection} />}
                      {activeSection === 'shop' && <ShopSection />}
                      {activeSection === 'bookings' && <BookingSection />}
                      {activeSection === 'social' && <SocialSection />}
                      {activeSection === 'streaming' && <StreamingSection />}
                      {activeSection === 'cashback' && <WalletSection />}
                      {activeSection === 'impact' && <CommunitySection />}
                      {activeSection === 'profile' && <AdminProfile />}
                    </main>

                    <SideMenu
                      isOpen={menuOpen}
                      onClose={() => setMenuOpen(false)}
                      onSectionChange={setActiveSection}
                      isAdmin={true}
                    />
                  </div>
                )}
              </ContentFlowProvider>
            </BalanceProvider>
          </BookingProvider>
        </CartProvider>
      </LanguageProvider>
    </VerificationProvider>
  );
}

export default App;