import React, { useState } from 'react';
import { Send, Shield, BadgeCheck, Heart, Loader } from 'lucide-react';
import { moderateContent } from '../../utils/contentModeration';
import { ModerationAlert } from './ModerationAlert';
import type { Comment } from '../../types/social';

interface CommentsProps {
  postId: string;
  comments: Comment[];
  onAddComment?: (content: string) => void;
}

export function Comments({ postId, comments: initialComments, onAddComment }: CommentsProps) {
  const [newComment, setNewComment] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModerationAlert, setShowModerationAlert] = useState(false);
  const [detectedWords, setDetectedWords] = useState<string[]>([]);
  const [moderatedContent, setModeratedContent] = useState('');
  const [comments, setComments] = useState(initialComments);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    setIsProcessing(true);

    try {
      // Content moderation
      const moderation = await moderateContent(newComment);
      
      if (!moderation.isClean) {
        setDetectedWords(moderation.detectedWords);
        setModeratedContent(moderation.moderatedText);
        setShowModerationAlert(true);
        setIsProcessing(false);
        return;
      }

      // Add comment with moderated content
      const newCommentObj: Comment = {
        id: Date.now().toString(),
        postId,
        userId: '1',
        username: 'Romain Lopez',
        userAvatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
        content: moderation.moderatedText,
        createdAt: new Date().toISOString(),
        likes: 0,
        isLiked: false,
        isVerified: true
      };

      setComments(prev => [newCommentObj, ...prev]);
      onAddComment?.(moderation.moderatedText);
      setNewComment('');
      
    } catch (error) {
      console.error('Error posting comment:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleModerationConfirm = () => {
    // Add comment with moderated content
    const newCommentObj: Comment = {
      id: Date.now().toString(),
      postId,
      userId: '1',
      username: 'Romain Lopez',
      userAvatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
      content: moderatedContent,
      createdAt: new Date().toISOString(),
      likes: 0,
      isLiked: false,
      isVerified: true
    };

    setComments(prev => [newCommentObj, ...prev]);
    onAddComment?.(moderatedContent);
    setNewComment('');
    setShowModerationAlert(false);
  };

  return (
    <div className="bg-purple-800 p-4 rounded-lg">
      <form onSubmit={handleSubmit} className="flex gap-2 mb-4">
        <div className="flex-1 relative">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Ajouter un commentaire..."
            className="w-full bg-purple-700 text-white rounded-full px-4 py-2 pr-12 focus:ring-2 focus:ring-yellow-400"
            disabled={isProcessing}
          />
          <button 
            type="submit"
            disabled={isProcessing || !newComment.trim()}
            className={`absolute right-1 top-1/2 -translate-y-1/2 bg-yellow-400 text-purple-900 p-2 rounded-full flex items-center justify-center ${
              isProcessing || !newComment.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-300'
            } transition-colors`}
          >
            {isProcessing ? (
              <Loader className="animate-spin" size={16} />
            ) : (
              <Send size={16} />
            )}
          </button>
        </div>
      </form>

      <div className="space-y-4">
        {comments.map(comment => (
          <div key={comment.id} className="flex gap-3">
            <img
              src={comment.userAvatar}
              alt={comment.username}
              className="w-8 h-8 rounded-full"
            />
            <div className="flex-1">
              <div className="bg-purple-700 rounded-lg p-3">
                <div className="flex items-center gap-1 mb-1">
                  <p className="font-semibold text-sm text-white">{comment.username}</p>
                  {comment.isVerified && (
                    <BadgeCheck size={14} className="text-yellow-400" />
                  )}
                </div>
                <p className="text-white">{comment.content}</p>
              </div>
              <div className="flex items-center gap-4 mt-2 text-sm">
                <button className={`flex items-center gap-1 ${comment.isLiked ? 'text-red-500' : 'text-gray-300'}`}>
                  <Heart size={14} fill={comment.isLiked ? 'currentColor' : 'none'} />
                  <span>{comment.likes}</span>
                </button>
                <span className="text-gray-300">
                  {new Date(comment.createdAt).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showModerationAlert && (
        <ModerationAlert
          detectedWords={detectedWords}
          onClose={handleModerationConfirm}
        />
      )}
    </div>
  );
}