import React from 'react';
import { ArrowRight, Loader } from 'lucide-react';

interface CompareButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

export function CompareButton({ isLoading, onClick }: CompareButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={isLoading}
      className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold flex items-center justify-center gap-2 hover:bg-yellow-300 transition-colors disabled:opacity-50"
    >
      {isLoading ? (
        <>
          <Loader className="animate-spin" size={20} />
          Recherche des meilleurs prix...
        </>
      ) : (
        <>
          Comparer les prix
          <ArrowRight size={20} />
        </>
      )}
    </button>
  );
}