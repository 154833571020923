import React, { useState, useRef } from 'react';
import { 
  Mic, 
  MicOff, 
  Video, 
  VideoOff, 
  Settings, 
  Users, 
  MessageCircle,
  Share2,
  X,
  Shield,
  Globe,
  Gift,
  Star,
  Heart,
  Languages,
  Loader,
  ChevronDown,
  Volume2,
  VolumeX,
  TrendingUp,
  Eye,
  Send,
  Smile
} from 'lucide-react';
import { translateWithAI } from '../../utils/aiTranslation';
import { ShareButton } from '../shared/ShareButton';
import { moderateContent } from '../../utils/contentModeration';

interface LiveBroadcastControlsProps {
  onClose: () => void;
  onEnd: () => void;
  isViewer?: boolean;
  stream?: any;
}

export function LiveBroadcastControls({ onClose, onEnd, isViewer = false, stream }: LiveBroadcastControlsProps) {
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const [viewers, setViewers] = useState(stream?.viewers || 0);
  const [reactions, setReactions] = useState(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [messageInput, setMessageInput] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const [targetLanguage, setTargetLanguage] = useState('fr');
  const [translations, setTranslations] = useState<Record<string, string>>({});
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [volume, setVolume] = useState(1);
  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [peakViewers, setPeakViewers] = useState(viewers);
  const [isProcessing, setIsProcessing] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);

  const languages = [
    { code: 'fr', name: 'Français' },
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' }
  ];

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!messageInput.trim() || isProcessing) return;

    setIsProcessing(true);

    try {
      // Content moderation
      const moderation = await moderateContent(messageInput);
      
      const newMessage = {
        id: Date.now(),
        user: {
          name: 'You',
          avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=50&h=50&fit=crop'
        },
        content: moderation.moderatedText,
        language: 'fr',
        timestamp: new Date().toISOString()
      };

      setMessages(prev => [...prev, newMessage]);
      setMessageInput('');

      // Translate if needed
      if (targetLanguage !== 'fr') {
        const translation = await translateWithAI(moderation.moderatedText, targetLanguage);
        setTranslations(prev => ({ ...prev, [newMessage.id]: translation }));
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleLanguageChange = async (lang: string) => {
    if (lang === targetLanguage) return;
    setIsTranslating(true);
    setTargetLanguage(lang);

    try {
      const newTranslations: Record<string, string> = {};
      for (const message of messages) {
        if (message.language !== lang) {
          const translation = await translateWithAI(message.content, lang);
          newTranslations[message.id] = translation;
        }
      }
      setTranslations(prev => ({ ...prev, ...newTranslations }));
    } catch (error) {
      console.error('Translation error:', error);
    } finally {
      setIsTranslating(false);
      setShowLanguageSelector(false);
    }
  };

  const handleReaction = () => {
    setReactions(r => r + 1);
    // Animate heart reaction
    const heart = document.createElement('div');
    heart.className = 'fixed text-red-500 text-2xl animate-float';
    heart.style.left = `${Math.random() * 80 + 10}%`;
    heart.style.bottom = '20%';
    heart.innerHTML = '❤️';
    document.body.appendChild(heart);
    setTimeout(() => heart.remove(), 3000);
  };

  return (
    <div className="fixed inset-0 bg-purple-900 flex flex-col">
      {/* Top Controls Bar */}
      <div className="bg-purple-900/80 backdrop-blur-sm p-4 flex items-center justify-between z-10 safe-area-top">
        <div className="flex items-center gap-2">
          <div className="bg-red-500 px-3 py-1 rounded-full text-white font-semibold flex items-center gap-2">
            <div className="w-2 h-2 bg-white rounded-full animate-pulse" />
            LIVE
          </div>
          <div className="flex gap-2">
            <div className="bg-purple-800/80 px-3 py-1 rounded-full text-white flex items-center gap-2">
              <Eye size={16} />
              <span>{viewers.toLocaleString()}</span>
            </div>
            <div className="bg-purple-800/80 px-3 py-1 rounded-full text-white flex items-center gap-2">
              <TrendingUp size={16} />
              <span>Max: {peakViewers.toLocaleString()}</span>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <div className="relative">
            <button
              onClick={() => setShowLanguageSelector(!showLanguageSelector)}
              className="bg-purple-800/80 px-3 py-1 rounded-full text-white flex items-center gap-2"
            >
              <Languages size={16} />
              {targetLanguage.toUpperCase()}
              <ChevronDown size={14} />
            </button>

            {showLanguageSelector && (
              <div className="absolute top-full right-0 mt-1 bg-purple-800 rounded-xl shadow-lg overflow-hidden">
                {languages.map(lang => (
                  <button
                    key={lang.code}
                    onClick={() => handleLanguageChange(lang.code)}
                    className={`w-full px-4 py-2 text-left hover:bg-purple-700 ${
                      targetLanguage === lang.code ? 'text-yellow-400' : 'text-white'
                    }`}
                  >
                    {lang.name}
                  </button>
                ))}
              </div>
            )}
          </div>

          <button onClick={onClose} className="bg-purple-800/80 p-2 rounded-full text-white">
            <X size={20} />
          </button>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="flex-1 relative">
        {stream && (
          <img 
            src={stream.thumbnail} 
            alt={stream.title}
            className="absolute inset-0 w-full h-full object-cover"
          />
        )}

        {/* Translation Loading Overlay */}
        {isTranslating && (
          <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
            <div className="text-center">
              <Loader className="animate-spin text-yellow-400 mx-auto mb-2" size={32} />
              <p className="text-white">Traduction en cours...</p>
            </div>
          </div>
        )}

        {/* Chat Overlay */}
        {showChat && (
          <div className="absolute right-4 top-20 bottom-24 w-80 max-w-[calc(100%-2rem)] bg-purple-900/90 rounded-xl flex flex-col">
            <div className="p-4 border-b border-purple-800">
              <h3 className="text-white font-semibold">Chat en direct</h3>
            </div>

            {/* Messages */}
            <div ref={chatRef} className="flex-1 overflow-y-auto p-4 space-y-4">
              {messages.map(message => (
                <div key={message.id} className="flex items-start gap-2">
                  <img
                    src={message.user.avatar}
                    alt=""
                    className="w-8 h-8 rounded-full"
                  />
                  <div>
                    <p className="text-yellow-400 text-sm">{message.user.name}</p>
                    <p className="text-white">{message.content}</p>
                    {translations[message.id] && (
                      <p className="text-sm text-gray-300 italic">
                        {translations[message.id]}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Message Input */}
            <div className="p-4 border-t border-purple-800">
              <form onSubmit={handleSendMessage} className="flex gap-2">
                <input
                  type="text"
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  placeholder="Envoyer un message..."
                  className="flex-1 bg-purple-800 text-white rounded-full px-4 py-2"
                  disabled={isProcessing}
                />
                <button
                  type="submit"
                  disabled={isProcessing || !messageInput.trim()}
                  className={`bg-yellow-400 text-purple-900 p-2 rounded-full flex items-center justify-center ${
                    isProcessing || !messageInput.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-300'
                  }`}
                >
                  {isProcessing ? (
                    <Loader className="animate-spin" size={20} />
                  ) : (
                    <Send size={20} />
                  )}
                </button>
              </form>
            </div>
          </div>
        )}

        {/* Left Side Controls */}
        <div className="absolute left-4 top-1/2 -translate-y-1/2 flex flex-col gap-4">
          {!isViewer && (
            <>
              <button
                onClick={() => setIsMuted(!isMuted)}
                className={`p-3 rounded-full ${isMuted ? 'bg-red-500' : 'bg-purple-800/80'}`}
              >
                {isMuted ? (
                  <MicOff className="text-white" size={24} />
                ) : (
                  <Mic className="text-white" size={24} />
                )}
              </button>
              <button
                onClick={() => setIsVideoOff(!isVideoOff)}
                className={`p-3 rounded-full ${isVideoOff ? 'bg-red-500' : 'bg-purple-800/80'}`}
              >
                {isVideoOff ? (
                  <VideoOff className="text-white" size={24} />
                ) : (
                  <Video className="text-white" size={24} />
                )}
              </button>
            </>
          )}
          <button
            onClick={() => setShowChat(!showChat)}
            className={`p-3 rounded-full ${showChat ? 'bg-yellow-400' : 'bg-purple-800/80'}`}
          >
            <MessageCircle className={showChat ? 'text-purple-900' : 'text-white'} size={24} />
          </button>
          <button
            onClick={handleReaction}
            className="p-3 rounded-full bg-purple-800/80"
          >
            <Heart className="text-white" size={24} />
          </button>
          <ShareButton />
          <button
            onClick={onEnd}
            className="bg-red-500 p-3 rounded-full text-white"
          >
            <X size={24} />
          </button>
        </div>

        {/* Viewer Stats */}
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-purple-900/80 backdrop-blur-sm px-4 py-2 rounded-full flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Users className="text-yellow-400" size={16} />
            <span className="text-white font-medium">{viewers.toLocaleString()}</span>
          </div>
          <div className="flex items-center gap-2">
            <Heart className="text-red-400" size={16} />
            <span className="text-white font-medium">{reactions.toLocaleString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
}