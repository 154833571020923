import React from 'react';
import { Star, BadgeCheck, Gift, Globe, Heart } from 'lucide-react';

interface Creator {
  id: string;
  name: string;
  avatar: string;
  contribution: number;
  isVerified: boolean;
  category: string;
  impact: number;
  supporters: number;
}

const mockCreators: Creator[] = [
  {
    id: '1',
    name: 'Sophie Martin',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
    contribution: 2500,
    isVerified: true,
    category: 'Éducation',
    impact: 1500,
    supporters: 450
  },
  {
    id: '2',
    name: 'Lucas Dubois',
    avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
    contribution: 1800,
    isVerified: true,
    category: 'Environnement',
    impact: 1200,
    supporters: 320
  },
  {
    id: '3',
    name: 'Marie Lambert',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop',
    contribution: 1500,
    isVerified: true,
    category: 'Innovation Sociale',
    impact: 900,
    supporters: 280
  }
];

export function CreatorsRewarded() {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-bold text-white flex items-center gap-2">
          <Star className="text-yellow-400" />
          Créateurs Récompensés
        </h3>
        <button className="text-yellow-400 hover:text-yellow-300 transition-colors text-sm">
          Voir tout
        </button>
      </div>

      <div className="space-y-4">
        {mockCreators.map(creator => (
          <div key={creator.id} className="bg-purple-800 p-4 rounded-xl">
            <div className="flex items-center gap-4">
              <img
                src={creator.avatar}
                alt={creator.name}
                className="w-12 h-12 rounded-full"
              />
              <div className="flex-1">
                <div className="flex items-center gap-2">
                  <h4 className="font-semibold text-white">{creator.name}</h4>
                  {creator.isVerified && (
                    <BadgeCheck size={16} className="text-yellow-400" />
                  )}
                </div>
                <p className="text-sm text-gray-300">{creator.category}</p>
              </div>
              <div className="text-right">
                <p className="text-lg font-bold text-yellow-400">
                  {creator.contribution.toLocaleString()}€
                </p>
                <p className="text-sm text-gray-300">récompensés</p>
              </div>
            </div>

            <div className="mt-4 grid grid-cols-3 gap-2">
              <div className="bg-purple-700/50 p-2 rounded-lg">
                <div className="flex items-center gap-1 text-sm">
                  <Gift className="text-yellow-400" size={14} />
                  <span className="text-white">{creator.contribution.toLocaleString()}€</span>
                </div>
                <p className="text-xs text-gray-300">Récompenses</p>
              </div>
              <div className="bg-purple-700/50 p-2 rounded-lg">
                <div className="flex items-center gap-1 text-sm">
                  <Globe className="text-blue-400" size={14} />
                  <span className="text-white">{creator.impact.toLocaleString()}€</span>
                </div>
                <p className="text-xs text-gray-300">Impact</p>
              </div>
              <div className="bg-purple-700/50 p-2 rounded-lg">
                <div className="flex items-center gap-1 text-sm">
                  <Heart className="text-red-400" size={14} />
                  <span className="text-white">{creator.supporters.toLocaleString()}</span>
                </div>
                <p className="text-xs text-gray-300">Soutiens</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}