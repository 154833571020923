import React, { createContext, useContext, useState } from 'react';
import { COMMUNITY_FUND, VERIFIED_MEMBERS } from '../utils/constants';

interface BalanceContextType {
  cashbackBalance: number;
  communityFundBalance: number;
  verifiedMembers: number;
  updateCashbackBalance: (amount: number) => void;
  updateCommunityFundBalance: (amount: number) => void;
}

const BalanceContext = createContext<BalanceContextType | undefined>(undefined);

export function BalanceProvider({ children }: { children: React.ReactNode }) {
  const [cashbackBalance, setCashbackBalance] = useState(325.50);
  const [communityFundBalance] = useState(COMMUNITY_FUND);
  const [verifiedMembers] = useState(VERIFIED_MEMBERS);

  const updateCashbackBalance = (amount: number) => {
    setCashbackBalance(amount);
  };

  const updateCommunityFundBalance = (amount: number) => {
    // This would update the community fund balance
    console.log('Updating community fund balance:', amount);
  };

  return (
    <BalanceContext.Provider 
      value={{ 
        cashbackBalance, 
        communityFundBalance,
        verifiedMembers,
        updateCashbackBalance, 
        updateCommunityFundBalance 
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
}

export function useBalance() {
  const context = useContext(BalanceContext);
  if (context === undefined) {
    throw new Error('useBalance must be used within a BalanceProvider');
  }
  return context;
}