import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Search } from 'lucide-react';
import { categories } from '../../data/categories';
import type { ProductCategory } from '../../types/shop';

interface CategoryFilterProps {
  selectedCategory: ProductCategory | null;
  selectedSubcategory: string | null;
  onSelectCategory: (category: ProductCategory | null) => void;
  onSelectSubcategory: (subcategory: string | null) => void;
}

export function CategoryFilter({ 
  selectedCategory, 
  selectedSubcategory,
  onSelectCategory, 
  onSelectSubcategory 
}: CategoryFilterProps) {
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSubcategories, setFilteredSubcategories] = useState<{id: string; label: string}[]>([]);

  useEffect(() => {
    if (expandedCategory) {
      const category = categories.find(cat => cat.id === expandedCategory);
      if (category) {
        const filtered = category.subcategories.filter(sub =>
          sub.label.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredSubcategories(filtered);
      }
    }
  }, [expandedCategory, searchQuery]);

  const handleCategoryClick = (categoryId: ProductCategory) => {
    if (selectedCategory === categoryId) {
      setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
    } else {
      onSelectCategory(categoryId);
      onSelectSubcategory(null);
      setExpandedCategory(categoryId);
      setSearchQuery('');
    }
  };

  const currentCategory = categories.find(cat => cat.id === expandedCategory);

  return (
    <div className="space-y-4">
      {/* Main Categories */}
      <div className="flex gap-2 overflow-x-auto pb-2 scrollbar-hide">
        <button
          onClick={() => {
            onSelectCategory(null);
            onSelectSubcategory(null);
            setExpandedCategory(null);
            setSearchQuery('');
          }}
          className={`flex-shrink-0 px-4 py-2 rounded-full ${
            !selectedCategory
              ? 'bg-yellow-400 text-purple-900'
              : 'bg-purple-800 text-white hover:bg-purple-700'
          }`}
        >
          Tout
        </button>
        {categories.map(category => (
          <button
            key={category.id}
            onClick={() => handleCategoryClick(category.id)}
            className={`flex-shrink-0 px-4 py-2 rounded-full flex items-center gap-2 ${
              selectedCategory === category.id
                ? 'bg-yellow-400 text-purple-900'
                : 'bg-purple-800 text-white hover:bg-purple-700'
            }`}
          >
            <category.icon size={16} />
            {category.label}
            {category.subcategories.length > 0 && (
              expandedCategory === category.id ? 
                <ChevronUp size={16} /> : 
                <ChevronDown size={16} />
            )}
          </button>
        ))}
      </div>

      {/* Subcategories Panel */}
      {expandedCategory && currentCategory && (
        <div className="bg-purple-900 p-4 rounded-xl animate-fade-in">
          <div className="mb-4">
            <h3 className="text-white font-medium mb-2">
              Sous-catégories {currentCategory.label}
            </h3>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Rechercher une sous-catégorie..."
                className="w-full bg-purple-800 text-white pl-10 pr-4 py-2 rounded-lg text-sm focus:ring-2 focus:ring-yellow-400"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-2">
            {filteredSubcategories.map(subcategory => (
              <button
                key={subcategory.id}
                onClick={() => onSelectSubcategory(subcategory.id)}
                className={`p-2 rounded-lg text-sm text-center transition-colors ${
                  selectedSubcategory === subcategory.id
                    ? 'bg-yellow-400 text-purple-900'
                    : 'bg-purple-800 text-white hover:bg-purple-700'
                }`}
              >
                {subcategory.label}
              </button>
            ))}
          </div>

          {filteredSubcategories.length === 0 && searchQuery && (
            <p className="text-center text-gray-300 py-4">
              Aucune sous-catégorie ne correspond à votre recherche
            </p>
          )}
        </div>
      )}
    </div>
  );
}