import React, { useState, useEffect } from 'react';
import { BookingCard } from './BookingCard';
import { FinalBookingModal } from './event/FinalBookingModal';
import { mockHotels } from '../../data/mockHotels';

interface HotelBookingProps {
  searchCriteria?: {
    location: string;
    checkIn: string;
    checkOut: string;
    guests: number;
  };
}

export function HotelBooking({ searchCriteria }: HotelBookingProps) {
  const [showFinalBooking, setShowFinalBooking] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hotels, setHotels] = useState(mockHotels);

  useEffect(() => {
    if (searchCriteria) {
      setIsLoading(true);
      // Simulate API call
      setTimeout(() => {
        setHotels(mockHotels);
        setIsLoading(false);
      }, 1500);
    }
  }, [searchCriteria]);

  const handleBooking = (hotel: any) => {
    if (searchCriteria) {
      setSelectedHotel({
        ...hotel,
        dates: {
          checkIn: searchCriteria.checkIn,
          checkOut: searchCriteria.checkOut,
          nights: Math.ceil(
            (new Date(searchCriteria.checkOut).getTime() - new Date(searchCriteria.checkIn).getTime()) / 
            (1000 * 60 * 60 * 24)
          )
        }
      });
      setShowFinalBooking(true);
    }
  };

  const handleConfirmBooking = () => {
    console.log('Hotel booking confirmed:', selectedHotel);
    setShowFinalBooking(false);
  };

  if (isLoading) {
    return (
      <div className="text-center py-12">
        <div className="w-12 h-12 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
        <p className="text-white">Recherche des meilleurs hôtels...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {hotels.map(hotel => (
        <BookingCard
          key={hotel.id}
          item={hotel}
          onBook={() => handleBooking(hotel)}
        />
      ))}

      {showFinalBooking && selectedHotel && (
        <FinalBookingModal
          event={selectedHotel}
          onClose={() => setShowFinalBooking(false)}
          onConfirm={handleConfirmBooking}
        />
      )}
    </div>
  );
}