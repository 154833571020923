export const fr = {
  'app.name': 'PLATFF',
  'app.tagline': 'Une nouvelle puissance révolutionnaire qui transforme votre expérience digitale',
  'landing.description': 'La première plateforme tout-en-un qui combine marketplace, réservations, réseau social et Fonds Communautaire, créant une expérience unique et engageante.',
  'landing.getStarted': 'Rejoignez la révolution',
  'landing.footer': 'Fait avec ❤️ en France | Europe',

  'auth.welcomeBack': 'Bon retour !',
  'auth.emailPlaceholder': 'votre@email.com',
  'auth.password': 'Mot de passe',
  'auth.passwordPlaceholder': '••••••••',
  'auth.forgotPassword': 'Mot de passe oublié ?',
  'auth.login': 'Se connecter',
  'auth.logout': 'Déconnexion',
  'auth.noAccount': "Vous n'avez pas de compte ?",
  'auth.register': "S'inscrire",

  'nav.home': 'Accueil',
  'nav.shop': 'Marketplace',
  'nav.bookings': 'Réserver',
  'nav.social': 'Social',
  'nav.wallet': 'PLATFFPAY',
  'nav.impact': 'Impact',
  'nav.navigation': 'Navigation',

  // Rest of translations remain the same...
};