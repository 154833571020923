import React, { createContext, useContext, useState } from 'react';
import { PassportVerificationModal } from '../components/social/verification/PassportVerificationModal';

interface VerificationContextType {
  isVerified: boolean;
  isVerifying: boolean;
  startVerification: () => void;
  completeVerification: () => void;
}

const VerificationContext = createContext<VerificationContextType | undefined>(undefined);

export function VerificationProvider({ children }: { children: React.ReactNode }) {
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const startVerification = () => {
    setIsVerifying(true);
  };

  const completeVerification = () => {
    setIsVerified(true);
    setIsVerifying(false);
  };

  return (
    <VerificationContext.Provider value={{
      isVerified,
      isVerifying,
      startVerification,
      completeVerification
    }}>
      {children}
      {isVerifying && (
        <PassportVerificationModal
          onClose={() => setIsVerifying(false)}
          onVerified={completeVerification}
        />
      )}
    </VerificationContext.Provider>
  );
}

export function useVerification() {
  const context = useContext(VerificationContext);
  if (context === undefined) {
    throw new Error('useVerification must be used within a VerificationProvider');
  }
  return context;
}