import React, { useState } from 'react';
import { 
  Rocket, 
  Globe, 
  Shield, 
  Gift, 
  Users,
  Star,
  TrendingUp,
  Sparkles,
  Zap,
  Heart,
  BadgeCheck,
  ArrowRight,
  Lock,
  Calendar,
  CreditCard,
  Search,
  Building,
  Info
} from 'lucide-react';
import { AnimatedLogo } from '../shared/AnimatedLogo';
import { CountdownTimer } from '../launch/CountdownTimer';

interface LandingPageProps {
  onGetStarted: () => void;
}

export function LandingPage({ onGetStarted }: LandingPageProps) {
  const [showDemoInfo, setShowDemoInfo] = useState(false);

  const handleDemoAccess = () => {
    if (!showDemoInfo) {
      setShowDemoInfo(true);
      return;
    }
    onGetStarted();
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-purple-800 to-purple-900 relative overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400/10 rounded-full filter blur-3xl animate-float" />
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-purple-600/10 rounded-full filter blur-3xl animate-float delay-1000" />
      </div>

      {/* Demo Access Button */}
      <div className="absolute top-4 right-4 z-10">
        <button
          onClick={handleDemoAccess}
          className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-full font-semibold hover:bg-yellow-300 transition-all transform hover:scale-105 flex items-center gap-2 shadow-lg"
        >
          <Lock size={20} />
          Accès Anticipé DEMO
        </button>
      </div>

      {/* Demo Info Modal */}
      {showDemoInfo && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
          <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md animate-slide-up">
            <div className="text-center mb-6">
              <Info className="text-yellow-400 mx-auto mb-4" size={32} />
              <h3 className="text-xl font-bold text-white mb-2">Version de démonstration</h3>
              <p className="text-gray-300">
                Vous allez accéder à une version de démonstration de PLATFF qui vous permettra d'explorer toutes les fonctionnalités de la plateforme.
              </p>
            </div>
            <button
              onClick={handleDemoAccess}
              className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors"
            >
              Accéder à la démo
            </button>
          </div>
        </div>
      )}

      <div className="relative max-w-7xl mx-auto px-4 pt-20 pb-32">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="inline-block mb-8">
            <AnimatedLogo />
          </div>
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6 leading-tight">
            La révolution de l'économie collaborative arrive le
            <div className="text-yellow-400 mt-2">1er Janvier 2026</div>
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-12">
            PLATFF redéfinit l'expérience digitale en combinant réseau social sécurisé, marketplace intelligente, 
            réservations optimisées, PLATFFPAY et impact communautaire dans une seule plateforme révolutionnaire.
          </p>

          {/* Countdown Timer */}
          <CountdownTimer />
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6 mb-16">
          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <Shield className="text-yellow-400 mb-4" size={32} />
            <h3 className="text-xl font-bold text-white mb-2">Réseau Social Sécurisé</h3>
            <p className="text-gray-300">
              Une communauté vérifiée avec modération IA pour des interactions authentiques et bienveillantes.
            </p>
          </div>

          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <Gift className="text-yellow-400 mb-4" size={32} />
            <h3 className="text-xl font-bold text-white mb-2">Marketplace Intelligente</h3>
            <p className="text-gray-300">
              Prix dynamiques et comparateur IA en temps réel pour garantir les meilleurs tarifs.
            </p>
          </div>

          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <Calendar className="text-yellow-400 mb-4" size={32} />
            <h3 className="text-xl font-bold text-white mb-2">Réservations Smart</h3>
            <p className="text-gray-300">
              Algorithme prédictif pour trouver les meilleurs tarifs hôtels, vols et activités.
            </p>
          </div>

          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <Building className="text-yellow-400 mb-4" size={32} />
            <h3 className="text-xl font-bold text-white mb-2">PLATFFPAY</h3>
            <p className="text-gray-300">
              Compte bancaire intégré avec cashback instantané et avances sans frais.
            </p>
          </div>

          <div className="bg-purple-800/50 backdrop-blur-sm p-6 rounded-xl transform hover:scale-105 transition-all">
            <Globe className="text-yellow-400 mb-4" size={32} />
            <h3 className="text-xl font-bold text-white mb-2">Impact Communautaire</h3>
            <p className="text-gray-300">
              15% des bénéfices reversés au Fonds Communautaire pour soutenir des projets à impact.
            </p>
          </div>
        </div>

        {/* Booking System Showcase */}
        <div className="bg-purple-800/30 backdrop-blur-sm p-8 rounded-2xl mb-16">
          <div className="flex items-center gap-3 mb-8">
            <Search className="text-yellow-400" size={32} />
            <div>
              <h2 className="text-2xl font-bold text-white">Système de Réservation Intelligent</h2>
              <p className="text-gray-300">Trouvez les meilleurs tarifs grâce à notre IA</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-purple-900 p-6 rounded-xl">
              <Calendar className="text-yellow-400 mb-4" size={24} />
              <h3 className="text-lg font-bold text-white mb-2">Prix Dynamiques</h3>
              <p className="text-gray-300">Algorithme prédictif pour trouver les meilleurs moments de réservation.</p>
            </div>

            <div className="bg-purple-900 p-6 rounded-xl">
              <Star className="text-yellow-400 mb-4" size={24} />
              <h3 className="text-lg font-bold text-white mb-2">Comparateur IA</h3>
              <p className="text-gray-300">Analyse en temps réel des prix sur plus de 500 plateformes.</p>
            </div>

            <div className="bg-purple-900 p-6 rounded-xl">
              <Gift className="text-yellow-400 mb-4" size={24} />
              <h3 className="text-lg font-bold text-white mb-2">Cashback Instantané</h3>
              <p className="text-gray-300">5% de cashback crédité instantanément sur votre PLATFFPAY.</p>
            </div>
          </div>
        </div>

        {/* PLATFFPAY Showcase */}
        <div className="bg-purple-800/30 backdrop-blur-sm p-8 rounded-2xl mb-16">
          <div className="flex items-center gap-3 mb-8">
            <CreditCard className="text-yellow-400" size={32} />
            <div>
              <h2 className="text-2xl font-bold text-white">PLATFFPAY</h2>
              <p className="text-gray-300">Votre compte bancaire nouvelle génération</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-purple-900 p-6 rounded-xl">
              <Zap className="text-yellow-400 mb-4" size={24} />
              <h3 className="text-lg font-bold text-white mb-2">Cashback Instantané</h3>
              <p className="text-gray-300">5% de cashback sur toutes vos transactions PLATFF.</p>
            </div>

            <div className="bg-purple-900 p-6 rounded-xl">
              <Building className="text-yellow-400 mb-4" size={24} />
              <h3 className="text-lg font-bold text-white mb-2">Avances 0%</h3>
              <p className="text-gray-300">Jusqu'à 200€ d'avance sans frais ni intérêts.</p>
            </div>

            <div className="bg-purple-900 p-6 rounded-xl">
              <Shield className="text-yellow-400 mb-4" size={24} />
              <h3 className="text-lg font-bold text-white mb-2">100% Sécurisé</h3>
              <p className="text-gray-300">Protection maximale de vos transactions et données.</p>
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="bg-purple-800/30 backdrop-blur-sm p-8 rounded-2xl mb-16">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div className="text-center">
              <div className="text-3xl font-bold text-yellow-400 mb-2">15K+</div>
              <div className="text-gray-300">Membres attendus</div>
            </div>
            <div className="text-center">
              <div className="text-3xl font-bold text-yellow-400 mb-2">150K€</div>
              <div className="text-gray-300">Fonds initial</div>
            </div>
            <div className="text-center">
              <div className="text-3xl font-bold text-yellow-400 mb-2">100%</div>
              <div className="text-gray-300">Transparent</div>
            </div>
            <div className="text-center">
              <div className="text-3xl font-bold text-yellow-400 mb-2">0%</div>
              <div className="text-gray-300">Commission</div>
            </div>
          </div>
        </div>

        {/* Newsletter Signup */}
        <div className="max-w-xl mx-auto text-center">
          <h3 className="text-2xl font-bold text-white mb-4">Soyez les premiers informés</h3>
          <p className="text-gray-300 mb-6">
            Inscrivez-vous à notre newsletter pour être notifié du lancement et recevoir des avantages exclusifs.
          </p>
          <form className="flex gap-2">
            <input
              type="email"
              placeholder="Votre email"
              className="flex-1 bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
            />
            <button
              type="submit"
              className="bg-yellow-400 text-purple-900 px-6 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center gap-2"
            >
              <Rocket size={20} />
              Je m'inscris
            </button>
          </form>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-purple-900/50 backdrop-blur-xl py-8 relative">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <p className="text-gray-300">
            © 2024 PLATFF | Made with <Heart size={16} className="inline text-red-500" /> in France
          </p>
        </div>
      </footer>
    </div>
  );
}