import React, { useState, useRef, useEffect } from 'react';
import { Volume2, VolumeX, Languages, Settings, Globe, Loader } from 'lucide-react';
import { useLanguage } from '../../contexts/LanguageContext';

interface VideoPlayerProps {
  src: string;
  title?: string;
  originalLanguage?: string;
}

export function VideoPlayer({ src, title, originalLanguage = 'fr' }: VideoPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(originalLanguage);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { language } = useLanguage();
  const [isLoaded, setIsLoaded] = useState(false);

  const languages = [
    { code: 'fr', name: 'Français' },
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' }
  ];

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleLoadedData = () => {
      setIsLoaded(true);
    };

    video.addEventListener('loadeddata', handleLoadedData);

    return () => {
      video.removeEventListener('loadeddata', handleLoadedData);
      if (isPlaying) {
        video.pause();
      }
    };
  }, [isPlaying]);

  const togglePlay = () => {
    if (!videoRef.current || !isLoaded) return;
    
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play().catch(error => {
        console.error('Error playing video:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
    setIsMuted(newVolume === 0);
  };

  const handleLanguageChange = (langCode: string) => {
    if (langCode !== currentLanguage) {
      setIsTranslating(true);
      setCurrentLanguage(langCode);
      
      // Simulate AI translation processing
      setTimeout(() => {
        setIsTranslating(false);
        setShowLanguageMenu(false);
      }, 2000);
    }
  };

  return (
    <div 
      className="relative aspect-video bg-black rounded-xl overflow-hidden group"
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <video
        ref={videoRef}
        src={src}
        className="w-full h-full object-contain"
        onClick={togglePlay}
        playsInline
      />

      {/* Translation Overlay */}
      {isTranslating && (
        <div className="absolute inset-0 bg-black/80 flex items-center justify-center">
          <div className="text-center">
            <Loader className="text-yellow-400 animate-spin mx-auto mb-3" size={32} />
            <p className="text-white font-medium">Traduction IA en cours...</p>
            <p className="text-sm text-gray-300">
              {languages.find(l => l.code === currentLanguage)?.name}
            </p>
          </div>
        </div>
      )}

      {/* Controls Overlay */}
      <div className={`absolute inset-0 bg-gradient-to-t from-black/80 via-transparent to-transparent transition-opacity duration-300 ${
        showControls || isTranslating ? 'opacity-100' : 'opacity-0'
      }`}>
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <div className="flex items-center justify-between gap-4">
            {/* Play/Pause & Volume */}
            <div className="flex items-center gap-4">
              <button onClick={togglePlay} className="text-white hover:text-yellow-400">
                {isPlaying ? (
                  <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M6 4h4v16H6V4zm8 0h4v16h-4V4z" />
                  </svg>
                ) : (
                  <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M8 5v14l11-7z" />
                  </svg>
                )}
              </button>

              <div className="flex items-center gap-2">
                <button onClick={toggleMute} className="text-white hover:text-yellow-400">
                  {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
                </button>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="w-20"
                />
              </div>
            </div>

            {/* Language Selection */}
            <div className="relative">
              <button
                onClick={() => setShowLanguageMenu(!showLanguageMenu)}
                className="flex items-center gap-2 bg-purple-900/80 text-white px-3 py-1.5 rounded-full text-sm hover:bg-purple-900"
              >
                <Globe size={16} />
                <span>{languages.find(l => l.code === currentLanguage)?.name}</span>
              </button>

              {showLanguageMenu && (
                <div className="absolute bottom-full right-0 mb-2 bg-purple-900 rounded-xl shadow-lg overflow-hidden">
                  {languages.map(lang => (
                    <button
                      key={lang.code}
                      onClick={() => handleLanguageChange(lang.code)}
                      className={`w-full px-4 py-2 text-left hover:bg-purple-800 ${
                        currentLanguage === lang.code ? 'text-yellow-400' : 'text-white'
                      }`}
                    >
                      {lang.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}