import React, { useState } from 'react';
import { Tv, Search, Upload, Info } from 'lucide-react';
import { CreatorBenefits } from './CreatorBenefits';

interface StreamingHeaderProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
  onUploadClick: () => void;
  isVerified: boolean;
}

export function StreamingHeader({ 
  searchQuery, 
  onSearchChange, 
  onUploadClick,
  isVerified 
}: StreamingHeaderProps) {
  const [showBenefits, setShowBenefits] = useState(false);

  return (
    <div className="flex flex-col sm:flex-row gap-4 sm:items-center sm:justify-between mb-6">
      <div className="flex items-center gap-3">
        <Tv className="text-yellow-400" size={24} />
        <div>
          <h2 className="text-xl font-bold text-white">PLATFF Streaming</h2>
          <p className="text-gray-300">Films, séries et documentaires à impact</p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-3 sm:items-center">
        <div className="relative flex-1 sm:flex-none">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder="Rechercher..."
            className="w-full sm:w-64 bg-purple-800 text-white pl-10 pr-4 py-2 rounded-full focus:ring-2 focus:ring-yellow-400"
          />
        </div>
        <div className="relative flex items-center gap-2">
          <button 
            onClick={onUploadClick}
            className="bg-yellow-400 text-purple-900 px-4 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2 whitespace-nowrap"
          >
            <Upload size={20} />
            <span>Upload</span>
          </button>
          <button
            onClick={() => setShowBenefits(!showBenefits)}
            className="bg-purple-800 p-2 rounded-full text-yellow-400 hover:bg-purple-700 transition-colors"
            title="Creator Benefits"
          >
            <Info size={20} />
          </button>
          {showBenefits && (
            <CreatorBenefits onClose={() => setShowBenefits(false)} />
          )}
        </div>
      </div>
    </div>
  );
}