import React from 'react';
import { AlertTriangle, Shield } from 'lucide-react';

interface ModerationAlertProps {
  detectedWords: string[];
  onClose: () => void;
}

export function ModerationAlert({ detectedWords, onClose }: ModerationAlertProps) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[70] p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md animate-slide-up">
        <div className="flex items-center gap-3 mb-4">
          <AlertTriangle className="text-yellow-400" size={24} />
          <h3 className="text-xl font-bold text-white">Modération IA</h3>
        </div>

        <div className="bg-purple-800 p-4 rounded-xl mb-4">
          <div className="flex items-center gap-2 mb-2">
            <Shield className="text-yellow-400" size={20} />
            <p className="text-white font-medium">Contenu inapproprié détecté</p>
          </div>
          <p className="text-sm text-gray-300">
            Pour maintenir une communauté bienveillante, les mots suivants seront automatiquement remplacés par des fruits :
          </p>
        </div>

        <div className="bg-purple-800 p-3 rounded-xl mb-6">
          <p className="text-white">
            {detectedWords.join(', ')}
          </p>
        </div>

        <button
          onClick={onClose}
          className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors"
        >
          Continuer avec la version modérée
        </button>
      </div>
    </div>
  );
}