import React, { useState, useRef } from 'react';
import { Music, Upload, Play, Pause, X, Clock, Gift, Globe, BadgeCheck } from 'lucide-react';
import WaveSurfer from 'wavesurfer.js';
import { useAudioPlayer } from '../../hooks/useAudioPlayer';
import { moderateContent } from '../../utils/contentModeration';

interface MusicUploadProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
}

export function MusicUpload({ onClose, onSubmit }: MusicUploadProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    isPlaying,
    duration,
    currentTime,
    containerRef,
    togglePlay,
    initializeWaveform
  } = useAudioPlayer();

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith('audio/')) {
      setAudioFile(file);
      const url = URL.createObjectURL(file);
      initializeWaveform(url);
    }
  };

  const handleSubmit = async () => {
    if (!audioFile || !title.trim()) return;

    setIsProcessing(true);
    
    // Content moderation for title and description
    const titleModeration = await moderateContent(title);
    const descModeration = await moderateContent(description);

    if (!titleModeration.isClean || !descModeration.isClean) {
      // Handle moderation issues
      setIsProcessing(false);
      return;
    }

    setIsUploading(true);

    // Simulate upload process
    await new Promise(resolve => setTimeout(resolve, 2000));

    onSubmit({
      title: titleModeration.moderatedText,
      description: descModeration.moderatedText,
      audioUrl: URL.createObjectURL(audioFile),
      duration: duration
    });

    setIsUploading(false);
    setIsProcessing(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-purple-900/95 z-50 p-4">
      <div className="max-w-lg mx-auto bg-purple-800 rounded-xl p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-white flex items-center gap-2">
            <Music className="text-yellow-400" />
            Partager votre musique
          </h3>
          <button 
            onClick={onClose}
            className="text-gray-300 hover:text-white"
          >
            <X size={24} />
          </button>
        </div>

        <div className="space-y-4">
          {!audioFile ? (
            <div className="border-2 border-dashed border-purple-700 rounded-xl p-8 text-center">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept="audio/*"
                className="hidden"
              />
              <Music className="text-yellow-400 mx-auto mb-4" size={48} />
              <p className="text-white font-medium mb-2">Sélectionnez votre fichier audio</p>
              <p className="text-sm text-gray-300 mb-4">MP3, WAV jusqu'à 50MB</p>
              <button
                onClick={() => fileInputRef.current?.click()}
                className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors inline-flex items-center gap-2"
              >
                <Upload size={20} />
                Choisir un fichier
              </button>
            </div>
          ) : (
            <>
              <div className="bg-purple-700 p-4 rounded-xl">
                <div ref={containerRef} className="mb-4" />
                <div className="flex items-center justify-between">
                  <button
                    onClick={togglePlay}
                    className="bg-yellow-400 text-purple-900 p-2 rounded-full hover:bg-yellow-300 transition-colors"
                  >
                    {isPlaying ? <Pause size={20} /> : <Play size={20} />}
                  </button>
                  <div className="flex items-center gap-2 text-gray-300 text-sm">
                    <Clock size={14} />
                    <span>
                      {Math.floor(currentTime / 60)}:
                      {String(Math.floor(currentTime % 60)).padStart(2, '0')} / 
                      {Math.floor(duration / 60)}:
                      {String(Math.floor(duration % 60)).padStart(2, '0')}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <label className="text-white text-sm mb-1 block">Titre</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full bg-purple-700 text-white rounded-xl px-4 py-2 focus:ring-2 focus:ring-yellow-400"
                  placeholder="Donnez un titre à votre musique"
                />
              </div>

              <div>
                <label className="text-white text-sm mb-1 block">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full bg-purple-700 text-white rounded-xl px-4 py-2 focus:ring-2 focus:ring-yellow-400 min-h-[100px]"
                  placeholder="Décrivez votre musique..."
                />
              </div>

              <div className="bg-purple-700 p-4 rounded-xl space-y-2">
                <div className="flex items-center gap-2 text-sm">
                  <Gift className="text-green-400" size={16} />
                  <span className="text-white">Récompenses activées pour ce contenu</span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <Globe className="text-blue-400" size={16} />
                  <span className="text-white">Impact communautaire activé</span>
                </div>
              </div>

              <button
                onClick={handleSubmit}
                disabled={isProcessing || isUploading || !title.trim()}
                className={`w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold flex items-center justify-center gap-2 ${
                  isProcessing || isUploading || !title.trim()
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-yellow-300'
                } transition-colors`}
              >
                {isUploading ? (
                  <>
                    <Upload className="animate-bounce" size={20} />
                    Upload en cours...
                  </>
                ) : (
                  <>
                    <Music size={20} />
                    Publier
                  </>
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}