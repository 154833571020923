export const es = {
  'app.name': 'PLATFF',
  'app.tagline': 'Un nuevo poder revolucionario que transforma tu experiencia digital',
  'landing.description': 'La primera plataforma todo en uno que combina marketplace, reservas, red social y Fondo Comunitario, creando una experiencia única y atractiva.',
  'landing.getStarted': 'Únete a la revolución',
  'landing.footer': 'Hecho con ❤️ en Francia | Europa',

  'auth.welcomeBack': '¡Bienvenido de nuevo!',
  'auth.emailPlaceholder': 'tu@email.com',
  'auth.password': 'Contraseña',
  'auth.passwordPlaceholder': '••••••••',
  'auth.forgotPassword': '¿Olvidaste tu contraseña?',
  'auth.login': 'Iniciar sesión',
  'auth.logout': 'Cerrar sesión',
  'auth.noAccount': '¿No tienes una cuenta?',
  'auth.register': 'Regístrate',

  'nav.home': 'Inicio',
  'nav.shop': 'Marketplace',
  'nav.bookings': 'Reservar',
  'nav.social': 'Social',
  'nav.wallet': 'PLATFFPAY',
  'nav.impact': 'Impacto',
  'nav.navigation': 'Navegación',

  // Rest of translations remain the same...
};