import type { Restaurant } from '../types/booking';

export const mockRestaurants: Restaurant[] = [
  {
    id: 'resto1',
    title: 'Le Petit Jardin',
    type: 'restaurant',
    image: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?w=800&h=400&fit=crop',
    badge: { text: 'Gastronomique', color: 'yellow' },
    location: 'Paris 8ème',
    rating: 4.8,
    reviews: 256,
    description: 'Restaurant étoilé avec terrasse. Cuisine raffinée et ambiance élégante.',
    price: 89,
    cuisine: 'Française',
    specialties: ['Menu Dégustation', 'Carte des Vins'],
    cashback: 13,
    impact: 13,
    availableTimes: ['19:00', '19:30', '20:00', '20:30', '21:00'],
    provider: {
      name: 'Le Petit Jardin',
      logo: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?w=50&h=50&fit=crop',
      isVerified: true
    }
  },
  {
    id: 'resto2',
    title: "L'Assiette Verte",
    type: 'restaurant',
    image: 'https://images.unsplash.com/photo-1552566626-52f8b828add9?w=800&h=400&fit=crop',
    badge: { text: 'Bio', color: 'green' },
    location: 'Lyon 2ème',
    rating: 4.7,
    reviews: 189,
    description: 'Restaurant bio et local. Menu végétarien et vegan disponible.',
    price: 45,
    cuisine: 'Bio & Végétarienne',
    specialties: ['Produits Locaux', 'Options Vegan'],
    cashback: 7,
    impact: 7,
    availableTimes: ['12:00', '12:30', '13:00', '19:30', '20:00'],
    provider: {
      name: "L'Assiette Verte",
      logo: 'https://images.unsplash.com/photo-1552566626-52f8b828add9?w=50&h=50&fit=crop',
      isVerified: true
    }
  }
];