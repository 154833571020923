import React, { useState, useRef } from 'react';
import { 
  Image as ImageIcon, 
  Video, 
  Radio, 
  Send, 
  Globe, 
  Lock,
  Users,
  Hash,
  MapPin,
  Smile,
  X,
  Camera
} from 'lucide-react';
import { LiveBroadcastControls } from '../social/LiveBroadcastControls';

interface SocialActionsProps {
  onPost: (content: any) => void;
}

export function SocialActions({ onPost }: SocialActionsProps) {
  const [isCreating, setIsCreating] = useState(false);
  const [content, setContent] = useState('');
  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [privacy, setPrivacy] = useState<'public' | 'private' | 'followers'>('public');
  const [isLiveStreaming, setIsLiveStreaming] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setMediaFiles([...mediaFiles, ...files]);
  };

  const handlePost = () => {
    if (content.trim() || mediaFiles.length > 0) {
      onPost({
        content,
        mediaFiles,
        privacy
      });
      setContent('');
      setMediaFiles([]);
      setIsCreating(false);
    }
  };

  const startLiveStream = () => {
    setIsLiveStreaming(true);
  };

  if (isLiveStreaming) {
    return (
      <LiveBroadcastControls
        onClose={() => setIsLiveStreaming(false)}
        onEnd={() => setIsLiveStreaming(false)}
      />
    );
  }

  if (isCreating) {
    return (
      <div className="fixed inset-0 bg-purple-900/95 z-50 p-4">
        <div className="max-w-lg mx-auto bg-purple-800 rounded-xl p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold text-white">Créer une publication</h3>
            <button 
              onClick={() => setIsCreating(false)}
              className="text-gray-300 hover:text-white"
            >
              <X size={24} />
            </button>
          </div>

          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Partagez quelque chose avec vos investisseurs..."
            className="w-full bg-purple-700 text-white rounded-xl p-4 min-h-[150px] mb-4 resize-none focus:ring-2 focus:ring-yellow-400"
          />

          {mediaFiles.length > 0 && (
            <div className="grid grid-cols-2 gap-2 mb-4">
              {mediaFiles.map((file, index) => (
                <div key={index} className="relative">
                  <img
                    src={URL.createObjectURL(file)}
                    alt=""
                    className="w-full h-32 object-cover rounded-lg"
                  />
                  <button
                    onClick={() => setMediaFiles(mediaFiles.filter((_, i) => i !== index))}
                    className="absolute top-2 right-2 bg-red-500 rounded-full p-1"
                  >
                    <X size={16} className="text-white" />
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex justify-between items-center mb-4">
            <div className="flex gap-4">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept="image/*,video/*"
                className="hidden"
                multiple
              />
              <button 
                onClick={() => fileInputRef.current?.click()}
                className="text-yellow-400 hover:text-yellow-300"
              >
                <ImageIcon size={24} />
              </button>
              <button 
                onClick={() => fileInputRef.current?.click()}
                className="text-yellow-400 hover:text-yellow-300"
              >
                <Video size={24} />
              </button>
              <button className="text-yellow-400 hover:text-yellow-300">
                <MapPin size={24} />
              </button>
              <button className="text-yellow-400 hover:text-yellow-300">
                <Hash size={24} />
              </button>
              <button className="text-yellow-400 hover:text-yellow-300">
                <Smile size={24} />
              </button>
            </div>

            <select
              value={privacy}
              onChange={(e) => setPrivacy(e.target.value as any)}
              className="bg-purple-700 text-white rounded-lg px-3 py-2"
            >
              <option value="public">Public</option>
              <option value="followers">Investisseurs</option>
              <option value="private">Privé</option>
            </select>
          </div>

          <button
            onClick={handlePost}
            disabled={!content.trim() && mediaFiles.length === 0}
            className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Publier
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-4">
      <button
        onClick={() => setIsCreating(true)}
        className="bg-purple-900 p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800 transition-colors"
      >
        <Camera className="text-yellow-400" size={24} />
        <span className="text-white text-sm">Photo/Vidéo</span>
      </button>
      <button
        onClick={startLiveStream}
        className="bg-purple-900 p-4 rounded-xl flex flex-col items-center gap-2 hover:bg-purple-800 transition-colors"
      >
        <Radio className="text-yellow-400" size={24} />
        <span className="text-white text-sm">Live</span>
      </button>
    </div>
  );
}