import React, { useState, useRef } from 'react';
import { Upload, X, Play, Image as ImageIcon, Info, Shield, Globe, Gift, Loader } from 'lucide-react';
import { streamingCategories } from '../../../data/streamingCategories';
import { moderateContent } from '../../../utils/contentModeration';

interface UploadModalProps {
  onClose: () => void;
  onUpload: (data: any) => void;
}

export function UploadModal({ onClose, onUpload }: UploadModalProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const thumbnailInputRef = useRef<HTMLInputElement>(null);

  const handleVideoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith('video/')) {
      setVideoFile(file);
    }
  };

  const handleThumbnailSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      setThumbnail(file);
    }
  };

  const handleSubmit = async () => {
    if (!videoFile || !title.trim() || !category || !subcategory) return;

    setIsProcessing(true);
    
    try {
      // Content moderation
      const titleModeration = await moderateContent(title);
      const descModeration = await moderateContent(description);

      if (!titleModeration.isClean || !descModeration.isClean) {
        alert('Le contenu contient des termes inappropriés');
        setIsProcessing(false);
        return;
      }

      setIsUploading(true);

      // Simulate upload progress
      const interval = setInterval(() => {
        setUploadProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 10;
        });
      }, 500);

      // Simulate upload delay
      await new Promise(resolve => setTimeout(resolve, 5000));

      onUpload({
        title: titleModeration.moderatedText,
        description: descModeration.moderatedText,
        category,
        subcategory,
        videoUrl: URL.createObjectURL(videoFile),
        thumbnailUrl: thumbnail ? URL.createObjectURL(thumbnail) : null
      });

      onClose();
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setIsUploading(false);
      setIsProcessing(false);
    }
  };

  const selectedCategory = streamingCategories.find(cat => cat.id === category);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-lg max-h-[90vh] flex flex-col">
        <div className="p-6 border-b border-purple-800">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold text-white">Upload Content</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X size={24} />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {!videoFile ? (
            <div className="border-2 border-dashed border-purple-700 rounded-xl p-8 text-center">
              <input
                type="file"
                ref={videoInputRef}
                onChange={handleVideoSelect}
                accept="video/*"
                className="hidden"
              />
              <Play className="text-yellow-400 mx-auto mb-4" size={48} />
              <p className="text-white font-medium mb-2">Select your video</p>
              <p className="text-sm text-gray-300 mb-4">MP4, MOV up to 2GB</p>
              <button
                onClick={() => videoInputRef.current?.click()}
                className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
              >
                Choose File
              </button>
            </div>
          ) : (
            <>
              <div>
                <label className="text-white text-sm mb-2 block">Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                  placeholder="Enter title..."
                />
              </div>

              <div>
                <label className="text-white text-sm mb-2 block">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400 min-h-[100px]"
                  placeholder="Describe your content..."
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="text-white text-sm mb-2 block">Category</label>
                  <select
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                      setSubcategory('');
                    }}
                    className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                  >
                    <option value="">Select category</option>
                    {streamingCategories.map(cat => (
                      <option key={cat.id} value={cat.id}>{cat.label}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="text-white text-sm mb-2 block">Subcategory</label>
                  <select
                    value={subcategory}
                    onChange={(e) => setSubcategory(e.target.value)}
                    className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                    disabled={!category}
                  >
                    <option value="">Select subcategory</option>
                    {selectedCategory?.subcategories.map(sub => (
                      <option key={sub.id} value={sub.id}>{sub.label}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <label className="text-white text-sm mb-2 block">Thumbnail</label>
                <input
                  type="file"
                  ref={thumbnailInputRef}
                  onChange={handleThumbnailSelect}
                  accept="image/*"
                  className="hidden"
                />
                <button
                  onClick={() => thumbnailInputRef.current?.click()}
                  className="w-full bg-purple-800 text-white rounded-xl p-4 hover:bg-purple-700 transition-colors"
                >
                  {thumbnail ? (
                    <img
                      src={URL.createObjectURL(thumbnail)}
                      alt="Thumbnail"
                      className="w-full h-32 object-cover rounded-lg"
                    />
                  ) : (
                    <div className="text-center">
                      <ImageIcon className="mx-auto mb-2" size={24} />
                      <span>Add thumbnail</span>
                    </div>
                  )}
                </button>
              </div>

              {isUploading && (
                <div className="bg-purple-800 p-4 rounded-xl">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-white">Uploading...</span>
                    <span className="text-white">{uploadProgress}%</span>
                  </div>
                  <div className="w-full bg-purple-700 rounded-full h-2">
                    <div
                      className="bg-yellow-400 h-2 rounded-full transition-all duration-300"
                      style={{ width: `${uploadProgress}%` }}
                    />
                  </div>
                </div>
              )}

              <div className="bg-purple-800 p-4 rounded-xl space-y-2">
                <div className="flex items-center gap-2 text-sm">
                  <Gift className="text-green-400" size={16} />
                  <span className="text-white">Rewards enabled for this content</span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <Globe className="text-blue-400" size={16} />
                  <span className="text-white">Community impact enabled</span>
                </div>
              </div>

              <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
                <Shield className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
                <div className="text-sm text-gray-300">
                  <p className="font-medium text-white mb-1">Community Guidelines</p>
                  <p>Your content will be reviewed to ensure it meets our community guidelines.</p>
                </div>
              </div>
            </>
          )}
        </div>

        {videoFile && (
          <div className="p-4 border-t border-purple-800">
            <button
              onClick={handleSubmit}
              disabled={isProcessing || isUploading || !title.trim() || !category || !subcategory}
              className={`w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold flex items-center justify-center gap-2 ${
                isProcessing || isUploading || !title.trim() || !category || !subcategory
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-yellow-300'
              } transition-colors`}
            >
              {isUploading ? (
                <>
                  <Loader className="animate-spin" size={20} />
                  Uploading...
                </>
              ) : (
                <>
                  <Upload size={20} />
                  Upload
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}