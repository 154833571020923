import React, { createContext, useContext, useState } from 'react';
import type { BookingCriteria } from '../types/booking';

interface BookingContextType {
  bookings: BookingCriteria[];
  addBooking: (booking: BookingCriteria) => void;
  removeBooking: (index: number) => void;
  clearBookings: () => void;
}

const BookingContext = createContext<BookingContextType | undefined>(undefined);

export function BookingProvider({ children }: { children: React.ReactNode }) {
  const [bookings, setBookings] = useState<BookingCriteria[]>([]);

  const addBooking = (booking: BookingCriteria) => {
    setBookings(prev => [...prev, booking]);
  };

  const removeBooking = (index: number) => {
    setBookings(prev => prev.filter((_, i) => i !== index));
  };

  const clearBookings = () => {
    setBookings([]);
  };

  return (
    <BookingContext.Provider value={{ bookings, addBooking, removeBooking, clearBookings }}>
      {children}
    </BookingContext.Provider>
  );
}

export function useBooking() {
  const context = useContext(BookingContext);
  if (context === undefined) {
    throw new Error('useBooking must be used within a BookingProvider');
  }
  return context;
}