import React, { useState, useEffect } from 'react';
import { BookingCard } from './BookingCard';
import { FinalBookingModal } from './event/FinalBookingModal';

const mockTrains = [
  {
    id: 'train1',
    title: 'Paris → Lyon',
    type: 'train',
    image: 'https://images.unsplash.com/photo-1474487548417-781cb71495f3?w=800&h=400&fit=crop',
    badge: { text: 'TGV INOUI', color: 'blue' },
    location: 'Paris Gare de Lyon → Lyon Part-Dieu',
    rating: 4.8,
    reviews: 892,
    description: 'Première classe. WiFi et restauration à bord.',
    price: 89,
    duration: '2h',
    features: ['1ère classe', 'WiFi gratuit', 'Bar TGV', 'Prises électriques'],
    provider: {
      name: 'SNCF',
      logo: 'https://images.unsplash.com/photo-1474487548417-781cb71495f3?w=50&h=50&fit=crop',
      isVerified: true
    }
  },
  {
    id: 'train2',
    title: 'Paris → Marseille',
    type: 'train',
    image: 'https://images.unsplash.com/photo-1474487548417-781cb71495f3?w=800&h=400&fit=crop',
    badge: { text: 'Ouigo', color: 'pink' },
    location: 'Paris Gare de Lyon → Marseille Saint-Charles',
    rating: 4.6,
    reviews: 567,
    description: 'Voyage économique et écologique.',
    price: 45,
    duration: '3h30',
    features: ['2nde classe', 'WiFi payant', 'Prises électriques'],
    provider: {
      name: 'SNCF',
      logo: 'https://images.unsplash.com/photo-1474487548417-781cb71495f3?w=50&h=50&fit=crop',
      isVerified: true
    }
  }
];

interface TrainBookingProps {
  searchCriteria?: {
    departureCity: string;
    arrivalCity: string;
    checkIn: string;
    guests: number;
  };
}

export function TrainBooking({ searchCriteria }: TrainBookingProps) {
  const [showFinalBooking, setShowFinalBooking] = useState(false);
  const [selectedTrain, setSelectedTrain] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [trains, setTrains] = useState(mockTrains);

  useEffect(() => {
    if (searchCriteria) {
      setIsLoading(true);
      // Simulate API call
      setTimeout(() => {
        setTrains(mockTrains);
        setIsLoading(false);
      }, 1500);
    }
  }, [searchCriteria]);

  const handleBooking = (train: any) => {
    setSelectedTrain(train);
    setShowFinalBooking(true);
  };

  const handleConfirmBooking = () => {
    console.log('Train booking confirmed:', selectedTrain);
    setShowFinalBooking(false);
  };

  if (isLoading) {
    return (
      <div className="text-center py-12">
        <div className="w-12 h-12 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
        <p className="text-white">Recherche des meilleurs trains...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {trains.map(train => (
        <BookingCard
          key={train.id}
          item={train}
          onBook={() => handleBooking(train)}
        />
      ))}

      {showFinalBooking && selectedTrain && (
        <FinalBookingModal
          event={selectedTrain}
          onClose={() => setShowFinalBooking(false)}
          onConfirm={handleConfirmBooking}
        />
      )}
    </div>
  );
}