import React, { useState } from 'react';
import { Post } from './Post';
import { CreatePost } from './CreatePost';
import { Stories } from './Stories';
import { ContentRecommendations } from './ContentRecommendations';
import { useContentFlow } from '../../contexts/ContentFlowContext';
import { useBalance } from '../../contexts/BalanceContext';
import { VERIFIED_MEMBERS, COMMUNITY_FUND } from '../../utils/constants';

export function Feed() {
  const { posts, stories, addPost } = useContentFlow();
  const { communityFundBalance } = useBalance();
  const [displayedFund, setDisplayedFund] = useState(COMMUNITY_FUND);

  React.useEffect(() => {
    const duration = 2000;
    const steps = 60;
    const increment = communityFundBalance / steps;
    const stepDuration = duration / steps;
    let current = 0;

    const timer = setInterval(() => {
      current += increment;
      if (current >= communityFundBalance) {
        setDisplayedFund(communityFundBalance);
        clearInterval(timer);
      } else {
        setDisplayedFund(current);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [communityFundBalance]);

  const handleNewPost = async (post: any) => {
    await addPost(post);
  };

  return (
    <div className="max-w-2xl mx-auto space-y-6">
      {/* Community Impact Summary */}
      <div className="bg-purple-900 rounded-xl p-4 sm:p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg sm:text-xl font-bold text-white flex items-center gap-2">
            Impact Communautaire
          </h2>
          <div className="flex items-center gap-2">
            <span className="text-white text-sm">{VERIFIED_MEMBERS.toLocaleString()} vérifiés</span>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 sm:gap-4">
          <div className="bg-purple-800 p-3 sm:p-4 rounded-lg">
            <p className="text-xs sm:text-sm text-gray-300 truncate">Fond total</p>
            <p className="text-base sm:text-2xl font-bold text-yellow-400">{displayedFund.toLocaleString()}€</p>
          </div>
          <div className="bg-purple-800 p-3 sm:p-4 rounded-lg">
            <p className="text-xs sm:text-sm text-gray-300 truncate">Cette semaine</p>
            <p className="text-base sm:text-2xl font-bold text-green-400">+1,250€</p>
          </div>
          <div className="bg-purple-800 p-3 sm:p-4 rounded-lg">
            <p className="text-xs sm:text-sm text-gray-300 truncate">Impact/membre</p>
            <p className="text-base sm:text-2xl font-bold text-blue-400">{Math.round(displayedFund/VERIFIED_MEMBERS)}€</p>
          </div>
        </div>
      </div>

      <Stories />
      <ContentRecommendations />
      <CreatePost onPost={handleNewPost} />
      
      <div className="space-y-6">
        {posts.map(post => (
          <Post key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
}