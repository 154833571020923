// Add more mock live streams
export const mockLiveStreams: LiveStream[] = [
  {
    id: '1',
    title: 'Comment maximiser votre impact sur PLATFF',
    streamer: {
      id: '1',
      name: 'Romain Lopez',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
      verified: true
    },
    thumbnail: 'https://images.unsplash.com/photo-1591115765373-5207764f72e7?w=800&h=400&fit=crop',
    viewers: 1250,
    category: 'Business',
    tags: ['Entrepreneuriat', 'Impact Social', 'Innovation'],
    startedAt: new Date().toISOString(),
    communityImpact: 250
  },
  {
    id: '2',
    title: 'Live Shopping: Nouveautés Tech Éco-responsables',
    streamer: {
      id: '2',
      name: 'Marie Lambert',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop',
      verified: true
    },
    thumbnail: 'https://images.unsplash.com/photo-1550009158-9ebf69173e03?w=800&h=400&fit=crop',
    viewers: 890,
    category: 'Shopping',
    tags: ['Tech', 'Écologie', 'Innovation'],
    startedAt: new Date().toISOString(),
    communityImpact: 180
  },
  {
    id: '3',
    title: 'Masterclass: Photographie Impact Social',
    streamer: {
      id: '3',
      name: 'Lucas Dubois',
      avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
      verified: true
    },
    thumbnail: 'https://images.unsplash.com/photo-1516035069371-29a1b244cc32?w=800&h=400&fit=crop',
    viewers: 450,
    category: 'Éducation',
    tags: ['Photo', 'Art', 'Impact'],
    startedAt: new Date().toISOString(),
    communityImpact: 120
  }
];

// Add more mock courses
export const mockCourses: Course[] = [
  {
    id: '1',
    title: 'Entrepreneuriat Social 101',
    description: 'Découvrez les fondamentaux de l\'entrepreneuriat social et apprenez à créer un impact positif.',
    instructor: {
      name: 'Romain Lopez',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
      verified: true
    },
    thumbnail: 'https://images.unsplash.com/photo-1552664730-d307ca884978?w=800&h=400&fit=crop',
    price: 0,
    duration: '6 heures',
    level: 'Débutant',
    rating: 4.8,
    students: 1200,
    chapters: [
      { title: 'Introduction à l\'impact social', duration: '45min' },
      { title: 'Modèles économiques', duration: '1h' },
      { title: 'Mesure d\'impact', duration: '1h' }
    ],
    communityImpact: 500
  },
  {
    id: '2',
    title: 'Impact Investing Masterclass',
    description: 'Formation avancée sur l\'investissement à impact et la finance sociale.',
    instructor: {
      name: 'Sophie Martin',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
      verified: true
    },
    thumbnail: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&h=400&fit=crop',
    price: 49,
    duration: '8 heures',
    level: 'Avancé',
    rating: 4.9,
    students: 850,
    chapters: [
      { title: 'Fondamentaux de l\'impact investing', duration: '1h' },
      { title: 'Analyse d\'impact', duration: '2h' },
      { title: 'Stratégies d\'investissement', duration: '2h' }
    ],
    communityImpact: 350
  },
  {
    id: '3',
    title: 'Production Vidéo pour Créateurs',
    description: 'Maîtrisez l\'art de la vidéo pour maximiser votre impact sur PLATFF.',
    instructor: {
      name: 'Lucas Dubois',
      avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
      verified: true
    },
    thumbnail: 'https://images.unsplash.com/photo-1492691527719-9d1e07e534b4?w=800&h=400&fit=crop',
    price: 29,
    duration: '5 heures',
    level: 'Intermédiaire',
    rating: 4.7,
    students: 620,
    chapters: [
      { title: 'Équipement et setup', duration: '1h' },
      { title: 'Techniques de tournage', duration: '2h' },
      { title: 'Montage et post-production', duration: '2h' }
    ],
    communityImpact: 200
  }
];

// Add more mock dating profiles
export const mockDatingProfiles: DatingProfile[] = [
  {
    id: '1',
    name: 'Sophie',
    age: 28,
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400&h=400&fit=crop',
    bio: 'Entrepreneure sociale passionnée par l\'innovation et l\'impact positif.',
    interests: ['Innovation sociale', 'Entrepreneuriat', 'Voyage', 'Tech'],
    location: 'Paris',
    verified: true,
    online: true,
    photos: [
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=800&h=800&fit=crop',
      'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=800&h=800&fit=crop'
    ],
    communityImpact: 250
  },
  {
    id: '2',
    name: 'Lucas',
    age: 31,
    avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=400&h=400&fit=crop',
    bio: 'Développeur et contributeur actif aux projets d\'impact social.',
    interests: ['Tech', 'Impact social', 'Sport', 'Musique'],
    location: 'Lyon',
    verified: true,
    online: false,
    lastActive: '2024-03-15T10:30:00Z',
    photos: [
      'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=800&h=800&fit=crop',
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=800&h=800&fit=crop'
    ],
    communityImpact: 180
  },
  {
    id: '3',
    name: 'Marie',
    age: 26,
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400&h=400&fit=crop',
    bio: 'Artiste et créatrice de contenu engagée pour l\'environnement.',
    interests: ['Art', 'Écologie', 'Photographie', 'Voyage'],
    location: 'Bordeaux',
    verified: true,
    online: true,
    photos: [
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=800&h=800&fit=crop',
      'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=800&h=800&fit=crop'
    ],
    communityImpact: 150
  }
];

// Add more mock events
export const mockEvents: Event[] = [
  {
    id: '1',
    title: 'PLATFF Community Summit 2024',
    description: 'Le plus grand rassemblement de la communauté PLATFF. Découvrez les dernières innovations et rencontrez des acteurs du changement.',
    image: 'https://images.unsplash.com/photo-1540575467063-178a50c2df87?w=800&h=400&fit=crop',
    date: '2024-04-15',
    time: '09:00',
    location: 'Paris Expo Porte de Versailles',
    organizer: {
      name: 'PLATFF Events',
      avatar: 'https://images.unsplash.com/photo-1549692520-acc6669e2f0c?w=100&h=100&fit=crop',
      verified: true
    },
    price: 0,
    capacity: 2000,
    registered: 1500,
    category: 'Conférence',
    communityImpact: 1500
  },
  {
    id: '2',
    title: 'Workshop: Maximiser son Impact Social',
    description: 'Apprenez à maximiser votre impact social à travers la plateforme PLATFF.',
    image: 'https://images.unsplash.com/photo-1559223607-a43c990c692c?w=800&h=400&fit=crop',
    date: '2024-04-20',
    time: '14:00',
    location: 'Station F, Paris',
    organizer: {
      name: 'PLATFF Academy',
      avatar: 'https://images.unsplash.com/photo-1552664730-d307ca884978?w=100&h=100&fit=crop',
      verified: true
    },
    price: 25,
    capacity: 200,
    registered: 150,
    category: 'Workshop',
    communityImpact: 250
  },
  {
    id: '3',
    title: 'Meetup Créateurs PLATFF',
    description: 'Rencontrez d\'autres créateurs de contenu et partagez vos expériences.',
    image: 'https://images.unsplash.com/photo-1511578314322-379afb476865?w=800&h=400&fit=crop',
    date: '2024-04-25',
    time: '18:30',
    location: 'WeWork La Fayette, Paris',
    organizer: {
      name: 'PLATFF Creators',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
      verified: true
    },
    price: 0,
    capacity: 100,
    registered: 85,
    category: 'Networking',
    communityImpact: 150
  }
];

// Add mock jobs
export const mockJobs = [
  {
    id: '1',
    title: 'Développeur Full Stack Impact',
    company: {
      name: 'PLATFF',
      logo: 'https://images.unsplash.com/photo-1549692520-acc6669e2f0c?w=100&h=100&fit=crop',
      verified: true
    },
    location: 'Paris, France',
    type: 'CDI',
    salary: {
      min: 45000,
      max: 65000,
      currency: 'EUR'
    },
    remote: true,
    description: 'Rejoignez notre équipe tech pour développer des solutions innovantes à impact social positif.',
    requirements: ['React', 'Node.js', 'TypeScript', '3+ ans d\'expérience'],
    benefits: ['RTT', 'Mutuelle', 'Tickets Restaurant', 'Formation continue'],
    postedAt: new Date().toISOString(),
    communityImpact: 500
  },
  {
    id: '2',
    title: 'Chef de Projet Impact Social',
    company: {
      name: 'Impact Partners',
      logo: 'https://images.unsplash.com/photo-1552664730-d307ca884978?w=100&h=100&fit=crop',
      verified: true
    },
    location: 'Lyon, France',
    type: 'CDI',
    salary: {
      min: 40000,
      max: 55000,
      currency: 'EUR'
    },
    remote: false,
    description: 'Pilotez des projets à fort impact social et environnemental.',
    requirements: ['Gestion de projet', 'ESS', '2+ ans d\'expérience'],
    benefits: ['RTT', 'Mutuelle', 'Formation ESS'],
    postedAt: new Date().toISOString(),
    communityImpact: 350
  }
];

// Add mock polls
export const mockPolls = [
  {
    id: '1',
    title: 'Quelle cause sociale devrait être prioritaire ?',
    description: 'Aidez-nous à orienter nos prochaines actions communautaires',
    creator: {
      name: 'PLATFF Impact',
      avatar: 'https://images.unsplash.com/photo-1549692520-acc6669e2f0c?w=100&h=100&fit=crop',
      verified: true
    },
    options: [
      { id: '1', text: 'Éducation', votes: 1250 },
      { id: '2', text: 'Environnement', votes: 980 },
      { id: '3', text: 'Inclusion sociale', votes: 750 },
      { id: '4', text: 'Innovation sociale', votes: 620 }
    ],
    totalVotes: 3600,
    endDate: '2024-04-15',
    category: 'Impact Social',
    featured: true,
    communityImpact: 500
  },
  {
    id: '2',
    title: 'Nouvelle fonctionnalité à développer',
    description: 'Votez pour la prochaine fonctionnalité majeure de PLATFF',
    creator: {
      name: 'Romain Lopez',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop',
      verified: true
    },
    options: [
      { id: '1', text: 'Marketplace de services', votes: 850 },
      { id: '2', text: 'Système de mentorat', votes: 720 },
      { id: '3', text: 'Crowdfunding social', votes: 690 }
    ],
    totalVotes: 2260,
    endDate: '2024-04-20',
    category: 'Produit',
    featured: false,
    communityImpact: 250
  }
];