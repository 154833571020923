import React, { useState } from 'react';
import { Feed } from '../social/Feed';
import { Messages } from '../social/Messages';
import { LiveSection } from '../social/LiveSection';
import { JobsSection } from '../social/JobsSection';
import { DatingSection } from '../social/DatingSection';
import { EventsSection } from '../social/EventsSection';
import { LearningSection } from '../social/LearningSection';
import { PollsSection } from '../social/PollsSection';
import { CreatorHub } from '../social/creator/CreatorHub';
import { 
  Radio, 
  Briefcase, 
  Heart, 
  Calendar, 
  BookOpen, 
  BarChart2,
  Globe,
  Shield,
  BadgeCheck,
  Users,
  Star,
  TrendingUp,
  Tv
} from 'lucide-react';

const tabs = [
  {
    id: 'feed',
    icon: Globe,
    label: 'Fil d\'actualité',
    color: 'from-yellow-400 to-yellow-500'
  },
  {
    id: 'live',
    icon: Radio,
    label: 'Live',
    color: 'from-red-400 to-red-500'
  },
  {
    id: 'creator',
    icon: Tv,
    label: 'Créateur',
    color: 'from-purple-400 to-purple-500'
  },
  {
    id: 'jobs',
    icon: Briefcase,
    label: 'Emplois',
    color: 'from-blue-400 to-blue-500'
  },
  {
    id: 'dating',
    icon: Heart,
    label: 'Rencontres',
    color: 'from-pink-400 to-pink-500'
  },
  {
    id: 'events',
    icon: Calendar,
    label: 'Événements',
    color: 'from-green-400 to-green-500'
  },
  {
    id: 'learn',
    icon: BookOpen,
    label: 'Apprendre',
    color: 'from-indigo-400 to-indigo-500'
  },
  {
    id: 'polls',
    icon: BarChart2,
    label: 'Sondages',
    color: 'from-orange-400 to-orange-500'
  }
];

const communityStats = {
  members: 15000,
  onlineNow: 1250,
  verifiedMembers: 8500,
  totalImpact: 125000
};

export function SocialSection() {
  const [activeTab, setActiveTab] = useState('feed');
  const [showMessages, setShowMessages] = useState(false);
  const [isUserVerified] = useState(true);

  const renderContent = () => {
    switch (activeTab) {
      case 'feed':
        return <Feed />;
      case 'live':
        return <LiveSection />;
      case 'creator':
        return <CreatorHub isVerified={isUserVerified} />;
      case 'jobs':
        return <JobsSection />;
      case 'dating':
        return <DatingSection />;
      case 'events':
        return <EventsSection />;
      case 'learn':
        return <LearningSection />;
      case 'polls':
        return <PollsSection />;
      default:
        return <Feed />;
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      {/* Community Stats */}
      <div className="bg-purple-900 rounded-xl p-6 mb-6">
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <div className="text-center">
            <div className="flex items-center justify-center gap-2 text-yellow-400 mb-2">
              <Users size={20} />
              <span className="text-lg font-bold">{communityStats.members.toLocaleString()}</span>
            </div>
            <p className="text-gray-300 text-sm">Membres</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center gap-2 text-green-400 mb-2">
              <BadgeCheck size={20} />
              <span className="text-lg font-bold">{communityStats.verifiedMembers.toLocaleString()}</span>
            </div>
            <p className="text-gray-300 text-sm">Vérifiés</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center gap-2 text-blue-400 mb-2">
              <Star size={20} />
              <span className="text-lg font-bold">{communityStats.onlineNow.toLocaleString()}</span>
            </div>
            <p className="text-gray-300 text-sm">En ligne</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center gap-2 text-purple-400 mb-2">
              <TrendingUp size={20} />
              <span className="text-lg font-bold">{communityStats.totalImpact.toLocaleString()}€</span>
            </div>
            <p className="text-gray-300 text-sm">Impact total</p>
          </div>
        </div>
      </div>

      {/* Security Notice */}
      {!isUserVerified && (
        <div className="bg-purple-900 p-4 rounded-xl mb-6">
          <div className="flex items-center gap-3">
            <div className="bg-yellow-400/10 p-2 rounded-lg">
              <Shield className="text-yellow-400" size={24} />
            </div>
            <div>
              <h3 className="text-white font-semibold">Sécurité Renforcée</h3>
              <p className="text-sm text-gray-300">
                Pour la protection de nos membres, certaines fonctionnalités sont réservées aux comptes vérifiés.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Tabs Navigation */}
      <div className="flex gap-2 overflow-x-auto pb-4 mb-6">
        {tabs.map(tab => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`flex items-center gap-2 px-4 py-2 rounded-full transition-colors whitespace-nowrap ${
              activeTab === tab.id
                ? 'bg-yellow-400 text-purple-900'
                : 'bg-purple-900 text-white hover:bg-purple-800'
            }`}
          >
            <tab.icon size={18} />
            <span>{tab.label}</span>
          </button>
        ))}
      </div>

      {/* Content */}
      <div className="space-y-6">
        {renderContent()}
      </div>

      {/* Messages Overlay */}
      {showMessages && (
        <div className="fixed inset-0 bg-black/50 z-50">
          <div className="absolute right-0 top-0 bottom-0 w-96 bg-purple-900">
            <Messages onClose={() => setShowMessages(false)} />
          </div>
        </div>
      )}
    </div>
  );
}