import React from 'react';
import { Info, X, DollarSign, Globe, Shield, BarChart2, Megaphone, Zap, CreditCard, Award } from 'lucide-react';

interface CreatorBenefitsProps {
  onClose: () => void;
}

export function CreatorBenefits({ onClose }: CreatorBenefitsProps) {
  return (
    <div className="fixed sm:absolute inset-0 sm:inset-auto sm:right-0 sm:top-full mt-0 sm:mt-2 p-4 sm:p-0 bg-black/50 sm:bg-transparent backdrop-blur-sm sm:backdrop-blur-none flex items-end sm:block z-50">
      <div className="w-full sm:w-80 max-h-[80vh] sm:max-h-[600px] bg-purple-900 rounded-xl shadow-lg overflow-y-auto animate-slide-up">
        <div className="sticky top-0 p-4 border-b border-purple-800 bg-purple-900 z-10">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-bold text-white">Benefits for Creators</h3>
            <button 
              onClick={onClose}
              className="p-2 -mr-2 text-gray-400 hover:text-white transition-colors"
            >
              <X size={20} />
            </button>
          </div>
        </div>

        <div className="p-4 space-y-4">
          <div className="flex items-start gap-3">
            <DollarSign className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div>
              <p className="text-white font-medium text-sm sm:text-base">Revenue Sharing</p>
              <p className="text-xs sm:text-sm text-gray-300">70% for creator, 30% for PLATFF</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <Globe className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div>
              <p className="text-white font-medium text-sm sm:text-base">Global Reach</p>
              <p className="text-xs sm:text-sm text-gray-300">Access to PLATFF's worldwide audience</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <Shield className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div>
              <p className="text-white font-medium text-sm sm:text-base">Content Protection</p>
              <p className="text-xs sm:text-sm text-gray-300">Professional DRM and copyright protection</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <BarChart2 className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div>
              <p className="text-white font-medium text-sm sm:text-base">Analytics Dashboard</p>
              <p className="text-xs sm:text-sm text-gray-300">Detailed performance metrics and insights</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <Megaphone className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div>
              <p className="text-white font-medium text-sm sm:text-base">Marketing Support</p>
              <p className="text-xs sm:text-sm text-gray-300">Promotion for approved content</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <Zap className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div>
              <p className="text-white font-medium text-sm sm:text-base">Smart Recommendations</p>
              <p className="text-xs sm:text-sm text-gray-300">AI-powered content discovery</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <CreditCard className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div>
              <p className="text-white font-medium text-sm sm:text-base">Flexible Monetization</p>
              <p className="text-xs sm:text-sm text-gray-300">Subscription and pay-per-view options</p>
            </div>
          </div>

          <div className="flex items-start gap-3">
            <Award className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <div>
              <p className="text-white font-medium text-sm sm:text-base">Quality Badge</p>
              <p className="text-xs sm:text-sm text-gray-300">Special recognition for approved content</p>
            </div>
          </div>
        </div>

        <div className="sticky bottom-0 p-4 border-t border-purple-800 bg-purple-900">
          <button
            onClick={onClose}
            className="w-full bg-yellow-400 text-purple-900 py-2 rounded-full font-medium hover:bg-yellow-300 transition-colors text-sm sm:text-base"
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );
}