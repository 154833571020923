import React, { useState } from 'react';
import { Menu, MessageCircle, ShoppingBag, Bell } from 'lucide-react';
import { Messages } from '../social/Messages';
import { CartModal } from '../shop/CartModal';
import { AnimatedLogo } from '../shared/AnimatedLogo';
import { useCart } from '../../contexts/CartContext';
import { useLanguage } from '../../contexts/LanguageContext';
import { NotificationsPanel } from './NotificationsPanel';

interface HeaderProps {
  onMenuToggle: () => void;
  onNavigate?: (section: string) => void;
  isAdmin?: boolean;
  isVerified?: boolean;
}

export function Header({ 
  onMenuToggle, 
  onNavigate, 
  isAdmin, 
  isVerified = true 
}: HeaderProps) {
  const [showMessages, setShowMessages] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { totalItems } = useCart();
  const { t } = useLanguage();

  const handleLogoClick = () => {
    if (onNavigate) {
      onNavigate('home');
    }
  };

  return (
    <>
      <header className="bg-purple-950 shadow-lg sticky top-0 z-50 p-4">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <Menu 
              className="text-yellow-400 cursor-pointer hover:text-yellow-300 transition-colors" 
              onClick={onMenuToggle}
            />
            <button
              onClick={handleLogoClick}
              className="transform scale-[0.6] origin-left cursor-pointer hover:opacity-80 transition-opacity -ml-4"
            >
              <AnimatedLogo variant="header" />
            </button>
          </div>
          <div className="flex gap-4 items-center">
            {/* Notifications */}
            <button
              onClick={() => setShowNotifications(!showNotifications)}
              className="relative text-yellow-400 hover:text-yellow-300 transition-colors"
            >
              <Bell size={24} />
              <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                3
              </span>
            </button>

            {/* Messages */}
            <button
              onClick={() => setShowMessages(!showMessages)}
              className="relative text-yellow-400 hover:text-yellow-300 transition-colors"
            >
              <MessageCircle size={24} />
              <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                2
              </span>
            </button>

            {/* Cart */}
            {isVerified && (
              <button
                onClick={() => setShowCart(true)}
                className="relative text-yellow-400 hover:text-yellow-300 transition-colors cursor-pointer"
              >
                <ShoppingBag size={24} />
                {totalItems > 0 && (
                  <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                    {totalItems}
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      </header>

      {/* Messages Panel */}
      {showMessages && (
        <div className="fixed inset-0 bg-black/50 z-[60] flex justify-end">
          <div className="w-full max-w-md bg-purple-900 h-full">
            <Messages onClose={() => setShowMessages(false)} />
          </div>
        </div>
      )}

      {/* Notifications Panel */}
      {showNotifications && (
        <NotificationsPanel onClose={() => setShowNotifications(false)} />
      )}

      {/* Cart Modal */}
      {isVerified && <CartModal isOpen={showCart} onClose={() => setShowCart(false)} />}
    </>
  );
}