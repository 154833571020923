import React, { useState } from 'react';
import { BadgeCheck, Star, Gift } from 'lucide-react';
import { mockLiveStreams, mockCourses } from '../../data/mockSocialData';
import { LiveBroadcastControls } from './LiveBroadcastControls';

export function ContentRecommendations() {
  const [selectedStream, setSelectedStream] = useState<any>(null);
  const [enrolledCourses, setEnrolledCourses] = useState<string[]>([]);

  const handleJoinStream = (stream: any) => {
    setSelectedStream(stream);
  };

  const handleEnrollCourse = (courseId: string) => {
    setEnrolledCourses(prev => [...prev, courseId]);
  };

  if (selectedStream) {
    return (
      <LiveBroadcastControls
        onClose={() => setSelectedStream(null)}
        onEnd={() => setSelectedStream(null)}
        isViewer={true}
        stream={selectedStream}
      />
    );
  }

  return (
    <div className="space-y-4">
      {/* Live Streams */}
      <div className="overflow-x-auto">
        <h3 className="text-lg font-semibold text-white mb-3">En Direct</h3>
        <div className="flex gap-4 pb-2">
          {mockLiveStreams.map(stream => (
            <div key={stream.id} className="flex-shrink-0 w-72 bg-purple-800 rounded-xl overflow-hidden">
              <div className="relative">
                <img src={stream.thumbnail} alt="" className="w-full h-40 object-cover" />
                <div className="absolute top-2 left-2 bg-red-500 px-3 py-1 rounded-full text-white text-sm font-semibold flex items-center gap-2">
                  <div className="w-2 h-2 bg-white rounded-full animate-pulse" />
                  LIVE
                </div>
                <div className="absolute bottom-2 left-2 bg-purple-900/80 px-3 py-1 rounded-full text-white text-sm">
                  {stream.viewers.toLocaleString()} spectateurs
                </div>
              </div>
              
              <div className="p-4">
                <div className="flex items-center gap-2 mb-2">
                  <img src={stream.streamer.avatar} alt="" className="w-8 h-8 rounded-full" />
                  <div>
                    <div className="flex items-center gap-1">
                      <span className="text-white font-medium">{stream.streamer.name}</span>
                      {stream.streamer.verified && (
                        <BadgeCheck size={14} className="text-yellow-400" />
                      )}
                    </div>
                    <span className="text-sm text-gray-300">{stream.category}</span>
                  </div>
                </div>
                
                <p className="text-white line-clamp-2 mb-2">{stream.title}</p>
                
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1 text-sm text-gray-300">
                    <Gift size={14} className="text-yellow-400" />
                    <span>{stream.communityImpact}€ impact</span>
                  </div>
                  <button 
                    onClick={() => handleJoinStream(stream)}
                    className="bg-yellow-400 text-purple-900 px-3 py-1 rounded-full text-sm font-medium hover:bg-yellow-300 transition-colors"
                  >
                    Rejoindre
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Courses */}
      <div className="overflow-x-auto">
        <h3 className="text-lg font-semibold text-white mb-3">Formations</h3>
        <div className="flex gap-4 pb-2">
          {mockCourses.map(course => (
            <div key={course.id} className="flex-shrink-0 w-72 bg-purple-800 rounded-xl overflow-hidden">
              <div className="relative">
                <img src={course.thumbnail} alt="" className="w-full h-40 object-cover" />
                <div className="absolute top-2 right-2 bg-purple-900/80 px-3 py-1 rounded-full text-white text-sm flex items-center gap-1">
                  <Star size={14} className="text-yellow-400" fill="currentColor" />
                  {course.rating}
                </div>
              </div>
              
              <div className="p-4">
                <div className="flex items-center gap-2 mb-2">
                  <img src={course.instructor.avatar} alt="" className="w-8 h-8 rounded-full" />
                  <div>
                    <div className="flex items-center gap-1">
                      <span className="text-white font-medium">{course.instructor.name}</span>
                      {course.instructor.verified && (
                        <BadgeCheck size={14} className="text-yellow-400" />
                      )}
                    </div>
                    <span className="text-sm text-gray-300">{course.level}</span>
                  </div>
                </div>
                
                <p className="text-white line-clamp-2 mb-2">{course.title}</p>
                
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <span className="text-lg font-bold text-white">
                      {course.price === 0 ? 'Gratuit' : `${course.price}€`}
                    </span>
                    <span className="text-sm text-gray-300">{course.duration}</span>
                  </div>
                  <button 
                    onClick={() => handleEnrollCourse(course.id)}
                    disabled={enrolledCourses.includes(course.id)}
                    className={`px-3 py-1 rounded-full text-sm font-medium transition-colors ${
                      enrolledCourses.includes(course.id)
                        ? 'bg-green-400 text-purple-900 cursor-not-allowed'
                        : 'bg-yellow-400 text-purple-900 hover:bg-yellow-300'
                    }`}
                  >
                    {enrolledCourses.includes(course.id) ? 'Inscrit' : "S'inscrire"}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}