import React, { useState } from 'react';
import { Hotel, Calendar, ArrowRight } from 'lucide-react';
import { DateSelectionModal } from './DateSelectionModal';
import { AccommodationModal } from './AccommodationModal';
import { TransportModal } from './TransportModal';
import { FinalBookingModal } from './FinalBookingModal';

interface BookingOptionsModalProps {
  event: {
    title: string;
    location: string;
    date: string;
    price: number;
    cashback: number;
    impact: number;
  };
  onClose: () => void;
}

export function BookingOptionsModal({ event, onClose }: BookingOptionsModalProps) {
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [showAccommodation, setShowAccommodation] = useState(false);
  const [showTransport, setShowTransport] = useState(false);
  const [showFinalBooking, setShowFinalBooking] = useState(false);
  const [selectedAccommodation, setSelectedAccommodation] = useState<any>(null);
  const [selectedTransport, setSelectedTransport] = useState<any>(null);
  const [bookingType, setBookingType] = useState<'event' | 'package' | null>(null);
  const [selectedDates, setSelectedDates] = useState<any>(null);

  const handleBooking = (type: 'event' | 'package') => {
    setBookingType(type);
    if (type === 'package') {
      setShowDateSelection(true);
    } else {
      setShowFinalBooking(true);
    }
  };

  const handleDateConfirm = (dates: any) => {
    setSelectedDates(dates);
    setShowDateSelection(false);
    setShowAccommodation(true);
  };

  const handleAccommodationSelected = (accommodation: any) => {
    setSelectedAccommodation(accommodation);
    setShowAccommodation(false);
    setShowTransport(true);
  };

  const handleTransportSelected = (transport: any) => {
    setSelectedTransport(transport);
    setShowTransport(false);
    setShowFinalBooking(true);
  };

  const handleConfirmBooking = () => {
    console.log('Booking confirmed:', {
      event,
      accommodation: selectedAccommodation,
      transport: selectedTransport,
      dates: selectedDates
    });
    onClose();
  };

  if (showDateSelection) {
    return (
      <DateSelectionModal 
        event={event}
        onClose={() => setShowDateSelection(false)}
        onConfirm={handleDateConfirm}
      />
    );
  }

  if (showAccommodation && selectedDates) {
    return (
      <AccommodationModal 
        event={{
          ...event,
          date: selectedDates.checkIn
        }}
        onClose={onClose}
        onAccommodationSelected={handleAccommodationSelected}
      />
    );
  }

  if (showTransport) {
    return (
      <TransportModal 
        event={event}
        onClose={onClose}
        onTransportSelected={handleTransportSelected}
      />
    );
  }

  if (showFinalBooking) {
    return (
      <FinalBookingModal
        event={event}
        accommodation={selectedAccommodation}
        transport={selectedTransport}
        dates={selectedDates}
        onClose={() => setShowFinalBooking(false)}
        onConfirm={handleConfirmBooking}
      />
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md">
        <h2 className="text-xl font-bold text-white mb-4">Options de réservation</h2>
        <p className="text-gray-300 mb-6">{event.title}</p>

        <div className="space-y-4">
          {/* Event Only Option */}
          <button
            onClick={() => handleBooking('event')}
            className="w-full bg-purple-800 p-4 rounded-xl text-left hover:bg-purple-700 transition-colors group"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Calendar className="text-yellow-400" size={24} />
                <div>
                  <h3 className="text-white font-semibold">Événement uniquement</h3>
                  <p className="text-sm text-gray-300">Réserver seulement l'événement</p>
                </div>
              </div>
              <ArrowRight className="text-gray-400 group-hover:text-white transition-colors" size={20} />
            </div>
            <div className="mt-3 text-right">
              <p className="text-xl font-bold text-white">{event.price}€</p>
            </div>
          </button>

          {/* Event + Accommodation Option */}
          <button
            onClick={() => handleBooking('package')}
            className="w-full bg-purple-800 p-4 rounded-xl text-left hover:bg-purple-700 transition-colors group"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Hotel className="text-yellow-400" size={24} />
                <div>
                  <h3 className="text-white font-semibold">Événement + Hébergement</h3>
                  <p className="text-sm text-gray-300">Trouver un hébergement à proximité</p>
                </div>
              </div>
              <ArrowRight className="text-gray-400 group-hover:text-white transition-colors" size={20} />
            </div>
            <div className="mt-3 text-right">
              <p className="text-sm text-gray-300">À partir de</p>
              <p className="text-xl font-bold text-white">{event.price + 89}€</p>
            </div>
          </button>
        </div>

        <button
          onClick={onClose}
          className="w-full mt-6 py-2 text-gray-300 hover:text-white transition-colors"
        >
          Annuler
        </button>
      </div>
    </div>
  );
}