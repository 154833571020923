import React, { useState, useEffect } from 'react';
import { Building, Shield, Lock, Loader } from 'lucide-react';

interface ActivationModalProps {
  onComplete: () => void;
  onClose: () => void;
}

export function ActivationModal({ onComplete, onClose }: ActivationModalProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          clearInterval(timer);
          setTimeout(onComplete, 500);
          return 100;
        }
        return prev + 20;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onComplete]);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl p-6 w-full max-w-md">
        <div className="text-center mb-8">
          <div className="w-16 h-16 bg-yellow-400 rounded-full flex items-center justify-center mx-auto mb-4">
            <Building className="text-purple-900" size={32} />
          </div>
          <h2 className="text-xl font-bold text-white mb-2">Ouverture de votre compte PLATFFPAY</h2>
          <p className="text-gray-300">Veuillez patienter pendant que nous préparons votre compte...</p>
        </div>

        {/* Progress Bar */}
        <div className="w-full bg-purple-800 rounded-full h-2 mb-4">
          <div 
            className="bg-yellow-400 h-2 rounded-full transition-all duration-500"
            style={{ width: `${progress}%` }}
          />
        </div>

        {/* Steps */}
        <div className="space-y-4">
          <div className="bg-purple-800 p-3 rounded-lg flex items-center gap-3">
            <Shield className="text-yellow-400" size={20} />
            <div className="flex-1">
              <p className="text-white text-sm">Vérification de votre identité</p>
            </div>
            {progress >= 40 && <Loader className="text-green-400 animate-spin" size={16} />}
          </div>

          <div className="bg-purple-800 p-3 rounded-lg flex items-center gap-3">
            <Building className="text-yellow-400" size={20} />
            <div className="flex-1">
              <p className="text-white text-sm">Création de votre compte bancaire</p>
            </div>
            {progress >= 60 && <Loader className="text-green-400 animate-spin" size={16} />}
          </div>

          <div className="bg-purple-800 p-3 rounded-lg flex items-center gap-3">
            <Lock className="text-yellow-400" size={20} />
            <div className="flex-1">
              <p className="text-white text-sm">Configuration de la sécurité</p>
            </div>
            {progress >= 80 && <Loader className="text-green-400 animate-spin" size={16} />}
          </div>
        </div>
      </div>
    </div>
  );
}