import React, { useState } from 'react';
import { 
  Tv, 
  Upload, 
  Play, 
  Pause,
  Eye, 
  Heart, 
  Share2,
  Radio,
  Video,
  Film,
  Plus,
  Star,
  Globe,
  Gift,
  Clock,
  BadgeCheck,
  MessageCircle,
  Users,
  Settings
} from 'lucide-react';
import { useVideoPlayer } from '../../../hooks/useVideoPlayer';
import { ContentInteractions } from './ContentInteractions';
import { UploadModal } from './UploadModal';

interface VideoContent {
  id: string;
  title: string;
  thumbnail: string;
  videoUrl: string;
  duration: string;
  views: number;
  likes: number;
  type: 'video' | 'live' | 'series';
  description: string;
  createdAt: string;
  communityImpact: number;
}

const mockVideos: VideoContent[] = [
  {
    id: '1',
    title: 'Comment maximiser son impact social',
    thumbnail: 'https://images.unsplash.com/photo-1516245834210-c4c142787335?w=600&h=400&fit=crop',
    videoUrl: 'https://cdn.coverr.co/videos/coverr-typing-on-laptop-1584/preview.mp4',
    duration: '12:34',
    views: 1250,
    likes: 450,
    type: 'video',
    description: 'Découvrez les meilleures pratiques pour créer un impact positif.',
    createdAt: new Date().toISOString(),
    communityImpact: 250
  },
  {
    id: '2',
    title: 'Live Trading Responsable',
    thumbnail: 'https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?w=600&h=400&fit=crop',
    videoUrl: 'https://cdn.coverr.co/videos/coverr-a-business-meeting-in-an-office-5244/preview.mp4',
    duration: '45:20',
    views: 890,
    likes: 320,
    type: 'live',
    description: 'Session live sur l\'investissement éthique et responsable.',
    createdAt: new Date().toISOString(),
    communityImpact: 180
  }
];

export function VideoChannel() {
  const [videos, setVideos] = useState(mockVideos);
  const [currentVideo, setCurrentVideo] = useState<VideoContent | null>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const {
    isPlaying,
    progress,
    volume,
    isMuted,
    videoRef,
    togglePlay,
    handleVideoProgress,
    handleVolume,
    toggleMute
  } = useVideoPlayer();

  const handleVideoSelect = (video: VideoContent) => {
    setCurrentVideo(video);
  };

  const handleReward = (amount: number) => {
    console.log(`Rewarded ${amount}€ for video:`, currentVideo?.title);
  };

  const handleUpload = (videoData: any) => {
    const newVideo: VideoContent = {
      id: videoData.id,
      title: videoData.title,
      thumbnail: videoData.thumbnailUrl || 'https://images.unsplash.com/photo-1516245834210-c4c142787335?w=600&h=400&fit=crop',
      videoUrl: videoData.fileUrl,
      duration: videoData.duration,
      views: 0,
      likes: 0,
      type: 'video',
      description: videoData.description,
      createdAt: new Date().toISOString(),
      communityImpact: 0
    };
    setVideos([newVideo, ...videos]);
  };

  return (
    <div className="space-y-6">
      {/* Upload Section */}
      <div className="bg-purple-900 p-6 rounded-xl">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-xl font-bold text-white flex items-center gap-2">
            <Tv className="text-yellow-400" />
            Votre Chaîne
          </h3>
          <button 
            onClick={() => setShowUploadModal(true)}
            className="bg-yellow-400 text-purple-900 px-4 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center gap-2"
          >
            <Upload size={20} />
            Upload
          </button>
        </div>

        <div className="grid grid-cols-3 gap-4">
          <div className="bg-purple-800 p-4 rounded-xl">
            <Video className="text-yellow-400 mb-2" size={24} />
            <h4 className="text-white font-semibold mb-1">Vidéos</h4>
            <p className="text-sm text-gray-300">
              Style YouTube
            </p>
          </div>

          <div className="bg-purple-800 p-4 rounded-xl">
            <Radio className="text-yellow-400 mb-2" size={24} />
            <h4 className="text-white font-semibold mb-1">Live</h4>
            <p className="text-sm text-gray-300">
              Streaming en direct
            </p>
          </div>

          <div className="bg-purple-800 p-4 rounded-xl">
            <Film className="text-yellow-400 mb-2" size={24} />
            <h4 className="text-white font-semibold mb-1">Séries</h4>
            <p className="text-sm text-gray-300">
              Contenus épisodiques
            </p>
          </div>
        </div>
      </div>

      {/* Video Player */}
      {currentVideo && (
        <div className="bg-purple-900 rounded-xl overflow-hidden">
          <div className="relative aspect-video bg-black">
            <video
              ref={videoRef}
              src={currentVideo.videoUrl}
              className="w-full h-full object-contain"
              onClick={togglePlay}
            />

            {/* Video Controls */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-transparent to-transparent opacity-0 hover:opacity-100 transition-opacity flex items-end">
              <div className="w-full p-4">
                <div className="flex items-center gap-4 mb-2">
                  <button onClick={togglePlay} className="text-white hover:text-yellow-400">
                    {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                  </button>

                  {/* Progress Bar */}
                  <div className="flex-1">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={progress}
                      onChange={(e) => handleVideoProgress(Number(e.target.value))}
                      className="w-full"
                    />
                  </div>

                  {/* Volume Control */}
                  <div className="flex items-center gap-2">
                    <button onClick={toggleMute} className="text-white hover:text-yellow-400">
                      {isMuted ? <Settings size={20} /> : <Settings size={20} />}
                    </button>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.1"
                      value={volume}
                      onChange={(e) => handleVolume(Number(e.target.value))}
                      className="w-20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="flex items-center gap-2 mb-2">
              <h3 className="text-lg font-bold text-white">{currentVideo.title}</h3>
              <BadgeCheck className="text-yellow-400" size={16} />
            </div>
            <p className="text-gray-300 text-sm mb-4">{currentVideo.description}</p>

            <ContentInteractions
              contentId={currentVideo.id}
              contentTitle={currentVideo.title}
              creatorName="PLATFF Creator"
              likes={currentVideo.likes}
              comments={0}
              isVerified={true}
              onReward={handleReward}
            />
          </div>
        </div>
      )}

      {/* Videos Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        {videos.map(video => (
          <div key={video.id} className="bg-purple-900 rounded-xl overflow-hidden">
            <div className="relative">
              <img 
                src={video.thumbnail}
                alt={video.title}
                className="w-full aspect-video object-cover"
              />
              <div className="absolute bottom-2 right-2 bg-black/80 px-2 py-1 rounded text-white text-sm flex items-center gap-1">
                <Clock size={14} />
                {video.duration}
              </div>
              {video.type === 'live' && (
                <div className="absolute top-2 left-2 bg-red-500 px-3 py-1 rounded-full text-white text-sm flex items-center gap-2">
                  <div className="w-2 h-2 bg-white rounded-full animate-pulse" />
                  LIVE
                </div>
              )}
            </div>

            <div className="p-4">
              <h4 className="text-lg font-semibold text-white mb-2">{video.title}</h4>
              <p className="text-gray-300 text-sm mb-4">{video.description}</p>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4 text-sm text-gray-300">
                  <span className="flex items-center gap-1">
                    <Eye size={14} />
                    {video.views.toLocaleString()}
                  </span>
                  <span className="flex items-center gap-1">
                    <Heart size={14} />
                    {video.likes.toLocaleString()}
                  </span>
                  <span className="flex items-center gap-1">
                    <Gift size={14} className="text-yellow-400" />
                    <span className="text-yellow-400">{video.communityImpact}€</span>
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <button className="text-gray-300 hover:text-white">
                    <Share2 size={20} />
                  </button>
                  <button
                    onClick={() => handleVideoSelect(video)}
                    className="bg-yellow-400 text-purple-900 p-2 rounded-full hover:bg-yellow-300 transition-colors"
                  >
                    <Play size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Upload Modal */}
      {showUploadModal && (
        <UploadModal
          type="video"
          onClose={() => setShowUploadModal(false)}
          onUpload={handleUpload}
        />
      )}
    </div>
  );
}