import React, { useState } from 'react';
import { 
  Share2, 
  Copy, 
  Check, 
  Facebook, 
  Link2,
  MessageCircle,
  Camera,
  Video,
  Ghost
} from 'lucide-react';

interface ShareButtonProps {
  url?: string;
  title?: string;
  description?: string;
}

export function ShareButton({ 
  url = window.location.href, 
  title = 'PLATFF', 
  description = 'Découvrez PLATFF - La première plateforme d\'impact social' 
}: ShareButtonProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleShare = (platform: string) => {
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);
    const encodedDesc = encodeURIComponent(description);

    let shareUrl = '';
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodedDesc}%20${encodedUrl}`;
        break;
      case 'instagram':
        // Instagram doesn't have a direct share URL, but we can copy the link
        handleCopy();
        break;
      case 'tiktok':
        // TikTok doesn't have a direct share URL, but we can copy the link
        handleCopy();
        break;
      case 'snapchat':
        // Snapchat doesn't have a direct share URL, but we can copy the link
        handleCopy();
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, '_blank', 'width=600,height=400');
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowMenu(!showMenu)}
        className="bg-purple-800 p-2 rounded-full text-white hover:bg-purple-700 transition-colors"
      >
        <Share2 size={20} />
      </button>

      {showMenu && (
        <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 bg-purple-800 rounded-xl shadow-lg p-2 min-w-[200px] z-50">
          <div className="flex flex-col gap-2">
            <button
              onClick={() => handleShare('facebook')}
              className="flex items-center gap-2 p-2 rounded-lg hover:bg-purple-700 text-white transition-colors"
            >
              <Facebook size={20} />
              <span>Facebook</span>
            </button>
            <button
              onClick={() => handleShare('linkedin')}
              className="flex items-center gap-2 p-2 rounded-lg hover:bg-purple-700 text-white transition-colors"
            >
              <Link2 size={20} />
              <span>LinkedIn</span>
            </button>
            <button
              onClick={() => handleShare('whatsapp')}
              className="flex items-center gap-2 p-2 rounded-lg hover:bg-purple-700 text-white transition-colors"
            >
              <MessageCircle size={20} />
              <span>WhatsApp</span>
            </button>
            <button
              onClick={() => handleShare('instagram')}
              className="flex items-center gap-2 p-2 rounded-lg hover:bg-purple-700 text-white transition-colors"
            >
              <Camera size={20} />
              <span>Instagram</span>
            </button>
            <button
              onClick={() => handleShare('tiktok')}
              className="flex items-center gap-2 p-2 rounded-lg hover:bg-purple-700 text-white transition-colors"
            >
              <Video size={20} />
              <span>TikTok</span>
            </button>
            <button
              onClick={() => handleShare('snapchat')}
              className="flex items-center gap-2 p-2 rounded-lg hover:bg-purple-700 text-white transition-colors"
            >
              <Ghost size={20} />
              <span>Snapchat</span>
            </button>
            <hr className="border-purple-700" />
            <button
              onClick={handleCopy}
              className="flex items-center gap-2 p-2 rounded-lg hover:bg-purple-700 text-white transition-colors"
            >
              {copied ? (
                <>
                  <Check size={20} className="text-green-400" />
                  <span>Copié !</span>
                </>
              ) : (
                <>
                  <Copy size={20} />
                  <span>Copier le lien</span>
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}