import React, { useState } from 'react';
import { Star, MapPin, BadgeCheck, Gift, Globe, Users, Calendar, Music, Dumbbell, Ticket, Coffee, Theater } from 'lucide-react';
import { BookingOptionsModal } from './event/BookingOptionsModal';

const eventCategories = [
  { id: 'concerts', icon: Music, label: 'Concerts' },
  { id: 'festivals', icon: Ticket, label: 'Festivals' },
  { id: 'sports', icon: Dumbbell, label: 'Sports' },
  { id: 'nightlife', icon: Coffee, label: 'Clubs' },
  { id: 'comedy', icon: Music, label: 'Humour' },
  { id: 'theatre', icon: Theater, label: 'Théâtre' }
];

const mockEvents = {
  concerts: [
    {
      id: 'concert1',
      title: 'DJ Guetta World Tour',
      image: 'https://images.unsplash.com/photo-1540039155733-5bb30b53aa14?w=800&h=400&fit=crop',
      date: '2024-05-15',
      time: '20:00',
      location: 'Accor Arena, Paris',
      price: 89,
      rating: 4.9,
      reviews: 1250,
      description: 'Une soirée électro inoubliable avec David Guetta',
      cashback: 13,
      impact: 13
    },
    {
      id: 'concert2',
      title: 'Coldplay Music of the Spheres',
      image: 'https://images.unsplash.com/photo-1540039155733-5bb30b53aa14?w=800&h=400&fit=crop',
      date: '2024-06-20',
      time: '21:00',
      location: 'Stade de France, Paris',
      price: 120,
      rating: 4.8,
      reviews: 980,
      description: 'Le retour de Coldplay en France',
      cashback: 18,
      impact: 18
    }
  ],
  festivals: [
    {
      id: 'festival1',
      title: 'Tomorrowland Winter',
      image: 'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3?w=800&h=400&fit=crop',
      date: '2024-03-16',
      time: '12:00',
      location: 'Alpe d\'Huez, France',
      price: 299,
      rating: 4.9,
      reviews: 2150,
      description: 'Le plus grand festival électro d\'hiver',
      cashback: 45,
      impact: 45
    }
  ],
  sports: [
    {
      id: 'sport1',
      title: 'PSG vs Real Madrid',
      image: 'https://images.unsplash.com/photo-1522778119026-d647f0596c20?w=800&h=400&fit=crop',
      date: '2024-04-10',
      time: '21:00',
      location: 'Parc des Princes, Paris',
      price: 150,
      rating: 4.8,
      reviews: 1580,
      description: 'Quart de finale Ligue des Champions',
      cashback: 22,
      impact: 22
    }
  ],
  nightlife: [
    {
      id: 'club1',
      title: 'Soirée Platinium',
      image: 'https://images.unsplash.com/photo-1545128485-c400e7702796?w=800&h=400&fit=crop',
      date: '2024-03-23',
      time: '23:00',
      location: 'L\'Arc Paris',
      price: 45,
      rating: 4.7,
      reviews: 750,
      description: 'La plus exclusive des soirées parisiennes',
      cashback: 7,
      impact: 7
    }
  ],
  comedy: [
    {
      id: 'comedy1',
      title: 'Gad Elmaleh - D\'ailleurs',
      image: 'https://images.unsplash.com/photo-1585699324551-f6c309eedeca?w=800&h=400&fit=crop',
      date: '2024-04-05',
      time: '20:30',
      location: 'Olympia, Paris',
      price: 55,
      rating: 4.8,
      reviews: 890,
      description: 'Le nouveau spectacle de Gad Elmaleh',
      cashback: 8,
      impact: 8
    }
  ],
  theatre: [
    {
      id: 'theatre1',
      title: 'Le Roi Lion - Le Musical',
      image: 'https://images.unsplash.com/photo-1507676184212-d03ab07a01bf?w=800&h=400&fit=crop',
      date: '2024-03-30',
      time: '19:30',
      location: 'Théâtre Mogador, Paris',
      price: 79,
      rating: 4.9,
      reviews: 1450,
      description: 'La comédie musicale événement',
      cashback: 12,
      impact: 12
    }
  ]
};

export function EventBooking() {
  const [selectedCategory, setSelectedCategory] = useState<string>('concerts');
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const handleBooking = (event: any) => {
    setSelectedEvent(event);
    setShowBookingModal(true);
  };

  return (
    <div className="space-y-6">
      {/* Categories */}
      <div className="flex gap-2 overflow-x-auto pb-2">
        {eventCategories.map(category => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(category.id)}
            className={`flex items-center gap-2 px-4 py-2 rounded-full whitespace-nowrap ${
              selectedCategory === category.id
                ? 'bg-yellow-400 text-purple-900'
                : 'bg-purple-800 text-white hover:bg-purple-700'
            }`}
          >
            <category.icon size={18} />
            {category.label}
          </button>
        ))}
      </div>

      {/* Events List */}
      <div className="space-y-6">
        {mockEvents[selectedCategory as keyof typeof mockEvents].map(event => (
          <div key={event.id} className="bg-purple-900 rounded-xl overflow-hidden">
            <div className="relative">
              <img src={event.image} alt={event.title} className="w-full h-48 object-cover" />
            </div>

            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-bold text-white">{event.title}</h3>
                  <div className="flex items-center gap-4 text-gray-300 mt-1">
                    <div className="flex items-center gap-2">
                      <Calendar size={16} />
                      <span>{new Date(event.date).toLocaleDateString()} - {event.time}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <MapPin size={16} />
                      <span>{event.location}</span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <Star className="text-yellow-400" size={16} fill="currentColor" />
                  <span className="text-white">{event.rating}</span>
                  <span className="text-gray-300">({event.reviews})</span>
                </div>
              </div>

              <p className="text-gray-300 mb-4">{event.description}</p>

              <div className="space-y-2 mb-4">
                <div className="flex items-center gap-2 text-sm">
                  <Gift className="text-green-400" size={16} />
                  <span className="text-white">{event.cashback}€ crédités sur votre PLATFFPAY</span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <Globe className="text-blue-400" size={16} />
                  <span className="text-white">{event.impact}€ ajoutés au Fonds Communautaire</span>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div>
                  <p className="text-2xl font-bold text-white">{event.price}€</p>
                </div>
                <button 
                  onClick={() => handleBooking(event)}
                  className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
                >
                  Réserver
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Booking Modal */}
      {showBookingModal && selectedEvent && (
        <BookingOptionsModal
          event={selectedEvent}
          onClose={() => setShowBookingModal(false)}
        />
      )}
    </div>
  );
}