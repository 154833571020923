import React, { useState } from 'react';
import { ShoppingBag, Gift, Globe, Lock, BadgeCheck, Shield, Tag, Sparkles, Star } from 'lucide-react';
import { PriceComparison } from './PriceComparison';
import { ProductPurchaseModal } from './ProductPurchaseModal';
import { fetchCompetitorPrices } from '../../utils/priceComparison';
import type { Product } from '../../types/shop';

interface ProductCardProps {
  product: Product;
}

export function ProductCard({ product }: ProductCardProps) {
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showPriceComparison, setShowPriceComparison] = useState(false);

  const getConditionBadge = () => {
    switch (product.condition) {
      case 'new':
        return { icon: Sparkles, text: 'Neuf', color: 'yellow' };
      case 'refurbished':
        return { icon: Shield, text: 'Reconditionné', color: 'green' };
      case 'used':
        return { icon: Tag, text: 'Occasion', color: 'blue' };
    }
  };

  const badge = getConditionBadge();

  const handleBuyClick = () => {
    if (product.seller.isPlatff) {
      setShowPriceComparison(true);
    } else {
      setShowPurchaseModal(true);
    }
  };

  // Show price if seller is a verified third-party vendor or verified individual seller
  const shouldShowPrice = !product.seller.isPlatff && product.seller.isVerified;

  return (
    <>
      <div className="bg-purple-900 rounded-xl overflow-hidden">
        <div className="relative">
          <img 
            src={product.images[0]} 
            alt={product.title}
            className="w-full h-48 object-cover"
          />
          <div className="absolute top-4 right-4 flex flex-wrap gap-2 max-w-[calc(100%-2rem)]">
            {product.seller.isPlatff && (
              <div className="bg-purple-900/80 px-3 py-1 rounded-full text-white text-sm flex items-center gap-1 whitespace-nowrap">
                <Shield size={14} />
                <span>PLATFF</span>
              </div>
            )}
            <div className={`bg-${badge.color}-400 text-purple-900 px-3 py-1 rounded-full text-sm font-semibold flex items-center gap-1 whitespace-nowrap overflow-hidden text-ellipsis`}>
              <badge.icon size={14} />
              <span>{badge.text}</span>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="flex items-start justify-between mb-2">
            <div className="flex items-center gap-2">
              <img
                src={product.seller.avatar}
                alt={product.seller.name}
                className="w-6 h-6 rounded-full"
              />
              <div>
                <div className="flex items-center gap-1">
                  <span className="text-white text-sm">{product.seller.name}</span>
                  {product.seller.isVerified && (
                    <BadgeCheck size={14} className="text-yellow-400" />
                  )}
                </div>
                <div className="flex items-center gap-1 text-xs text-gray-300">
                  <Star size={12} className="text-yellow-400" />
                  {product.seller.rating} • {product.seller.totalSales} ventes
                </div>
              </div>
            </div>
          </div>

          <h3 className="text-lg font-bold text-white mb-1">{product.title}</h3>
          <p className="text-gray-300 text-sm mb-4 line-clamp-2">{product.description}</p>

          {shouldShowPrice && (
            <div className="space-y-2 mb-4">
              <div className="flex items-center gap-2 text-sm">
                <Gift className="text-green-400" size={16} />
                <span className="text-white">
                  {(product.price * 0.05).toFixed(2)}€ cashback
                </span>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <Globe className="text-blue-400" size={16} />
                <span className="text-white">
                  Impact: {(product.price * 0.15).toFixed(2)}€
                </span>
              </div>
            </div>
          )}

          <div className="flex justify-between items-center">
            {shouldShowPrice ? (
              <div>
                {product.originalPrice && (
                  <p className="text-sm text-gray-400 line-through">
                    {product.originalPrice}€
                  </p>
                )}
                <p className="text-2xl font-bold text-white">{product.price}€</p>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <BadgeCheck className="text-yellow-400" size={20} />
                <span className="text-gray-300">Meilleur prix garanti</span>
              </div>
            )}
            <button
              onClick={handleBuyClick}
              className="bg-yellow-400 text-purple-900 px-4 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center gap-2 whitespace-nowrap"
            >
              <ShoppingBag size={16} />
              <span>Acheter</span>
            </button>
          </div>
        </div>
      </div>

      {showPriceComparison && (
        <PriceComparison
          productName={product.title}
          ourPrice={product.price}
          profitMargin={30}
          onCompare={async () => {
            return fetchCompetitorPrices(
              product.title,
              product.category,
              product.price,
              product.ean
            );
          }}
          onAddToCart={() => {
            setShowPriceComparison(false);
            setShowPurchaseModal(true);
          }}
        />
      )}

      {showPurchaseModal && (
        <ProductPurchaseModal
          product={product}
          onClose={() => setShowPurchaseModal(false)}
          onPurchase={() => {
            console.log('Product purchased:', product);
            setShowPurchaseModal(false);
          }}
        />
      )}
    </>
  );
}