// Update community stats
export const APP_NAME = 'PLATFF.';

// Profit Distribution
export const PLATFF_PERCENTAGE = 75;
export const COMMUNITY_FUND_PERCENTAGE = 15;
export const CASHBACK_PERCENTAGE = 5;
export const PLATFFPAY_PERCENTAGE = 5;

// Commission Rates
export const DEFAULT_BOOKING_COMMISSION = 10; // 10% commission on bookings

// PLATFFPAY Credit Limits
export const MAX_MINI_CREDIT = 200;
export const MAX_OVERDRAFT = 100;

// Community Stats
export const VERIFIED_MEMBERS = 970;
export const TOTAL_MEMBERS = 1000;
export const COMMUNITY_FUND = 15000;
export const ACTIVE_MEMBERS = 985;
export const ONLINE_MEMBERS = 125;

// Creator Fund
export const CREATOR_FUND_PERCENTAGE = 50; // 50% of Community Fund goes to creators
export const CREATOR_FUND = COMMUNITY_FUND * (CREATOR_FUND_PERCENTAGE / 100);
export const MEMBER_ALLOCATION = CREATOR_FUND / VERIFIED_MEMBERS;

// Routes
export const ROUTES = {
  HOME: '/',
  SHOP: '/shop',
  BOOKINGS: '/bookings',
  SOCIAL: '/social',
  WALLET: '/wallet',
  IMPACT: '/impact',
};