import React, { useState } from 'react';
import { MapPin, Search, X } from 'lucide-react';

interface LocationPickerProps {
  onSelect: (location: string) => void;
  onClose: () => void;
}

export function LocationPicker({ onSelect, onClose }: LocationPickerProps) {
  const [searchQuery, setSearchQuery] = useState('');

  const mockLocations = [
    'Paris, France',
    'Lyon, France',
    'Marseille, France',
    'Bordeaux, France',
    'Nice, France'
  ].filter(loc => 
    loc.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[60] p-4">
      <div className="bg-purple-900 rounded-xl p-4 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold text-white">Ajouter un lieu</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={20} />
          </button>
        </div>

        <div className="relative mb-4">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher un lieu..."
            className="w-full bg-purple-800 text-white pl-10 pr-4 py-2 rounded-xl focus:ring-2 focus:ring-yellow-400"
          />
        </div>

        <div className="space-y-2 max-h-60 overflow-y-auto">
          {mockLocations.map((location) => (
            <button
              key={location}
              onClick={() => {
                onSelect(location);
                onClose();
              }}
              className="w-full flex items-center gap-3 p-3 rounded-lg hover:bg-purple-800 transition-colors text-left"
            >
              <MapPin className="text-yellow-400" size={20} />
              <span className="text-white">{location}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}