import React, { useState } from 'react';
import { X, Trash2, ShoppingBag, Gift, Globe, ArrowRight, CreditCard } from 'lucide-react';
import { useCart } from '../../contexts/CartContext';
import { CheckoutForm } from './CheckoutForm';

interface CartModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CartModal({ isOpen, onClose }: CartModalProps) {
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const { 
    items, 
    removeFromCart, 
    updateQuantity,
    totalAmount,
    totalCashback,
    totalCommunityImpact
  } = useCart();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose} />
      
      <div className="absolute right-0 top-0 bottom-0 w-full max-w-md bg-purple-900 shadow-xl">
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="p-4 border-b border-purple-800 flex items-center justify-between">
            <h2 className="text-xl font-bold text-white flex items-center gap-2">
              <ShoppingBag className="text-yellow-400" />
              {isCheckingOut ? 'Paiement' : 'Panier'}
            </h2>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X size={24} />
            </button>
          </div>

          {/* Content */}
          <div className="flex-1 overflow-y-auto p-4">
            {isCheckingOut ? (
              <CheckoutForm 
                onClose={() => {
                  onClose();
                  setIsCheckingOut(false);
                }}
              />
            ) : (
              <>
                {items.length === 0 ? (
                  <div className="text-center py-8">
                    <ShoppingBag className="mx-auto text-gray-400 mb-4" size={48} />
                    <p className="text-gray-300">Votre panier est vide</p>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {items.map(item => (
                      <div key={item.productId} className="bg-purple-800 rounded-xl p-4">
                        <div className="flex items-center gap-4">
                          <div className="flex-1">
                            <h3 className="text-white font-semibold">{item.productId}</h3>
                            <div className="flex items-center gap-4 mt-2">
                              <div className="flex items-center gap-2">
                                <button
                                  onClick={() => updateQuantity(item.productId, Math.max(1, item.quantity - 1))}
                                  className="text-gray-400 hover:text-white"
                                >
                                  -
                                </button>
                                <span className="text-white">{item.quantity}</span>
                                <button
                                  onClick={() => updateQuantity(item.productId, item.quantity + 1)}
                                  className="text-gray-400 hover:text-white"
                                >
                                  +
                                </button>
                              </div>
                              <button
                                onClick={() => removeFromCart(item.productId)}
                                className="text-red-400 hover:text-red-300"
                              >
                                <Trash2 size={20} />
                              </button>
                            </div>
                          </div>
                          <div className="text-right">
                            <p className="text-white font-bold">{item.price.toFixed(2)}€</p>
                            <p className="text-sm text-gray-300">Total: {(item.price * item.quantity).toFixed(2)}€</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>

          {/* Summary */}
          {items.length > 0 && !isCheckingOut && (
            <div className="p-4 border-t border-purple-800">
              <div className="space-y-4">
                <div className="flex justify-between items-center text-gray-300">
                  <span>Sous-total</span>
                  <span className="text-white font-bold">{totalAmount.toFixed(2)}€</span>
                </div>
                
                <div className="bg-purple-800 p-4 rounded-xl space-y-2">
                  <div className="flex items-center gap-2 text-green-400">
                    <Gift size={20} />
                    <span>{totalCashback.toFixed(2)}€ seront crédités sur votre compte PLATFFPAY</span>
                  </div>
                  <div className="flex items-center gap-2 text-blue-400">
                    <Globe size={20} />
                    <span>{totalCommunityImpact.toFixed(2)}€ seront reversés au Fonds Communautaire</span>
                  </div>
                </div>

                <button
                  onClick={() => setIsCheckingOut(true)}
                  className="w-full bg-yellow-400 text-purple-900 py-4 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2"
                >
                  <CreditCard size={20} />
                  <span>Payer Maintenant</span>
                  <ArrowRight size={20} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}