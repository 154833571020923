import { useEffect, useState } from 'react';

export function useCountAnimation(endValue: number, duration: number = 2000) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const steps = 60;
    const increment = endValue / steps;
    const stepDuration = duration / steps;
    let current = 0;

    const timer = setInterval(() => {
      current += increment;
      if (current >= endValue) {
        setValue(endValue);
        clearInterval(timer);
      } else {
        setValue(current);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [endValue, duration]);

  return value;
}