import React, { useState, useRef } from 'react';
import { 
  Music, 
  Upload, 
  Play, 
  Pause, 
  X, 
  Clock, 
  Gift, 
  Globe, 
  BadgeCheck,
  Info,
  Send,
  Shield
} from 'lucide-react';
import WaveSurfer from 'wavesurfer.js';
import { useAudioPlayer } from '../../../hooks/useAudioPlayer';
import { moderateContent } from '../../../utils/contentModeration';

interface UploadModalProps {
  type: 'music' | 'video';
  onClose: () => void;
  onUpload: (data: any) => void;
}

export function UploadModal({ type, onClose, onUpload }: UploadModalProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const thumbnailInputRef = useRef<HTMLInputElement>(null);
  const {
    isPlaying,
    duration,
    currentTime,
    containerRef,
    togglePlay,
    initializeWaveform
  } = useAudioPlayer();

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (type === 'music' && !selectedFile.type.startsWith('audio/')) {
        alert('Veuillez sélectionner un fichier audio');
        return;
      }
      if (type === 'video' && !selectedFile.type.startsWith('video/')) {
        alert('Veuillez sélectionner un fichier vidéo');
        return;
      }
      setFile(selectedFile);
      if (type === 'music') {
        const url = URL.createObjectURL(selectedFile);
        initializeWaveform(url);
      }
    }
  };

  const handleThumbnailSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setThumbnail(selectedFile);
    }
  };

  const handleSubmit = async () => {
    if (!file || !title.trim()) return;

    setIsProcessing(true);
    
    try {
      const titleModeration = await moderateContent(title);
      const descModeration = await moderateContent(description);

      if (!titleModeration.isClean || !descModeration.isClean) {
        alert('Le contenu contient des termes inappropriés');
        setIsProcessing(false);
        return;
      }

      setIsUploading(true);
      await new Promise(resolve => setTimeout(resolve, 2000));

      onUpload({
        title: titleModeration.moderatedText,
        description: descModeration.moderatedText,
        fileUrl: URL.createObjectURL(file),
        thumbnailUrl: thumbnail ? URL.createObjectURL(thumbnail) : null,
        duration: duration
      });

      onClose();
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setIsUploading(false);
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-purple-900 z-50 flex flex-col">
      {/* Header */}
      <div className="p-4 border-b border-purple-800 flex items-center justify-between">
        <h3 className="text-xl font-bold text-white flex items-center gap-2">
          {type === 'music' ? <Music className="text-yellow-400" /> : <Upload className="text-yellow-400" />}
          {type === 'music' ? 'Partager votre musique' : 'Uploader une vidéo'}
        </h3>
        <button onClick={onClose} className="text-gray-300 hover:text-white">
          <X size={24} />
        </button>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto p-4">
        <div className="max-w-lg mx-auto space-y-4">
          {!file ? (
            <div className="border-2 border-dashed border-purple-700 rounded-xl p-8 text-center">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept={type === 'music' ? 'audio/*' : 'video/*'}
                className="hidden"
              />
              {type === 'music' ? (
                <Music className="text-yellow-400 mx-auto mb-4" size={48} />
              ) : (
                <Upload className="text-yellow-400 mx-auto mb-4" size={48} />
              )}
              <p className="text-white font-medium mb-2">
                {type === 'music' ? 'Sélectionnez votre fichier audio' : 'Sélectionnez votre vidéo'}
              </p>
              <p className="text-sm text-gray-300 mb-4">
                {type === 'music' ? 'MP3, WAV jusqu\'à 50MB' : 'MP4, MOV jusqu\'à 100MB'}
              </p>
              <button
                onClick={() => fileInputRef.current?.click()}
                className="bg-yellow-400 text-purple-900 px-6 py-2 rounded-full font-semibold hover:bg-yellow-300 transition-colors"
              >
                Choisir un fichier
              </button>
            </div>
          ) : (
            <>
              {type === 'music' && (
                <div className="bg-purple-700 p-4 rounded-xl">
                  <div ref={containerRef} className="mb-4" />
                  <div className="flex items-center justify-between">
                    <button
                      onClick={togglePlay}
                      className="bg-yellow-400 text-purple-900 p-2 rounded-full hover:bg-yellow-300 transition-colors"
                    >
                      {isPlaying ? <Pause size={20} /> : <Play size={20} />}
                    </button>
                    <div className="flex items-center gap-2 text-gray-300 text-sm">
                      <Clock size={14} />
                      <span>
                        {Math.floor(currentTime / 60)}:
                        {String(Math.floor(currentTime % 60)).padStart(2, '0')} / 
                        {Math.floor(duration / 60)}:
                        {String(Math.floor(duration % 60)).padStart(2, '0')}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <label className="text-white text-sm mb-1 block">Titre</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full bg-purple-700 text-white rounded-xl px-4 py-2 focus:ring-2 focus:ring-yellow-400"
                  placeholder={`Titre de votre ${type === 'music' ? 'musique' : 'vidéo'}`}
                />
              </div>

              <div>
                <label className="text-white text-sm mb-1 block">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full bg-purple-700 text-white rounded-xl px-4 py-2 focus:ring-2 focus:ring-yellow-400 min-h-[100px]"
                  placeholder="Décrivez votre contenu..."
                />
              </div>

              {type === 'video' && (
                <div>
                  <label className="text-white text-sm mb-1 block">Miniature</label>
                  <input
                    type="file"
                    ref={thumbnailInputRef}
                    onChange={handleThumbnailSelect}
                    accept="image/*"
                    className="hidden"
                  />
                  <button
                    onClick={() => thumbnailInputRef.current?.click()}
                    className="w-full bg-purple-700 text-white rounded-xl p-4 hover:bg-purple-600 transition-colors"
                  >
                    {thumbnail ? (
                      <img
                        src={URL.createObjectURL(thumbnail)}
                        alt="Thumbnail"
                        className="w-full h-32 object-cover rounded-lg"
                      />
                    ) : (
                      <div className="text-center">
                        <Upload className="mx-auto mb-2" size={24} />
                        <span>Ajouter une miniature</span>
                      </div>
                    )}
                  </button>
                </div>
              )}

              <div className="bg-purple-700 p-4 rounded-xl space-y-2">
                <div className="flex items-center gap-2 text-sm">
                  <Gift className="text-green-400" size={16} />
                  <span className="text-white">Récompenses activées pour ce contenu</span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <Globe className="text-blue-400" size={16} />
                  <span className="text-white">Impact communautaire activé</span>
                </div>
              </div>

              <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
                <Shield className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
                <div className="text-sm text-gray-300">
                  <p className="font-medium text-white mb-1">Règles de la communauté</p>
                  <p>Votre contenu sera vérifié pour assurer le respect des règles de la communauté.</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Fixed Bottom Bar */}
      {file && (
        <div className="p-4 border-t border-purple-800 bg-purple-900 safe-area-bottom">
          <div className="max-w-lg mx-auto">
            <button
              onClick={handleSubmit}
              disabled={isProcessing || isUploading || !title.trim()}
              className={`w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold flex items-center justify-center gap-2 ${
                isProcessing || isUploading || !title.trim()
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-yellow-300'
              } transition-colors`}
            >
              {isUploading ? (
                <>
                  <Upload className="animate-bounce" size={20} />
                  Upload en cours...
                </>
              ) : (
                <>
                  <Send size={20} />
                  Publier
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}