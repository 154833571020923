import React, { useState } from 'react';
import { CompareButton } from './price/CompareButton';
import { PlatffPrice } from './price/PlatffPrice';
import { CompetitorPrice } from './price/CompetitorPrice';
import { PriceSearchPopup } from './PriceSearchPopup';
import { ProfitDistribution } from '../shared/ProfitDistribution';
import { Shield, TrendingDown, ExternalLink, Star, BadgeCheck, Info } from 'lucide-react';
import type { PriceComparison as PriceComparisonType } from '../../utils/priceComparison';

interface PriceComparisonProps {
  productName: string;
  ourPrice: number;
  profitMargin: number;
  onCompare: () => Promise<PriceComparisonType[]>;
  onAddToCart: () => void;
}

export function PriceComparison({ 
  productName, 
  ourPrice, 
  profitMargin,
  onCompare, 
  onAddToCart
}: PriceComparisonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [prices, setPrices] = useState<PriceComparisonType[]>([]);
  const [showPrices, setShowPrices] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleCompare = async () => {
    setIsLoading(true);
    setShowPopup(true);
    
    try {
      const competitorPrices = await onCompare();
      setPrices(competitorPrices || []);
      await new Promise(resolve => setTimeout(resolve, 7000));
      setShowPrices(true);
    } catch (error) {
      console.error('Failed to fetch prices:', error);
      setPrices([]);
    } finally {
      setShowPopup(false);
      setIsLoading(false);
    }
  };

  if (!showPrices) {
    return (
      <>
        <CompareButton isLoading={isLoading} onClick={handleCompare} />
        {showPopup && <PriceSearchPopup onClose={() => setShowPopup(false)} />}
      </>
    );
  }

  const averageCompetitorPrice = prices.reduce((sum, p) => sum + p.price, 0) / prices.length;
  const savings = averageCompetitorPrice - ourPrice;

  return (
    <div className="space-y-4">
      {/* PLATFF Price Card */}
      <div className="bg-green-400/10 p-4 rounded-xl">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <Shield className="text-yellow-400" size={24} />
            <div>
              <div className="flex items-center gap-2">
                <h3 className="text-lg font-bold text-white">PLATFF</h3>
                <BadgeCheck className="text-yellow-400" size={16} />
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-300">
                <Star className="text-yellow-400" size={14} />
                <span>Vendeur officiel</span>
              </div>
            </div>
          </div>
          <div className="text-right">
            <p className="text-3xl font-bold text-green-400">{ourPrice.toFixed(2)}€</p>
            {savings > 0 && (
              <p className="text-sm text-green-400 flex items-center gap-1 justify-end">
                <TrendingDown size={14} />
                -{savings.toFixed(2)}€
              </p>
            )}
          </div>
        </div>

        <div className="bg-purple-800 p-3 rounded-xl mb-4">
          <ProfitDistribution 
            profitMargin={profitMargin}
            saleAmount={ourPrice}
          />
        </div>

        <button
          onClick={onAddToCart}
          className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2"
        >
          <Shield size={20} />
          Acheter avec PLATFF
        </button>
      </div>

      {/* Market Comparison */}
      <div className="bg-purple-800 p-4 rounded-xl">
        <div className="flex items-center gap-2 mb-4">
          <TrendingDown className="text-yellow-400" size={20} />
          <h3 className="text-lg font-bold text-white">Prix du marché</h3>
        </div>

        <div className="space-y-3">
          {/* Major Retailers Section */}
          <div className="space-y-2">
            <h4 className="text-sm font-medium text-gray-300 mb-2">Grandes enseignes</h4>
            {prices.filter(p => ['Sephora', 'Nocibé', 'Marionnaud'].includes(p.merchant)).map((competitor, index) => (
              <div key={index} className="bg-purple-700 p-3 rounded-xl">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <img
                      src={competitor.logo}
                      alt={competitor.merchant}
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="text-white">{competitor.merchant}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="text-xl font-bold text-white">
                      {competitor.price.toFixed(2)}€
                    </span>
                    <a
                      href={competitor.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-300 hover:text-white transition-colors"
                    >
                      <ExternalLink size={16} />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Other Retailers Section */}
          <div className="space-y-2">
            <h4 className="text-sm font-medium text-gray-300 mb-2">Autres revendeurs</h4>
            {prices.filter(p => !['Sephora', 'Nocibé', 'Marionnaud'].includes(p.merchant)).map((competitor, index) => (
              <div key={index} className="bg-purple-700 p-3 rounded-xl">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <img
                      src={competitor.logo}
                      alt={competitor.merchant}
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="text-white">{competitor.merchant}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="text-xl font-bold text-white">
                      {competitor.price.toFixed(2)}€
                    </span>
                    <a
                      href={competitor.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-300 hover:text-white transition-colors"
                    >
                      <ExternalLink size={16} />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4 pt-4 border-t border-purple-700">
          <div className="flex justify-between items-center text-sm">
            <span className="text-gray-300">Prix moyen du marché</span>
            <span className="text-white font-bold">
              {averageCompetitorPrice.toFixed(2)}€
            </span>
          </div>
          {savings > 0 && (
            <div className="flex justify-between items-center text-sm mt-1">
              <span className="text-gray-300">Économies avec PLATFF</span>
              <span className="text-green-400 font-bold">
                {savings.toFixed(2)}€
              </span>
            </div>
          )}
        </div>

        <div className="mt-4 bg-purple-700 p-3 rounded-xl flex items-start gap-2">
          <Info className="text-yellow-400 flex-shrink-0 mt-1" size={16} />
          <p className="text-sm text-gray-300">
            Les prix sont mis à jour en temps réel grâce à notre algorithme de comparaison dynamique.
            Nous garantissons toujours le meilleur prix du marché.
          </p>
        </div>
      </div>
    </div>
  );
}