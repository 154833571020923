import React, { useState } from 'react';
import { useCart } from '../../contexts/CartContext';
import { CreditCard, Truck, Gift, Globe, Lock, ArrowRight } from 'lucide-react';

interface ShippingInfo {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  phone: string;
  email: string;
}

interface PaymentInfo {
  cardNumber: string;
  expiryDate: string;
  cvv: string;
}

interface CheckoutFormProps {
  onClose: () => void;
}

export function CheckoutForm({ onClose }: CheckoutFormProps) {
  const { totalAmount, totalCashback, totalCommunityImpact, clearCart } = useCart();
  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>({
    firstName: 'Romain',
    lastName: 'Lopez',
    address: '',
    city: '',
    postalCode: '',
    country: 'France',
    phone: '',
    email: ''
  });
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>({
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      // Simulate payment processing
      await new Promise(resolve => setTimeout(resolve, 2000));
      clearCart();
      onClose();
    } catch (error) {
      console.error('Payment failed:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleShippingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setShippingInfo(prev => ({ ...prev, [name]: value }));
  };

  const handlePaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPaymentInfo(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="bg-purple-900 rounded-xl">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Personal Information */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white flex items-center gap-2">
            <Lock className="text-yellow-400" />
            Informations personnelles
          </h3>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="text-white text-sm mb-1 block">Prénom</label>
              <input
                type="text"
                name="firstName"
                value={shippingInfo.firstName}
                onChange={handleShippingChange}
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>
            <div>
              <label className="text-white text-sm mb-1 block">Nom</label>
              <input
                type="text"
                name="lastName"
                value={shippingInfo.lastName}
                onChange={handleShippingChange}
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>
          </div>

          <div>
            <label className="text-white text-sm mb-1 block">Email</label>
            <input
              type="email"
              name="email"
              value={shippingInfo.email}
              onChange={handleShippingChange}
              className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
              required
            />
          </div>
        </div>

        {/* Shipping Information */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white flex items-center gap-2">
            <Truck className="text-yellow-400" />
            Adresse de livraison
          </h3>

          <div>
            <label className="text-white text-sm mb-1 block">Adresse</label>
            <input
              type="text"
              name="address"
              value={shippingInfo.address}
              onChange={handleShippingChange}
              className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="text-white text-sm mb-1 block">Ville</label>
              <input
                type="text"
                name="city"
                value={shippingInfo.city}
                onChange={handleShippingChange}
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>
            <div>
              <label className="text-white text-sm mb-1 block">Code postal</label>
              <input
                type="text"
                name="postalCode"
                value={shippingInfo.postalCode}
                onChange={handleShippingChange}
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>
          </div>

          <div>
            <label className="text-white text-sm mb-1 block">Téléphone</label>
            <input
              type="tel"
              name="phone"
              value={shippingInfo.phone}
              onChange={handleShippingChange}
              className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
              required
            />
          </div>
        </div>

        {/* Payment Information */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white flex items-center gap-2">
            <CreditCard className="text-yellow-400" />
            Informations de paiement
          </h3>

          <div>
            <label className="text-white text-sm mb-1 block">Numéro de carte</label>
            <input
              type="text"
              name="cardNumber"
              value={paymentInfo.cardNumber}
              onChange={handlePaymentChange}
              placeholder="4242 4242 4242 4242"
              className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="text-white text-sm mb-1 block">Date d'expiration</label>
              <input
                type="text"
                name="expiryDate"
                value={paymentInfo.expiryDate}
                onChange={handlePaymentChange}
                placeholder="MM/YY"
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>
            <div>
              <label className="text-white text-sm mb-1 block">CVV</label>
              <input
                type="text"
                name="cvv"
                value={paymentInfo.cvv}
                onChange={handlePaymentChange}
                placeholder="123"
                className="w-full bg-purple-800 text-white rounded-xl px-4 py-3 focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>
          </div>
        </div>

        {/* Summary */}
        <div className="space-y-4">
          <div className="bg-purple-800 p-4 rounded-xl space-y-2">
            <div className="flex items-center gap-2 text-green-400">
              <Gift size={20} />
              <span>{totalCashback.toFixed(2)}€ seront crédités sur votre compte PLATFFPAY</span>
            </div>
            <div className="flex items-center gap-2 text-blue-400">
              <Globe size={20} />
              <span>{totalCommunityImpact.toFixed(2)}€ seront reversés au Fonds Communautaire</span>
            </div>
          </div>

          <div className="flex justify-between items-center text-xl font-bold text-white">
            <span>Total</span>
            <span>{totalAmount.toFixed(2)}€</span>
          </div>

          <button
            type="submit"
            disabled={isProcessing}
            className={`w-full bg-yellow-400 text-purple-900 py-4 rounded-xl font-semibold transition-colors flex items-center justify-center gap-2 ${
              isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-300'
            }`}
          >
            {isProcessing ? (
              'Traitement en cours...'
            ) : (
              <>
                <span>Payer {totalAmount.toFixed(2)}€</span>
                <ArrowRight size={20} />
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}