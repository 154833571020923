import React, { useState, useEffect } from 'react';
import { Building, MapPin, Star, BadgeCheck, Gift, Globe, Calendar, Info } from 'lucide-react';

interface AccommodationModalProps {
  event: {
    title: string;
    location: string;
    date: string;
    price: number;
    cashback: number;
    impact: number;
  };
  onClose: () => void;
  onAccommodationSelected: (accommodation: any) => void;
}

const mockAccommodations = [
  {
    id: 'acc1',
    title: 'Hôtel Le Royal',
    type: 'hotel',
    image: 'https://images.unsplash.com/photo-1566073771259-6a8506099945?w=800&h=400&fit=crop',
    distance: '0.5',
    rating: 4.8,
    reviews: 256,
    price: 189,
    features: ['Vue sur ville', 'Petit-déjeuner inclus', 'Spa'],
    cashback: 28,
    impact: 28,
    availability: true
  },
  {
    id: 'acc2',
    title: 'Appartement Centre-Ville',
    type: 'apartment',
    image: 'https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?w=800&h=400&fit=crop',
    distance: '0.8',
    rating: 4.7,
    reviews: 124,
    price: 145,
    features: ['2 chambres', 'Cuisine équipée', 'Balcon'],
    cashback: 22,
    impact: 22,
    availability: true
  }
];

export function AccommodationModal({ event, onClose, onAccommodationSelected }: AccommodationModalProps) {
  const [isSearching, setIsSearching] = useState(true);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSearching(false);
      setShowResults(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleBooking = (accommodation: any) => {
    onAccommodationSelected(accommodation);
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-purple-900 rounded-xl w-full max-w-xl max-h-[80vh] overflow-hidden flex flex-col">
        {/* Header */}
        <div className="p-4 border-b border-purple-800">
          <h2 className="text-lg font-bold text-white mb-1">Hébergements disponibles</h2>
          <p className="text-sm text-gray-300">{event.title} à {event.location}</p>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-4">
          {isSearching ? (
            <div className="text-center py-8">
              <div className="w-12 h-12 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin mx-auto mb-3" />
              <p className="text-white font-medium text-sm">Vérification des disponibilités...</p>
              <p className="text-gray-300 text-xs mt-1">Nous recherchons les meilleurs hébergements</p>
            </div>
          ) : (
            <div className="space-y-4">
              {mockAccommodations.map(acc => (
                <div key={acc.id} className="bg-purple-800 rounded-xl overflow-hidden">
                  <div className="relative">
                    <img src={acc.image} alt={acc.title} className="w-full h-32 object-cover" />
                    <div className="absolute top-2 right-2 bg-purple-900/80 px-2 py-1 rounded-full text-white text-xs flex items-center gap-1">
                      <MapPin size={12} />
                      {acc.distance} km
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h3 className="font-bold text-white">{acc.title}</h3>
                        <div className="flex items-center gap-1 text-gray-300 text-sm mt-0.5">
                          <Building size={14} />
                          <span>{acc.type === 'hotel' ? 'Hôtel' : 'Appartement'}</span>
                        </div>
                      </div>
                      <div className="flex items-center gap-1">
                        <Star className="text-yellow-400" size={14} fill="currentColor" />
                        <span className="text-white text-sm">{acc.rating}</span>
                      </div>
                    </div>

                    <div className="flex flex-wrap gap-1 mb-3">
                      {acc.features.map((feature, index) => (
                        <span
                          key={index}
                          className="bg-purple-700 text-white px-2 py-0.5 rounded-full text-xs"
                        >
                          {feature}
                        </span>
                      ))}
                    </div>

                    <div className="space-y-1 mb-3">
                      <div className="flex items-center gap-1 text-xs">
                        <Gift className="text-green-400" size={14} />
                        <span className="text-white">{acc.cashback}€ crédités sur PLATFFPAY</span>
                      </div>
                      <div className="flex items-center gap-1 text-xs">
                        <Globe className="text-blue-400" size={14} />
                        <span className="text-white">{acc.impact}€ au Fonds Communautaire</span>
                      </div>
                    </div>

                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-lg font-bold text-white">{acc.price}€</p>
                        <p className="text-xs text-gray-300">par nuit</p>
                      </div>
                      <button 
                        onClick={() => handleBooking(acc)}
                        className="bg-yellow-400 text-purple-900 px-4 py-1.5 rounded-full text-sm font-semibold hover:bg-yellow-300 transition-colors"
                      >
                        Réserver
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="p-3 border-t border-purple-800">
          <button
            onClick={onClose}
            className="w-full bg-purple-800 text-white py-2 rounded-full text-sm hover:bg-purple-700 transition-colors"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
}