import { useEffect, useRef, useState } from 'react';
import Peer from 'simple-peer';
import { io, Socket } from 'socket.io-client';

// Polyfill global for WebRTC
if (typeof global === 'undefined') {
  (window as any).global = window;
}

export function useWebRTC() {
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isCalling, setIsCalling] = useState(false);

  const peerRef = useRef<Peer.Instance | null>(null);
  const socketRef = useRef<Socket | null>(null);

  const initializeMedia = async (video: boolean = true) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video,
        audio: true
      });
      setLocalStream(stream);
      return stream;
    } catch (err) {
      setError('Failed to access media devices');
      return null;
    }
  };

  const startCall = async (recipientId: string, video: boolean = true) => {
    try {
      const stream = await initializeMedia(video);
      if (!stream) return;

      setIsCalling(true);

      // Initialize Peer with polyfills
      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream,
        config: {
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:global.stun.twilio.com:3478' }
          ]
        }
      });

      peerRef.current = peer;

      peer.on('signal', data => {
        socketRef.current?.emit('callUser', {
          userToCall: recipientId,
          signalData: data,
          from: socketRef.current?.id
        });
      });

      peer.on('stream', stream => {
        setRemoteStream(stream);
      });

      peer.on('connect', () => {
        setIsConnected(true);
        setIsCalling(false);
      });

      peer.on('error', (err) => {
        console.error('Peer error:', err);
        setError('Connection error occurred');
      });

    } catch (err) {
      console.error('Call error:', err);
      setError('Failed to start call');
    }
  };

  const answerCall = async (incomingSignal: any, callerId: string) => {
    try {
      const stream = await initializeMedia();
      if (!stream) return;

      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream,
        config: {
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:global.stun.twilio.com:3478' }
          ]
        }
      });

      peerRef.current = peer;

      peer.on('signal', data => {
        socketRef.current?.emit('answerCall', { signal: data, to: callerId });
      });

      peer.on('stream', stream => {
        setRemoteStream(stream);
      });

      peer.on('connect', () => {
        setIsConnected(true);
      });

      peer.on('error', (err) => {
        console.error('Peer error:', err);
        setError('Connection error occurred');
      });

      peer.signal(incomingSignal);
    } catch (err) {
      console.error('Answer call error:', err);
      setError('Failed to answer call');
    }
  };

  const endCall = () => {
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
    }
    setLocalStream(null);
    setRemoteStream(null);
    setIsConnected(false);
    setIsCalling(false);
  };

  useEffect(() => {
    // For demo, we'll use a mock socket connection
    const mockSocket = {
      on: () => {},
      emit: () => {},
      disconnect: () => {},
      id: 'mock-socket-id'
    };
    socketRef.current = mockSocket as any;

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      endCall();
    };
  }, []);

  return {
    localStream,
    remoteStream,
    error,
    isConnected,
    isCalling,
    startCall,
    answerCall,
    endCall
  };
}