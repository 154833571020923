import React, { useState } from 'react';
import { 
  Globe, 
  Users, 
  TrendingUp, 
  Heart,
  Leaf,
  HandHeart,
  Star,
  BadgeCheck,
  Award,
  Sparkles,
  Gift,
  Rocket,
  Target,
  Tree,
  Lightbulb,
  Zap,
  Book,
  HeartHandshake,
  Eye,
  Plus,
  Info,
  Shield,
  ClockIcon
} from 'lucide-react';
import { SubmitProject } from '../community/SubmitProject';
import { CreatorsRewarded } from '../community/CreatorsRewarded';
import { useBalance } from '../../contexts/BalanceContext';
import { VERIFIED_MEMBERS } from '../../utils/constants';
import { SupportModal } from '../community/SupportModal';

interface Project {
  id: string;
  title: string;
  category: string;
  description: string;
  fundingGoal: number;
  currentFunding: number;
  supporters: number;
  daysLeft: number;
  impact: string;
  creator: {
    name: string;
    avatar: string;
    verified: boolean;
  };
}

const mockProjects: Project[] = [
  {
    id: '1',
    title: 'Programme de Mentorat Jeunes Entrepreneurs',
    category: 'Éducation',
    description: 'Accompagnement de 100 jeunes entrepreneurs dans le développement de leurs projets à impact.',
    fundingGoal: 25000,
    currentFunding: 18500,
    supporters: 245,
    daysLeft: 15,
    impact: 'Formation et accompagnement de 100 jeunes entrepreneurs',
    creator: {
      name: 'Marie Lambert',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=100&h=100&fit=crop',
      verified: true
    }
  },
  {
    id: '2',
    title: 'Reforestation Urbaine',
    category: 'Environnement',
    description: 'Plantation de 1000 arbres dans les zones urbaines défavorisées.',
    fundingGoal: 15000,
    currentFunding: 12000,
    supporters: 180,
    daysLeft: 20,
    impact: 'Amélioration de la qualité de l\'air et création d\'espaces verts',
    creator: {
      name: 'Lucas Dubois',
      avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
      verified: true
    }
  }
];

export function CommunitySection() {
  const { communityFundBalance } = useBalance();
  const [displayedFund, setDisplayedFund] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [showSubmitProject, setShowSubmitProject] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  const MEMBER_ALLOCATION = communityFundBalance * 0.5 / VERIFIED_MEMBERS;

  React.useEffect(() => {
    const duration = 2000;
    const steps = 60;
    const increment = communityFundBalance / steps;
    const stepDuration = duration / steps;
    let current = 0;

    const timer = setInterval(() => {
      current += increment;
      if (current >= communityFundBalance) {
        setDisplayedFund(communityFundBalance);
        clearInterval(timer);
      } else {
        setDisplayedFund(current);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, [communityFundBalance]);

  const handleSubmitProject = (project: any) => {
    console.log('New project:', project);
    setShowSubmitProject(false);
  };

  const handleSupportClick = (project: Project) => {
    setSelectedProject(project);
    setShowSupportModal(true);
  };

  const handleSupport = (amount: number) => {
    if (selectedProject) {
      console.log(`Supporting project ${selectedProject.id} with ${amount}€`);
      setShowSupportModal(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Fund Overview */}
      <div className="bg-purple-900 p-6 rounded-xl">
        <div className="flex items-center gap-3 mb-6">
          <Globe className="text-yellow-400" size={24} />
          <div>
            <h2 className="text-xl font-bold text-white">Fonds Communautaire</h2>
            <p className="text-gray-300">Impact total généré</p>
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <div className="text-center">
            <p className="text-3xl font-bold text-yellow-400">
              {Math.round(displayedFund).toLocaleString()}€
            </p>
            <p className="text-sm text-gray-300">Total collecté</p>
          </div>
          
          <div className="text-center">
            <p className="text-3xl font-bold text-green-400">
              +25%
            </p>
            <p className="text-sm text-gray-300">Croissance</p>
          </div>
          
          <div className="text-center">
            <p className="text-3xl font-bold text-blue-400">
              {VERIFIED_MEMBERS.toLocaleString()}
            </p>
            <p className="text-sm text-gray-300">Membres vérifiés</p>
          </div>
          
          <div className="text-center">
            <p className="text-3xl font-bold text-purple-400">
              25
            </p>
            <p className="text-sm text-gray-300">Projets</p>
          </div>
        </div>
      </div>

      {/* Projects Section */}
      <div className="bg-purple-900 p-6 rounded-xl">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <Rocket className="text-yellow-400" size={24} />
            <div>
              <h2 className="text-xl font-bold text-white">Projets à Impact</h2>
              <p className="text-gray-300">Découvrez et soutenez des projets innovants</p>
            </div>
          </div>
          <button
            onClick={() => setShowSubmitProject(true)}
            className="bg-yellow-400 text-purple-900 px-3 py-1.5 text-sm rounded-full font-semibold hover:bg-yellow-300 transition-colors flex items-center gap-1"
          >
            <Plus size={16} />
            <span className="hidden sm:inline">Soumettre un projet</span>
            <span className="sm:hidden">Projet</span>
          </button>
        </div>

        <div className="space-y-6">
          {mockProjects.map(project => (
            <div key={project.id} className="bg-purple-800 p-6 rounded-xl">
              <div className="flex items-start gap-4 mb-4">
                <img
                  src={project.creator.avatar}
                  alt={project.creator.name}
                  className="w-12 h-12 rounded-full"
                />
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <h3 className="font-semibold text-white">{project.creator.name}</h3>
                    {project.creator.verified && (
                      <BadgeCheck size={16} className="text-yellow-400" />
                    )}
                  </div>
                  <p className="text-sm text-gray-300">{project.category}</p>
                </div>
                <div className="flex items-center gap-2">
                  <ClockIcon size={16} className="text-yellow-400" />
                  <span className="text-sm text-gray-300">{project.daysLeft} jours restants</span>
                </div>
              </div>

              <h3 className="text-xl font-bold text-white mb-2">{project.title}</h3>
              <p className="text-gray-300 mb-4">{project.description}</p>

              <div className="space-y-4">
                <div>
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-300">Progression</span>
                    <span className="text-white">
                      {Math.round((project.currentFunding / project.fundingGoal) * 100)}%
                    </span>
                  </div>
                  <div className="w-full bg-purple-700 rounded-full h-2">
                    <div
                      className="bg-yellow-400 h-2 rounded-full"
                      style={{
                        width: `${(project.currentFunding / project.fundingGoal) * 100}%`
                      }}
                    />
                  </div>
                  <div className="flex justify-between text-sm mt-1">
                    <span className="text-white">{project.currentFunding.toLocaleString()}€</span>
                    <span className="text-gray-300">
                      sur {project.fundingGoal.toLocaleString()}€
                    </span>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-1">
                      <Users size={16} className="text-yellow-400" />
                      <span className="text-white">{project.supporters}</span>
                      <span className="text-gray-300">soutiens</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <Target size={16} className="text-yellow-400" />
                      <span className="text-white">{project.impact}</span>
                    </div>
                  </div>
                  <button 
                    onClick={() => handleSupportClick(project)}
                    className="bg-yellow-400 text-purple-900 px-3 py-1.5 text-sm rounded-full font-semibold hover:bg-yellow-300 transition-colors"
                  >
                    Soutenir
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Creators Rewarded Section */}
      <div className="bg-purple-900 p-6 rounded-xl">
        <CreatorsRewarded />
      </div>

      {showSubmitProject && (
        <SubmitProject
          onSubmit={handleSubmitProject}
          onClose={() => setShowSubmitProject(false)}
        />
      )}

      {showSupportModal && selectedProject && (
        <SupportModal
          project={selectedProject}
          onClose={() => setShowSupportModal(false)}
          onSupport={handleSupport}
        />
      )}
    </div>
  );
}