import { useState, useRef, useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';

export function useAudioPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const waveformRef = useRef<WaveSurfer | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);

  const initializeWaveform = async (audioUrl: string) => {
    if (!containerRef.current) return;

    // Cleanup previous instance
    if (waveformRef.current) {
      waveformRef.current.destroy();
    }

    // Create AudioContext
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
    }

    try {
      // Create new WaveSurfer instance
      waveformRef.current = WaveSurfer.create({
        container: containerRef.current,
        waveColor: '#9CA3AF',
        progressColor: '#EAB308',
        cursorColor: '#EAB308',
        barWidth: 2,
        barGap: 3,
        height: 48,
        responsive: true,
        audioContext: audioContextRef.current
      });

      // Load audio
      await waveformRef.current.load(audioUrl);

      // Set up event listeners
      waveformRef.current.on('ready', () => {
        setDuration(waveformRef.current?.getDuration() || 0);
      });

      waveformRef.current.on('audioprocess', () => {
        setCurrentTime(waveformRef.current?.getCurrentTime() || 0);
      });

      waveformRef.current.on('finish', () => {
        setIsPlaying(false);
      });

      waveformRef.current.on('play', () => {
        setIsPlaying(true);
      });

      waveformRef.current.on('pause', () => {
        setIsPlaying(false);
      });

    } catch (error) {
      console.error('Error initializing waveform:', error);
    }
  };

  const togglePlay = () => {
    if (waveformRef.current) {
      if (isPlaying) {
        waveformRef.current.pause();
      } else {
        waveformRef.current.play();
      }
    }
  };

  useEffect(() => {
    return () => {
      if (waveformRef.current) {
        waveformRef.current.destroy();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  return {
    isPlaying,
    duration,
    currentTime,
    containerRef,
    togglePlay,
    initializeWaveform
  };
}