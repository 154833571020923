import { 
  PLATFF_PERCENTAGE,
  COMMUNITY_FUND_PERCENTAGE,
  CASHBACK_PERCENTAGE,
  PLATFFPAY_PERCENTAGE
} from './constants';

export interface ProfitDistribution {
  platffProfit: number;
  communityFund: number;
  cashback: number;
  platffpayFund: number;
}

export function calculateProfitDistribution(saleAmount: number, profitMargin: number): ProfitDistribution {
  // Calculate total profit from sale
  const totalProfit = saleAmount * (profitMargin / 100);

  // Calculate individual amounts
  return {
    platffProfit: totalProfit * (PLATFF_PERCENTAGE / 100),
    communityFund: totalProfit * (COMMUNITY_FUND_PERCENTAGE / 100),
    cashback: totalProfit * (CASHBACK_PERCENTAGE / 100),
    platffpayFund: totalProfit * (PLATFFPAY_PERCENTAGE / 100)
  };
}