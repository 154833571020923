import React, { useState, useEffect } from 'react';
import { BookingCard } from './BookingCard';
import { FinalBookingModal } from './event/FinalBookingModal';

const mockFlights = [
  {
    id: 'flight1',
    title: 'Paris → New York',
    type: 'flight',
    image: 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?w=800&h=400&fit=crop',
    badge: { text: 'Air France', color: 'blue' },
    location: 'CDG → JFK',
    rating: 4.7,
    reviews: 328,
    description: 'Vol direct en Business. Repas gastronomique et lounge inclus.',
    price: 2499,
    duration: '8h20',
    features: ['Business Class', 'Vol direct', 'Repas inclus', 'Lounge accès'],
    provider: {
      name: 'Air France',
      logo: 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?w=50&h=50&fit=crop',
      isVerified: true
    }
  },
  {
    id: 'flight2',
    title: 'Paris → Dubai',
    type: 'flight',
    image: 'https://images.unsplash.com/photo-1464037866556-6812c9d1c72e?w=800&h=400&fit=crop',
    badge: { text: 'Emirates', color: 'yellow' },
    location: 'CDG → DXB',
    rating: 4.9,
    reviews: 456,
    description: 'First Class avec suite privée et service de chef.',
    price: 3999,
    duration: '6h45',
    features: ['First Class', 'Suite privée', 'Chef personnel', 'Spa à bord'],
    provider: {
      name: 'Emirates',
      logo: 'https://images.unsplash.com/photo-1464037866556-6812c9d1c72e?w=50&h=50&fit=crop',
      isVerified: true
    }
  }
];

interface FlightBookingProps {
  searchCriteria?: {
    departureCity: string;
    arrivalCity: string;
    checkIn: string;
    checkOut: string;
    guests: number;
  };
}

export function FlightBooking({ searchCriteria }: FlightBookingProps) {
  const [showFinalBooking, setShowFinalBooking] = useState(false);
  const [selectedFlight, setSelectedFlight] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [flights, setFlights] = useState(mockFlights);

  useEffect(() => {
    if (searchCriteria) {
      setIsLoading(true);
      // Simulate API call
      setTimeout(() => {
        setFlights(mockFlights);
        setIsLoading(false);
      }, 1500);
    }
  }, [searchCriteria]);

  const handleBooking = (flight: any) => {
    setSelectedFlight(flight);
    setShowFinalBooking(true);
  };

  const handleConfirmBooking = () => {
    console.log('Flight booking confirmed:', selectedFlight);
    setShowFinalBooking(false);
  };

  if (isLoading) {
    return (
      <div className="text-center py-12">
        <div className="w-12 h-12 border-4 border-yellow-400 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
        <p className="text-white">Recherche des meilleurs vols...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {flights.map(flight => (
        <BookingCard
          key={flight.id}
          item={flight}
          onBook={() => handleBooking(flight)}
        />
      ))}

      {showFinalBooking && selectedFlight && (
        <FinalBookingModal
          event={selectedFlight}
          onClose={() => setShowFinalBooking(false)}
          onConfirm={handleConfirmBooking}
        />
      )}
    </div>
  );
}