import React, { useState, useRef } from 'react';
import { 
  Search, 
  X, 
  ChevronRight,
  BadgeCheck,
  Ghost,
  Mic,
  Video,
  Phone,
  Languages,
  Camera,
  Image as ImageIcon,
  Smile,
  Send,
  Info,
  MoreVertical,
  Loader
} from 'lucide-react';
import { VideoCall } from './VideoCall';
import { AudioCall } from './AudioCall';
import { VerificationRequiredModal } from './VerificationRequiredModal';
import { MessageOptionsMenu, type MessageSettings } from './messaging/MessageOptionsMenu';
import { processMessage, type ProcessedMessage } from '../../utils/messageModeration';
import { ModerationAlert } from './ModerationAlert';

interface MessagesProps {
  onClose?: () => void;
}

const mockUsers = [
  {
    id: '1',
    username: 'Sophie Martin',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop',
    isOnline: true,
    status: "En train d'écrire...",
    isVerified: true,
    streak: 15
  },
  {
    id: '2',
    username: 'Lucas Dubois',
    avatar: 'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=100&h=100&fit=crop',
    isOnline: false,
    status: 'Dernière connexion il y a 5m',
    isVerified: false,
    streak: 0
  }
];

export function Messages({ onClose }: MessagesProps) {
  const [selectedUser, setSelectedUser] = useState<typeof mockUsers[0] | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [isInCall, setIsInCall] = useState<'audio' | 'video' | null>(null);
  const [messages, setMessages] = useState<ProcessedMessage[]>([]);
  const [messageInput, setMessageInput] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModerationAlert, setShowModerationAlert] = useState(false);
  const [moderatedMessage, setModeratedMessage] = useState<ProcessedMessage | null>(null);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [messageSettings, setMessageSettings] = useState<MessageSettings>({
    isEncrypted: true,
    isEphemeral: false,
    autoTranslate: false,
    expiryTime: 300000,
    targetLanguage: 'fr'
  });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUserSelect = (user: typeof mockUsers[0]) => {
    if (!user.isVerified) {
      setShowVerificationModal(true);
    } else {
      setSelectedUser(user);
    }
  };

  const handleStartCall = (type: 'audio' | 'video') => {
    if (selectedUser) {
      setIsInCall(type);
    }
  };

  const handleEndCall = () => {
    setIsInCall(null);
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!messageInput.trim() || !selectedUser || isProcessing) return;

    setIsProcessing(true);

    try {
      const processedMessage = await processMessage(messageInput, messageSettings);
      
      if (processedMessage.isModerated) {
        setModeratedMessage(processedMessage);
        setShowModerationAlert(true);
      } else {
        setMessages(prev => [...prev, processedMessage]);
        setMessageInput('');
      }
    } catch (error) {
      console.error('Error processing message:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleModerationConfirm = () => {
    if (moderatedMessage) {
      setMessages(prev => [...prev, moderatedMessage]);
      setMessageInput('');
      setModeratedMessage(null);
      setShowModerationAlert(false);
    }
  };

  if (isInCall === 'video' && selectedUser) {
    return (
      <VideoCall
        recipientId={selectedUser.id}
        onEnd={handleEndCall}
      />
    );
  }

  if (isInCall === 'audio' && selectedUser) {
    return (
      <AudioCall
        recipientId={selectedUser.id}
        recipientName={selectedUser.username}
        recipientAvatar={selectedUser.avatar}
        onEnd={handleEndCall}
      />
    );
  }

  if (!selectedUser) {
    return (
      <div className="h-full bg-gradient-to-br from-purple-900 to-purple-800 p-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold text-white">Messages</h2>
          {onClose && (
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X size={24} />
            </button>
          )}
        </div>

        <div className="relative mb-4">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher une conversation..."
            className="w-full bg-purple-800/50 backdrop-blur-sm text-white pl-10 pr-4 py-3 rounded-xl focus:ring-2 focus:ring-yellow-400"
          />
        </div>

        <div className="space-y-2">
          {mockUsers
            .filter(user => 
              user.username.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map(user => (
              <button
                key={user.id}
                onClick={() => handleUserSelect(user)}
                className="w-full flex items-center gap-3 p-4 rounded-xl hover:bg-purple-800/50 backdrop-blur-sm transition-colors"
              >
                <div className="relative">
                  <img
                    src={user.avatar}
                    alt={user.username}
                    className="w-12 h-12 rounded-full"
                  />
                  <div className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-purple-900 ${
                    user.isOnline ? 'bg-green-400' : 'bg-gray-400'
                  }`} />
                </div>
                <div className="flex-1 text-left">
                  <div className="flex items-center gap-1">
                    <span className="font-semibold text-white">{user.username}</span>
                    {user.isVerified && (
                      <BadgeCheck size={16} className="text-yellow-400" />
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="text-sm text-gray-300">{user.status}</p>
                    {user.streak > 0 && (
                      <div className="flex items-center gap-1 text-yellow-400 text-xs">
                        <Ghost size={12} />
                        <span>{user.streak}🔥</span>
                      </div>
                    )}
                  </div>
                </div>
                <ChevronRight className="text-gray-400" size={20} />
              </button>
            ))}
        </div>

        {showVerificationModal && (
          <VerificationRequiredModal onClose={() => setShowVerificationModal(false)} />
        )}
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col bg-gradient-to-br from-purple-900 to-purple-800">
      {/* Chat Header */}
      <div className="p-4 border-b border-purple-800 flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="relative">
            <img
              src={selectedUser.avatar}
              alt={selectedUser.username}
              className="w-10 h-10 rounded-full"
            />
            <div className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-purple-900 ${
              selectedUser.isOnline ? 'bg-green-400' : 'bg-gray-400'
            }`} />
          </div>
          <div>
            <div className="flex items-center gap-1">
              <h3 className="font-semibold text-white">{selectedUser.username}</h3>
              {selectedUser.isVerified && (
                <BadgeCheck size={16} className="text-yellow-400" />
              )}
            </div>
            <p className="text-sm text-gray-300">{selectedUser.status}</p>
          </div>
        </div>
        
        <div className="flex items-center gap-4">
          <button
            onClick={() => handleStartCall('audio')}
            className="text-yellow-400 hover:text-yellow-300"
          >
            <Phone size={20} />
          </button>
          <button
            onClick={() => handleStartCall('video')}
            className="text-yellow-400 hover:text-yellow-300"
          >
            <Video size={20} />
          </button>
          <div className="relative">
            <button
              onClick={() => setShowOptionsMenu(!showOptionsMenu)}
              className="text-yellow-400 hover:text-yellow-300"
            >
              <MoreVertical size={20} />
            </button>
            
            {showOptionsMenu && (
              <MessageOptionsMenu
                onClose={() => setShowOptionsMenu(false)}
                onSettingsChange={setMessageSettings}
                currentSettings={messageSettings}
              />
            )}
          </div>
          <button onClick={onClose} className="text-yellow-400 hover:text-yellow-300">
            <X size={20} />
          </button>
        </div>
      </div>

      {/* Messages Area */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map(message => (
          <div
            key={message.id}
            className={`flex ${message.sender === 'me' ? 'justify-end' : 'justify-start'}`}
          >
            <div className={`max-w-[70%] rounded-xl p-3 ${
              message.sender === 'me'
                ? 'bg-yellow-400 text-purple-900'
                : 'bg-purple-800 text-white'
            }`}>
              <p>{message.content}</p>
              {message.translations && (
                <p className="text-sm opacity-70 mt-1 italic">
                  {message.translations[messageSettings.targetLanguage]}
                </p>
              )}
              <p className="text-xs opacity-70 mt-1">
                {new Date(message.timestamp).toLocaleTimeString()}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Message Input */}
      <div className="p-4 border-t border-purple-800">
        <form onSubmit={handleSendMessage} className="flex gap-2">
          <div className="flex-1 relative">
            <input
              type="text"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              placeholder="Message..."
              className="w-full bg-purple-800 text-white rounded-full px-4 py-2 pr-12 focus:ring-2 focus:ring-yellow-400"
              disabled={isProcessing}
            />
            <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2">
              <button type="button" className="text-gray-400 hover:text-white">
                <Smile size={20} />
              </button>
              <button type="button" className="text-gray-400 hover:text-white">
                <Camera size={20} />
              </button>
              <button type="button" className="text-gray-400 hover:text-white">
                <Mic size={20} />
              </button>
            </div>
          </div>
          <button
            type="submit"
            disabled={isProcessing || !messageInput.trim()}
            className={`bg-yellow-400 text-purple-900 p-2 rounded-full ${
              isProcessing || !messageInput.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-300'
            }`}
          >
            {isProcessing ? (
              <Loader className="animate-spin" size={20} />
            ) : (
              <Send size={20} />
            )}
          </button>
        </form>
      </div>

      {showModerationAlert && moderatedMessage && (
        <ModerationAlert
          detectedWords={moderatedMessage.detectedWords}
          onClose={handleModerationConfirm}
        />
      )}
    </div>
  );
}