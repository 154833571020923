import React, { useState, useRef, useEffect } from 'react';
import { Heart, MessageCircle, Share2, Gift, BadgeCheck, TrendingUp, Bookmark } from 'lucide-react';
import { Comments } from './Comments';
import { ShareButton } from '../shared/ShareButton';
import { RewardModal } from './creator/RewardModal';
import type { Post as PostType } from '../../types/social';
import { VideoPlayer } from './VideoPlayer';

interface PostProps {
  post: PostType;
}

export function Post({ post }: PostProps) {
  const [isLiked, setIsLiked] = useState(post.isLiked);
  const [likesCount, setLikesCount] = useState(post.likes);
  const [showComments, setShowComments] = useState(false);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [comments, setComments] = useState<any[]>([]);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, []);

  const handleLike = () => {
    setIsLiked(!isLiked);
    setLikesCount(prev => isLiked ? prev - 1 : prev + 1);
  };

  const handleRewardClick = () => {
    setShowRewardModal(true);
  };

  const handleReward = (amount: number) => {
    console.log(`Rewarded ${amount}€ for post:`, post.id);
    setShowRewardModal(false);
  };

  return (
    <article className="bg-purple-900 rounded-xl">
      {/* User Info */}
      <div className="p-4 flex items-center gap-3">
        <img
          src={post.userAvatar}
          alt={post.username}
          className="w-10 h-10 rounded-full"
        />
        <div className="flex-1">
          <div className="flex items-center gap-1">
            <h3 className="font-semibold text-white">{post.username}</h3>
            {post.isVerified && (
              <BadgeCheck size={16} className="text-yellow-400" />
            )}
          </div>
          <p className="text-sm text-gray-300">
            {new Date(post.createdAt).toLocaleDateString()}
          </p>
        </div>
      </div>
      
      <p className="px-4 mb-4 text-white whitespace-pre-wrap break-words text-sm sm:text-base">
        {post.content}
      </p>
      
      {post.mediaUrl && (
        <div className="mb-4">
          {post.mediaType === 'image' ? (
            <img
              src={post.mediaUrl}
              alt=""
              className="w-full aspect-video object-cover"
            />
          ) : post.mediaType === 'video' && (
            <VideoPlayer src={post.mediaUrl} />
          )}
        </div>
      )}

      <div className="p-4 border-t border-purple-800">
        <div className="flex flex-wrap justify-between mb-2 gap-2">
          <div className="flex flex-wrap gap-4">
            <button 
              onClick={handleLike}
              className={`flex items-center gap-1 ${isLiked ? 'text-red-500' : 'text-white'} text-sm`}
            >
              <Heart size={18} fill={isLiked ? 'currentColor' : 'none'} />
              <span>{likesCount}</span>
            </button>
            <button 
              onClick={() => setShowComments(!showComments)}
              className="flex items-center gap-1 text-white text-sm"
            >
              <MessageCircle size={18} />
              <span>{post.comments}</span>
            </button>
            <ShareButton 
              title={`Publication de ${post.username} sur PLATFF`}
              description={post.content}
            />
            {post.isVerified && (
              <button 
                onClick={handleRewardClick}
                className="flex items-center gap-1 text-yellow-400 text-sm"
              >
                <Gift size={18} />
                <span className="hidden sm:inline">Récompenser</span>
              </button>
            )}
          </div>
          <button className="text-white">
            <Bookmark size={18} />
          </button>
        </div>

        {/* Community Impact */}
        {post.communityImpact && (
          <div className="p-3 border-t border-purple-800">
            <div className="flex items-center gap-2 mb-2">
              <TrendingUp size={16} className="text-yellow-400" />
              <span className="text-white text-sm font-medium">Impact Communautaire</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              <div>
                <p className="text-xs sm:text-sm text-green-400">Cashback gagné</p>
                <p className="text-base sm:text-lg font-bold text-white">
                  +{post.communityImpact.cashback} €
                </p>
              </div>
              <div>
                <p className="text-xs sm:text-sm text-blue-400">Contribution communautaire</p>
                <p className="text-base sm:text-lg font-bold text-white">
                  +{post.communityImpact.communityFund} €
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Comments */}
        {showComments && (
          <Comments
            postId={post.id}
            comments={comments}
            onAddComment={(content) => {
              setComments([
                {
                  id: Date.now().toString(),
                  content,
                  username: 'You',
                  createdAt: new Date().toISOString(),
                  likes: 0
                },
                ...comments
              ]);
            }}
          />
        )}
      </div>

      {/* Reward Modal */}
      {showRewardModal && (
        <RewardModal
          creatorName={post.username}
          contentTitle={post.content.substring(0, 50) + '...'}
          onReward={handleReward}
          onClose={() => setShowRewardModal(false)}
        />
      )}
    </article>
  );
}