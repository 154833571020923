import React, { useState } from 'react';
import { Search, TrendingUp, Star, Filter, SlidersHorizontal, BadgeCheck, Heart, Share2, Sparkles, Shield, Tag } from 'lucide-react';
import { CategoryFilter } from '../shop/CategoryFilter';
import { ProductCard } from '../shop/ProductCard';
import { mockProducts } from '../../data/mockProducts';
import type { Product, ProductCategory } from '../../types/shop';

const sortOptions = [
  { id: 'popular', label: 'Populaires' },
  { id: 'recent', label: 'Récents' },
  { id: 'priceAsc', label: 'Prix croissant' },
  { id: 'priceDesc', label: 'Prix décroissant' },
  { id: 'cashback', label: 'Meilleur cashback' }
];

const conditions = [
  { id: 'new', label: 'Neuf', icon: Sparkles },
  { id: 'refurbished', label: 'Reconditionné', icon: Shield },
  { id: 'used', label: 'Occasion', icon: Tag }
];

export function ShopSection() {
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('popular');
  const [showFilters, setShowFilters] = useState(false);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 2000]);
  const [selectedConditions, setSelectedConditions] = useState<string[]>(['new']);
  const [showSortOptions, setShowSortOptions] = useState(false);

  const filteredProducts = mockProducts.filter(product => {
    const matchesCategory = !selectedCategory || product.category === selectedCategory;
    const matchesSubcategory = !selectedSubcategory || product.subcategory === selectedSubcategory;
    const matchesSearch = !searchQuery || 
      product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesPrice = product.price >= priceRange[0] && product.price <= priceRange[1];
    const matchesCondition = selectedConditions.length === 0 || selectedConditions.includes(product.condition);
    return matchesCategory && matchesSubcategory && matchesSearch && matchesPrice && matchesCondition;
  });

  const sortedProducts = [...filteredProducts].sort((a, b) => {
    switch (sortBy) {
      case 'priceAsc':
        return a.price - b.price;
      case 'priceDesc':
        return b.price - a.price;
      case 'cashback':
        return (b.originalPrice || b.price) - b.price - ((a.originalPrice || a.price) - a.price);
      default:
        return b.reviews - a.reviews;
    }
  });

  return (
    <div className="max-w-6xl mx-auto p-4 space-y-6">
      {/* Header with Stats */}
      <div className="bg-purple-900 p-6 rounded-xl mb-6">
        <div className="grid grid-cols-3 gap-4 mb-6">
          <div className="text-center">
            <div className="flex items-center justify-center gap-2 text-yellow-400 mb-2">
              <Star size={20} />
              <span className="text-lg font-bold">4.9/5</span>
            </div>
            <p className="text-gray-300 text-sm">Note moyenne</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center gap-2 text-yellow-400 mb-2">
              <TrendingUp size={20} />
              <span className="text-lg font-bold">15K+</span>
            </div>
            <p className="text-gray-300 text-sm">Produits</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center gap-2 text-yellow-400 mb-2">
              <Star size={20} />
              <span className="text-lg font-bold">98%</span>
            </div>
            <p className="text-gray-300 text-sm">Satisfaction</p>
          </div>
        </div>

        {/* Search Bar */}
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher un produit..."
            className="w-full bg-purple-800 text-white pl-10 pr-4 py-3 rounded-xl focus:ring-2 focus:ring-yellow-400 transition-all"
          />
        </div>
      </div>

      {/* Product Conditions */}
      <div className="grid grid-cols-3 gap-4">
        {conditions.map(condition => (
          <button
            key={condition.id}
            onClick={() => {
              if (selectedConditions.includes(condition.id)) {
                setSelectedConditions(selectedConditions.filter(c => c !== condition.id));
              } else {
                setSelectedConditions([...selectedConditions, condition.id]);
              }
            }}
            className={`bg-purple-900 p-4 rounded-xl flex flex-col items-center gap-2 transition-all ${
              selectedConditions.includes(condition.id)
                ? 'ring-2 ring-yellow-400'
                : 'hover:bg-purple-800'
            }`}
          >
            <condition.icon className="text-yellow-400" size={24} />
            <span className="text-white font-medium">{condition.label}</span>
          </button>
        ))}
      </div>

      {/* Filters and Sort */}
      <div className="flex items-center justify-between gap-4 flex-wrap">
        <div className="flex items-center gap-2">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="bg-purple-900 text-white px-4 py-2 rounded-xl flex items-center gap-2 hover:bg-purple-800 transition-colors"
          >
            <Filter size={20} />
            Filtres
          </button>
          <div className="relative">
            <button
              onClick={() => setShowSortOptions(!showSortOptions)}
              className="bg-purple-900 text-white px-4 py-2 rounded-xl flex items-center gap-2 hover:bg-purple-800 transition-colors"
            >
              <SlidersHorizontal size={20} />
              Trier
            </button>
            {showSortOptions && (
              <div className="absolute top-full mt-2 left-0 bg-purple-900 rounded-xl shadow-lg p-2 z-10 min-w-[200px]">
                {sortOptions.map(option => (
                  <button
                    key={option.id}
                    onClick={() => {
                      setSortBy(option.id);
                      setShowSortOptions(false);
                    }}
                    className={`w-full text-left px-4 py-2 rounded-lg ${
                      sortBy === option.id ? 'bg-yellow-400 text-purple-900' : 'text-white hover:bg-purple-800'
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Categories */}
      <CategoryFilter
        selectedCategory={selectedCategory}
        selectedSubcategory={selectedSubcategory}
        onSelectCategory={setSelectedCategory}
        onSelectSubcategory={setSelectedSubcategory}
      />

      {/* Filters Panel */}
      {showFilters && (
        <div className="bg-purple-900 p-6 rounded-xl mb-6">
          <h3 className="text-white font-semibold mb-4">Filtres avancés</h3>
          <div className="space-y-4">
            <div>
              <label className="text-white mb-2 block">Prix</label>
              <div className="flex items-center gap-4">
                <input
                  type="range"
                  min="0"
                  max="2000"
                  value={priceRange[0]}
                  onChange={(e) => setPriceRange([Number(e.target.value), priceRange[1]])}
                  className="w-full"
                />
                <input
                  type="range"
                  min="0"
                  max="2000"
                  value={priceRange[1]}
                  onChange={(e) => setPriceRange([priceRange[0], Number(e.target.value)])}
                  className="w-full"
                />
              </div>
              <div className="flex justify-between text-gray-300 text-sm mt-2">
                <span>{priceRange[0]}€</span>
                <span>{priceRange[1]}€</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Products Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedProducts.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>

      {sortedProducts.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-300 text-lg">Aucun produit ne correspond à vos critères</p>
        </div>
      )}
    </div>
  );
}