import React, { useState } from 'react';
import { 
  Search, 
  Calendar, 
  MapPin, 
  Users, 
  Clock, 
  Plane, 
  Train, 
  Car,
  Coffee,
  Music,
  Hotel,
  ArrowRight,
  Sparkles,
  Info
} from 'lucide-react';

interface BookingWidgetProps {
  onSearch: (criteria: any) => void;
}

export function BookingWidget({ onSearch }: BookingWidgetProps) {
  const [type, setType] = useState('hotel');
  const [location, setLocation] = useState('');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [guests, setGuests] = useState(2);
  const [departureCity, setDepartureCity] = useState('');
  const [arrivalCity, setArrivalCity] = useState('');
  const [departureTime, setDepartureTime] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSearching(true);

    const searchCriteria = {
      type,
      location: type === 'flight' || type === 'train' ? departureCity : location,
      checkIn,
      checkOut,
      guests,
      departureCity,
      arrivalCity,
      departureTime
    };

    // Simulate search delay
    await new Promise(resolve => setTimeout(resolve, 1500));
    onSearch(searchCriteria);
    setIsSearching(false);
  };

  const services = [
    { id: 'hotel', icon: Hotel, label: 'Hôtels' },
    { id: 'flight', icon: Plane, label: 'Vols' },
    { id: 'train', icon: Train, label: 'Trains' },
    { id: 'car', icon: Car, label: 'Location' },
    { id: 'restaurant', icon: Coffee, label: 'Restaurants' },
    { id: 'event', icon: Music, label: 'Événements' }
  ];

  const renderTypeSpecificFields = () => {
    switch (type) {
      case 'flight':
      case 'train':
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="text-white text-sm mb-1 block">
                {type === 'flight' ? 'Ville de départ' : 'Gare de départ'}
              </label>
              <div className="relative">
                {type === 'flight' ? (
                  <Plane className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                ) : (
                  <Train className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                )}
                <input
                  type="text"
                  value={departureCity}
                  onChange={(e) => setDepartureCity(e.target.value)}
                  placeholder={type === 'flight' ? 'Paris CDG...' : 'Paris Gare de Lyon...'}
                  className="w-full bg-purple-800 text-white pl-10 pr-4 py-3 rounded-xl focus:ring-2 focus:ring-yellow-400"
                />
              </div>
            </div>
            <div>
              <label className="text-white text-sm mb-1 block">
                {type === 'flight' ? 'Ville d\'arrivée' : 'Gare d\'arrivée'}
              </label>
              <div className="relative">
                {type === 'flight' ? (
                  <Plane className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 rotate-90" size={20} />
                ) : (
                  <Train className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                )}
                <input
                  type="text"
                  value={arrivalCity}
                  onChange={(e) => setArrivalCity(e.target.value)}
                  placeholder={type === 'flight' ? 'New York JFK...' : 'Lyon Part-Dieu...'}
                  className="w-full bg-purple-800 text-white pl-10 pr-4 py-3 rounded-xl focus:ring-2 focus:ring-yellow-400"
                />
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <label className="text-white text-sm mb-1 block">Destination</label>
            <div className="relative">
              <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Ville ou région"
                className="w-full bg-purple-800 text-white pl-10 pr-4 py-3 rounded-xl focus:ring-2 focus:ring-yellow-400"
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="bg-purple-900 p-6 rounded-xl">
      {/* Service Type Selection */}
      <div className="grid grid-cols-3 sm:grid-cols-6 gap-4 mb-6">
        {services.map(service => (
          <button
            key={service.id}
            onClick={() => setType(service.id)}
            className={`flex flex-col items-center gap-2 p-3 rounded-xl transition-colors ${
              type === service.id
                ? 'bg-yellow-400 text-purple-900'
                : 'bg-purple-800 text-white hover:bg-purple-700'
            }`}
          >
            <service.icon size={24} />
            <span className="text-sm whitespace-nowrap">{service.label}</span>
          </button>
        ))}
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Search Form */}
        <div className="space-y-4">
          {renderTypeSpecificFields()}

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div>
              <label className="text-white text-sm mb-1 block">
                {type === 'flight' ? 'Date aller' : type === 'car' ? 'Date de début' : 'Arrivée'}
              </label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="date"
                  value={checkIn}
                  onChange={(e) => setCheckIn(e.target.value)}
                  min={new Date().toISOString().split('T')[0]}
                  className="w-full bg-purple-800 text-white pl-10 pr-4 py-3 rounded-xl focus:ring-2 focus:ring-yellow-400"
                />
              </div>
            </div>

            <div>
              <label className="text-white text-sm mb-1 block">
                {type === 'flight' ? 'Date retour' : type === 'car' ? 'Date de fin' : 'Départ'}
              </label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="date"
                  value={checkOut}
                  onChange={(e) => setCheckOut(e.target.value)}
                  min={checkIn || new Date().toISOString().split('T')[0]}
                  className="w-full bg-purple-800 text-white pl-10 pr-4 py-3 rounded-xl focus:ring-2 focus:ring-yellow-400"
                />
              </div>
            </div>

            <div>
              <label className="text-white text-sm mb-1 block">
                {type === 'flight' || type === 'train' ? 'Passagers' : 'Voyageurs'}
              </label>
              <div className="relative">
                <Users className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="number"
                  min="1"
                  value={guests}
                  onChange={(e) => setGuests(Number(e.target.value))}
                  className="w-full bg-purple-800 text-white pl-10 pr-4 py-3 rounded-xl focus:ring-2 focus:ring-yellow-400"
                />
              </div>
            </div>
          </div>

          {/* Info Box */}
          <div className="bg-purple-800 p-4 rounded-xl flex items-start gap-3">
            <Info className="text-yellow-400 flex-shrink-0 mt-1" size={20} />
            <p className="text-sm text-gray-300">
              Notre algorithme analyse en temps réel les prix pour vous garantir le meilleur tarif.
              Profitez du cashback instantané et contribuez au Fonds Communautaire.
            </p>
          </div>

          <button
            type="submit"
            disabled={isSearching}
            className="w-full bg-yellow-400 text-purple-900 py-3 rounded-xl font-semibold hover:bg-yellow-300 transition-colors flex items-center justify-center gap-2"
          >
            {isSearching ? (
              <>
                <div className="w-5 h-5 border-2 border-purple-900 border-t-transparent rounded-full animate-spin" />
                Recherche en cours...
              </>
            ) : (
              <>
                <Search size={20} />
                Rechercher
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}