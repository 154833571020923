import Filter from 'bad-words';

// English bad words filter
const englishFilter = new Filter();

// Extended French bad words list
const frenchBadWords = [
  'merde', 'putain', 'connard', 'connasse', 'salop', 'salope', 'enculé',
  'encule', 'pute', 'bite', 'couille', 'nique', 'niquer', 'foutre',
  'pd', 'fdp', 'fils de pute', 'batard', 'bâtard', 'cul', 'bordel',
  'chier', 'con', 'conne', 'pétasse', 'poufiasse', 'branler', 'crétin',
  'débile', 'ducon', 'emmerdeur', 'enfoiré', 'garce', 'gueule', 'pédé',
  'pédale', 'salaud', 'salopard', 'tapette', 'trou du cul', 'va te faire'
];

// Fruits for replacement (with emojis)
const fruits = [
  '🍎 pomme', '🍌 banane', '🍊 orange', '🍇 raisin', '🍓 fraise',
  '🥝 kiwi', '🍐 poire', '🍑 pêche', '🍒 cerise', '🥭 mangue'
];

function getRandomFruit(): string {
  return fruits[Math.floor(Math.random() * fruits.length)];
}

export async function moderateContent(text: string): Promise<{
  isClean: boolean;
  moderatedText: string;
  detectedWords: string[];
  detectedIssues: string[];
}> {
  // Simulate AI processing time
  await new Promise(resolve => setTimeout(resolve, 200));

  const detectedWords: string[] = [];
  const detectedIssues: string[] = [];
  let moderatedText = text;
  let isClean = true;

  // Check English bad words
  if (englishFilter.isProfane(text)) {
    isClean = false;
    detectedIssues.push('Langage inapproprié détecté (English)');
    const words = text.split(' ');
    words.forEach(word => {
      if (englishFilter.isProfane(word)) {
        detectedWords.push(word);
      }
    });
    moderatedText = text.split(' ').map(word => 
      englishFilter.isProfane(word) ? getRandomFruit() : word
    ).join(' ');
  }

  // Check French bad words
  frenchBadWords.forEach(word => {
    const regex = new RegExp(`\\b${word}\\b`, 'gi');
    if (regex.test(moderatedText)) {
      isClean = false;
      detectedIssues.push('Langage inapproprié détecté (Français)');
      detectedWords.push(word);
      moderatedText = moderatedText.replace(regex, getRandomFruit());
    }
  });

  return {
    isClean,
    moderatedText,
    detectedWords,
    detectedIssues
  };
}